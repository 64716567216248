import { useState } from "react";
import { Box } from "@mui/system";
import { Menu, MenuItem, Typography } from "@mui/material";
import { t } from "locales";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const Dropdown = ({ options, value, onChange, initialValue }) => {
  const [inValue, setInValue] = useState(initialValue || value || options?.[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeHandler = (newValue) => {
    onChange?.(newValue);
    setInValue(newValue);

    handleClose();
  };

  // useEffect(() => {
  //   if (!value && !!onChange) {
  //     onChange(options?.[0]);
  //   }
  // }, [options, value]);

  return (
    <Box>
      <Box
        id="basic-button"
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "7px 10px 7px 20px",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "#2a2e34" : "#eee",
          borderRadius: "7px",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold",
          }}
        >
          {t(value?.title || inValue?.title)}
        </Typography>
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((item) => (
          <MenuItem
            key={item?.title}
            sx={{
              bgcolor: (theme) =>
                item?.value === value?.value &&
                (theme.palette.mode === "dark" ? "#555" : "#eee"),
            }}
            onClick={() => changeHandler(item)}
          >
            {t(item?.title)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default Dropdown;
