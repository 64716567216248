import { CssBaseline, Drawer } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { toggleWalletSidebar } from "store/reducers/app/appSlice";
import Content from "./Content";

const drawerExpandedWidth = 420;
const drawerStyle = {
  width: { xs: "100vw", md: drawerExpandedWidth },
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: {
    right: "0 !important",
    top: "64px !important",
    width: { xs: "100vw", md: drawerExpandedWidth },
    boxSizing: "border-box",
    backgroundImage: "none",
    backgroundColor: "background.default",
  },
};

const WalletSidebar = () => {
  const walletSidebar = useSelector((state) => state?.app?.walletSidebar);
  const dispatch = useDispatch();

  const toggleHandler = () => dispatch(toggleWalletSidebar());

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CssBaseline />

      <Drawer
        variant="temporary"
        open={walletSidebar}
        onClose={toggleHandler}
        anchor="right"
        sx={{
          ...drawerStyle,
          ".MuiListItemText-primary": {
            textAlign: (theme) => theme.direction === "rtl" && "right",
          },
          "& > .MuiPaper-root": {
            height: "calc(100% - 64px)",
          },
        }}
      >
        <Content toggleHandler={toggleHandler} />
      </Drawer>
    </Box>
  );
};

export default WalletSidebar;
