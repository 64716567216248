import React from 'react';
import banner from "assets/images/tradeBanner.jpg";
import { Avatar, Box, Skeleton, Stack, Typography } from '@mui/material';
import { getImageUrl } from 'library/helper';

const Header = ({data,loading}) => {
    return (
        <Box sx={{ background: `url(${banner})`, backgroundSize: "cover", backgroundPosition: "center" }}>
            <Box classname="container">
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    py: 10,
                }}>
                    {loading ? 
                    <Stack gap={1}>
                        <Skeleton variant='circular' width={128} height={128} />
                        <Skeleton variant='rectangular' width={128} height={42} sx={{borderRadius: 3}} />
                        <Skeleton variant='rectangular' width={128} height={16} sx={{borderRadius: 3}} />
                    </Stack>
                    :
                    <>
                        <Avatar src={getImageUrl(data?.traders?.trader?.avatar)} sx={{width: 128, height: 128}} alt="" />
                        <Typography sx={{color: '#FFF', fontSize: 42, fontWeight: 500, }}>{data?.traders?.trader?.name}</Typography>
                        <Typography sx={{color: '#FFF', fontWeight: 300, lineHeight: 1 }}>{data?.traders?.description}</Typography>
                    </>
                }
                </Box>
            </Box>
        </Box>
    );
};

export default Header;