import { Typography, Stack, Grid } from "@mui/material";
import { t } from "locales";
import Traders from "./Traders";

const TradersInfo = () => {
  return (
    <Grid container spacing={6} sx={{mt: 16}}>
      <Grid item xs={12} md={6}>
        <Traders />
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack
          direction="column"
          alignItems={{ xs: "center", md: "flex-start" }}
          sx={{
            "& > *": {
              textAlign: { xs: "center", md: "unset" },
            },
            width: { xs: "100%", md: "90%" },
            img: { width: "48px", height: "48px" },
          }}
        >
          <Typography
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="100"
            sx={{
              color: (theme) => theme.palette.primary.contrastText,
              fontSize: 24,
              fontWeight: 700,
              "& span": {
                color: (theme) => theme.palette.primary.main,
              },
            }}
          >
            Top traders are monitoring the performance of the AI bot and makes it 100% <span>RISK-FREE!</span>
          </Typography>
          <Typography 
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="150"
            variant="body1" 
            fontWeight={300} 
            mt={3}
          >
            {t("tradersInfoDesc")}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TradersInfo;
