export const styles = {
    boxWrapper: { 
        mt: 4,
        display: "flex",
        flexDirection: "column",
        bgcolor: "#fff",
        border: "1px solid rgba(0,0,0,0.1)",
        boxShadow: "none",
        borderRadius: "24px",
        p: {xs: 2, md: 3},
        transition: "all 0.2s ease-out",
        '&:hover': {
            border: "1px solid transparent",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
    },
    value: {
        color: 'primary.main',
        fontSize: 32,
        fontWeight: 600,
        letterSpacing: '24%',
        lineHeight: '43.86px',
    },
    title: {
        fontSize: 15,
        fontWeight: 500,
        lineHeight: '18px',
        color: '#5E6282',
        textTransform: 'capitalize',
        textAlign: 'center',
    },
};