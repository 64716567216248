export const styles = {
    imageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        '& img': {
            width: '100%',
            maxWidth: 320,
        },
    },
    wrapper: {
        mb: 12,
    },
    titletext: {
        fontWeight: "700",
        fontSize: {
            xs: "1.6rem",
            sm: "2rem",
            md: "2.2rem",
            lg: "2.6rem",
            xl: "2.9rem",
        },
        color: (theme) => theme.palette.primary.contrastText,
        lineHeight: 1.4,
        "& span": {
            color: (theme) => theme.palette.primary.main,
        },
    },
    descriptionText: {
        mt: 2,
        color: "#5E6282",
    },
    button: {
        display: "inline-block",
        borderRadius: 2.4,
        p: {xs: "6px 12px", lg: "9px 18px"},
        mt: 3,
        fontSize: {xs: 12, lg: 14},
        bgcolor: "primary.main",
        border: "1px solid transparent",
        color: '#FFF',
        textDecoration: 'none',
        '&:hover': {
            borderColor: 'primary.main',
            color: 'primary.main',
        },
    }
};