import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { t } from "locales";
import { Avatar, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import SubscribeModal from "../SubscribeModal";
import usdtIcon from "assets/icons/cryptoicon/usdt.png";
import { useNavigate } from "react-router";
import { XTAKER_COIN } from "library/constants";
import { getImageUrl } from "library/helper";

export default function CustomTable({ columns, rows }) {
  const isAuth = useSelector((state) => !!state.auth.user);
  const modal = useSelector((state) => state.app.modal);
  const navigate = useNavigate();

  const onSubscribe = (id, title, imageUrl) => {
    if (!isAuth) {
      navigate("/auth/register");

      return;
    }

    modal?.hide?.();
    modal.show(<SubscribeModal closeModal={modal.hide} id={id} title={title} image={getImageUrl(imageUrl)} key={id} />);
  };

  return (
    <Box >
      <Box aria-label="table" component="div" 
        sx={{
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          borderRadius: "24px",
          overflowX: "auto",
          mt: 3,
        }}>
        <Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "flex-end",
              justifyContent: "space-between",
              padding: "8px 16px",
              margin: "0 8px",
            }}
          >
            {columns.map((column) =>
              column.id === "icon" ? null : (
                <Box
                  component="div"
                  key={column.id}
                  align={column.align}
                  sx={{
                    minWidth: column?.minWidth || 32,
                    maxWidth: column.maxWidth,
                    width: column.width ? column.width : "100px",
                    backgroundColor: "none",

                    pb: 1,
                  }}
                >
                  <Typography variant="body2" fontWeight="500" sx={{ opacity: "0.5" }}>
                    {t(column.label)}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </Box>
        <Box component="div"
        >
          {rows.map((row) => {
            return (
              <Box
                key={row.id}
                component="div"
                hover
                role="checkbox"
                tabIndex={-1}
                sx={{
                  border: "none",
                  borderBottom: "1px solid",
                  borderColor: (theme) => (theme.palette.mode === "dark" ? "#cecece16" : "#3d3d3d12"),
                  borederRadius: 4,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: 3,
                  py: 3,
                  margin: "0 8px",
                }}
              >
                {columns.map((column, index) => {
                  const value = row[column.id];

                  if (column.id === "button") {
                    return (
                      <Box
                        component="div"
                        sx={{
                          textAlign: { xs: "start", md: "end" },
                          minWidth: column?.minWidth || 32,
                          maxWidth: column.maxWidth,
                          width: {
                            xs: "100%",
                            md: column.width ? column.width : "100px",
                          },
                        }}
                        key={index}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            fontWeight: "400",
                            px: 3,
                            background: "#222",
                            color: "#FFF",
                            borderRadius: 2.4,
                            width: { xs: "100%", md: "auto" },
                            mt: { xs: 1, md: 0 },
                          }}
                          onClick={() => {
                            onSubscribe(row?.id, row?.name, row?.icon);
                          }}
                        >
                          {t("Subscribe")}
                        </Button>
                      </Box>
                    );
                  }
                  if (column.id === "duration") {
                    return (
                      <Box
                        component="div"
                        key={column.id + index}
                        align={column.align}
                        sx={{
                          minWidth: column?.minWidth || 32,
                          maxWidth: column.maxWidth,
                          width: column.width ? column.width : "100px",
                          mb: 1,
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={[
                            true && {
                              background: (theme) => (theme.palette.mode === "dark" ? "#cecece16" : theme.palette.secondary.light),
                              color: "#000",
                              "&:hover": {
                                color: "#fff",
                              },
                            },
                            {
                              borderRadius: 2.4,
                              py: "2px",
                              width: "fit-content",
                              minWidth: "40px",
                            },
                          ]}
                        >
                          {value}
                        </Button>
                      </Box>
                    );
                  }
                  if (column.id === "name")
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          maxWidth: "178px",
                          gap: 1,
                          mb: { xs: 1, md: 0 },
                        }}
                        key={index}
                      >
                        <Box
                          component="div"
                          sx={{
                            maxWidth: 36,
                            img: {
                              width: 32,
                              height: 32,
                              borderRadius: "50%",
                              display: "flex",
                              objectFit: "contain",
                            },
                          }}
                          key={index}
                        >
                          <Avatar src={row?.icon || usdtIcon} alt="" />
                        </Box>
                        <Box
                          component="div"
                          key={column.id + index}
                          align={column.align}
                          sx={{
                            // minWidth: column?.minWidth || 32,
                            maxWidth: column.maxWidth,
                            width: column.width ? column.width : "100px",
                            ml: 1,
                          }}
                        >
                          <Typography variant="body1" fontWeight="500">
                            {value}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  if (column.id === "icon") {
                    return null;
                  }
                  if (column.id === "interest") {
                    return (
                      <Box
                        component="div"
                        key={column.id + index}
                        align={column.align}
                        sx={{
                          minWidth: column?.minWidth || 32,
                          maxWidth: column.maxWidth,
                          width: column.width ? column.width : "100px",
                        }}
                        mb={1}
                      >
                        <Typography variant="body1" fontWeight="500">
                          {column.format && typeof value === "number" ? column.format(value) : value}%
                        </Typography>
                      </Box>
                    );
                  }
                  if (column.id === "invesment") {
                    return (
                      <Box
                        component="div"
                        key={column.id + index}
                        align={column.align}
                        sx={{
                          minWidth: column?.minWidth || 32,
                          maxWidth: column.maxWidth,
                          width: column.width ? column.width : "100px",
                        }}
                        mb={1}
                      >
                        <Typography variant="body1" fontWeight="500">
                          {column.format && typeof value === "number" ? column.format(value) : value} {XTAKER_COIN}
                        </Typography>
                      </Box>
                    );
                  } else {
                    return null;
                  }
                })}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
