export const styles  = {
    wrapper: {
        my: 10
    },
    iconWrapper: {
        '& svg': {
            width: 38,
            height: 38,
            fill: (theme) => theme.palette.primary.main
        },
        "& div": {
            height: "100%",
            width: "1px",
            bgcolor: (theme) => theme.palette.primary.light
        }
    },
    textsWrapper: {
        pt: 3
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
    },
    description: {
        fontSize: 14,
        minHeight: {xs: "auto", md:120},
    },
};