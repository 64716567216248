import { useInfiniteQuery, useQuery } from "react-query";
import ApiCall from "_clients/apiCall";
import { calcTotalPages } from "library/helper";

const limit = 10;
export const useGetPublicAuctionInfinite = (params = {}) => {
  return useInfiniteQuery(
    ["public-auction", params],
    ({ pageParam = 1 }) =>
      ApiCall(
        "GET",
        "public/auction",
        null,
        { ...params, page: pageParam, limit },
        (res) => ({
          list: res.data?.data?.data,
          total: calcTotalPages(res.data?.data?.total, limit),
          count: res.data?.data?.total,
        })
      ),

    {
      getPreviousPageParam: (firstPage) => firstPage.previousId ?? undefined,
      getNextPageParam: (lastPage, pages) => {
        return lastPage?.total === pages.length ? undefined : pages.length + 1;
      },
    }
  );
};
export const useGetMainPageTickets = (params) => {
  return useQuery(["main-page-tickets", params], () =>
    ApiCall("GET", `public/tickets`, null, params, (res) => ({
      list: res.data?.data,
      total: calcTotalPages(res.data?.total, res.data?.pageSize),
    }))
  );
};
export const useGetLeaderBoardsAll = (params) => {
  return useQuery(["leaderboard-all", params], () =>
    ApiCall("GET", `public/rates`, null, params, (res) => ({
      list: res.data?.data?.data,
      total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
    }))
  );
};
export const useGetRefferal = (params) => {
  return useQuery(["refferal", params], () =>
    ApiCall("GET", `user/referral`, null, params, (res) => ({
      data: res.data?.data,
    }))
  );
};
export const useGetRefferalHistory = (params) => {
  return useQuery(["refferal-history", params], () =>
    ApiCall("GET", `user/referral/history`, null, params, (res) => ({
      list: res.data?.data?.data,
      total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
    }))
  );
};
