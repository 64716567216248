import { Typography, Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { useSelector } from "react-redux";
import affiliation from "assets/images/affiliation.png";

const Sidebar = ({ refferal = {} }) => {
  const modal = useSelector((state) => state.app.modal);
  return (
    <Box
      sx={{
        p: "20px",
        borderRadius: "24px",
        bgcolor: (theme) => (theme.palette.mode === "dark" ? "card.dark" : "card.light"),
        border: "1px solid",
        borderColor: (theme) => (theme.palette.mode === "dark" ? "transparent" : "#eee"),
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box mt={2}>
          <Typography variant="h6" fontWeight="600">
            Friends Invited: {refferal?.data?.friendsInvited}
          </Typography>
          <Typography variant="h6" fontWeight="600" mt={3}>
            Referral Reward: {refferal?.data?.prize || 0}
          </Typography>
          <Box
            sx={{
              mt: 3,
            }}
          >
            <Typography
              variant="body2"
              fontWeight="300"
              sx={{
                opacity: 0.8,
              }}
            >
              You'll be earning a portion of your referral's profit on Tradergpt. Click the button below to see the exact percentages you'll get on each level.
            </Typography>
          </Box>
          <Box mt={3}>
            <Button
              variant="contained"
              onClick={() => {
                modal.show(
                  <Box
                    sx={{
                      maxWidth: 650,
                      "& img": {
                        width: "100%",
                      },
                    }}
                  >
                    <img src={affiliation} alt="flowchart" />
                  </Box>,
                  false,
                  true,
                  true
                );
              }}
              sx={{ ">svg": { fill: "white" }, "&:hover svg": { fill: "white" } }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <AccountTreeOutlinedIcon />
                <Typography variant="body1"> Schematic view</Typography>
              </Stack>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
