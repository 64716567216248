import { TaskAltOutlined } from "@mui/icons-material";
import { Avatar, CircularProgress, Container, CssBaseline, FormHelperText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { replaceJSX } from "library/helper";
import { t } from "locales";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { verifValidation } from "library/validations/authValidation";
import { updateUserData, updateWallets, verify } from "store/reducers/auth/asyncActions";
import CustomButton from "components/CustomButton";
import _ from "lodash";
import VerifyTimer from "components/VerifyTimer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { goBackFromVerify } from "store/reducers/auth/authSlice";
import { OtpInputCustom } from "assets/styles/styles";
import { openToast } from "components/Toast";
import AuthLayout from "components/AuthLayout";
import { Helmet } from "react-helmet";

const getBackPath = (verifyType) => {
  if (verifyType === "REGISTER") {
    return "/auth/register";
  }

  if (verifyType === "FORGOT_PASSWORD") {
    return "/auth/forgot-password";
  }

  return "/auth/login";
};

let intervalId;

const Index = () => {
  const { verifyToken, verifyType, resendEmail, status } = useSelector((state) => state.auth);
  const verifyTypeRef = useRef(verifyType);
  const { executeRecaptcha } = useGoogleReCaptcha();
  let attempts = 1;
  const navigate = useNavigate();
  function getRetryTimeout(attemptNumber) {
    const retryTimeouts = {
      1: 40,
      2: 80,
      3: 100,
      4: 120,
      5: 140,
    };

    const maxTimeout = 600;
    const defaultTimeout = maxTimeout - Object.values(retryTimeouts).reduce((a, b) => a + b);

    return retryTimeouts[attemptNumber] || defaultTimeout;
  }
  const [timer, setTimer] = useState(getRetryTimeout(attempts));
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(verifValidation),
    defaultValues: { code: "" },
  });

  // CHECK FOR VERIFY TOKEN
  useEffect(() => {
    if (!verifyToken) {
      navigate("/auth/login");
    }
    // eslint-disable-next-line
  }, []);

  // HANDLE TIMER
  useEffect(() => {
    if (timer === 0) {
      setTimer(null);
      clearInterval(intervalId);
    }

    if (!timer) return;

    intervalId = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  useEffect(() => {
    if (status === "fulfilled-verify") {
      if (verifyTypeRef.current === "FORGOT_PASSWORD") {
        navigate("/auth/reset-password");
      } else {
        dispatch(updateWallets());
        dispatch(updateUserData());

        if (verifyTypeRef.current === "REGISTER") navigate("/profile/overview");
      }
    }
  }, [status, navigate, dispatch, verifyType]);

  const resetTimer = () => {
    attempts += 1;
    setTimer(getRetryTimeout(attempts));
    reset({ code: "" });
  };

  const submitHandler = async (data) => {
    const gToken = await executeRecaptcha();
    const result = await dispatch(
      verify({
        token: verifyToken,
        code: data?.code,
        gRecaptchaResponse: gToken,
      })
    );

    if (result.error) {
      openToast("error", "wrong code or something went wrong");
    }
  };

  const backHandler = () => {
    dispatch(goBackFromVerify());

    navigate(getBackPath(verifyType));
  };

  return (
    <AuthLayout>
      <Helmet>
        <title>Verify | Tradergpt</title>
        <meta name="description" content="Verify | Tradergpt" />
      </Helmet>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            mb: "48px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              gap: 1,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <TaskAltOutlined />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ direction: "ltr", span: { display: "inline-block" } }}>
              <span>{t(verifyType)}</span> <span>{t("verification")}</span>
            </Typography>
            <Typography variant="body1" sx={{ color: "gray" }}>
              {replaceJSX(
                t("verifyDesc"),
                "#",
                <Typography component="span" variant="body1">
                  {resendEmail}
                </Typography>
              )}
            </Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit(submitHandler)} noValidate>
            <Box sx={{ marginBottom: 2 }}>
              <Controller
                control={control}
                name="code"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Box sx={{ "& > div": { justifyContent: "center" } }}>
                    <OtpInputCustom value={value} onChange={onChange} onBlur={onBlur} numInputs={4} isInputNum separator={<span>-</span>} hasErrored={!!errors.code} />
                  </Box>
                )}
              />
              {errors?.code && <FormHelperText sx={{ color: "#f44336", textAlign: "center", mt: 2 }}>{t(errors?.code?.message)}</FormHelperText>}
            </Box>
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isSubmitting}
              disabled={!_.isEmpty(errors, true)}
              withSpinner
              extraSx={{
                borderRadius: 2.4,
                width: "100%",
                color: "#FFF",
                py: "11.15px",
                mt: 1,
                mb: 1,
              }}
            >
              {t("verify")}
            </CustomButton>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                onClick={backHandler}
                variant="body2"
                sx={{
                  color: "primary.contrastText",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {t("back")}
              </Typography>
              <VerifyTimer
                timeLeft={timer}
                resetTimer={resetTimer}
                // setError={setError}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </AuthLayout>
  );
};

export default Index;
