import i18n from "i18next";
import fa from "./fa/fa";
import en from "./en/en";

export const languages = [
  {
    title: "فارسی",
    symbol: "fa",
    dir: "rtl",
  },
  {
    title: "English",
    symbol: "en",
    dir: "ltr",
  },
];

i18n.init({
  lng: i18n.options.lng,
  resources: {
    fa: {
      translations: fa,
    },
    en: {
      translations: en,
    },
  },
  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
export const t = (key, opt) => i18n.t(key, opt);
