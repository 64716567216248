import React, { useEffect } from "react";
import AdvancedOrder from "./AdvancedOrder";
import Benefits from "./Benefits";
import BulkOrder from "./BulkOrders";
import CareOrders from "./CareOrders";
import Header from "./Header";
import HowDoesItWork from "./HowDoesItWork";
import { Box } from "@mui/material";
import Paragraph from "./Paragraph";

const OrderManagement = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Header />
      <Box className="container">
        <Paragraph />
        <Benefits />
        <CareOrders />
        <AdvancedOrder />
        <BulkOrder />
      </Box>
      <HowDoesItWork />
    </>
  );
};

export default OrderManagement;
