import { Button, Typography, Stack, Box } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import PublicContent from "./PublicContent";
import { mainRoutes } from "routing/GeneralRoutes/routes";

const Header = () => {
  return (
    <Stack
      direction={{
        xs: "column",
        md: "row",
      }}
      alignItems="center"
      sx={{
        width: "100%",
        justifyContent: "space-between",
        mt: { xs: 8, md: 0 },
      }}
    >
      <Stack
        direction="column"
        alignItems="flex-start"
        sx={{
          width: { xs: "100%", md: "45%" },
          img: { width: "48px", height: "48px" },
        }}
      >
        <Box
          data-aos="fade-up"
          data-aos-duration="600"
          data-aos-delay="0"
          sx={{
            px: 2,
            py: 0.5,
            mt: 12,
            background: (theme) => theme.palette.primary.light,
            borderRadius: 15,
          }}
        >
          <Typography
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontWeight: 600,
            }}
          >
            AI + Human
          </Typography>
        </Box>
        <Box>
          <Typography
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="150"
            sx={{
              fontWeight: "700",
              fontSize: {
                xs: "1.6rem",
                sm: "2rem",
                md: "2.3rem",
                lg: "2.7rem",
                xl: "3rem",
              },
              color: (theme) => theme.palette.primary.contrastText,
              lineHeight: 1.4,
              "& span": {
                color: (theme) => theme.palette.primary.main,
              },
            }}
          >
            Revolutionize Your <span> Trading </span> with TraderGPT.ai!
          </Typography>
          <Typography data-aos="fade-up" data-aos-duration="600" data-aos-delay="250" variant="body1" sx={{ color: "#5E6282", mt: 2 }}>
            Harness the Power of AI to Make Informed Trading Decisions
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1} mt={4}>
            {/* <Button
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-delay="350"
              as={HashLink}
              to={mainRoutes.presale}
              sx={{
                color: "#fff",
                backgroundColor: "black",
                px: "24px",
                py: "12px",
                borderRadius: "12px",
                textDecoration: "none",
                textAlign: "center",
                mr: "12px",
                boxShadow: `none`,
                transition: "all 0.2s ease-out",
                minWidth: "73.7px !important",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: "black",
                  boxShadow: `4px 4px 15px rgba(0, 0, 0, 0.3)`,
                  transform: "scale(1.2)",
                },
              }}
            >
              Presale
            </Button> */}
            <Button
              color="primary"
              variant="contained"
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-delay="400"
              as={HashLink}
              to="/#plans"
              sx={{
                // color: "#fff",
                // backgroundColor: "primary.main",
                px: "24px",
                py: "12px",
                borderRadius: "12px",
                textDecoration: "none",
                textAlign: "center",
                mr: "12px",
                boxShadow: `none`,
                transition: "all 0.2s ease-out",
                "&:hover": {
                  // color: "#fff",
                  backgroundColor: "primary.main",
                  boxShadow: `4px 4px 15px rgba(41,  98, 255, 0.3)`,
                  transform: "scale(1.2)",
                },
              }}
            >
              Subscribe
            </Button>
          </Stack>
        </Box>
      </Stack>
      {/* {!!user ? <UserContent user={user} /> : <PublicContent />} */}
      <PublicContent />
    </Stack>
  );
};

export default Header;
