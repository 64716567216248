const app = {
  appName: "Tradergpt",
  //plans
  basic: "ساده",
  premium: "ویژه",
  custom: "سفارشی",
  upgrade: "ارتقاء",

  //buttons
  saveChanges: "ذخیره تغییرات",
  upload: "بارگذاری",
  tradeOnBinance: "معامله در Binance",
  signUp: "ثبت نام",
  followInstagram: "ما را در اینستاگرام دنبال کنید",
  launch: "شروع",

  //fields :
  email: "ایمیل",
  username: "نام کاربری",
  password: "رمزعبور",
  firstName: "نام",
  lastName: "نام خانوادگی",
  address: "آدرس",
  telephone: "تلفن",
  city: "شهر",
  zipPostal: "Zip/کد پستی",
  stateProvince: "ایالت/استان",
  country: "کشور",
  FORGOT_PASSWORD: "فراموشی رمز عبور",
  resetPassword: "Reset Password",
  resend: "Resend",
  mobileNotValid: "Mobile isn't valid!",
  referredCodeLabel: "Referral Id (Optional)",
  signupRecieveEmailAgreement: "I want to receive email offers from Tradergpt partnering clubs.",
  // fields: affiliatePortal:
  paypalAddress: "آدرس حساب paypal",
  bitcoinWallet: "آدرس کیف پول Bitcoin",
};

export default app;
