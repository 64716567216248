import React from 'react';
import { styles } from './styles';
import { Box, Grid, Stack, Typography, } from '@mui/material';
import { TrackChangesRounded } from '@mui/icons-material';

const Paragraph = () => {
    const paragraphData = [
        {
            id: 1,
            content: 'Order Management AI tools are computer-based algorithms that use sophisticated mathematical models and statistical analysis to manage and optimize trading orders in financial markets. These tools are designed to improve the efficiency of trading operations, reduce risk, and increase profitability.',
        },
        {
            id: 2,
            content: 'Order Management AI tools can analyze vast amounts of market data, including real-time market conditions, order flows, and market liquidity, to make informed trading decisions. This includes optimizing order execution timing, minimizing transaction costs, and ensuring that trades are executed in a controlled and responsible manner.',
        },
        {
            id: 3,
            content: 'One key advantage of Order Management AI tools is their ability to learn from past trades and continuously improve their performance over time. This includes refining their algorithms based on new data and adjusting trading strategies to adapt to changing market conditions.',
        },
        {
            id: 4,
            content: 'Order Management AI tools can also provide traders with real-time data and analysis, including order book depth, market volatility, and liquidity. This enables traders to make informed decisions about order placement and execution, while minimizing risk and maximizing profitability.',
        },
        {
            id: 5,
            content: 'Overall, Order Management AI tools are a powerful tool for traders looking to improve their trading performance and optimize their order management processes. Whether you are a seasoned trader or new to the world of trading, Order Management AI tools can help you achieve your trading goals.',
        },
    ];
    return (
        <Stack sx={styles.wrapper} gap={2}>
            <Grid container spacing={3}>
            {paragraphData?.map((item) => (
                <Grid item xs={12} md={6}>
                    <Stack direction="row" spacing={2} key={item?.id}>
                        <Stack alignItems="center" spacing={2} sx={styles.iconWrapper}>
                            <TrackChangesRounded />
                            <Box component="div" orientation="vertical" />
                        </Stack>
                        <Box sx={styles.textsWrapper}>
                            <Typography sx={styles.text}>
                                {item?.content}
                            </Typography>
                        </Box>
                    </Stack>
                </Grid>
            ))}
            </Grid>
        </Stack>
    );
};

export default Paragraph;