import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styles } from './styles';
import Image from 'react-image-webp';
import signalPic from '../../assets/tradergpt-09.png';

const AIPowered = () => {
    return (
        <Box sx={styles.wrapper}>
            <Grid container spacing={2} direction='row' alignItems='center'>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.textsWrapper}>
                        <Typography data-aos='zoom-in' data-aos-delay='200' data-aos-duration='600' sx={styles.titletext}> TraderGPT's <span> AI-Powered </span> Trading Indicators</Typography>
                        <Typography data-aos='zoom-in' data-aos-delay='200' data-aos-duration='600' sx={styles.descriptionText}>Unleash the potential of AI-Powered trading indicators to facilitate informed and profitable trading choices across diverse markets. This tool is your optimum solution for maximizing gains and maintaining a competitive edge in the financial trading arena.</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.imageWrapper} data-aos='zoom-in' data-aos-delay='200' data-aos-duration='600'>
                        <Image src={signalPic} webp={signalPic} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AIPowered;