import React from 'react';
import styles from './styles.js';
import { Box, Stack, Typography } from '@mui/material';
import { StarRounded } from '@mui/icons-material';

const CommentCard = ({data}) => {
    return (
        <Box 
            sx={styles.cardWrapper} 
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="200"
        >
            <Stack direction={{xs: 'column', md: 'row'}} alignItems={{xs: 'start', md: 'center'}} gap='6px' sx={{mb: 2}}>
                <Typography sx={styles.ownerText}>{data?.owner}</Typography>
                <Stack
                    data-aos="fade-up"
                    data-aos-duration="600"
                    data-aos-delay="0"
                    direction='row'
                    alignItems='center'
                    gap='4px'
                    sx={styles.ratingBox}
                >
                    <Typography
                        sx={{
                            color: (theme) => theme.palette.primary.main,
                            fontWeight: 700,
                            fontSize: 14,
                        }}
                    >
                        {data?.rating}
                    </Typography>
                    <StarRounded color='primary' fontSize='0.5rem' />
                </Stack>
            </Stack>
            <Typography sx={styles.commentText}>{data?.description}</Typography>
        </Box>
    );
};

export default CommentCard;