import React from 'react';
import Header from './Header';
import Paragraph from './Paragraph';
import { Box } from '@mui/material';

const Components = ({data}) => {
    return (
        <>
            <Header title={data?.name} />
            <Box className="container" sx={{mt: 6}}>
                <Paragraph title={data?.name} />
            </Box>
        </>
    );
};

export default Components;