import React, { useState } from "react";
import List from "./List";
import useDebounce from "_hooks/app/useDebounce";
import { styles } from "./styles";
import { Stack, Typography, Box } from "@mui/material";
import { useGetAllPlans } from "_hooks/Xtakers";

export default function Plans() {
  const [searchInputs, setSearchInputs] = useState();
  const debouncedSearchTerm = useDebounce(searchInputs, 500);
  const [selectedTags, setSelectedTags] = useState([]);
  const { data: plans, isFetching: isLoading } = useGetAllPlans({
    tag: selectedTags,
    searchQuery: debouncedSearchTerm,
  });

  return (
    <Box sx={styles.wrapper}>
      <Box className="container">
        <Typography variant="h2" sx={styles.title}>
          COMPARE BASIC VS. <span>ADVANCED FEATURES</span>
        </Typography>
        <Stack sx={{ width: "100%", mb: 12 }}>
          <List plans={plans} isLoading={isLoading} />
        </Stack>
      </Box>
    </Box>
  );
}
