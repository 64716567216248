import { useEffect, useState } from "react";
import DataTable from "components/DataTable";
import { t } from "locales";
import { useSelector } from "react-redux";
import formatDate from "library/formatDate";
import { Box } from "@mui/system";
import { styles } from "./styles";
import { useNavigate } from "react-router";
import SelectInput from "components/SelectInput";
import { Pagination, Skeleton, Typography } from "@mui/material";
import { useGetTicketDepartments, useGetTicketsList } from "_hooks/User/ticket";
import NoData from "components/NoData";

const SORT_OPTIONS = [
  { id: "desc", label: "Newest" },
  { id: "asc", label: "Oldest" },
];
const PRIORITY_OPTIONS = [
  { id: undefined, label: "All" },
  { id: "LOW", label: "Low" },
  { id: "MEDIUM", label: "Medium" },
  { id: "HIGH", label: "High" },
];

const TicketsList = () => {
  const { lang } = useSelector((state) => state.setting);
  const [sort, setSort] = useState(SORT_OPTIONS[0]);
  const [priority, setPriority] = useState(PRIORITY_OPTIONS[0]);
  const [department, setDepartment] = useState({});
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const {
    data: departments,
    isLoading: loadingDepartments,
    isError: errorDepartments,
  } = useGetTicketDepartments(true);
  const { data: ticketsData, isLoading: loadingTickets } = useGetTicketsList({
    page,
    sortDirection: sort?.id,
    priority: priority?.id,
    departmentId: department?.id,
  });
  console.log({priority, departments}, "sortsort");
  useEffect(() => {
    if (departments?.[0] && !loadingDepartments)
      setDepartment(departments?.[0]);
    // eslint-disable-next-line
  }, [departments?.length, loadingDepartments]);

  const onPageChange = (_, value) => setPage(value);
  const onSortChange = (value) => setSort(value);
  const onPriorityChange = (value) => setPriority(value);
  const onDepartmentChange = (value) => setDepartment(value);

  const openTicket = ({ id }) => {
    navigate(`/profile/ticket/${id}`);
  };

  const { total = 1, list = [] } = ticketsData || {};

  return (
    <Box>
      <Box sx={styles.filters}>
        <Box sx={styles.filter}>
          <SelectInput
            label={t("sort")}
            options={SORT_OPTIONS}
            onChange={onSortChange}
            value={sort}
            containerSx={styles.selectContainer}
            selectSx={styles.selectInput}
            shrink={true}
            labelSx={{mt: -1, ml: -1.5}}
          />
        </Box>
        <Box sx={styles.filter}>
          <SelectInput
            label={t("priority")}
            options={PRIORITY_OPTIONS}
            onChange={onPriorityChange}
            value={priority}
            containerSx={styles.selectContainer}
            selectSx={styles.selectInput}
            shrink={true}
            labelSx={{mt: -1, ml: -1.5}}
          />
        </Box>
        {!loadingDepartments && !errorDepartments && (
          <Box sx={styles.filter}>
            <SelectInput
              label={t("department")}
              options={departments}
              onChange={onDepartmentChange}
              value={department}
              containerSx={styles.selectContainer}
              selectSx={styles.selectInput}
              shrink={true}
              labelSx={{mt: -1, ml: -1.5}}
            />
          </Box>
        )}
      </Box>
      <DataTable
        data={loadingTickets ? Array(10).fill({}) : list}
        onSelect={loadingTickets ? null : openTicket}
        columns={[
          {
            title: t("title"),
            field: "title",
            render: ({ title }) =>
              loadingTickets ? (
                <Skeleton width="70px" height="15px" variant="rectangular" sx={{borderRadius: 2}} />
              ) : (
                <Typography variant="body1">{title}</Typography>
              ),
          },
          {
            title: t("priority"),
            field: "priority",
            render: ({ priority }) =>
              loadingTickets ? (
                <Skeleton width="50px" height="15px" variant="rectangular" sx={{borderRadius: 2}} />
              ) : (
                <Typography
                  variant="body2"
                  sx={() => styles.priority(priority)}
                >
                  {t(priority)}
                </Typography>
              ),
          },
          {
            title: t("status"),
            field: "status",
            render: ({ status }) =>
              loadingTickets ? (
                <Skeleton width="70px" height="15px" variant="rectangular" sx={{borderRadius: 2}} />
              ) : (
                <Typography variant="body2" sx={() => styles.status(status)}>
                  {t(status)}
                </Typography>
              ),
          },
          {
            title: t("time"),
            field: "createdAt",
            render: ({ createdAt }) =>
              loadingTickets ? (
                <Skeleton width="90px" height="15px" variant="rectangular" sx={{borderRadius: 2}} />
              ) : (
                <Typography variant="body2">
                  {formatDate(createdAt, {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    lang,
                  })}
                </Typography>
              ),
          },
        ]}
      />
      {!loadingTickets && list.length === 0 ? (
        <Box sx={styles.noData}>
          <NoData title={"No Ticket Found!"} />
        </Box>
      ) : (
        <Box sx={styles.pagination}>
          <Pagination
            sx={{ width: "fit-content" }}
            count={total}
            page={page}
            onChange={onPageChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default TicketsList;
