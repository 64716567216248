const pages = {
  // dashboard ::
  getPaid: "برای هر ثبت نام ۲۵ دلار در ماه دریافت کنید",
  advTrader: "کنسول پیشرفته تریدر",
  newMO: "جهت گیری اعضای جدید",
  startTour: "شروع تور",
  launch: "شروع",
  joinOC: "عضویت در شبکه اجتماعی",
  telegram: "تلگرام",
  instagram: "اینستاگرام",

  // dashboard cards titles :
  trustedExchangeAB: "صرافی ها و کارگزاران مورد اعتماد",
  premiumSignals: "سیگنال های ویژه",
  academy: "آکادمی",
  tradingCryptoTerminal: "پورتال تبادل رمزارز",
  autoTradeCopier: "کپی ترید اتوماتیک",
  cryptoMarketplace: "بازار کریپتو",
  dailyCryptoNews: "اخبار روزانه بازار کریپتو",
  cryptoMarket: "بازار کریپتو",
  allSignals: "همه سیگنال‌ها",
  allCourses: "همه آموزش‌ها",
  // dashboard cards contents :
  advanceCryptoAcademy: "آکادمی پیشرفته کریپتو",
  cryptoAcademyText: "مهارت های مورد نیاز برای موفقیت را بدست آورید.",
  cryptoMastryTitle: "برنامه تسلط بر کریپتو",
  cryptoMastryText: "برنامه انحصاری 1 به 1 با Neno.",

  cryptoTerminalTitle: "ترمینال کریپتو",
  cryptoTerminalText: "ترمینال پیشرفته تحلیل کریپتو .",
  chartAnalyzerTitle: "تحلیل‌گر نمودار",
  chartAnalyzerText: "نمودار پیشرفته با ابزارهای تجزیه و تحلیل.",
  marketCapTitle: "ارزش بازار",
  marketCapText: "سرمایه گذاری در بازار ارزهای دیجیتال",
  marketScreenerTitle: "غربالگر بازار",
  marketScreenerText: "برای پیدا کردن ارز دلخواهتان، بازار را فیلتر کنید.",
  autoTradeCardText:
    "به زودی، می‌توانید هر معامله‌ای را که توسط Neno انجام می‌شود، به‌طور خودکار با دستگاه کپی Advance Auto Trade کپی کنید. ساده ترین راه برای کسب سود بدون بلند کردن انگشت. منتظر این ویژگی باشید و حتما ما را در اینستاگرام برای به روز رسانی دنبال کنید.",
  buyCryptoHere: "اینجا رمزارز بخرید",
  enterAmount: "مقدار را وارد کنید",
  buy: "خرید",

  // portfolio ::
  connectPortfolio: "اتصال portfolio",
  allAssets: "همه",
  managePortfolios: "مدیریت Portfolios",
  holdings: "دارایی ها",
  nft: "nft",
  openPositions: "پوزیشن های باز",
  charts: "نمودار‌ها",
  orders: "سفارش‌ها",
  trades: "معاملات",
  transactions: "تراکنش‌ها",
  openOrders: "سفارشات باز",
  syncAll: "همگام‌سازی",
  moreActions: "بیشتر",
  addTransaction: "اضافه کردن تراکنش",
  deposite: "سپرده گذاری",

  // tradeSignals ::
  signals: "سیگنال ها",
  premiumTradingSignals: "سیگنال های ترید ویژه",
  advanceSignalTracker: "دنبال کننده سیگنال پیشرفته",
  dateTime: "تاریخ : زمان",
  action: "عمل",
  pair: "جفت",
  OP: "OP",
  TP: "TP",
  SL: "SL",
  exchange: "تبادل",

  take: "سود",
  stop: "توقف",
  open: "باز کردن",

  binanceFutures: "Binance Futures",
  tradeNow: "همین حالا معامله کن",

  // tradersTools ::
  // affiliatePortal ::
  paymentsHistory: "سابقه پرداخت ها",
  signupsHistory: "سابقه ثبت نام ها",
  referralDate: "تاریخ معرفی",
  commissionValue: "مقدار کمیسیون",
  paymentDate: "زمان پرداخت",
  method: "نوع",
  amount: "مقدار",
  affiliateLinkTitle: "لینک دعوت",
  affiliateLinkText1:
    "این لینک را با دوستان خود یا در رسانه های اجتماعی به اشتراک بگذارید و به ازای هر ثبت نام، هر ماه 25 دلار کمیسیون دوره ای دریافت کنید. و برای ثبت نام های سالانه، یک بار کمیسیون 100 دلاری دریافت خواهید کرد.",
  affiliateLinkText2:
    "پرداخت به معرف ها معمولا در اول هر ماه انجام می شود. لطفاً آدرس پی پال یا کیف پول بیت کوین را برای دریافت مبلغ کمیسیون ارائه دهید.",
  referralHint:
    "همچنین می‌توانید شناسه معرف خود را به اشتراک بگذارید تا مطمئن شوید که کوکی‌های حمایت مالی غیرفعال هستند",
  copyUrl: "کپی لینک",
  saveAddress: "ذخیره آدرس",

  monthlyClicks: "کلیک ماهیانه",
  totalClicks: "کل کلیک ها",
  monthlySignups: "ثبت نام ماهیانه",
  totalSignups: "کل ثبت نام ها",
  totalPaidOut: "کل دریافتی",
  owingAmount: "مبلغ طلب‌کاری",

  // profile ::
  accountSettings: "تنظیمات حساب",
  walletDetail: "اطلاعات حساب",
  //auth ::
  //create :
  createAcc: "ساخت حساب",
  emailSignup: "ثبت نام با ایمیل",
  createPassword: "رمزعبور",
  // login :
  forgetPass: "رمزعبور خود را فراموش کرده اید؟",
  noAccSignup: "حساب کاربری ندارید؟ ثبت نام کنید.",
  // verify :
  REGISTER: "ثبت نام",
  verification: "اهراز هویت",
  verifyDesc:
    "لطفا کد ۴ رقمی ارسالی به (#) را وارد کنید. کد برای ۱ دقیقه معتبر است.",
  // recovery :
  passRecovery: "بازیابی رمزعبور",
};

export default pages;
