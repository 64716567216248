import React, { useState, useEffect } from "react";
import ConfirmModal from "pages/Profile/Body/Xtaker/components/ActivePlans/ConfirmModal";
import { useGetSingleSubscribedPlan, useGetXtakerTraders, useChangeXtakerTrader } from "_hooks/Xtakers";
import TraderSelect from "pages/Home/components/TraderSelect";
import XtakerClaimReward from "pages/Profile/Body/Xtaker/components/ActivePlans/ClaimReward";
import CircularProgress from "components/CircularProgress";
import PlanStatistics from "./components/PlanStatistics";
import ClaimRewardHistory from "./components/ClaimRewardHistory";
import ClaimHistoryChart from "./components/ClaimHistoryChart";
import TradesHistory from "pages/Profile/Body/Xtaker/components/TradesHistory";
import { t } from "locales";
import { openToast } from "components/Toast";
import { useSelector } from "react-redux";
import { SkeletonLoader } from "components/Skeleton";
import { useNavigate, useParams } from "react-router";
import { Box, Typography, Stack, Button, Grid } from "@mui/material";

const getTimeLeftInPercent = (start, end) => {
  const startUTC = new Date(start).getTime();
  const endUTC = new Date(end).getTime();
  const nowUTC = Date.now();

  if (nowUTC > endUTC) return 100;
  else return ((nowUTC - startUTC) / (endUTC - startUTC)) * 100;
};

const XtakerSinglePlan = () => {
  const { id } = useParams();
  const { mutateAsync: changeTrader } = useChangeXtakerTrader();
  const { data: traders, isLoading: loadingTrader } = useGetXtakerTraders();
  const { data: planData, isLoading: loadingPlan } = useGetSingleSubscribedPlan({ id });
  const [selectedTrader, setSelectedTrader] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loadingTrader && !loadingPlan) {
      setSelectedTrader(traders?.list?.find((tr) => tr.id === planData?.traderId));
    }
  }, [loadingTrader, loadingPlan, planData, traders]);

  const modal = useSelector((state) => state.app.modal);
  const isLight = useSelector((state) => state.setting.theme === "light");

  const onChangeTraderSubmit = async () => {
    try {
      await changeTrader({ userPlanId: id, traderId: selectedTrader?.id });

      openToast("success", "Your trader changed successfully.");
    } catch (err) {
      openToast("error", "somethingWentWrong");
    }
  };

  const openConfirmModal = () => {
    modal.show(
      <ConfirmModal
        isFinished={isFinished}
        onCancel={() => {
          modal.hide();
        }}
        onSuccess={() => {
          openToast("success", "Your plan terminated successfully.");
          navigate("/tradergpts");
          modal.hide();
        }}
        id={id}
      />,
      "md"
    );
  };
  const onTraderChange = (value) => setSelectedTrader(value);

  const isFinished = planData?.end ? new Date() - new Date(planData?.end) >= 0 : null;
  const canFinished = planData?.status === "ACTIVE";
  const loading = loadingTrader || loadingPlan;

  return (
    <Stack className="container" gap={2} my={4}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" flexGrow="1" fontWeight="600" sx={{ opacity: "0.9" }}>
          {t("planDetail")}
        </Typography>

        <Box>
          {loading ? (
            <SkeletonLoader width="60" height="30" borderRadius="4px" isLight={isLight} />
          ) : canFinished ? (
            <Stack direction="row" gap={1} sx={{ button: { borderRadius: "4px" } }}>
              <Button fullWidth onClick={openConfirmModal} variant="contained">
                <Typography variant="body2" fontWeight="500">
                  {isFinished ? t("withdrawPlan") : t("terminatePlan")}
                </Typography>
              </Button>
            </Stack>
          ) : (
            <Typography variant="body1">You plan is finished</Typography>
          )}
        </Box>
      </Stack>

      <Grid
        container
        spacing={2}
        sx={{
          "& > div > div": {
            bgcolor: (theme) => (theme.palette.mode === "light" ? "#F1F0F5" : "#0b0b0b"),
            borderRadius: "8px",
            p: 2,
          },
        }}
      >
        <PlanStatistics planData={planData} loading={loading} />

        <Grid container item xs={12} justifyContent="space-between" sx={{ gap: { xs: 2, sm: 0 } }}>
          <Grid item xs={12} sm={5.9}>
            <Stack gap={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" sx={{ opacity: "0.9", mb: 1 }}>
                  Trader
                </Typography>

                <Stack direction="row">
                  <Button
                    disabled={!selectedTrader?.id || selectedTrader?.id === planData?.traderId}
                    variant="outlined"
                    onClick={onChangeTraderSubmit}
                    sx={{
                      py: 0.5,
                      px: 1,
                      borderRadius: "4px",
                      border: "none !important",
                      color: "#fff",
                      fontWeight: "400",

                      "&, &:hover": {
                        bgcolor: (theme) => (theme.palette.mode === "dark" ? "#8b1af440" : "#8b1af4"),
                      },
                      "&:disabled": {
                        bgcolor: (theme) => (theme.palette.mode === "dark" ? "#1d1d1d" : "#d2d2d2"),
                      },
                    }}
                  >
                    {t("submit")}
                  </Button>
                </Stack>
              </Stack>

              {loading ? (
                <SkeletonLoader width="100%" height="60" borderRadius="4px" isLight={isLight} />
              ) : (
                <TraderSelect onChange={onTraderChange} value={selectedTrader} options={traders?.list || []} />
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={5.9}>
            <Stack direction="row" justifyContent="space-between">
              <Stack justifyContent="space-between">
                <Typography variant="body1" sx={{ opacity: "0.9", mb: 1 }}>
                  Claim Profit
                </Typography>
                {loading ? <SkeletonLoader width="60" height="30" isLight={isLight} /> : <XtakerClaimReward data={planData} />}
              </Stack>

              <Stack>
                <Typography variant="body1" sx={{ opacity: "0.7", mb: 1 }}>
                  Remain time
                </Typography>
                <CircularProgress
                  // label="Remain time"
                  value={getTimeLeftInPercent(planData?.start, planData?.end)}
                  size={90}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <TradesHistory />

      <Grid container justifyContent="space-between" sx={{ gap: { xs: 2, lg: 0 } }}>
        <Grid item xs={12} lg={7.9}>
          <ClaimHistoryChart id={id} />
        </Grid>

        <Grid item xs={12} lg={4}>
          <ClaimRewardHistory id={id} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default XtakerSinglePlan;
