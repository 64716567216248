const app = {
  appName: "Tradergpt",
  //plans
  basic: "Basic",
  premium: "Premium",
  custom: "Custom",
  upgrade: "UPGRADE",

  //buttons
  saveChanges: "Save Changes",
  upload: "UPLOAD",
  tradeOnBinance: "trade on BINANCE",
  signUp: "Sign Up",
  followInstagram: "Follow Us On Instagram",
  launch: "Launch",

  //fields :
  email: "Email",
  username: "Username",
  password: "Password",
  firstName: "First Name",
  lastName: "Last Name",
  address: "Address",
  telephone: "Telephone",
  city: "City",
  zipPostal: "Zip/Postal",
  stateProvince: "State/Province",
  country: "Country",
  FORGOT_PASSWORD: "Forgot Password",
  resetPassword: "Reset Password",
  resend: "Resend",
  mobileNotValid: "Mobile isn't valid!",
  referredCodeLabel: "Referral Id (Optional)",
  signupRecieveEmailAgreement: "I want to receive email offers from Tradergpt partnering clubs.",
  // fields: affiliatePortal:
  paypalAddress: "Paypal Address",
  bitcoinWallet: "Bitcoin Wallet",
  tickets: "Tickets",
  myTickets: "My Tickets",
  supportTicket: "Support Ticket",

  astronaut: "Astronaut",
  astronauts: "Astronauts",
  myAstronauts: "My Astronauts",
  newAstronauts: "newAstronauts",
};

export default app;
