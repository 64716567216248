import { Box, Typography, CircularProgress } from "@mui/material";
import { fixNumber } from "library/helper";

const CustomCircularProgress = ({
  value = 0,
  noLabel = false,
  label,
  size = 100,
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        height: "fit-content",
      }}
    >
      <CircularProgress
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          "svg.MuiCircularProgress-svg": {
            color: (theme) =>
              theme.palette.mode === "dark" ? "#1d1d1d " : "#bfbfbf ",
          },
        }}
        variant="determinate"
        value={100}
        size={size}
      />

      <CircularProgress
        variant="determinate"
        value={fixNumber(Math.abs(value ?? 0), 1)}
        size={size}
        sx={{
          "svg.MuiCircularProgress-svg": {
            color: value > 0 ? "#5cab02 " : "#ff0070 ",
          },
        }}
      />
      {!noLabel && (
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ opacity: "0.6" }} variant="body2">
            {label ? label : `${fixNumber(value, 1)}%`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CustomCircularProgress;
