const starlexErrors = {
  "error-1000": "The entered mobile number is incorrect",
  "error-1001": "Email or Password is incorrect",
  "error-1002": "Mobile or Password is incorrect",
  "error-1003": "The entered mobile number is already registered in the system",
  "error-1004": "The entered email address is already registered in the system",
  "error-1005": "There is no user with the details entered in the system",
  "error-1006": "The token sent is incorrect",
  "error-1007": "Password update operation failed",
  "error-1008": "The password sent is incorrect",
  "error-1009": "Update operation failed",
  "error-1010": "You're not authorized to access this resource",
  "error-1011": "Asset Network not found",
  "error-1012": "Minimum allowed for withdraw: ",
  "error-1013": "User Wallet not found",
  "error-1014": "The requested amount is more than the users balance",
  "error-1015": "An error occurred while registering the transaction",
  "error-1016": "Asset not found",
  "error-1017": "An error occurred while registering",
  "error-1018": "An error occurred while validating the token",
  "error-1019": "An error occurred while registering the user",
  "error-1020": "An error occurred while registering the blog",
  "error-1021": "Blog not found",
  "error-1022": "Payment token not found",
  "error-1023": "Payment record not found",
  "error-1024": "Duplicate payment record",
  "error-1025": "Payment res num not found",
  "error-1026": "Payment failed",
  "error-1027": "Payment failed during confirmation stage",
  "error-1028": "Notification not found",
  "error-1029": "Pair not found",
  "error-1030": "An error occurred while registering the Payment",
  "error-1031": "An error occurred while registering the Setting",
  "error-1032": "Setting not found",
  "error-1033": "Already edited",
  "error-1034": "Transaction not found",
  "error-1035": "Asset not found",
  "error-1036": "Wallet not found",
  "error-1037": "List not found",
  "error-1038": "An error occurred while logging in",
  "error-1039": "An error occurred during the password forgetting operation",
  "error-1040": "You are not authorized to access this content",
  "error-1041": "amount is low",
  "error-1042": "low wallet amount",
  "error-1043": "Fiat account not found",
  "error-1044": "Category not found.",
  "error-1045": "Payment partner not found",
  "error-1046": "amount is not in range",
  "error-1047": "An error occurred while creating the payment partner",
  "error-1048": "An error occurred while generating the address",
  "error-1051": "An error occurred while registering the auction",
  "error-1052": "auction not found",
  "error-1053": "offer not found",
  "error-1054": "Please enter at least one of these fields, mobile or email.",
  "error-1055": "An error occurred while adding!",
  "error-1056": "An error occurred while updating!",
  "error-1057": "An error occurred while deleting!",
  "error-1058": "Item not found!",
  "error-1059": "The item is not editable!",
  "error-1060": "Swap failed!",
  "error-1061": "One of playerId and assetId is required!",
  "error-1062": "Auction offer not found!",
  "error-1063": "Competition not found!",
  "error-1064": "Competition league not found!",
  "error-1065": "Prize not found!",
  "error-1066": "Prize pool not found!",
  "error-1067": "One or more auction offer exist!",
  "error-1068": "Language not found!",
  "error-1069": "Language creation failed!",
  "error-1070": "Cards must contain at least 5 items",
  "error-1071": "Cards position is wrong!",
  "error-1072": "Wallet is low!",
  "error-1073": "Invalid Request : ",
  "error-1074": "Asset network not found",
  "error-1075": "User transaction not found",
  "error-1101": "Department not found.",
  "error-1102": "Astronaut not found.",
  "error-1103": "Reply not found.",
  "error-1115": "This Astronaut is currently closed!",
  "error-1125": "Failed captcha verification",
  "error-1126": "You are not allowed to enter",
  "error-1127": "Your account is Inactive",
  "error-1128": "card xp not found",
  "error-1129": "Just Gmail extension emails supported",
  "error-2001": "An error occurred while creating the competitions",
  "error-2002": "Competitions not found",
  "error-2003": "An error occurred while creating the country",
  "error-2004": "Country not found",
  "error-2005": "An error occurred while creating the match",
  "error-2006": "Match not found",
  "error-2007": "An error occurred while creating the player",
  "error-2008": "Player not found",
  "error-2009": "An error occurred while creating the team",
  "error-2010": "Team not found",
  "error-2011": "Selected gateway is not valid",
  "error-2012": "Plan not found",
  "error-2013": "Plan price is corrupted",
  "error-2014": "Duplicate wallet",
  "error-2015": "There is no currency compatible with requested gateway",
  "error-2016": "You wallet amount is lower than desired plan. Please charge your wallet first.",
  "error-2017": "There is no currency compatible with requested asset",
  "error-2018": "Duplicate signal",
  "error-2019": "There is no signal with given id",
  "error-2020": "There was an error while updating signal",
  "error-2021": "You need to get a Subscription plan to access this resource",
  "error-2022": "Duplicate plan",
  "error-2023": "Please specify an user for custom subscription",
  "error-2024": "There was an error while creating plan",
  "error-2025": "There was an error while updating plan",
  "error-2026": "There was an error while creating card type",
  "error-2027": "There was an error while updating card type",
  "error-2028": "There was an error while updating card type",
  "error-2029": "Card type not found",
  "error-2030": "Duplicate Card type",
  "error-2031": "Please upload card image",
  "error-2032": "Duplicate card",
  "error-2033": "Card not found",
  "error-2034": "There are already some assigned tokens assotiated with this card.",
  "error-2035": "Order not found",
  "error-2036": "There is another account associated with this email",
  "error-2037": "Please send an address.",
  "error-2038": "Tradergpt plan not found",
  "error-2039": "An error occurred while registering the Tradergpt Plan",
  "error-2040": "Your Tradergpt wallet has insufficient amount.",
  "error-2041": "There is already an auction on this card. Please Edit that one.",
  "error-2042": "There is no enough token to be assigned.",
  "error-2043": "There is already offers on this auction.",
  "error-2044": "The number of assigned tokens reached it's limit",
  "error-2045": "You already have a token with this type",
  "error-2046": "There is no token assosiated with current auction",
  "error-2047": "Country not found",
  "error-2048": "Duplicate tag",
  "error-2049": "There is no tag with given id",
  "error-2050": "There was an error while updating tag",
};

export default starlexErrors;
