const config = {
  BASE_URL: mandatory("REACT_APP_TRADERGPT_BASE_URL_PROFITIO"),
  BASE_URL_ALGO: mandatory("REACT_APP_TRADERGPT_BASE_URL_ALGO"),
  BASE_URL_PROTFOLIO: mandatory("REACT_APP_TRADERGPT_SITE_URL_PROFITIO"),
  IMAGE_URL: mandatory("REACT_APP_TRADERGPT_IMAGE_URL_PROFITIO"),
  SOCKET_URL: mandatory("REACT_APP_TRADERGPT_SOCKET_URL_PROFITIO"),
  SITE_URL: mandatory("REACT_APP_TRADERGPT_SITE_URL_PROFITIO"),
  // PORTFI_API: mandatory("REACT_APP_API_PORTFI_URL"),
};

function mandatory(key) {
  const value = process.env[key];
  if (!value) {
    throw new Error(`missing mandatory env value: ${key}`);
  }
  return value;
}

const {
  BASE_URL,
  BASE_URL_ALGO,
  IMAGE_URL,
  SOCKET_URL,
  SITE_URL,
  BASE_URL_PROTFOLIO,
  // PORTFI_API,
} = config;
export {
  BASE_URL,
  BASE_URL_ALGO,
  IMAGE_URL,
  SOCKET_URL,
  SITE_URL,
  BASE_URL_PROTFOLIO,
  // PORTFI_API,
};
