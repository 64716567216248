import React, { useEffect } from "react";
import AdvancedOrder from "./AdvancedOrder";
import Benefits from "./Benefits";
import BulkOrder from "./BulkOrders";
import Header from "./Header";
import HowDoesItWork from "./HowDoesItWork";
import Plans from "./Plans";
import LearnMore from "./LearnMore";
import Analytics from "./Analytics";

const OptionsTrading = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Header />
      <Benefits />
      <Plans />
      <AdvancedOrder />
      <BulkOrder />
      {/* <HowDoesItWork /> */}
      <Analytics />
      <LearnMore />
    </>
  );
};

export default OptionsTrading;
