import React from 'react';
import TradersInfoCard from 'pages/Home/components/TradersInfo/Card';
import { Box, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetXtakerTraders } from '_hooks/Xtakers';
import { Autoplay, FreeMode } from 'swiper';
import { CustomNextArrow, CustomPrevArrow } from 'components/CustomSwiperArrows';

const OtherTrader = () => {
    const { data: traders } = useGetXtakerTraders();
    const sliderSettings = {
      freeMode: false,
      pagination:{ clickable: false },
      modules: [Autoplay, FreeMode],
      className: "mySwiper",
      slidesPerView: 3,
      spaceBetween: 26,
      breakpoints: {
        1800: { slidesPerView: 3 },
        1600: { slidesPerView: 3 },
        1400: { slidesPerView: 3 },
        1200: { slidesPerView: 2 },
        900: { slidesPerView: 2 },
        600: { slidesPerView: 2 },
        500: { slidesPerView: 1 },
        1: { slidesPerView: 1 },
      },
    };
    return (
        <Box sx={{
            my: 10,
            
        }}>
            <Box>
                <Typography
                    sx={{
                        fontSize: {
                          xs: "1.2rem",
                          lg: "2.0rem",
                        },
                        mb: 2,
                        fontWeight: 600,
                        color: (theme) => theme.palette.primary.contrastText,
                        "& span": {
                          color: (theme) => theme.palette.primary.main,
                        },
                      }}
                >More <span>Trader</span></Typography>
            </Box>
            <Swiper {...sliderSettings}>
            {traders?.list?.slice(0, 4)?.map((item) => (
              <SwiperSlide>
                    <TradersInfoCard data={item} />
                </SwiperSlide>
            ))}
            <Box
              sx={{
                mt: 2,
                gap: 2,
                display: "flex", 
                justifyContent: 'space-between', 
              }}
            >
                <CustomPrevArrow />
                <CustomNextArrow />
            </Box>
            </Swiper>
        </Box>
    );
};

export default OtherTrader;