import React from "react";
import ReactDOM from 'react-dom/client';
import axios, {XtakersAxios} from "./library/http";
import App from "./App";
import setupAxios from "./config/setupAxios";
import SocketProvider from "context/socket/SocketProvider";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { CssBaseline } from "@material-ui/core";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "assets/styles/index.css";
import "assets/styles/en.css";
import "assets/styles/swiper-bundle.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "react-multi-carousel/lib/styles.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);

setupAxios(axios, store);
setupAxios(XtakersAxios, store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SocketProvider>
        <PersistGate persistor={persistor}>
          <BrowserRouter basename="/">
            <QueryClientProvider client={queryClient}>
              <CssBaseline>
                <App />
              </CssBaseline>
            </QueryClientProvider>
          </BrowserRouter>
        </PersistGate>
      </SocketProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
