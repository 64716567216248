const layout = {
  //navbar
  portfolioTracker: "Portfolio tracker",
  cryptocurrencies: "رمزارز‌ها",
  pricing: "لیست پلن‌ها",
  login: "ورود",
  getStarted: "شروع کن!",
  theme: "پوسته",
  language: "زبان",
  connectWallet: "اتصال کیف پول",

  //sidebar
  dashboard: "داشبورد",
  portfolio: "portfolio",
  tradeSignals: "سیگنال معاملات",
  cryptoWars: "جنگ های کریپتو",
  tradersTools: "ابزار تریدر",
  affiliatePortal: "درگاه معرف",
  myAccount: "حساب من",
  logout: "خروج",

  //wallets sidebar
  selectPortfolio: "به یک کیف پول متصل شوید",
  connectYourWallet:
    "کیف پول و صرافی های خود را به هم متصل کنید تا همه دارایی های خود را با هم مدیریت کنید.",

  //footer
  stayInTouch: "در ارتباط باشید",
  platform: "پلتفرم",
  learn: "آموزش‌ها",
  resources: "منابع",
  otherProducts: "محصولات دیگر",
  company: "کمپانی",
  contactUs: "ارتباط با ما",
};

export default layout;
