import React, { useEffect } from "react";
import DashboardBody from "./Body";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Helmet>
        <title>Profile | Tradergpt</title>
        <meta name="description" content="Profile | Tradergpt" />
      </Helmet>
      {/* <DashboardHeader /> */}
      <DashboardBody />
    </Box>
  );
};

export default Dashboard;
