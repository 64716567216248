import React from 'react';
import { styles } from './styles';
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller, Parallax } from "swiper";
import { Box, Grid, Typography } from '@mui/material';

const SuccessStories = () => {
    const sliderSettings = {
        modules: [Parallax, Controller],
        className: "mySwiper",
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        centeredSlides: true,
        breakpoints: {
          1400: { slidesPerView: 1 },
          1200: { slidesPerView: 1 },
          1: { slidesPerView: 1 },
        },
    };

    const data = [
        {
            id: 'comment-1',
            owner: 'Barish B',
            comment: `"TraderGPT is undoubtedly a leader in the world of Forex and Crypto trading. I'm thrilled with their AI-based indicators and their impressive win rate. Looking forward to more successful trades with TraderGPT."`,
        },
        {
            id: 'comment-2',
            owner: 'Javad G',
            comment: `"With TraderGPT, I've found a trading partner that understands my needs. Their services, especially the AI-based indicators, are top-notch, and their support team is always ready to help. Keep up the great work, TraderGPT!"`,
        },
        {
            id: 'comment-3',
            owner: 'Akasha',
            comment: `"TraderGPT has revolutionized the way I trade. Their AI-based indicators have made my trades more accurate and profitable. I highly recommend their services!"`,
        },
        {
            id: 'comment-4',
            owner: 'Laura S',
            comment: `"Thanks to TraderGPT's educational courses, I've gained a deeper understanding of the financial market. Their course content is comprehensive and easy to understand, even for beginners like me."`,
        },
        {
            id: 'comment-5',
            owner: 'Mohammed A',
            comment: `"I've been using TraderGPT's VIP Signal and Analysis Channel for a few months now, and it's been a game-changer. Real-time data and insights have made my trading journey smoother and more successful."`,
        },
        {
            id: 'comment-6',
            owner: 'Pouya H',
            comment: `"I love how TraderGPT strikes a balance between providing excellent trading tools and educating its users. The 10-day free trial of the Signal Generator Indicator was an added bonus. I can't wait to see what they come up with next!"`,
        },
    ]
    return (
        <Box sx={styles.wrapper}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <Box>
                        <Box
                            data-aos="fade-up"
                            data-aos-duration="600"
                            data-aos-delay="0"
                            sx={styles.blueBox}
                        >
                            <Typography sx={styles.subTitle}>
                                TESTIMONIALS
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                data-aos="fade-up"
                                data-aos-duration="600"
                                data-aos-delay="150"
                                sx={styles.title}
                            >
                                 Success <span> Stories </span> with TraderGPT!
                            </Typography>
                            <Typography 
                                data-aos="fade-up" 
                                data-aos-duration="600" 
                                data-aos-delay="250" 
                                variant="body1"
                                sx={styles.description}
                            >
                                Hear directly from our community of traders. Discover their experiences with our services.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box 
                        sx={{px: 2}}
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-delay='0'
                    >
                        <Swiper {...sliderSettings}>
                            {data?.map((item) => (
                                <SwiperSlide key={item?.id}>
                                    <Box sx={styles.commentWrapper}>
                                        <Typography sx={styles.owner}>{item?.owner}</Typography>
                                        <Typography sx={styles.comment}>{item?.comment}</Typography>
                                    </Box>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SuccessStories;