import React from 'react';
import { styles } from './styles';
import { Grid, Stack, Typography } from '@mui/material';

const Statistic = () => {
    const data = [
        {
            id: 'item-1',
            value: '+3000',
            title: 'Traders Trusted',
        },
        {
            id: 'item-2',
            value: '+1200',
            title: 'Assets',
        },
        {
            id: 'item-3',
            value: '+$50M',
            title: 'Take profit archived',
        },
        {
            id: 'item-4',
            value: '+50K',
            title: 'Users',
        },
    ];
    return (
        <Grid container spacing={2} sx={{mt: 6}}>
            {data?.map((item) => (
                <Grid item xs={6} md={3} key={item.id}>
                    <Stack alignItems='center' direction='column' gap='6px' sx={styles.boxWrapper}>
                        <Typography sx={styles.value}>{item?.value}</Typography>
                        <Typography sx={styles.title}>{item?.title}</Typography>
                    </Stack>
                </Grid>
            ))}
        </Grid>
    );
};

export default Statistic;