import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserData,
  updateWallets,
} from "store/reducers/auth/asyncActions";

const useGetUserData = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => !!state.auth.user);
  const { accessToken } = useSelector((state) => state.auth);

  // Update user data & wallets
  useEffect(() => {
    if (!isAuth) return;

    dispatch(updateUserData());
    dispatch(updateWallets());
  }, [accessToken, dispatch, isAuth]);
};

export default useGetUserData;
