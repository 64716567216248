import { Avatar, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { getImageUrl } from "library/helper";

const SelectInput = ({ options = [], onChange, value, label, shrink = false, containerSx = {}, selectSx = {}, labelSx = {}, itemSx = {}, setId }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleChange = (event) => {
    const newValue = event.target.value;
    const item = typeof newValue === "string" ? options.find((o) => o.id === newValue) : newValue;
    if (!!setId) {
      onChange(item?.id);
      setId(item?.id);
    } else {
      onChange(newValue);
    }
  };
  return (
    <FormControl sx={{ minWidth: 120, ...containerSx }}>
      {label && (
        <InputLabel shrink={shrink} sx={{ ...labelSx }}>
          {label}
        </InputLabel>
      )}
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        label={label}
        onChange={handleChange}
        sx={{
          ...selectSx,
          borderRadius: 2,
          "& .MuiSelect-select": {
            py: 1.4,
          },
        }}
      >
        {options?.map?.((item) => (
          <MenuItem value={item?.id} key={item.label}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                ...itemSx,
              }}
            >
              <Avatar sx={{ width: 24, height: 24 }} src={getImageUrl(item?.imageUrl || item?.icon)} alt="" />
              <Typography component="span" variant="body1">
                {item.name || item?.title}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
