import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import Header from "./Header";
import Sidebar from "./Sidebar";
import History from "./History";
import { useGetRefferal } from "_hooks/public/landing";
import { useEffect } from "react";

const Invite = () => {
  const { data: referralData } = useGetRefferal();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pb: 5, pr: 0 }}>
      <Box
        sx={{
          backgroundBlendMode: "multiply",
          backgroundSize: "cover",
          backgroundPosition: "center",
          bgcolor: "primary.light",
          borderRadius: 6,
        }}
      >
        <Header />
      </Box>
      <Box className="container">
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            p: "20px",
            pr: 0,
            pl: 0,
          }}
        >
          <Grid item xs={12} md={5} sx={{ mb: { xs: 2, md: 0 } }}>
            <History />
          </Grid>
          <Grid item xs={12} md={7}>
            <Sidebar refferal={referralData} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Invite;
