const mainEn = {
  brandName: "Tradergpt",
  home: "Home",
  competitions: "Competitions",
  howToPlay: "How To Play",
  P2PPenalty: "P2P Penalty",
  nftMarketplace: "Marketplace",
  community: "Community",
  leaderboard: "Leaderboard",
  games: "Games",
  buyVLX: "Buy crypto",
  support: "Support",
  dashboard: "Dashboard",
  setting: "Setting",
  settings: "Settings",
  logout: "Log Out",
  profile: "Profile",
  somethingWentWrong: "Something went wrong!",
  loading: "Loading",
  noData: "No Data",

  mainPageTag: "Play & Earn Trading Platform",
  mainPageHeader: "TRADERGPT",
  mainPageBtn: "Start the journey",

  feedTitle: "PLAY Feed",
  feedDesc: "What is new on Tradergpt ?",
  feedBtn: "HOW TO PLAY ?",
  newPackTitle: "New Packs",
  newPackDesc: "Announced New Cards",
  tradeCardsTitle: "Collect & Trade",
  sponsoredBy: "Sponsored by",
  buildAndManageTitle: "Build & manage your Squad",
  buildAndDescription: `Build strategies, create lineups and earn points based on your
  players’ real-life performances.`,
  clubsDataTitle: "120 Clubs officially licensed",
  clubsDataDesc: "New clubs every week",
  tradeCardsDesc: "NFT Collectable digital cards",
  bannersTitle: "All new games this year",
  bannersDesc: "dolor sit amet consectetur adipisicing elit. Doloremque sit cupiditate magnam natus ratione distinctio doloribus omnis!",
  bannersBtn: "Learn more",
  language: "Language",

  footerTitle: "NFT game platform",
  aboutUs: "About us",
  quickLinks: "Quick links",
  all: "All",
  newPackSliderBtn: "Buy Now",
  blog: "Blog",
  tournament: "Tournament",
  soccer: "Soccer",
  cryptoWars: "CryptoWars",
  play: "Play",
  penalty: "Penalty",
  noticeNextBtn: "Got it",
  notices: "Notices",
  noCardFound: "No card found!",

  noNewNotif: "No new notification",
  blockchainDetails: "Blockchain details",
  nftTokenId: "NFT Token ID",
  iUnderstand: "I understand",
  notificationDetail: "Notification Detail",
  unreadNotifications: "Unread notifications",
  clearAll: "Clear All",
  viewAll: "View all",
  enterAmount: "Enter Amount",
};

export default mainEn;
