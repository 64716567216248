import { Typography, Box, Table, TableCell, TableBody, TableRow, TableHead, Button, Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { profile } from "routing/GeneralRoutes/routes";

const Actions = () => {
  const wallets = useSelector((state) => state.auth.wallets);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography sx={{ fontSize: 20, fontWeight: 600 }}>My Wallet Balance</Typography>
      <Box sx={{ overflowX: "auto" }}>
        <Table sx={{ minWidth: 390 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(wallets || {}).map((row) => (
              <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: "none" } }}>
                <TableCell component="th" scope="row">
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Avatar src={row?.icon} />
                    <Box>
                      <Typography sx={{ fontSize: 14 }}>{row.name}</Typography>
                      <Typography sx={{ fontSize: 13, opacity: 0.8 }}>{row.coin}</Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  {row.amount} {row.coin}
                </TableCell>
                <TableCell align="right">
                  <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
                {row.coin !== "TREX" && 
                    <>
                    <Button
                        as={Link}
                        to={profile.deposid}
                        sx={{ borderRadius: 2.4, background: "#54B435", fontSize: 13, color: "#FFF", py: 0.3, px: 1.5, textDecoration: "none" }}
                      >
                        Deposit
                      </Button><Button
                        as={Link}
                        to={profile.withdraw}
                        sx={{ borderRadius: 2.4, background: "#DF2E38", fontSize: 13, color: "#FFF", py: 0.3, px: 1.5, textDecoration: "none" }}
                      >
                          Withdraw
                    </Button>
                    </>
                }
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default Actions;
