import { Box } from "@mui/system";
import { t } from "locales";
import { Link, useSearchParams } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useLocation } from "react-router";
import { ExpandMoreRounded } from "@mui/icons-material";
import { useState } from "react";

const SecondarySidebar = ({ menu, asMenu = false, findActiveCallback }) => {
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box
      sx={[
        {
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          gap: 1,
        },
        asMenu && { width: "80vw", display: "flex !important" },
      ]}
    >
      {menu.map(({ title, link, icon: Icon, activePath, sub }) => {
        const isActive = findActiveCallback
          ? findActiveCallback(activePath || link)
          : pathname?.includes(activePath || link);
        const Activesub = !!sub?.find((item) => item?.tabName === tab);
          return (
            sub?.length > 0 ?
            <Box sx={{
              '.MuiListItem-root': {
                p: "0px !important",
                display: "block",
              },
              '.MuiPaper-root': {
                boxShadow: "none",
                backgroundColor: 'transparent',
              },
              '.MuiButtonBase-root': {
                minHeight: 48,
                pl: 0,
              },
              '.MuiAccordionSummary-content': {
                my: 0,
              },
              '.Mui-expanded': {
                my: "0 !important",
              }
            }}>
              <Accordion expanded={expanded === title} onChange={handleChange(title)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreRounded />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={[
                    Activesub && {
                      bgcolor: 'primary.light',
                      borderRight: {xs: 'none', md: '3px solid'},
                      borderColor: 'primary.main',
                    },
                  ]}
                >
                    <Box
                      key={title}
                      sx={[
                        {
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          padding: "10px",
                          color: "inherit",
                          textDecoration: "none",
                        },
                        
                      ]}
                    >
                      <Box
                        sx={[
                          {
                            width: "32px",
                            height: "32px",
                            display: "flex",
                            padding: "10px",
                            alignItems: "center",
                            borderRadius: "12px",
                            justifyContent: "center",
                            color: (theme) => (theme.palette.mode === "dark" ? "inherit" : "primary.main"),
                          },
                        ]}
                      >
                        <Icon fontSize="small" />
                    </Box>
                    <Typography
                      variant="body1"
                      sx={[
                        isActive && {
                          color: ({ palette }) => (palette.mode === "dark" ? palette.primary.main : palette.primary.dark),
                        },
                      ]}
                    >
                      {t(title)}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {sub?.map(({title: subtitle, icon: SubIcon, link: subLink, tabName}) => {
                    const isSubActive = tabName === tab;
                    return(
                    <Box
                      key={subtitle}
                      as={Link}
                      to={subLink}
                      className="item"
                      sx={[
                        {
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          padding: "10px",
                          color: "inherit",
                          textDecoration: "none",
                        },
                        isSubActive && {
                          bgcolor: 'primary.light',
                        },
                      ]}
                  >
                    <Box
                      sx={[
                        {
                          width: "32px",
                          height: "32px",
                          display: "flex",
                          padding: "10px",
                          alignItems: "center",
                          borderRadius: "12px",
                          justifyContent: "center",
                          color: (theme) => (theme.palette.mode === "dark" ? "inherit" : "primary.main"),
                        },
                      ]}
                    >
                      <SubIcon fontSize="small" />
                    </Box>
                    <Typography
                      variant="body1"
                      sx={[
                        isActive && {
                          color: ({ palette }) => (palette.mode === "dark" ? palette.primary.main : palette.primary.dark),
                        },
                      ]}
                    >
                      {t(subtitle)}
                    </Typography>
                    </Box>
                  )})}
                </AccordionDetails>
              </Accordion>
            </Box>
            :
            <Box
              key={title}
              as={Link}
              to={link}
              className="item"
              sx={[
                {
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  padding: "10px",
                  color: "inherit",
                  textDecoration: "none",
                },
                isActive && {
                  bgcolor: 'primary.light',
                  borderRight: {xs: 'none', md: '3px solid'},
                  borderColor: 'primary.main',
                },
              ]}
            >
              <Box
                sx={[
                  {
                    width: "32px",
                    height: "32px",
                    display: "flex",
                    padding: "10px",
                    alignItems: "center",
                    borderRadius: "12px",
                    justifyContent: "center",
                    color: (theme) => (theme.palette.mode === "dark" ? "inherit" : "primary.main"),
                  },
                ]}
              >
                <Icon fontSize="small" />
              </Box>
              <Typography
                variant="body1"
                sx={[
                  isActive && {
                    color: ({ palette }) => (palette.mode === "dark" ? palette.primary.main : palette.primary.dark),
                  },
                ]}
              >
                {t(title)}
              </Typography>
            </Box>
          );
      })}
    </Box>
  );
};

export default SecondarySidebar;
