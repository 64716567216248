import { Helmet } from "react-helmet";
import { Container, Stack, Typography, Box } from "@mui/material";
import CustomTextField from "components/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useContactUs } from "_hooks/User/ContactUs";
import { openToast } from "components/Toast";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthLayout from "components/AuthLayout";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { contactUsFormValidation } from "library/validations/contactUs";
import { yupResolver } from "@hookform/resolvers/yup";

const ContactUs = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutateAsync: postContactUs, isLoading: loading } = useContactUs();
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm({
    defaultValues: { title: "", description: "", email: "" },
    resolver: yupResolver(contactUsFormValidation),
  });
  const onSubmit = async (values, actions) => {
    const gToken = await executeRecaptcha();
    const result = await postContactUs({
      email: values.email,
      title: values.title,
      description: values.description,
      gRecaptchaResponse: gToken,
    });
    if (result?.status >= 200 && result?.status < 300) {
      openToast("success", "Your Message successfully sent");
      reset();
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact Us | Tradergpt</title>
        <meta name="description" content="Contact Us" />
      </Helmet>
      <AuthLayout>
        <Container
          maxWidth="md"
          sx={{
            bgcolor: "loginCardBg.default",
            paddingTop: "40px",
            paddingBottom: "30px",
            marginTop: "36px",
            borderRadius: "12px",
          }}
        >
          <Stack direction="row" justifyContent="center" alignItems="top">
            <Typography variant="h3" component="h1" fontWeight="600">
              Contact Us
            </Typography>
          </Stack>
          <Box
            onSubmit={handleSubmit(onSubmit)}
            as="form"
            autocomplete="off"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Stack direction="column" spacing={4} mt={4} mb={4}>
              <Controller
                control={control}
                name="title"
                render={({ field: { onChange, value } }) => (
                  <CustomTextField label="Title" placeholder="Enter title" id="title" name="title" value={value} onChange={onChange} error={errors?.title} helperText={errors?.title?.message} />
                )}
              />
              <Controller
                control={control}
                name="description"
                render={({ field: { onChange, value } }) => (
                  <CustomTextField
                    label="Description"
                    placeholder="Description"
                    id="description"
                    name="description"
                    value={value}
                    onChange={onChange}
                    error={errors?.description}
                    helperText={errors?.description?.message}
                    multiline={true}
                    rows="4"
                  />
                )}
              />
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <CustomTextField
                    label="Email Address"
                    placeholder="Enter email"
                    id="email"
                    name="email"
                    type="email"
                    value={value}
                    onChange={onChange}
                    error={errors?.email}
                    helperText={errors?.email?.message}
                  />
                )}
              />

              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={loading}
                disabled={isSubmitting || loading}
                style={{
                  width: 170,
                  padding: "10px",
                  margin: "0 auto",
                  marginTop: "30px",
                }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Box>
        </Container>
      </AuthLayout>
    </>
  );
};
export default ContactUs;
