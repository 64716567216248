import {
  GeneralPublicRoutes,
  GeneralAuthRoutes,
  GeneralPrivateRoutes,
} from "./GeneralRoutes";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import DashboardSidebar from "components/DashboardSidebar";
import { Stack, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import PlansRoutes from "pages/Plans";

const AppRoutes = () => {
  const { expandedSidebar } = useSelector((state) => state.app);
  const smallDevice = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMobileSize = useMediaQuery("(max-width:1200px)");


  return (
    <>
      {isMobileSize && <DashboardSidebar />}
      <Stack
        sx={{
          maxWidth: smallDevice
            ? "100%"
            : expandedSidebar
            ? "calc(100%)"
            : "calc(100%)",
          transition: "all .25s ease-in-out",
          minHeight: "100vh",
          justifyContent: "space-between",
        }}
      >
        <Navbar />
        <Stack>
          <GeneralPublicRoutes />
          <GeneralAuthRoutes />
          <GeneralPrivateRoutes />
          <PlansRoutes />
        </Stack>
        <Footer />
      </Stack>
    </>
  );
};
export default AppRoutes;
