const validationEn = {
  emailRequired: "Email is a required field",
  emailNotValid: "Email Format Not Correct",
  mobileRequired: "Mobile is a required field",
  mobileNotValid: "Mobile is not valid",
  passwordRequired: "Password is a required field",
  passwordNotValid:
    "The password must contain letters, numbers and must be at least 8 characters long",
  passwordConfirmationRequired: "Password confirmation is a required field",
  passwordConfirmationNotMatch:
    "The password entered is different from repeating the password",
  nameRequired: "Name is a required field",
  termNotChecked: "Terms And Condition is a required field",
  codeRequired: "Please provide Verify Code",
  codeMin: "Code must be a 4 number",
  code_type: "Code must be a number",
  codeType: "Code must be a number",
  requiredField: "This field is required!",
  insufficientWalletAmount: "Insuffient amount!",
  invalidTimeFormat: "Please enter a valid date!",
  mustBeNumber: "You must specify a number!",
};

export default validationEn;
