import { useQuery } from "react-query";
import ApiCall from "_clients/apiCall";

export const useGetNoticeData = () => {
  return useQuery("app-notice", () =>
    ApiCall("GET", "public/notices", null, null, (res) => res.data?.data)
  );
};
export const useGetAllCountries = (params) => {
  return useQuery(
    ["all-country", params],
    () =>
      ApiCall(
        "GET",
        "public/country",
        null,
        { ...params, page: 1, limit: 300, order: params?.order || "ASC" },
        (res) => res.data?.data
      ),
    {}
  );
};
