export const styles = {
  ul: {
    listStyle: "none",
    paddingLeft: "0",
  },
  li: {
    display: "flex",
    paddingLeft: "0",
    pb: 1.2,
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    bgcolor: "#fff",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    borderRadius: "24px",
    height: "100%",
    p: 3,
    transition: "all 0.2s ease-out",
    '&:hover': {
      border: "1px solid transparent",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  },
  cardTitle: {
      fontWeight: 600,
      my: 1,
  },
  cardDescription: {
      fontSize: 14,
      color: "#5E6282",
  },
};
