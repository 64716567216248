const links = {
  instagram: "#",
  telegram: "#",
  discord: "https://discord.gg/XTcrSe7x",
  twitter: "#",
  youtube: "#",
  whitePaper: "#",
  medium: "#",
};

export const {
  instagram,
  telegram,
  discord,
  twitter,
  youtube,
  whitePaper,
  medium,
} = links;
