import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, Typography, Grid, InputAdornment, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import { t } from "locales";
import ErrorAlert from "components/ErrorAlert";
import { setAuthData, leaveLoginPage } from "store/reducers/auth/authSlice";
import { login, updateUserData } from "store/reducers/auth/asyncActions";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { loginWithEmailValidation } from "library/validations/authValidation";
import { inputAutoFillStyles } from "assets/styles/styles";
import AuthLayout from "components/AuthLayout";
import { Helmet } from "react-helmet";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomButton from "components/CustomButton";

const Index = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginError, status } = useSelector((state) => state.auth);
  const [validation] = useState(loginWithEmailValidation);
  const resolver = yupResolver(validation);
  const [ShowPasswordInLogin, setShowPasswordInLogin] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { email: "", password: "" },
    resolver,
  });

  const onSubmit = async (data) => {
    try {
      const gToken = await executeRecaptcha();
      const loginData = {
        email: data?.email,
        password: data?.password,
        gRecaptchaResponse: gToken,
      };
      dispatch(setAuthData(loginData));
      await dispatch(login(loginData));
    } catch (error) {
      console.log(error?.response);
    }
  };

  useEffect(() => {
    if (status === "fulfilled-login" && !loginError) {
      dispatch(updateUserData());
      // dispatch(updateWallets());
      navigate("/profile/overview");
    } else if (loginError) {
      // console.log(loginError);
    }
  }, [status, loginError, dispatch, navigate]);

  // Reset error and status after leaving page
  useEffect(() => {
    return () => {
      dispatch(leaveLoginPage());
    };
  }, [dispatch]);

  return (
    <AuthLayout>
      <Helmet>
        <title>Login | Tradergpt</title>
        <meta name="description" content="Login | Tradergpt" />
      </Helmet>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            mb: 6,
            mt: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          gap={0}
          component="form"
          onSubmit={handleSubmit(onSubmit, (errors, e) => console.log(errors, e))}
          noValidate
        >
          <Typography variant="h2" fontWeight="700" sx={{ fontSize: "2rem" }}>
            {t("login")}
          </Typography>
          <Box sx={{ mt: 4 }}>
            {/* <Box>
              <Button
                sx={{
                  background: "rgb(234, 240, 247)",
                  width: "100%",
                  height: 48.6,
                  borderRadius: 2.4,
                  boxShadow: "none",
                  border: "1px solid transparent",
                  transition: "all 0.2s ease-out",
                  "&:hover": {
                    color: "#000",
                    background: "rgb(234, 240, 247)",
                    borderColor: "rgb(220,220,220)",
                    boxShadow: "rgba(234, 240, 247, 1) 0px 8px 24px",
                  },
                }}
              >
                <Avatar src={googleIcon} sx={{ width: 28, height: 28 }} alt="" />
                <Typography sx={{ fontSize: 16, fontWeight: 500, ml: 1 }}>Login with Google</Typography>
              </Button>
              <Divider sx={{ mt: 3 }}>
                <Typography sx={{ fontSize: 12, color: "rgba(0,0,0,0.24)" }}>or do it via E-mail</Typography>
              </Divider>
            </Box> */}
            <Box sx={{ width: "100%", my: 3 }}>{loginError && <ErrorAlert text={loginError} />}</Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{ "& .MuiFormControl-root": { my: 0 } }}>
                <Typography sx={{ mb: 0.5, fontSize: 14, fontWeight: 500 }}>E-mail</Typography>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      error={errors?.email}
                      helperText={t(errors?.email?.message)}
                      margin="normal"
                      placeholder="@mail.com"
                      fullWidth
                      variant="outlined"
                      id="email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      sx={{ ...inputAutoFillStyles }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ "& .MuiFormControl-root": { my: 0 } }}>
                <Typography sx={{ mb: 0.5, fontSize: 14, fontWeight: 500 }}>Password</Typography>
                <Controller
                  control={control}
                  name="password"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPasswordInLogin(!ShowPasswordInLogin)} edge="end">
                              {ShowPasswordInLogin ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.password}
                      helperText={t(errors?.password?.message)}
                      margin="normal"
                      fullWidth
                      id="password"
                      name="password"
                      placeholder="Password"
                      autoComplete="password"
                      type={ShowPasswordInLogin ? "text" : "password"}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      sx={[
                        { ...inputAutoFillStyles },
                        // (theme) => theme.direction === "rtl" && rtlTextFieldStyle,
                      ]}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomButton
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  withSpinner
                  fullWidth
                  extraSx={{
                    borderRadius: 2.4,
                    width: "100%",
                    color: "#FFF",
                    py: "11.15px",
                    mt: 1,
                  }}
                >
                  {t("login")}
                </CustomButton>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs>
                <Typography
                  as={Link}
                  to="/auth/forgot-password"
                  sx={{
                    fontSize: 13,
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "primary.main",
                  }}
                >
                  {t("forgetPass")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  as={Link}
                  to="/auth/register"
                  sx={{
                    fontSize: 13,
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "primary.main",
                  }}
                >
                  {t("noAccSignup")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </AuthLayout>
  );
};

export default Index;
