import React from 'react';

const XtakerLogo = () => {
    return (
        <div>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
                <path d="M400,53.75C208.77141,53.75,53.75,208.77141,53.75,400S208.77141,746.25,400,746.25,746.25,591.22859,746.25,400,591.22859,53.75,400,53.75Zm54.43231,161.78493a68.03377,68.03377,0,0,0,67.98309-65.77238h.07927c-.003.25324-.022.50185-.022.756a68.045,68.045,0,0,0,64.26037,67.93066v.16317c-.754-.02631-1.50776-.05344-2.268-.05344a68.03615,68.03615,0,0,0-68.02129,67.28439h-.097c.02621-.756.07789-1.50444.07789-2.268a68.04583,68.04583,0,0,0-68.04039-68.0404Zm176.905,358.50693a27.05528,27.05528,0,0,1-27.05531,27.0553H540.456a27.05527,27.05527,0,0,1-27.0553-27.0553V511.72779a27.05528,27.05528,0,0,0-27.05531-27.0553H322.7267a27.05527,27.05527,0,0,0-27.0553,27.0553v62.31407a27.05528,27.05528,0,0,1-27.05531,27.0553H203.278a27.05527,27.05527,0,0,1-27.0553-27.0553V511.72779a27.05527,27.05527,0,0,1,27.0553-27.0553h62.31407a27.05527,27.05527,0,0,0,27.0553-27.0553V278.87845a27.05528,27.05528,0,0,1,27.0553-27.05531h62.31407a27.05528,27.05528,0,0,1,27.0553,27.05531v62.31406a27.05528,27.05528,0,0,0,27.05531,27.05531h51.73a27.05527,27.05527,0,0,1,27.0553,27.0553v62.31407a27.05528,27.05528,0,0,0,27.05531,27.0553H604.282a27.05528,27.05528,0,0,1,27.05531,27.0553Z"
                fill="#0067e4"
                />
            </svg>
        </div>
    );
};

export default XtakerLogo;