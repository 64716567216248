import { ClickAwayListener, IconButton, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { Box, styled } from "@mui/system";
import QRCode from "qrcode.react";
import Clipboard from "react-clipboard.js";
import { useSelector } from "react-redux";
import InviteModal from "./InviteModal";
import { ContentCopy, QrCodeScannerRounded } from "@mui/icons-material";
import { useState } from "react";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
}));

const ClipboardCustom = styled(Clipboard)({
  display: "flex",
  cursor: "pointer",
});

const Header = () => {
  const user = useSelector((state) => state.auth.user);
  const modal = useSelector((state) => state.app.modal);
  const referralCode = `https://tradergpt.ai/auth/register?ref=${user?.referralCode}`;

  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(false);
  
  const onCopy = () => {
    setCopied(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
      setOpen(!open);
  };

  
  return (
    <Box className="container">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          color: "white",
          py: 5,
          maxWidth: { md: "600px" },
          px: "20px",
        }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              textAlign: { xs: "center", md: "start" },
              color: 'primary.contrastText',
            }}
          >
            Invite a friend to Tradergpt
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              textAlign: { xs: "center", md: "start" },
              color: 'primary.contrastText',
            }}
          >
            and make profits from their income.
          </Typography>
        </Box>
        <Box>
          <Typography 
            variant="body1" 
            sx={{ 
              fontSize: 16,
              fontWeight: 500,
              textAlign: { xs: "center", md: "start" }, 
              color: 'primary.contrastText', 
            }}>
            Know someone passionate about investing? When your friends subscribe 5 new plans, you'll receive 100$.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{
            bgcolor: '#FFF',
            px: 3,
            py: 1,
            borderRadius: 12
          }}>
            <Typography variant="body1" sx={{ color: "#444", fontSize: 14, wordBreak: 'break-all', }}>
              {referralCode}
            </Typography>
          </Box>

          <ClipboardCustom
              component="span"
              data-clipboard-text={referralCode}
              onSuccess={onCopy}
          >
              <Tooltip title={copied ? "Copied" : "Copy to clipboard"} arrow>
                  <IconButton sx={{bgcolor: '#FFF', width: 40, height: 40}}>
                      <ContentCopy sx={{ fontSize: 20 }} />
                  </IconButton>
              </Tooltip>
          </ClipboardCustom>
          
          <ClickAwayListener onClickAway={handleTooltipClose}>
              <LightTooltip
                  open={open}
                  onClose={handleTooltipClose}
                  placement="top"
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                  title={
                      <Box sx={{pt: 0.8}}>
                          <QRCode value={referralCode} level="Q" size={120} />
                      </Box>
                  }
              >
                  <IconButton onClick={handleTooltipOpen} sx={{bgcolor: '#FFF', width: 40, height: 40}}>
                      <QrCodeScannerRounded sx={{ fontSize: 20 }} />
                  </IconButton>
              </LightTooltip>
          </ClickAwayListener>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
