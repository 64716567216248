import { Pagination, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DataTable from "components/DataTable";
import { getImageUrl } from "library/helper";
import { t } from "locales";
import { useState } from "react";
import { useSelector } from "react-redux";
import defaultProfileImg from "assets/images/default-profile.png";
import Dropdown from "components/Dropdown";
import { useGetRefferalHistory } from "_hooks/public/landing";
import NoData from "components/NoData";

const PERPAGE = 10;
const LOADING_LIST = Array(5).fill({});

const TYPES = [
  { title: "All", value: undefined },
  { title: "Register", value: "REGISTER" },
];

const History = () => {
  const auth = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [selectedType, setSelectedType] = useState(TYPES[0]);
  const { data: dataHistory, isLoading: loading } = useGetRefferalHistory(
    {
      page,
      limit: PERPAGE,
      type: selectedType?.value || undefined,
    },
    !!auth?.accessToken
  );

  const onPageChange = (_, value) => setPage(value);

  console.log({ dataHistory }, "history data");

  return (
    <Box
      sx={{
        height: "100%",
        p: "20px",
        borderRadius: "24px",
        bgcolor: (theme) => (theme.palette.mode === "dark" ? "card.dark" : "card.light"),
        border: "1px solid",
        borderColor: (theme) => (theme.palette.mode === "dark" ? "transparent" : "#eee"),
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 2, sm: 0 },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{fontSize: 18, fontWeight: 600}}>Subordinate Bonus</Typography>
          <Dropdown options={TYPES ?? []} value={selectedType} onChange={(newValue) => setSelectedType(newValue)} />
        </Box>

        <Box>
          <DataTable
            columns={[
              {
                title: t("User"),
                field: "referredUser",
                render: ({ referredUser, _id: { referredUserId } = {} }) => {
                  return loading ? (
                    <Skeleton width="40px" height="16px" variant="rectangular" sx={{borderRadius: 2.4}} />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        img: {
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          objectFit: "contain",
                        },
                      }}
                    >
                      <img src={getImageUrl(referredUserId?.avatar) || defaultProfileImg} alt="" />
                      <Typography variant="body1">{referredUserId?.name ?? t("unnamed")}</Typography>
                    </Box>
                  );
                },
              },
              {
                title: t("Reward"),
                field: "amount",
                render: ({ totalCommission, _id: { assetId } = {} }) =>
                  loading ? <Skeleton width="40px" height="16px" variant="rectangular" sx={{borderRadius: 2.4}}/> : <Typography variant="body2">{`${totalCommission} ${assetId?.[0]?.coin || ""}`}</Typography>,
              },
            ]}
            data={loading ? LOADING_LIST : dataHistory?.list}
            rowClass="py-2"
            headClass="table-head"
          />
          {!loading && !dataHistory?.length ? (
            null
          ) : (
            dataHistory?.total > 1 && (
              <Box sx={{ mx: "auto", width: "fit-content", mt: 3 }}>
                <Pagination sx={{ width: "fit-content" }} count={dataHistory?.total} page={page} onChange={onPageChange} />
              </Box>
            )
          )}
        </Box>
        <Box>
          {!loading && dataHistory?.list?.length === 0 ?
            <NoData title={'No user found!'} />
            : null
          }
        </Box>
      </Box>
    </Box>
  );
};

export default History;
