import { useState, useEffect } from "react";
import { Stack, CircularProgress, Typography } from "@mui/material";

import Table from "./Table";
import { WarningAmberOutlined } from "@mui/icons-material";
import { getImageUrl } from "library/helper";
import noImage from "assets/images/no_image.png";
// import { t } from "locales";

const columns = [
  {
    id: "name",
    label: "Feature",
    minWidth: 178,
    align: "start",
    width: 178,
  },

  {
    id: "interest",
    label: "Basic (Included)",
    minWidth: 80,
    // format: (value) => value.toLocaleString("en-US"),
    align: "start",
    width: 80,
  },
  {
    id: "duration",
    label: "Advanced (Add-on)",
    minWidth: 100,
    align: "start",
    width: 150,
  },
];

export default function FixedTable({ plans = [], isLoading }) {
  const [rows, setRows] = useState([]);
  console.log({rows}, "rowsrows");
  useEffect(() => {
    setRows(
      plans?.map?.((item) => ({
        id: item.id,
        icon: getImageUrl(item.imageUrl) ?? noImage,
        color: item.color,
        name: item.name,
        interest: `${item.minInterest}-${item.maxInterest}`,
        // invesment: `${item.minInvesment} - ${item.maxInvesment}`,
        duration: item.duration,
      }))
    );
  }, [plans]);

  return !isLoading ? (
    !!plans?.length ? (
      <Table columns={columns} rows={rows} key={0} />
    ) : (
      <Stack
        mt={8}
        gap={1}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",

          svg: {
            fontSize: "60px",
            opacity: "0.9",
          },
        }}
      >
        <WarningAmberOutlined />
        <Typography variant="h6">No Plan Found!</Typography>
      </Stack>
    )
  ) : (
    <Stack sx={{ minHeight: "100px", alignItems: "center", mt: 8 }}>
      <CircularProgress size={28} />
    </Stack>
  );
}
