import SelectInput from "components/SelectInput";
import { t } from "locales";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { useDropzone } from "react-dropzone";
import { addTicketValidation } from "library/validations/ticketValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/system";
import {
  Button,
  ButtonBase,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import ErrorAlert from "components/ErrorAlert";
import { styles } from "./styles.js";
import { inputAutoFillStyles, rtlTextFieldStyle } from "assets/styles/styles.js";
import { Close, CloudUploadRounded } from "@mui/icons-material";
import {
  useGetTicketDepartments,
  usePostCreateTicket,
} from "_hooks/User/ticket.js";
import { getHumanError } from "library/translateServerErrors.js";

const PRIORITY_OPTIONS = [
  { label: "Low", id: "LOW" },
  { label: "Medium", id: "MEDIUM" },
  { label: "High", id: "HIGH" },
];
const MIN_FILE_SIZE = 5000000;

const NewTicket = ({ onClose }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      ".png, .jpg, .svg, .gif, .jpeg, .pdf, .doc, .docx, .txt, .html, .ppt, .pptx, .csv",
    onDrop: (acceptedFiles) => {
      const allowedFiles = acceptedFiles.filter(
        (f) => f?.size <= MIN_FILE_SIZE
      );

      setFiles((prev) => [...prev, ...allowedFiles]);
    },
  });
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [priority, setPriority] = useState(PRIORITY_OPTIONS[0]);
  const [department, setDepartment] = useState({});

  const { data: departments, isLoading: loadingDepartments } =
    useGetTicketDepartments();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(addTicketValidation),
    defaultValues: {
      title: "",
      description: "",
    },
  });
  const {
    mutate: createTicket,
    isLoading: loading,
    isError,
    error,
  } = usePostCreateTicket(setProgress);

  useEffect(() => {
    if (departments?.[0] && !loadingDepartments)
      setDepartment(departments?.[0]);
    // eslint-disable-next-line
  }, [departments?.length]);

  const onPriorityChange = (value) => setPriority(value);
  const onDepartmentChange = (value) => setDepartment(value);
  const closeModal = () => !isSubmitting && onClose();
  const unselectFile = (path) => {
    if (!isSubmitting)
      setFiles((prev) => prev?.filter((file) => file?.path !== path));
  };

  const submitHandler = (data) =>
    createTicket(
      {
        title: data.title,
        text: data.description,
        department,
        priority,
        files,
      },
      {
        onSuccess: () => {
          setFiles([]);
          onClose();
        },
        onSettled: () => setProgress(0),
      }
    );

  const renderedFiles = files.map((file, i) => (
    <Box sx={styles.singleSelectedFile} key={file.path}>
      <Close fontSize="medium" onClick={() => unselectFile(file.path)} />
      <Typography variant="body2">
        {i + 1}: {file.path}
      </Typography>
    </Box>
  ));

  return (
    <Box sx={styles.newTicket}>
      <Box sx={styles.header}>
        <Typography variant="h5" sx={styles.headerTitle}>
          {t("newTicket")}
        </Typography>
        {onClose && (
          <ButtonBase onClick={onClose} sx={styles.closeWrapper}>
            <Close fontSize="small" />
          </ButtonBase>
        )}
      </Box>

      {isError && (
        <Box mb={2} mt={1}>
          <ErrorAlert text={t(getHumanError(error))} />
        </Box>
      )}

      <Box>
        <Box component="form" onSubmit={handleSubmit(submitHandler)}>
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                type="text"
                error={errors?.title}
                helperText={t(errors?.title?.message)}
                disabled={isSubmitting}
                margin="normal"
                fullWidth
                id="title"
                placeholder={t("title")}
                name="title"
                autoComplete="title"
                autoFocus
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                sx={{...inputAutoFillStyles}}
              />
            )}
          />
          <Box sx={styles.dropdownWrapper}>
            <Box>
              <SelectInput
                label={t("priority")}
                options={PRIORITY_OPTIONS}
                onChange={onPriorityChange}
                value={priority}
                containerSx={styles.selectContainer}
                selectSx={styles.selectInput}
                shrink={true}
              />
            </Box>
            <Box>
              <SelectInput
                options={departments}
                onChange={onDepartmentChange}
                value={department}
                label={t("department")}
                containerSx={styles.selectContainer}
                selectSx={styles.selectInput}
                shrink={true}
              />
            </Box>
          </Box>

          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                rows={5}
                multiline
                type="text"
                error={errors?.description}
                helperText={t(errors?.description?.message)}
                disabled={isSubmitting}
                margin="normal"
                fullWidth
                id="description"
                placeholder={t("description")}
                name="description"
                autoComplete="description"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                sx={{...inputAutoFillStyles}}
              />
            )}
          />
          <Box sx={styles.fileInput}>
            <Typography component="label" variant="body1" htmlFor="file">
              {t("sendingFile")} <span>({t("maxTicketFile")})</span>
            </Typography>
            <Box {...getRootProps()} sx={styles.dropzone}>
              <input id="file" {...getInputProps()} />
              <Box sx={styles.dropzoneText}>
                <Typography component="span" variant="body2">
                  {t("uploadFile")}
                </Typography>
                <CloudUploadRounded fontSize="meduim" />
              </Box>
            </Box>
            <Box sx={styles.selectedFiles}>{renderedFiles}</Box>
          </Box>

          {loading && files?.length > 0 && (
            <Box sx={styles.progressWrapper}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          )}

          <Box sx={styles.footer}>
            <Button
              sx={styles.cancelBtn}
              onClick={closeModal}
              variant="outlined"
            >
              {t("cancel")}
            </Button>

            <Button
              disabled={!_.isEmpty(errors, true) || isSubmitting || loading}
              type="submit"
              variant="contained"
              sx={{
                color: "#fff",
                padding: "7px 20px",
                width: "fit-content",
                borderRadius: "0.6rem",
              }}
            >
              <Typography variant="body1">{t("sendTicket")}</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewTicket;
