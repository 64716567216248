import React from "react";
import stress from "./tradergpt.png";
import Image from "react-image-webp";
import { styles } from "./styles";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";

const Signals = () => {
  return (
    <Box sx={styles.wrapper}>
      <Grid container spacing={3} direction="row" alignItems="center">
        <Grid item xs={12} md={7}>
          <Box sx={{ position: "relative" }}>
            <Box sx={styles.securityImage}>
              <Image src={stress} webp={stress} alt="" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography sx={styles.title}>
            Check Free <span> AI Signals!</span>
          </Typography>
          <Typography sx={styles.description}>
            We provide our AI trading platform's real-time results for free, just for test with over 70% success rate.
          </Typography>
          <Box sx={{ mt: 3, width: "100%" }}>
            {/* <Button sx={styles.signalButton} as={Link} to={'/ai-signals'}>
                            Go To Signals
                        </Button> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signals;
