export const styles = {
  wrapper: {
    mt: 10,
  },
  containerBox: {
    minHeight: "500px",
    backgroundColor: (theme) => theme.palette.primary.light,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    padding: "100px 60px",
    gap: 2,
  },
  title: {
    fontSize: {
        xs: "1.2rem",
        lg: "2.0rem",
      },
    fontWeight: 600,
    textAlign: "center",
    color: (theme) => theme.palette.primary.contrastText,
    '& span': {
        color: (theme) => theme.palette.primary.main,
        textDecoration: "underline",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    maxHeight: "750px",
  },
};
