import Countdown from "react-countdown";
import {
  Typography,
  Stack,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { useClaimPlanReward } from "_hooks/Xtakers";
import { openToast } from "components/Toast";

const zeroPad = (number) => (number < 10 ? `0${number}` : number);

// Renderer callback with condition
const XtakerClaimReward = ({ data }) => {
  const { mutateAsync: claimRequest, isLoading } = useClaimPlanReward();

  const onClaimReward = async () => {
    try {
      const res = await claimRequest(data?.id);

      console.log(res?.data);

      openToast("success", "Your profit successfully claimed.");
    } catch {
      openToast("error", "Something went wrong during claiming your profit!");
    }
  };

  if (!data?.remain) {
    return (
      <Box
        py={1}
        px={2}
        sx={{
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#1d1d1d99" : "#d2d2d2",
        }}
      >
        <Typography variant="body2" sx={{ opacity: "0.75" }}>
          Plan is finished
        </Typography>
      </Box>
    );
  }

  return (
    <Countdown
      date={Date.now() + (data?.remain || 0) * 1000}
      renderer={({ hours, minutes, seconds, completed }) =>
        completed ? (
          <Box>
            <Button
              disabled={isLoading}
              onClick={onClaimReward}
              variant="contained"
              sx={{
                svg: { color: "#fff" },
                py: isLoading ? 1 : 0,
                borderRadius: "4px",
              }}
            >
              {isLoading ? <CircularProgress size={15} /> : "Claim"}
            </Button>
          </Box>
        ) : (
          <Stack>
            <Typography variant="body2" sx={{ opacity: "0.6", mb: 1 }}>
              Remain Time
            </Typography>

            <Stack
              direction="row"
              gap={1}
              sx={{
                "& > div": {
                  width: "30px",
                  height: "30px",
                  borderRadius: "1px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "#1f1f1f" : "#ffff",
                },
              }}
            >
              <Box>
                <Typography variant="body1" fontWeight="600">
                  {zeroPad(hours)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" fontWeight="600">
                  {zeroPad(minutes)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" fontWeight="600">
                  {zeroPad(seconds)}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        )
      }
    />
  );
};

export default XtakerClaimReward;
