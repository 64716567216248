import { useQuery, useQueryClient, useMutation } from "react-query";
import ApiCall from "_clients/apiCall";
import { calcTotalPages } from "library/helper";
import { MARKETPLACE_COIN } from "library/constants";

export const useGetBuyCryptoHistory = (type, page) => {
  return useQuery(
    ["buy-crypto-history", type, page],
    () =>
      ApiCall("GET", `asset/${type}`, null, { page }, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    { keepPreviousData: true }
  );
};

export const useGetDepositHistory = (page) => {
  return useQuery(
    ["deposit-history", page],
    () =>
      ApiCall("GET", "asset/deposit", null, { page }, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    { keepPreviousData: true }
  );
};

export const useGetWithdrawHistory = (page) => {
  return useQuery(
    ["withdraw-history", page],
    () =>
      ApiCall("GET", "asset/withdraw", null, { page }, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    { keepPreviousData: true }
  );
};

export const useGetUserCards = (params) => {
  return useQuery(
    ["user-cards", params],
    () =>
      ApiCall("GET", "user/card", null, params, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    { keepPreviousData: true }
  );
};

export const useGetAllNotifications = (page) => {
  return useQuery(
    ["notifications", page],
    () =>
      ApiCall("GET", "user/notifications", null, { page }, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    { keepPreviousData: true }
  );
};
export const useReadAllNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation(() => ApiCall("PATCH", "user/notifications"), {
    onSuccess: () => queryClient.invalidateQueries("notifications"),
  });
};
export const useReadSingleNotification = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => ApiCall("PATCH", `user/notifications/${id}`), {
    onSuccess: () => queryClient.invalidateQueries("notifications"),
  });
};

export const useGetWallet = () => {
  return useQuery("wallet", () =>
    ApiCall("GET", `wallet`, null, null, (res) =>
      res?.data?.data?.find((w) => w?.asset?.coin === MARKETPLACE_COIN)
    )
  );
};

export const useGetSwapAssets = (params) => {
  return useQuery(
    ["swap-assets", params],
    () =>
      ApiCall("GET", `asset/swap`, null, params, (res) => ({
        list: res.data?.data?.data,
        total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
      })),
    {
      refetchInterval: 60000, // 1 minute
    }
  );
};
export const useGetMyWallet = (params) => {
  return useQuery(["my-wallet", params], () =>
    ApiCall("GET", `wallet`, null, params, (res) => res.data?.data)
  );
};


export const useGetAssetNetworks = (params) => {
  return useQuery(
    ["asset-network", params],
    () => ApiCall("GET", `user/allAssetNetwork`, null, params, (res) => ({
      list: res.data?.data?.data,
      total: calcTotalPages(res.data?.data?.total, res.data?.data?.pageSize),
    })),
    {
      refetchInterval: 60000, // 1 minute
    }
  );
};

export const useSwapPriceTokens = () => {
  return useMutation((data) => ApiCall("POST", "swap/price", data, null, (res) => res));
};