import { mainRoutes } from "routing/GeneralRoutes/routes";
import { Link as RouterLink } from "react-router-dom";
import {
  AccountBalanceRounded,
  CurrencyExchangeRounded,
  HomeRounded,
  MiscellaneousServicesRounded,
  // SignalCellularAltRounded,
} from "@mui/icons-material";
import RedeemIcon from '@material-ui/icons/Redeem';
// import { discord } from "library/links";

export const navbarMenu = () => {
  return [
    {
      id: 1,
      title: "Home",
      as: RouterLink,
      to: mainRoutes.base,
      icon: HomeRounded,
    },
    // {
    //   id: 5,
    //   title: "Presale",
    //   as: RouterLink,
    //   to: mainRoutes.presale,
    //   icon: RedeemIcon,
    // },
    {
      id: 3,
      title: "Plans",
      as: RouterLink,
      to: `${mainRoutes.base}`,
      icon: CurrencyExchangeRounded,
      scrollTo: "#plans",
    },
    // {
    //   id: 3,
    //   title: "AI Signals",
    //   as: RouterLink,
    //   to: mainRoutes.signals,
    //   icon: SignalCellularAltRounded,
    // },
    {
      id: 4,
      title: "Services",
      as: RouterLink,
      to: mainRoutes.services,
      icon: MiscellaneousServicesRounded,
    },
    {
      id: 5,
      title: "Investing",
      as: RouterLink,
      to: mainRoutes.investing.base,
      icon: AccountBalanceRounded,
    },
    // {
    //   id: 5,
    //   title: "Discord",
    //   as: 'a',
    //   to: discord,
    //   icon: AccountBalanceRounded,
    //   isBlank: true,
    // },
  ];
};
