import { Box, Typography, Grid } from "@mui/material";
import { SkeletonLoader } from "components/Skeleton";
import formatDate from "library/formatDate";
import { fixNumber } from "library/helper";
import { useSelector } from "react-redux";
import { formatDistanceToNowStrict } from "date-fns";
import fa from "date-fns/locale/fa-IR";
import en from "date-fns/locale/en-US";
import ar from "date-fns/locale/ar-SA";
import tr from "date-fns/locale/tr";
import { t } from "locales";
import { XTAKER_COIN } from "library/constants";

const locales = {
  fa,
  en,
  ar,
  tr,
};
const STATUS = {
  ACTIVE: "active",
  TERMINATED: "terminated",
  PENDING: "pending",
  WITHDRAWED: "withdrawed",
};

const PlanStatistics = ({ planData, loading = false }) => {
  const { lang, theme } = useSelector((state) => state.setting);
  const isLight = theme === "light";

  const dataRows = [
    {
      label: "planName",
      loadingContent: (
        <SkeletonLoader width="60" height="10" isLight={isLight} />
      ),
      value: (data) => data?.name ?? "-",
    },
    {
      label: "initialAmount",
      loadingContent: (
        <SkeletonLoader width="80" height="10" isLight={isLight} />
      ),
      value: (data) => (
        <>
          <span>{fixNumber(data?.initialAmount, 4) ?? "-"}</span>
          <span> {XTAKER_COIN}</span>
        </>
      ),
    },
    {
      label: "currentAmount",
      loadingContent: (
        <SkeletonLoader width="90" height="10" isLight={isLight} />
      ),
      value: (data) => (
        <>
          <span>{fixNumber(data?.currentAmount, 4) ?? "-"}</span>
          <span> {XTAKER_COIN}</span>
        </>
      ),
    },
    {
      label: "start",
      loadingContent: (
        <SkeletonLoader width="120" height="10" isLight={isLight} />
      ),
      value: (data) => (data?.start ? formatDate(data?.start, lang) : "-"),
    },
    {
      label: "remainingTime",
      loadingContent: (
        <SkeletonLoader width="60" height="10" isLight={isLight} />
      ),
      value: (data) =>
        data?.end ? (
          new Date(data?.end) - new Date() > 0 ? (
            <Box sx={{ span: { opacity: "0.6", fontSize: "0.8rem" } }}>
              {formatDistanceToNowStrict(new Date(data?.end), {
                locale: locales[lang],
              })}
              <span> ( of {data?.algotrexPlan?.duration} days )</span>
            </Box>
          ) : (
            "finished"
          )
        ) : (
          "-"
        ),
    },
    {
      label: "status",
      loadingContent: (
        <SkeletonLoader width="60" height="10" isLight={isLight} />
      ),
      value: (data) => t(STATUS[data?.status]) ?? "-",
    },
  ];

  return dataRows.map(({ loadingContent, label, value }) => (
    <Grid key={label} item xs={12} sm={6} md={4}>
      <Box>
        <Typography variant="body1" sx={{ opacity: "0.8" }}>
          {t(label)}
        </Typography>
        {loading ? (
          loadingContent
        ) : (
          <Typography variant="body1">{value(planData)}</Typography>
        )}
      </Box>
    </Grid>
  ));
};

export default PlanStatistics;
