export const styles = {
  wrapper: {
    mt: 10,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    mb: 4,
  },
  subTitle: {
    fontWeight: 600,
    color: (theme) => theme.palette.primary.contrastText,
    mt: 1,
  },
  title: {
    px: 2,
    py: 0.5,
    fontSize: { xs: "1.1rem", md: "1.25rem" },
    background: (theme) => theme.palette.primary.light,
    color: (theme) => theme.palette.primary.main,
    fontWeight: 600,
    borderRadius: 15,
  },

  planWrapper: {
    display: "flex",
    flexDirection: "column",
    bgcolor: "#fff",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    borderRadius: "24px",
    height: "100%",
    p: 3,
    transition: "all 0.2s ease-out",
    "&:hover": {
      border: "1px solid transparent",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  },
  planNumberWrapper: {
    maxWidth: "32px",
    height: 32,
    mb: 1,
  },
  planNumber: {
    width: "14px",
    height: "14px",
    borderRadius: "50%",
    bgcolor: (theme) => theme.palette.primary.main,
  },
  planTitle: {
    fontWeight: 600,
    my: 1,
  },
  planDescription: {
    fontSize: 14,
    color: "#5E6282",
  },
  iconWrapper: {
    background: (theme) => theme.palette.primary.light,
    width: 64,
    height: 64,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: 38,
      height: 38,
    },
  },
};
