const COINS_LISTS = {
    USDT: "tether",
    STYL: "stylike-governance",
    STL: "",
    BNB: "binancecoin",
    BUSD: "binance-usd",
    STARZ: "starz",
    MMT: "",
};

export const COIN_VALIDATOR = (value) => {
    const trueCoin = Object?.keys(COINS_LISTS)?.find((item) => item === value);
    return COINS_LISTS?.[trueCoin];
};