import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const PrivacyAndPolicyPage = () => {
  const personalCollectData = [
    {
      title: "Category of personal information",
      desc: "Personal details, contact details, and identifiers.",
    },
    {
      title: "Types of personal information captured by category",
      desc: "Name and contact details (such as e-mail, phone numbers when you voluntarily have shared this information with us in order to be contacted)",
    },
    {
      title: "Purpose",
      desc: "Facilitating communication with you for customer support purposes.",
    },
    {
      title: "Legal Basis (UK & GDPR)",
      desc: "Our legitimate interests in ensuring proper communication and handling within the organization. Your consent when we ask for it to process your personal information for a specific purpose that we communicate to you. When you consent to processing your personal information for a specified purpose, you may withdraw your consent at any time and we will stop processing your data for that purpose.",
    },
    {
      title: "",
      desc: "System and application access data and internet and electronic network activity information",
    },
    {
      title: "",
      desc: "The Internet protocol (IP) address used to connect your computer or mobile device to the Internet. Tradergpt Services metrics (e.g., the occurrences of technical errors, your interactions with service features and content and your setting preferences) Records of any contractual agreements between yourself and Tradergpt",
    },
    {
      title: "",
      desc: "Provide troubleshooting, and improve Tradergpt Services. To analyse performance, fix errors, and improve the usability and effectiveness of Tradergpt services. Necessary for the performance of a contract to which you are a party.",
    },
    {
      title: "",
      desc: "Our legitimate interests and the interests of our users in, for example, detecting and preventing fraud and abuse in order to protect the security of our users, ourselves, or others. Our legitimate interests in keeping records and necessary for the performance of your contract.",
    },
  ];

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <title>Privacy and policy | Tradergpt</title>
        <meta name="description" content="Privacy and policy | Tradergpt" />
      </Helmet>

      <Box sx={{ display: "flex", flexDirection: "column", my: 5 }} className="container">
        <Box sx={{ mb: 3 }}>
          <Typography variant="h3" sx={{ mb: 3 }}>
            Privacy And Policy
          </Typography>
        </Box>

        <Typography sx={{ mb: 2 }}>
          Please note that this privacy statement will regularly be updated to reflect any change in the way we handle your personal information or any changes in applicable laws.
        </Typography>
        <Typography sx={{ mb: 2 }}>
          This Privacy Notice describes the policies and procedures of fargo lab, Inc., (“we,” “our,” or “us”) pertaining to the collection, use, and disclosure of your information on{" "}
          <span>
            <Link to="/" style={{ textDecoration: "none" }}>
              Tradergpt.ai
            </Link>
          </span>{" "}
          and related mobile applications and products we offer (the “Services” or “Tradergpt”).
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          OVERVIEW
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Your right to privacy and the protection of your personal data is important to us. The following sections provide further details as to how we process your personal information through
          Tradergpt. We don’t share your information with third parties except to deliver you our Services and products, comply with the law, make Tradergpt better, protect our rights, or effectuate a
          business transfer. We’re not a huge, faceless corporation. We’re just developers trying to deliver an incredible product. If you have any questions or concerns about this policy, please
          reach out to us at info@Tradergpt.ai.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          HOW DO YOU ACCEPT THIS POLICY?
        </Typography>
        <Typography sx={{ mb: 2 }}>
          By using Tradergpt, including downloading one of our mobile applications or visiting our website, you agree to the use, disclosure, and procedures outlined in this Privacy Policy.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          WHAT PERSONAL INFORMATION DO WE COLLECT FROM OUR USERS?
        </Typography>
        <Typography sx={{ mb: 2 }}>
          We do our best to minimize the amount of Personal Information that we collect from Tradergpt users. Your contact information, such as your phone number,, or email address (depending on how
          you contact us), may be collected when you communicate with us for support, or if you report a bug or other error related to Tradergpt or when you interact with us through social media. When
          you use our Services, we process PUBLIC wallet addresses that you generate through Tradergpt.
        </Typography>

        <Grid container spacing={3} mb={1}>
          {personalCollectData.map((item, index) => (
            <Grid key={index} item sx={12} md={3}>
              {item.title !== "" && (
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {item.title}
                </Typography>
              )}
              <Typography sx={{ mb: 2 }}>{item.desc}</Typography>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h6" sx={{ mb: 2 }}>
          WILL WE SHARE YOUR PERSONAL DATA WITH THIRD PARTIES?
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Information about our users is an important part of our business and we are not in the business of selling our users’ personal information to others. We may transfer personal data to our
          service providers or third parties in connection with Tradergpt’s operation of its business, as certain features on Tradergpt rely on various third-party products and services (collectively
          “Third Party Services”), . These third party services providers only have access to certain Personal Information, such as your public Wallet addresses, to perform their functions and may not
          use it for other purposes. Furthermore, they must process the personal information in accordance with our contractual agreements and only as permitted by applicable data protections laws.
          Business transfers: As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, user information generally is one of the transferred
          business assets but remains subject to the promises made in any pre-existing Privacy notice (unless, of course, the user consents otherwise). Also, in the unlikely event that Tradergpt or
          substantially all of its assets are acquired, users' information will be one of the transferred assets. We may share non-personally identifiable information publicly and with our partners-
          like publishers, advertisers, developers, or right holders.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          HOW WE USE THE INFORMATION WE GATHER
        </Typography>
        <Typography sx={{ mb: 2 }}>
          We primarily use the limited information that we collect to enhance Tradergpt. Except if we sell all or a portion of our business, or as otherwise described below, we do not rent, trade, or
          sell your Personal Information. Some ways we may use your Personal Information are to: Contact you when necessary; Respond to your comments, questions, or issues related to bugs or errors
          with Tradergpt; Provide you with additional information; Send you information and marketing materials about services and products available through Tradergpt, using push notifications or
          other means; Train our team members; Other internal business purposes. Aggregated Personal Data and Non-Personal Information We may share or disclose aggregated Personal Data or Non-Personal
          Information with service providers or with other persons we conduct business with, including but not limited potential third-parties for the purpose of showcasing the performance of the
          company. These service providers and other persons may also share with us aggregated Non-Personal Information that they have independently developed or acquired. Additionally, we may combine
          aggregate information from the pixel tags, and web beacons, with similar data we collect from other visitors to help us improve our Services. When doing so, we do our best to ensure that
          aggregated information cannot be linked back to you. Agents or Third Party Partners We may provide your Personal Information to our employees, contractors, agents, service providers, and
          designees (“Agents”) to enable them to perform certain services for us, including improvement of website-related services and features, and performance of maintenance services. Business
          Transfers We may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets that would be transferred. Also, if we (or our
          assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, your Personal Information could be one of the assets transferred to or
          acquired by a third party. By accepting this Privacy Notice, as outlined above, you consent to any such transfer. Protection of Us and Others We will share personal information outside of
          Tradergpt if we have a reasonable belief that access, use, preservation, or disclosure of the information is reasonably necessary to comply with any applicable law, regulation, legal
          process, or enforceable governmental request; to cooperate with law enforcement; to enforce or apply our Terms of Use and other agreements; or to protect the rights, property, or safety of
          Tradergpt, our employees, our users, or others.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          YOUR RIGHTS WITH RESPECT TO THE PROCESSING OF PERSONAL DATA
        </Typography>
        <Typography sx={{ mb: 2 }}>
          You are entitled (under the conditions, and subject to the exceptions, set out in applicable law) to: Request Access to the personal information we process about you: You can request access
          to the information we have collected from you. You can do this by contacting us at info@Tradergpt.ai. We will provide you with a copy of the data we process about you. To comply with your
          request, we may ask you to verify your identity. We will fulfill your request by sending your copy electronically. For any subsequent access request, we may charge you an administrative fee.
          Request a rectification of your personal data: If you believe that the information we have collected is incorrect or incomplete, you may contact us so we can update it and keep your data
          accurate. Object to the processing of your personal data: you may request that we no longer process your personal data. Request to erase your personal data: you may request the erasure of
          your personal data, including where such personal data would no longer be necessary to achieve the purposes for which it was collected. Any data that is no longer needed for purposes
          specified in the “How We Use the Information We Gather” section will be deleted after ninety (90) days. Wallet addresses created through the Tradergpt application cannot be deleted from the
          Ethereum blockchain, therefore we are unable to delete this personal information. If at any point you wish for Tradergpt to delete information about you, you may contact us at
          info@Tradergpt.ai. Request the restriction of the processing of your personal data: you may request that Tradergpt only processes your personal data in limited circumstances, including with
          your consent. Under California Consumer Protection Act (“CCPA”) you also have the right not to be discriminated against because of the exercise of your rights. Please note that we do not
          sell your personal data.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          DATA RETENTION
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Please note that even If you delete your Wallet or addresses from the Tradergpt mobile application, uninstall Tradergpt mobile applications from your device, or request that your information
          be deleted, we still may retain some information that you have provided to us to maintain Tradergpt or to comply with the laws and regulations to which Tradergpt is subject. If you have any
          question or objection as to how we collect and process your personal information, please contact info@Tradergpt.ai.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          DATA SECURITY
        </Typography>
        <Typography sx={{ mb: 2 }}>
          We are committed to making sure your information is protected in accordance with applicable laws and our data privacy policies. We have selected third-party vendors that use the Ethereum
          network, including Coinbase, Shapeshift, and Changelly, that help us keep your Personal Information safe. Unfortunately, we do not control these third parties and therefore cannot guarantee
          complete security. We work to protect the security of your personal information during transmission by using encryption protocols and software. We maintain physical, electronic and
          procedural safeguards in connection with the collection, storage and disclosure of your personal information and secure all connections with industry standard transport layer security. Even
          with all these precautions, we cannot fully guarantee against the access, disclosure, alteration, or deletion of data through events, including but not limited to hardware or software
          failure or unauthorized use. Any information that you provide to us is done so entirely at your own risk.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          CHILDREN
        </Typography>
        <Typography sx={{ mb: 2 }}>
          We are especially sensitive about children’s information. Our Services are not targeted towards children, and our users must be at least eighteen (18) years old to use our services. We don’t
          knowingly collect information from children under the age of 13. If you are a parent or legal guardian of a minor child, we will treat any information that you provide us while using
          Tradergpt on behalf of your minor child as Personal Information as otherwise provided in this Privacy Policy. If you have questions concerning our information practices with respect to
          children, or if you learn that a child under the age of 13 has used Tradergpt, created a user account, or provided us with personal information, please email us at info@Tradergpt.ai
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          CONDITIONS OF USE, NOTICES, CHANGES AND UPDATES TO PRIVACY NOTICE
        </Typography>
        <Typography sx={{ mb: 2 }}>
          If you choose to use Tradergpt, your use and any dispute over privacy is subject to this Notice and our Terms of Use. If you have any concerns about privacy at Tradergpt, please contact us
          with a complete description, and we will try to resolve it. You also have the right to contact your local Data Protection Authority. We reserve the right to update and revise this Privacy
          Nolicy at any time. We occasionally review this Privacy Notice to make sure it complies with applicable laws and conforms to changes in our business. If we do revise this Privacy Notice, we
          will update the “Effective Date” at the top of this page so that you can tell if it has changed since your last visit and will do our best to notify you. Please review this Privacy Notice
          regularly to ensure that you are aware of its terms. Any use of Tradergpt after an amendment to our Privacy Notice constitutes your acceptance to the revised or amended terms.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          QUESTIONS
        </Typography>
        <Typography sx={{ mb: 1 }}>We’d be happy to answer them. Shoot us an email or send us a note:</Typography>
        <ul>
          <li>
            Email:{" "}
            <span>
              <a href="mailto:info@Tradergpt.ai" style={{ color: "inherit", textDecoration: "none" }}>
                info@Tradergpt.ai
              </a>
            </span>
          </li>
          {/* <li>Mailing Address: fargo lab, Inc. 2810 N Church St, PMB 96630 Wilmington, Delaware 19802 US</li> */}
        </ul>
        <Typography sx={{ mb: 2 }}>Thanks for reading our Privacy Policy!</Typography>
      </Box>
    </>
  );
};

export default PrivacyAndPolicyPage;
