import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import Header from './Header';
import List from './List';

const FAQ = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, []);
    return (
        <>
            <Header />
            <Box className="container">
                <List /> 
            </Box>
        </>
    );
};

export default FAQ;