export const styles = {
    wrapper: {
        display: { xs: "none", md: "block" },
        borderRadius: "24px",
        bgcolor: (theme) => `card.${theme.palette.mode}`,
        border: "1px solid",
        borderColor: "#eee",
        py: 2,
    },
    userDetailWrapper: {
        px: 2
    },
    avatarWrapper: {
        border: '3px solid',
        borderColor: 'rgb(220,220,220)',
        borderRadius: '50%',
        mx: 'auto',
        width: 72, 
        height: 72,
        mb: 1.5,
    },
    avatar: {
        width: '100%', 
        height: '100%',
    },
    name: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 600,
    },
    email: {
        fontSize: 12,
        textAlign: 'center',
        wordBreak: 'break-word',
        opacity: 0.8
    },
    buttonWrapper: {
        mt: 1,
        mb: 4,
    },
    bscButton: {
        borderRadius: 8,
        border: '1px solid',
        borderColor: 'primary.main',
        color: 'primary.main',
        width: '100%',
        fontSize: 12,
        py: 1,
    },
    editButton: {
        borderRadius: 8,
        bgcolor: 'primary.main',
        color: '#FFF',
        width: '100%',
        fontSize: 12,
        py: 1,
    },
    bscAddrressWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 0.5,
        mt: 1,
    },
}