import { Instagram, Twitter, YouTube } from "@mui/icons-material/";
import DiscordIcon from "assets/icons/DiscordIcon";
import { instagram, twitter, telegram, youtube, discord } from "library/links";
import TelegramIcon from "@mui/icons-material/Telegram";
import { mainRoutes } from "routing/GeneralRoutes/routes";

export const accountItems = [
  {
    name: "Profile",
    link: "/account",
  },
  {
    name: "Favorites",
    link: "/account/favorites",
  },
  {
    name: "My Collections",
    link: "/my-collection",
  },
  {
    name: "Settings",
    link: "/account/settings?tab=profile",
  },
  {
    name: "Rankings",
    link: "/rankings",
  },
];
export const resourcesItems = [
  {
    name: "FAQ",
    link: mainRoutes.faq,
  },
];

export const companyItems = [
  {
    name: "Plans",
    link: mainRoutes.plans,
  },
  // {
  //   name: "Presale",
  //   link: mainRoutes.presale,
  // },
  {
    name: "Investing",
    link: mainRoutes.investing.base,
  },
  {
    name: "Order Management",
    link: mainRoutes.trading.orderManagement,
  },
];
export const socialLinks = [
  {
    key: "instagram",
    icon: Instagram,
    link: instagram,
  },
  {
    key: "twitter",
    icon: Twitter,
    iconProps: {},
    link: twitter,
  },
  {
    key: "telegram",
    icon: TelegramIcon,
    iconProps: {},
    link: telegram,
  },
  {
    key: "youtube",
    icon: YouTube,
    iconProps: {},
    link: youtube,
  },
  {
    key: "discord",
    icon: DiscordIcon,
    iconProps: {},
    link: discord,
  },
];

export const linksList = [
  {
    title: "TraderGPT",
    list: [
      { title: "Plans", link: mainRoutes.base, scrollTo: "#plans" },
      // { title: "Presale", link: mainRoutes.presale },
      // { title: "AI Signals", link: mainRoutes.signals },
      { title: "Services", link: mainRoutes.services },
      { title: "Investing", link: mainRoutes.investing.base },
    ],
  },
];
