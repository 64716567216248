import React from "react";
import List from "./List";
import { useGetAllPlans } from "_hooks/Xtakers";
import { Stack, Typography, Box } from "@mui/material";
import { HashScroll } from "react-hash-scroll";

export default function Plans() {
  const { data: plans, isFetching: isLoading } = useGetAllPlans();

  return (
    <HashScroll hash="plans">
    <Stack sx={{ width: "100%", mt: 8, mb: 15 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.75,
        }}
      >
        <Box
          sx={{
            height: "18px",
            width: "2px",
            background: (theme) => theme.palette.primary.main,
          }}
        />
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontSize: 18,
            fontWeight: 600,
          }}
        >
          Tradergpt plans
        </Typography>
      </Box>
      <List plans={plans} isLoading={isLoading} />
    </Stack>
    </HashScroll>
  );
}
