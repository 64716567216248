import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hideMobileSidebar } from "store/reducers/app/appSlice";
import { t } from "locales";
import { topMenuItems } from "./mainSidebar.config";
import { logout } from "store/reducers/auth/asyncActions";
import { navbarMenu } from "enum/navbarMenu";
import { Circle, ExpandMoreRounded } from "@mui/icons-material";
import { useGetAllPlans } from "_hooks/Xtakers";
import { auth } from "routing/GeneralRoutes/routes";

const drawerExpandedWidth = 240;

const MainSidebarContent = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user);
  const smallDevice = useMediaQuery("(max-width:1200px)");
  const { pathname } = useLocation();
  const { mobileSidebar } = useSelector((state) => state.app);
  const { data: plans, isFetching: isLoading } = useGetAllPlans();
  const hideHandler = () => {
    dispatch(hideMobileSidebar());
  };

  const logoutHandler = () => {
    dispatch(logout());
  };
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const drawerStyle = {
    width: drawerExpandedWidth,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      left: "0 !important",
      width: drawerExpandedWidth,
      boxSizing: "border-box",
      backgroundImage: "none",
      backgroundColor: (theme) => theme.palette.background[theme.palette.mode],
    },
  };

  return (
    <Box>
      <Drawer
        variant="temporary"
        open={smallDevice && mobileSidebar}
        onClose={hideHandler}
        sx={drawerStyle}
        anchor={"left"}
      >
        <Box
          sx={{
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "72px",
          }}
        >
          <List sx={{width: drawerExpandedWidth - 40}}>
            {!!user ? (
              <Box>
                <Button
                  onClick={logoutHandler}
                  sx={(theme) => ({
                    my: 2,
                    padding: "7px 20px",
                    width: "100%",
                    bgcolor: theme.palette.primary.main,
                    borderRadius: 2.4,
                    color: "#FFF",
                  })}
                >
                  {"logout"}
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  py: 1,
                }}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    
                  }}
                  component={Link}
                  to={auth.register}
                  onClick={hideHandler}
                >
                  {t("getStarted")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={[
                    {
                      boxShadow: "none",
                      padding: "7px 20px",
                    },
                  ]}
                  component={Link}
                  to="/auth/login"
                  onClick={hideHandler}
                >
                  {t("login")}
                </Button>
              </Box>
            )}

            {!!user ? topMenuItems.map(
              (
                { title, icon: Icon, path = "/404", as, href, target }, index
              ) => (
                <ListItem
                  as={as}
                  to={path}
                  href={href}
                  onClick={hideHandler}
                  target={target}
                  key={index}
                  sx={{
                    padding: "6px 0px",
                    ".MuiListItemText-primary, .icon": {
                      color:
                        !!path && pathname === path ? `primary.main` : "unset",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      fill: (theme) =>
                        theme.palette.mode === "dark" ? "white" : "#333",
                      color: (theme) =>
                        theme.palette.mode === "dark" ? "white" : "#333",
                    }}
                  >
                    <Icon fontSize="small" className="icon" />
                  </ListItemIcon>
                  <ListItemText
                    primary={t(title)}
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === "dark" ? "white" : "#333",
                      textAlign: "left",
                    }}
                  />
                </ListItem>
              ) 
            ) : null}
            <Divider />
            <Box sx={{mt: 1}}>
              {navbarMenu({plans, planLoading: isLoading})?.map(({icon: Icon, title, id, submenu, as, to, target}) => {
               if(!submenu?.length ) {return (
                <ListItem
                  as={as}
                  to={to}
                  onClick={hideHandler}
                  key={id}
                  target={target}
                  sx={{ padding: "6px 0px" }}
                >
                <ListItemIcon
                  sx={{
                    fill: "#333",
                    color: "#333",
                  }}
                >
                  <Icon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                    primary={t(title)}
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === "dark" ? "white" : "#333",
                      textAlign: "left",
                    }}
                  />
                </ListItem>
               )} else return (
                <Box sx={{
                  '.MuiListItem-root': {
                    p: "0px !important",
                    display: "block",
                  },
                  '.MuiPaper-root': {
                    boxShadow: "none",
                  },
                  '.MuiButtonBase-root': {
                    minHeight: 48
                  },
                  '.MuiAccordionSummary-content': {
                    my: 0,
                  }
                }}>
                <ListItem>
                    <Accordion
                      expanded={expanded === title} onChange={handleChange(title)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreRounded />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{p: 0}}
                      >
                        <ListItemIcon
                          sx={{
                            fill: "#333",
                            color: "#333",
                          }}
                        >
                          <Icon fontSize="small" />
                        </ListItemIcon>
                        <Box sx={{display: "flex", justifyContent: "space-between", flex: 1, alignItems: "center", width: "100%"}}> 
                          <Typography sx={{color: expanded === title ? "primary.main" : "#333" }}>
                            {t(title)}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {submenu?.map((item) => (
                          <Box
                            as={item?.as}
                            to={item?.to}
                            onClick={hideHandler}
                            key={item?.id}
                            sx={{
                              display: "flex", 
                              alignItems: "center", 
                              textDecoration: "none !important",
                            }}
                          >
                            <Circle sx={{width: 10, height: 10, mr: 2}} color="primary" />
                            <Typography sx={{
                                color: pathname === item?.to ? "primary.main" : "#333",
                                py: 0.8,
                                flex: 1,
                                textDecoration: "none !important",
                                fontSize: 14,
                              }}>
                              {t(item?.title)}
                            </Typography>
                          </Box>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </ListItem>
                </Box>
              )}
              )}
            </Box>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MainSidebarContent;
