export const styles = {
    wrapper: {
        // backgroundImage: `url(${bgImage})`,
        // backgroundSize: {xs: '100%',md: '80%'},
        // backgroundPosition: 'right',
        // backgroundRepeat: 'no-repeat',
        pt: 12,
    },
    title: {
        fontSize: {
            xs: "1.6rem",
            sm: "2rem",
            md: "2.3rem",
            lg: "2.6rem",
          },
          fontWeight: "700",
          color: (theme) => theme.palette.primary.contrastText,
          lineHeight: 1.4,
          "& span": {
            color: (theme) => theme.palette.primary.main,
          },
    },
    description: {
        mt: 2,
        color: "#5E6282", 
        fontSize: 17,
        lineHeight: '32px',
    },
    securityImage: {
        '& img': {
            width: '100%',
            borderRadius: '16px',
        }
    },
    signalButton: {
        bgcolor: 'primary.main',
        color: '#FFF',
        textDecoration: 'none',
        borderRadius: '12px',
        px: 3,
        py: 2,
        fontSize: 16,
        width: {xs: '100%', md: 'fit-content'}
    },
};