import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Avatar from 'react-avatar-edit';
import { Button } from '@mui/material';
import { openToast } from 'components/Toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const buttonStyle = {
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 2.4,
    bgcolor: 'primary.main',
    color: '#FFF',
    flex: 1,
}

export default function AvatarModal(props) {
    const { imageSrc, createImage, handleClose, minSize } = props;
    const [preview, setPreview] = useState();
  
    const onClose = () => setPreview(null);
    const onCrop = (preview) => setPreview(preview);
  
    const onBeforeFileLoad = (elem) => {
      if (elem.target.files[0].size > minSize) {
        openToast("warning", "Maximum supported file size is 5MB!");
        elem.target.value = "";
      }
    };
  return (
    <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Box>
                <Avatar
                    width={"100%"}
                    height={295}
                    onCrop={onCrop}
                    onClose={onClose}
                    onBeforeFileLoad={onBeforeFileLoad}
                    src={imageSrc}
                    labelStyle={{ color: "#333" }}
                />
                <Box sx={{
                    display: "flex",
                    mt: 2,
                    gap: 1,
                }}>
                    <Button 
                        onClick={handleClose} 
                        sx={buttonStyle}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => createImage(preview)}
                        sx={buttonStyle}
                    >
                        Crop
                    </Button>
                </Box>
            </Box>
        </Box>
    </Modal>
  );
}