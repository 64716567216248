const xtakerEn = {
  xtaker: "Tradergpt",
  xtakerPlans: "Tradergpt plans",
  plansHistory: "Plans history",
  activePlans: "Active plans",
  addFundHeader: "Add funds to Financial account",
  initialAmount: "Initial amount",
  currentAmount: "Current amount",
  plan: "Plan",
  planName: "Plan name",
  start: "Start",
  end: "End",
  action: "Action",
  remainingTime: "Remaining Time",
  planDetail: "Plan detail",
  withdrawPlan: "Withdraw Plan",
  terminatePlan: "Terminate Plan",
  upgradePlan: "Upgrade Plan",
  confirmTerminatePlanDesc: "Are you sure about terminate your plan?",
  cancel: "Cancel",
  terminate: "Terminate",
  xtakerWallet: "Tradergpt Wallet",
  totalBalance: "Total Balance",
  xtakerBalance: "Tradergpt Balance",
  spotBalance: "Spot balance",
  xtakerProfit: "Tradergpt profit",
  noActiveXtakerPlan: "There is no active plan!",
  subscribe: "Subscribe",
  subscribeNow: "Subscribe Now",
  xtakerMainHeader: "Cryptocurrency Experts Trade for You!",
  xtakerSubHeader: `Use your stablecoins in high-interest accounts and earn a passive income.`,
  plans: "Plans",
  tradersInfoMainHeader: "Top traders work for you and the platform makes it 100% RISK-FREE!",
  tradersInfoDesc:
    "Subscribe to one of Tradergpt plans and our top traders start trading for you with zero fees and zero risk. There are no hidden costs involved. You will be able to check the trading results everyday.",
  results: "Results",
  todayProfit: "Today's Profit",
  ranking: "Ranking",
  claimProfit: "Claim Profit",
  changeTrader: "Change Trader",
  earnByMinute: "Earn by the Minute",
  earnByMinuteDesc: "The more crypto you invest, the higher your earnings",
  newPlan: "New plan",
  fromTo: "From/To",
  totalProfit: "Total Profit",
  profit: "Profit",
  asset: "Asset",
  yourProfit: "Your Profit",
  tradesHistory: "Trades History",
};

export default xtakerEn;
