export const styles = {
  wrapper: {
    marginTop: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    maxWidth: "70%",
  },

  title: {
    fontSize: {
        xs: "1.2rem",
        lg: "2.0rem",
      },
    fontWeight: 600,
    textAlign: "center",
    '& span': {
        color: (theme) => theme.palette.primary.main,
        textDecoration: "underline",
    },
    mb: 8,
  },
  imageWrapper: {
    "& img": {
      width: "100%",
    },
  },
  subtitle: {
    color: (theme) => theme.palette.primary.main,
    fontWeight: 600,
  },
  description: {
    fontSize: "16px",
    color: "#5E6282",
  },
  button: {
      color: "#fff",
      backgroundColor: "primary.main",
      px: "24px",
      py: "12px",
      borderRadius: "12px",
      textDecoration: "none",
      textAlign: "center",
      mr: "12px",
      boxShadow: `none`,
      transition: "all 0.2s ease-out",
      "&:hover": {
        color: "#fff",
        backgroundColor: "primary.main",
        boxShadow: `4px 4px 15px rgba(41,  98, 255, 0.3)`,
        transform: "scale(1.04)",
      },
  },
};
