import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { inputAutoFillStyles } from "assets/styles/styles";
import { t } from "locales";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomButton from "components/CustomButton";

const EmailTab = ({ onSubmit, control, errors, loading }) => {
  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      noValidate
      sx={{ mt: 1, width: "100%" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{'& .MuiFormControl-root': { my: 0 }}}>
          <Typography sx={{ mb: 0.5 ,fontSize: 14, fontWeight: 500}}>E-mail</Typography>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                error={errors?.email}
                helperText={t(errors?.email?.message)}
                margin="normal"
                fullWidth
                id="email"
                placeholder="Enter Your Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                sx={{ ...inputAutoFillStyles }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomButton
            type="submit"
            fullWidth
            withSpinner
            variant="contained"
            extraSx={{
              borderRadius: 2.4,
              width: "100%",
              color: "#FFF",
              py: "11.15px",
              mt: 1,
              mb: 1,
            }}
            disabled={loading}
            loading={loading}
          >
            {t("next")}
          </CustomButton>
        </Grid>
      </Grid>
      <Typography
        as={Link}
        to="/auth/login"
        variant="body2"
        sx={{
          cursor: "pointer",
          textDecoration: "none",
          color: "inherit",
        }}
      >
        {t("back")}
      </Typography>
    </Box>
  );
};

export default EmailTab;
