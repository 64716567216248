import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { useState } from "react";
import { t } from "locales";

const Icon = styled("img")({
  width: "25px",
  height: "25px",
  borderRadius: "50%",
});

const SelectInput = ({
  options = [],
  withIcon = false,
  onChange,
  value,
  label,
  shrink = false,
  containerSx = {},
  selectSx = {},
  labelSx = {},
  itemSx = {},
  setId = false,
  defaultValue = false
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleChange = (event) => {
    const newValue = event.target.value;
    const item = typeof newValue === "string" ? options.find((o) => o.id === newValue) : newValue;
    if(!!setId) {
      onChange(item?.id);
    } else {
      onChange(newValue);
    }
  };
  return (
    <FormControl sx={{ minWidth: 120, ...containerSx }}>
      {label && (
        <InputLabel shrink={shrink} sx={{ ...labelSx }}>
          {label}
        </InputLabel>
      )}
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        displayEmpty
        label={label}
        onChange={handleChange}
        sx={{ ...selectSx, borderRadius: 2 }}
      >
      {!!defaultValue && value === 1000 && <MenuItem value={1000} key={1000}>{defaultValue}</MenuItem>}
        {options?.map?.((item) => (
          <MenuItem value={!!setId ? item?.id : item} key={item.label}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                ...itemSx,
              }}
            >
              {withIcon && item.icon && <Icon src={item.icon} alt="" />}
              <Typography component="span" variant="body1">
                {t(item.label)}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
