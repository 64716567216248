const landing = {
  mainPageTag: "CHALLENGE YOUR SKILLS",
  mainPageHeader1: "TALENTED TRADERS",
  mainPageHeader2: "WANTED",
  mainPageDesc:
    "Challenge in tournaments & earn. Become our trader and get funded.",
  mainPageBtn: "START THE CHALLENGE",
  landingAboutTitle: "We're Investing On Top Traders",
  landingAboutContent:
    " is an NFT-based trading platform that allows you to challenge your trading skills and earn without money loss. Through our exclusive NFTs, you are able to participate in daily/weekly tournaments. The top-ranking traders will be funded with a $100K trading account.",
  highlightsTitle: "Key highlights",
  highlightsTitle1: "Swing Account",
  highlightsTitle2: "Scaling Plan",
  highlightsTitle3: "Free Trial",
};

export default landing;
