import React from 'react';
import { styles } from "./styles";
import { Box, Typography } from '@mui/material';

const Card = ({data, length}) => {
    return (
        <Box sx={styles.roadmap}>
            <Box>
                <Typography sx={styles.sesionRoadmap}>{data?.sesion}</Typography>
                <Box sx={{...styles.colorLine, borderRadius: {xs: '16px', md: length === data?.id ? "0 16px 16px 0" : data?.id === 1 ? "16px 0 0 16px" : 0}}} />
                <Typography sx={styles.roadmapDescription}>{data?.description}</Typography>
            </Box>
            <Box sx={{...styles.roadmapLinesWrapper, display: length === data?.id ? "none" : "block"}}>
                <Box sx={styles.line} />
                <Box sx={styles.circle} />
            </Box>
        </Box>
    );
};

export {Card};