const mainFa = {
  brandName: "ولکس",
  Competitions: "مسابقات",
  home: "خانه",
  howToPlay: "نحوه انجام بازی",
  P2PPenalty: "پنالتی P2P",
  nftMarketplace: "بازار",
  community: "پیام ها",
  leaderboard: "جدول امتیازات",
  games: "بازی ها",
  buyVLX: "خرید توکن",
  support: "پشتیبانی",
  dashboard: "داشبورد",
  setting: "تنظیمات",
  settings: "تنظیمات",
  logout: "خروج",
  profile: "حساب کاربری",
  somethingWentWrong: "مشکلی پیش آمده است!",
  loading: "در حالی بارگذاری",
  noData: "داده ای موجود نیست!",
  search: "جستجو",

  mainPageTag: "NFT بستر بازی",
  mainPageHeader: "بازی ولکس",
  mainPageDesc1: "تجربه دنیای واقعی",
  mainPageDesc2: "راهبردهای دنیای فانتزی",
  mainPageBtn: "ماجراجویی خود را شروع کن!",

  feedTitle: "خبرنامه بازی",
  feedDesc: "جدیدترین های بازی ولکس",
  feedBtn: "چگونه بازی کنم‌؟",
  newPackTitle: "مجموعه های جدید",
  newPackDesc: "کارت های جدید اعلامی",
  tradeCardsTitle: "جمع آوری و معامله کن",
  sponsoredBy: "حامیان",
  buildAndManageTitle: "تیم خودت رو بساز و مدیریت کن",
  buildAndDescription: `استراتژی بسازید، صف بندی ایجاد کنید و بر اساس عملکرد بازیکنان در واقعیت امتیاز کسب کنید  `,
  clubsDataTitle: "120 باشگاه به طور رسمی ثبت شده اند",
  clubsDataDesc: "باشگاه های جدید در هر هفته",
  tradeCardsDesc: "NFT کارت های قابل گرد آوری",
  bannersTitle: "تمامی بازی های جدید امسال",
  bannersDesc:
    "در بازی ولکس رایگان ثبت نام کن ، تیم خود را جمع کن و به کسب در آمد بپرداز!",
  bannersBtn: "اطلاعات بیشتر",
  language: "زبان",

  footerTitle: "پلتفرم بازی NFT",
  aboutUs: "درباره ما",
  quickLinks: "درسترسی سریع",
  all: "همه",
  privacyPolicy: "سیاست حفظ حریم خصوصی",
  termsAndConditions: "شرایط و ضوابط",

  newPackSliderBtn: "خرید",
  blog: "اخبار",
  penalty: "پنالتی",

  noNewNotif: "اعلان جدیدی موجود نیست",
  blockchainDetails: "جزئیات شبکه",
  nftTokenId: "شناسه توکن NFT",
  OPEN: "باز",
  LIVE: "زنده",
  iUnderstand: "من متوجه هستم",
  notificationDetail: "جزئیات اعلان",
  unreadNotifications: "اعلان جدید",
  clearAll: "پاک کردن همه",
  viewAll: "مشاهده همه",
  BUY: "خرید",
  enterAmount: "مبلغ را وارد کنید",
  // "limited league": "لیگ محدود",
};

export default mainFa;
