import React from "react";
import { styles } from "./styles";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { DesktopWindowsRounded, SmartphoneRounded, WebAssetRounded } from "@mui/icons-material";

const Access = () => {
  const isSmall = useMediaQuery("(max-width:904px)");
  const plans = [
    {
      id: 1,
      Icon: WebAssetRounded,
      title: "Browser",
      description: "Trade from anywhere you have an Internet connection with no software to install or maintain.",
    },
    {
      id: 2,
      Icon: DesktopWindowsRounded,
      title: "Desktop",
      description: "Maximize your view of the markets on up to 16 monitors with our highest-performing user interface, designed for the most demanding traders.",
    },
    {
      id: 3,
      Icon: SmartphoneRounded,
      title: "Mobile",
      description: "Access TT from your pocket with an interface tailored to the mobile experience.",
    },
  ];
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.titleWrapper}>
        <Typography sx={styles.title} data-aos="fade-up" data-aos-duration="500">
          01 ACCESS
        </Typography>
        <Typography sx={styles.subTitle} data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
          Connect virtually anywhere from your workstation, laptop, or Android or iOS phone
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={isSmall ? 3 : 1}>
          {plans?.map(({ id, title, Icon, description }) => (
            <Grid item xs={12} md={4} key={id} data-aos="fade-up" data-aos-duration="500" data-aos-delay={id * 50 + 100}>
              <Box sx={styles.planWrapper}>
                <Box sx={styles.iconWrapper}>
                  <Icon />
                </Box>
                <Box>
                  <Typography sx={styles.planTitle}>{title}</Typography>
                  <Typography sx={styles.planDescription}>{description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Access;
