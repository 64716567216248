import React, { useState } from 'react';
import SwapSystem from './Swap';
import Chart from './Chart';
import Details from './Details';

import { Box, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';

const Swap = () => {
    const [coin, setCoin] = useState("STZ");
    return (
        <>
        <Helmet>
            <title>Swap | TraderGPT</title>
            <meta name="description" content="Swap | TraderGPT" />
        </Helmet>
        <Box className='container' sx={{mt: 10, mb: 6}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <Chart />
                </Grid>
                <Grid item xs={12} md={5}>
                    <SwapSystem selectedCoin={setCoin} />
                </Grid>
                <Grid item xs={12}>
                    <Details coin={coin} />
                </Grid>
            </Grid>
        </Box>
        </>
    );
};

export default Swap;