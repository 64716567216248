import { Box, Grid, Typography } from "@mui/material";
import { styles } from "./styles";

const LeftSide = ({ data }) => {
  return (
      <Grid container spacing={3}>
        {data.map((item, i) => (
          <Grid item xs={12} md={6} lg={4}>
            <Box key={`${i}-leftSideData`} sx={styles.cardWrapper}>
              <Typography sx={styles.cardTitle}>{item?.title}</Typography>
              <Typography sx={styles.cardDescription}>{item?.description}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
  );
};

export default LeftSide;
