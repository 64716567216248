const termsAndConditionEn = {
  headerTitle: "TERMS AND CONDITIONS",
  termsAndConditions: "Terms & conditions",
  headerDesc: "TRADERGPT online service is made available by TRADERGPT is mainly hosted by Amazon Web Services. Contact : info@Tradergpt.ai",
  article1: "Article 1 – Definitions",
  article2: "Article 2 – Scope",
  article3: "Article 3 – Account",
  article4: "Article 4 – Services",
  article5: "Article 5 – Marketplace",
  article6: "Article 6 – Liability of the Users",
  article7: "Article 7 – Liability of Tradergpt",
  article8: "Article 8 – Termination",
  article9: "Article 9 – Tradergpt content",
  article10: "Article 10 – Privacy policy and Personal data",
  article11: "Article 11 – Hyperlink",
  article12: "Article 12 – Amendment",
  article13: "Article 13 – Non waiver",
  article14: "Article 14 – Jurisdiction and applicable law",

  "1.1.1": "1.1.1 For the purposes of these T&Cs, the following capitalized terms shall have the following meanings:",
  "1.1.1.item1": "Buyer: Refers to the User acquiring a Collectible on Tradergpt’s Marketplace.",
  "1.1.1.item2": "Collectible Refers to the digital cards featuring a professional football player available within the Service and the underlying tokens that can be used on a blockchain.",
  "1.1.1.item3":
    "Collectible Related Rights Refers to any third party patent rights, image rights, copyrights, trade secrets, trademarks, know-how or any other intellectual property rights recognized in any country or jurisdiction in the world associated with the Collectibles (including without limitation, the intellectual property rights of the club, the league or the image rights of the player).",
  "1.1.1.item4": "Cryptocurrency Refers to any digital asset designed to work as a medium of exchange on blockchain and accepted in payment throughout the Services.",
  "1.1.1.item5": "Currency Refers to any currency being legal tender (euro, dollar,pounds).",
  "1.1.1.item6": "Marketplace Refers to the service offered by Tradergpt that allows Users to exchange or sell Collectibles amongst them.",
  "1.1.1.item7":
    "NFT Refers to the non-fungible token, which represents the Collectible digital card (also known as crypto-good or crypto-collectible). This token ensures the scarcity of the Collectible and the transparency of the Services.",
  "1.1.1.item8": "Seller Refers to the User offering to sell his Collectible on Tradergpt’s Marketplace.",
  "1.1.1.item9": "Services Refers to every service accessible on the Website and provided by Tradergpt.",
  "1.1.1.item10":
    'Terms and Conditions or(T&Cs) Refers to this document. These Terms and Conditions constitute a legally binding agreement (hereinafter, the "T&Cs") between you and Tradergpt (as defined above) governing your access to and use of the Website and all associated Services.',
  "1.1.1.item11":
    "Third-Party Rights Refers to any third party patent rights, image rights, copyrights, trade secrets, trademarks, know-how or any other intellectual property rights recognised in any country or jurisdiction in the world, and, in particular, the rights that may be associated with the Collectibles.",
  "1.1.1.item12":
    "Third-Party Services Refers to the services not operated by Tradergpt, but providing content or additional features to the Users such as payment service (Stripe, Apple Pay), purchase and sale of digital assets in legal tender (Ramp, Moonpay), digital asset wallet (WalletConnect, Coinbase Wallet, Portis, Opera, Metamask), content on players, external marketplaces of collectibles. Whether or not integrated into the Website, these services are provided under the sole responsibility of the providers who offer them. The terms of use of these services can be found on the website of the relevant service provider.",
  "1.1.1.item13": "User Refers to any natural person or legal person using the services offered by the Service or visiting its website with a full legal capacity.",
  "1.1.1.item14": "Website Refers to the website www.Tradergpt.ai including any subdomains thereof, and any other websites through which Tradergpt makes its services available.",

  "2.1.1":
    "2.1.1 Tradergpt is an online website that enables Users to acquire, collect, exchange and play collectibles digital cards featuring professional football players designated, for the purposes hereof, as the Collectibles and materialised on NFTs. The service is available on the website https://Tradergpt.ai.",
  "2.1.2":
    "2.1.2 For more information, Users are invited to consult the Help Center (https://help.Tradergpt.ai), where information relating to the Services and answers to questions asked by Users are available.",
  "2.1.3":
    "2.1.3 These T&Cs apply, without restriction or reservation, to any use of the Services offered by Tradergpt on the Website to all. When the User chooses to create an account on the Website, he/she fully and unconditionally accepts these T&Cs.",
  "2.1.4":
    "2.1.4 The User acknowledges that he/she has the capacity to contract and to use the Services in his/her country and declares that he/she has read and accepted the T&Cs by ticking the box provided for this purpose. The account creation by the User constitutes acceptance without restriction or reservation of these T&Cs.",
  "2.1.5":
    "2.1.5 Unless proven otherwise, and as an evidence agreement, the data recorded in the Tradergpts computer system constitutes proof of all transactions concluded within the Services by the User.",
  "2.1.6": "2.1.6 These T&Cs are accessible at any time on the Website and will prevail over any other version or any other contradictory document.",
  "2.1.7":
    "2.1.7 The Website may contain links or content from Third-Party Services. Such Third-Party Services may be subject to different terms and conditions and privacy practices. Tradergpt is not responsible or liable for the availability or accuracy of such Third-Party Services, or the content, products, or services available from such Third-Party Services. Links to such Third-Party Services are not an endorsement by Tradergpt of such Third-Party Services.",

  3.1: "Article 3.1 – Creation and access",
  "3.1.1": "3.1.1 Any User can create an account on the Website by:",
  "3.1.1.item1": "providing his/her username, e-mail address;",
  "3.1.1.item2": "choosing a (strong) password;",
  "3.1.1.item3": "providing and verifying his/her mobile phone number;",
  "3.1.1.item4": "accepting the T&Cs and the Game Rules (https://Tradergpt.ai/game_rules) and any additional contractual documents governing the use of the Services.",
  "3.1.2":
    "3.1.2 Tradergpt shall not be held liable for the loss or theft of a password by the User, or by the resulting account impersonation. It is the User's responsibility to take all measures to secure access to the login and password of their account. Users are strongly encouraged to set up two-factor authentication. The User is responsible for the actions of any person using his account, including without his/her prior consent. He/she agrees to report any fraudulent use of his/her account, login or password to Tradergpt as quickly as possible.",
  "3.1.3":
    "3.1.3 The Service provides each account with a unique and personal wallet to ensure the conservation and the use of Collectibles and Cryptocurrencies in order to facilitate the use of the Services (the “Wallet”).",
  "3.1.4":
    "3.1.4 Users are free to use the Wallet to execute transactions in connection with the use of the Services. He/She may credit or debit the Wallet from an external wallet with his/her own Cryptocurrency and Collectibles at any time. Information on how to deposit and withdraw Collectibles or Cryptocurrencies to and from the Wallet from and to an external wallet is provided on the Help Center.",
  3.2: "Article 3.2 – Deletion",
  "3.2.1": "3.2.1 The User may, at any time, request the deletion of his/her account under the conditions set out by the Website.",
  "3.2.2":
    "3.2.2 The deletion of an account may result in the irreversible deletion of all User data associated with the account, as well as the associated Wallet and, thus, Collectibles and Cryptocurrencies stored. Thus, deletion of the account, regardless of the cause, may result in the permanent loss of Collectibles and Cryptocurrencies. Users are required to save their Collectibles and Cryptocurrencies stored on the Wallet on an external wallet before any deletion procedure.",
  3.3: "Article 3.3 – Suspension",
  "3.3.1":
    "3.3.1 Without excluding other coercive measures, Tradergpt reserves the right to temporarily or permanently suspend a User account in case of breach of these T&Cs by the User, unfair, fraudulent, criminal or harmful behaviour, and in particular but not exclusively, in the following cases:",
  "3.3.1.item1": "the User does not comply with all or part of these T&Cs;",
  "3.3.1.item2": "the User conducts auctions that are not carried out with the aim of purchasing a Collectible and prove to be of a nature to disrupt the proper functioning of the Services;",
  "3.3.1.item3": "the User carries out suspicious transactions, in particular by repeatedly buying or selling his/her Collectibles at prices that are clearly out of line with the market;",
  "3.3.1.item4": "the User is suspected of fraud;",
  "3.3.1.item5": "the User abuses or attempts to abuse SO5 Game, the Referral program or the Affiliate program;",
  "3.3.1.item6": "the User's login and password have been transmitted to third parties;",
  "3.3.1.item7": "the User uses several distinct accounts.",
  "3.3.2":
    "3.3.2 In case of suspension, Tradergpt informs the User of the measure by e-mail to the e-mail address associated with the account, indicating (i) the reason for the suspension, (ii) its scope, (iii) its permanent or temporary nature and, in the latter case, (iv) its duration.",
  "3.3.3":
    "3.3.3 In case of permanent suspension, the User has a period of thirty (30) days to proceed with withdrawals from his Wallet to an external wallet, at the end of which the account may be deleted under the conditions set out in clause 3.2. of the T&Cs.",
  "3.3.4":
    "3.3.4 Tradergpt has the right, but not the obligation, to ask the User to provide explanations about the account activity considered suspicious. The User has the right to provide Tradergpt spontaneously with any useful information concerning the account activity considered as suspicious. In case of dispute concerning a suspension measure, the User is invited to contact, before any other action, the support team (support@Tradergpt.ai).",
  3.4: "Article 3.4 – Referral program",
  "3.4.1":
    '3.4.1 Tradergpt offers its Users the possibility to participate in the referral program governed by this clause (the “Referral Program"). The Referral Program offers Users (the “Referrer”) the opportunity to earn rewards by inviting others (the “Referee”) to use the Services.',
  "3.4.2": "3.4.2 Participation in the Referral Program is subject to the following conditions:",
  "3.4.2.item1": "the Referrer has a legitimate account on the Website;",
  "3.4.2.item2": "the Referrer complies with these T&Cs and expressly accepts the terms of the Referral Program;",
  "3.4.2.item3": 'the Referrer has generated a referral link from the Website (https://Tradergpt.ai/invite) (the "Referral Link").',
  "3.4.3":
    '3.4.3 To be considered valid (the "Qualified Referral"), the referral must meet the following conditions: i) the Referee has created an account by accessing the Website through the Referrer\'s Referral Link and has accepted all of these T&Cs without reserve; ii) the Referral Link used by the Referee has never been promoted, directly or indirectly, through or with the help of paid advertising; iii) the Referee is a new user, which implies that (i) he has never created an account before and (ii) he is not in the process of creating an account; iv) the Referee has purchased five (5) Collectibles on the primary market; it is specified that purchases made on the Marketplace are not taken into account.',
  "3.4.4":
    "3.4.4 In case of a Qualified Referral, both the Referrer and the Referee will receive one (1) limited Collectible as a reward (the “Referral Reward”). The Collectible awarded as a Referral Reward is selected on a completely random basis. For the avoidance of doubt, referrals that do not qualify as Qualified Referral are not eligible for any reward.",
  "3.4.5":
    "3.4.5 Except for delays due to technical reasons, Referral Rewards are paid out within fourteen (14) days of the date on which the Referee purchased its fifth Collectible on the primary market.",
  "3.4.6":
    "3.4.6 In case of fraud or attempted fraud (especially in case of multiple accounts) or violation of these T&Cs, Tradergpt reserves the right to suspend the account and ban the user from the Referral Program of the concerned Users under the conditions provided for in these T&Cs, without prejudice to any legal action that may be taken by Tradergpt against the concerned User.",
  "3.4.7": "3.4.7 The conditions and benefits set out in this Article may, at any time and without prior notice, be unilaterally amended or deleted by Tradergpt through amendments to this Article.",

  3.5: "Article 3.5 – Affiliate program",
  "3.5.1":
    '3.5.1 Tradergpt offers its Users the possibility to participate in the affiliate program governed by this clause (the “Affiliate Program"). The Affiliate Program offers Users the opportunity to earn a commission on income generated by other Users they have introduced to Tradergpt.',
  "3.5.2": "3.5.2 Participation in the Affiliate Program is subject to the following conditions:",
  "3.5.2.item1": "the User has a legitimate account on the Website;",
  "3.5.2.item2": "the User complies with these T&Cs and expressly accepts the terms of the Affiliate Program;",
  "3.5.2.item3":
    "the User (a) has applied for participation in the Affiliate Program through the Website (https://Tradergpt.ai/affiliate_program), (b) has provided accurate and not misleading information and (c) his/her participation in the Affiliate Program has been expressly and individually accepted by Tradergpt; the decision to accept or reject an application for participation in the Affiliate Program is at the sole discretion of Tradergpt; Tradergpt may reject an application or terminate participation at any time without giving any reason and without prior notice;",
  "3.5.2.item4": "the User has accepted without reserve the Master Campaign Agreement of the Third-Party Service;",
  "3.5.2.item5": 'the User has generated an affiliate link under the conditions indicated on the Website (the "Affiliate Link").',
  "3.5.3": '3.5.3 For purposes of this Article, Users eligible for the Affiliate Program in accordance with the above conditions are referred to as "Affiliates"',
  "3.5.4":
    "3.5.4 To be considered as an Affiliate referred User (a “Lead”), the following conditions must be met: i) the referred User has created an account by accessing the Website through the Affiliate Link and has accepted all of these T&Cs without reserve; ii) the Affiliate Link used by the Affiliate has never been promoted, directly or indirectly, through or with the help of paid advertising; iii) the referred User is a new user, which implies that (i) he has never created an account before and (ii) he is not in the process of creating an account.",
  "3.5.5":
    "3.5.5 In consideration of the affiliations made, the Affiliate shall receive a commission of 10% (the “Affiliate Fees”), all taxes included, of the revenue generated by the referred Leads for a one-year period from the date of creation of the account by the Lead.",
  "3.5.6":
    "3.5.6 The Affiliate understands and expressly agrees that Tradergpt will only pay Affiliate Fees due for Leads referred through the Affiliate Link automatically tracked and reported by the systems of Tradergpt or its external providers. The Affiliate understands and acknowledges that, unless proven otherwise, and as an evidence agreement, the tracking data of the affiliations kept in the information systems of Tradergpt or its external providers will constitute proof of the Affiliate Fees due. Tradergpt will not be liable for any Affiliate Fees not recorded in its information systems or those of its service providers.",
  "3.5.7": "3.5.7 Affiliate Fees are paid during the month following the month in which the revenue was generated by the Lead.",
  "3.5.8":
    "3.5.8 In case of fraud or attempted fraud or violation of these T&Cs, Tradergpt reserves the right to suspend the account of the concerned Users under the conditions provided for in these T&Cs, without prejudice to any legal action that may be taken by Tradergpt against the concerned User.",
  "3.5.9":
    "3.5.9 In case of fraud by the Affiliate, or if the Affiliate's participation in the Affiliate Program has been accepted by Tradergpt on the basis of false information, the Affiliate will be liable to refund Tradergpt the full amount of the Affiliate Fees, which the Affiliate expressly understands and accepts.",
  "3.5.10": "3.5.10 The conditions and benefits set out in this Article may, at any time and without prior notice, be unilaterally amended or deleted by Tradergpt through amendments to this Article.",

  4.1: "Article 4.1 – Collectible features and characteristics",
  "4.1.1":
    "4.1.1 Collectibles are issued by Tradergpt based on a level of scarcity specified on each item. For instance, a Collectible may be limited, rare, super rare or unique, regarding the total number of items offered. The scarcity of a Collectible is guaranteed by the underlying NFT, that can be monitored directly on the blockchain.",
  "4.1.2":
    "4.1.2 The Collectibles represent rights of third parties that Tradergpt exploits under licenses granted by the holders of the rights. All contractual provisions between Tradergpt and the holders of these rights are enforceable against Users.",
  4.2: "Article 4.2 – Issuance of Collectible",
  "4.2.1":
    "4.2.1 Tradergpt offers Collectibles for sale by way of auctions in which Tradergpt only sets the floor price before Users bid up for a fixed period of time. The Buyer is the last bidder. There is no reservation price. Any bid registered on the Website through a valid account is deemed authentic and valid. Regular bids are binding and cannot be withdrawn or modified.",
  "4.2.2":
    "4.2.2 In the event of a service interruption (technical problem), which makes it impossible to access an auction within 4 hours of its end, Tradergpt reserves the right to extend the auction period or to cancel the auction in order to restart it later.",
  "4.2.3": "4.2.3 Tradergpt also offers the sale of Collectible packs. The Collectibles included in the pack are specified before the sale.",
  "4.2.4":
    "4.2.4 The non-professional User has a right of withdrawal that can be exercised within fourteen (14) days after the conclusion of the contract pursuant to the provisions of Article L. 221-18 of the UK Consumer Code. Given the digital nature of the Collectibles and the immediate delivery of the Collectibles after purchase, the withdrawal period provided for in Articles L. 221-18 et seq. of the UK Consumer Code and the article 9 of the European directive related to Consumer Right Protection shall not apply.",
  "4.2.5": "4.2.5 BY PURCHASING A COLLECTIBLE ON THE WEBSITE, THE USER EXPRESSLY WAIVES HIS/HER RIGHT OF WITHDRAWAL",
  4.3: "Article 4.3 – Transfer and exchange",
  "4.3.1":
    "4.3.1 User has the possibility to withdraw his/her Collectibles from his/her account, as well as to exchange his/her Collectibles on the Marketplace or through a Third-Party Service, such as a third-party marketplace.",
  "4.3.2": "4.3.2 Tradergpt is under no circumstances liable for any damage that may occur during the transfer, withdrawal or exchange of Collectibles outside the Website.",
  4.4: "Article 4.4 – Fantasy football",
  "4.4.1": '4.4.1 The game, entitled "SO5", is based on a virtual tournament that tracks the real performance of players on the field, accessible from the Website.',
  "4.4.2": "4.4.2 This promotional game is legally governed by rules that can be consulted here: https://Tradergpt.ai/game_rules.",
  4.5: "Article 4.5 – Support",
  "4.5.1": "4.5.1 Support is available on the Website.",
  "4.5.2": "4.5.2 Tradergpt undertakes to respond as quickly as possible, depending on the number of requests in process.",

  5.1: "Article 5.1 – Terms of Use",
  "5.1.1": "5.1.1 Tradergpt provides a Marketplace that allows Users to exchange amongst them Collectibles against Cryptocurrencies or other Collectibles.",
  "5.1.2": "5.1.2 This article shall apply only to the relations between Buyer and Seller in the context of the exchange of a Collectible on the Website.",
  "5.1.3": "5.1.3 THE USE OF THE MARKETPLACE IS SUBJECT TO THE UNCONDITIONAL ACCEPTANCE AND RESPECT OF THESE T&CS AND SPECIFICALLY OF THE FOLLOWING ARTICLES.",
  5.2: "Article 5.2 – Sale and exchange",
  "5.2.1":
    "5.2.1 Users holding Collectibles have the ability to sell them throughout the Marketplace in accordance with the procedures set out in the Help Center. In this case, the Seller is free to set the fixed selling price offered (the « Offer »). The Collectible is offered on the Marketplace for 48 hours. If the Collectible is not sold within this period, the offer is removed from the Marketplace and the User may offer the Collectible for sale again at any time.",
  "5.2.2":
    "5.2.2 Tradergpt does not intervene in any way in the choice of the Collectibles offered for sale on the Marketplace nor in the determination of the selling prices fixed by the Sellers. More generally, Tradergpt does not intervene in any way in the sale between Buyer and Seller within the Marketplace. Consequently, Tradergpt never acts as a counterpart to this sale.",
  "5.2.3":
    "5.2.3 More specifically, Tradergpt does not intervene in any way in the choice of the Collectibles offered for sale on the Marketplace nor in the determination of the selling prices fixed by the Sellers. The Users understand and accept that Tradergpt does not control the reality, the relevance and the fairness of the prices, whether they are excessive or derisory, proposed by the Sellers. The Buyer is the only responsible for the appreciation of the price of the Collectibles proposed through the Marketplace.",
  5.3: "Article 5.3 – Listing and delisting of Offers",
  "5.3.1": "5.3.1 Any user can list a Collectible Offer on the Marketplace. The Collectibles listed on the Marketplace are chosen solely by the Sellers offering them for sale.",
  "5.3.2": "5.3.2 Tradergpt reserves the right to remove from the Marketplace the Offers of a Seller, especially, but not exclusively, if the Seller:",
  "5.3.2.item1": "does not respect the applicable regulations;",
  "5.3.2.item2": "does not respect the T&Cs;",
  "5.3.2.item3": "is subject to a temporary or permanent suspension of his account;",
  "5.3.2.item4": "makes fraudulent use of the Services.",
  5.4: "Article 5.4 – Ranking of offers",
  "5.4.1":
    '5.4.1 The Collectibles listed on the Marketplace are ranked by default according to an algorithm that takes into account the price offered by the Seller and the performance of the Collectible with the aim of highlighting the Offers with the best ratio between the price offered by the Seller and the performance of the players represented by the Collectible. This default ranking method corresponds to the sorting criterion of the search results called "Best Value" on the Marketplace.',
  "5.4.2": "5.4.2 The Buyer can also, if he/she wishes, refine his/her search by sorting the results on the basis of the following other criteria:",
  "5.4.2.item1": 'Offers from the most recent to the least recent ("Ending Soon");',
  "5.4.2.item2": 'Offers from the least recent to the most recent ("Newly Listed");',
  "5.4.2.item3": 'Offers from the most expensive to the cheapest ("Highest Price");',
  "5.4.2.item4": 'Offers from the cheapest to the most expensive ("Lowest Price");',
  "5.4.3": "5.4.3 The Collectibles are then ranked in the search results according to the selected listing criteria.",
  5.5: "Article 5.5 – Tax and social security obligations",
  "5.5.1":
    "5.5.1 Tradergpt draws the attention of the Users that the sale of Collectible is likely, according to the applicable tax legislation, to constitute taxable income, in particular when the sale price of the Collectible is higher than its purchase price. Users are strongly advised to contact the competent tax and social security authorities to ensure that they meet their tax and, where applicable, social security obligations.",
  "5.5.2": "5.5.2 It is the Users sole responsibility to declare the income from the Sales of their Collectible to the competent tax administration and social security administration.",
  "5.5.3":
    "5.5.3 Users residing in France for tax purposes can fulfil their tax obligations via the UK tax authority's website, www.impots.gouv.fr, and obtain information on the tax regimes that may apply and the tax declaration procedures on the page accessible via the following link: https://www.impots.gouv.fr/portail/node/10841",
  "5.5.4":
    "5.5.4 Users residing in France for tax purposes may, where applicable, fulfil their social security obligations via the social security website, www.urssaf.fr, and obtain information on the social security schemes that may apply and the reporting procedures: https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html",
  "5.5.5":
    "5.5.5 Tradergpt makes available to each Seller, at the beginning of the year, a statement of transactions summarizing the number and amount of transactions made by the Seller through the Marketplace.",
  5.6: "Article 5.6 – Disputes on the Marketplace",
  "5.6.1":
    "5.6.1 As part of its Marketplace, Tradergpt acts as a mere intermediary between Sellers and Buyers. As such, Tradergpt shall not be held liable for damages caused by a sale, due to the price or an act done by one of the parties.",
  "5.6.2":
    "5.6.2 In addition, the services provided through the Marketplace take place only between the Buyer and the Seller. Tradergpt’s responsibility cannot be incurred in respect of such services from which Tradergpt is unconcerned.",
  "5.6.3":
    "5.6.3 Therefore, any potential claim must be addressed by the Buyer to the Seller. The Seller is solely responsible for the claims processing. Nevertheless, Tradergpt reserves the right to take part in arbitration, in its discretion, if the Seller does not provide a response to a request nor a settlement to a dispute.",

  "6.1.1": "6.1.1 The User guarantees Tradergpt against any breach, exceeding the foreseeable risk, that could result from the use of the Services.",
  "6.1.2":
    "6.1.2 The User undertakes to use the Services only in accordance with the T&Cs. It is not authorized to reconstruct the Services, decompile, disassemble or circumvent the technical restrictions it contains, except to the extent that such operations would be permitted by applicable laws.",
  "6.1.3": "6.1.3 The User guarantees Tradergpt against fraudulent, unfair or bad faith use of the service, in particular if this interferes with the use of the service by other Users.",

  7.1: "Article 7.1 – Disclaimer",
  "7.1.1":
    '7.1.1 Users expressly understands and agrees that access to and use of the Services is at the User\'s own risk, and that the Services are provided on an "as is" and "as available" basis without warranty of any kind, either express or implied, except as otherwise provided by law.',
  "7.1.2":
    "7.1.2 To the fullest extent permitted by law, Tradergpt does not represent or warrant that: (i) access to or use of the Services will be uninterrupted, timely, secure or error-free; (ii) usage data provided by the Services or on the Website will always be accurate; (iii) the Services are free of malwares or other harmful components.",
  "7.1.3":
    "7.1.3 Users accept the security risks inherent in the provision of information, online trading on the internet and experimental technologies such as blockchains and non-fungible tokens, and agree that Tradergpt shall not be liable for any breach of security unless caused by gross negligence on the part of Tradergpt.",
  "7.1.4":
    "7.1.4 Tradergpt is not responsible for any losses incurred by Users as a result of using the blockchain network and digital asset wallets. Tradergpt has no control over the blockchain network and shall not be liable for any losses arising from the blockchain.",
  "7.1.5": "7.1.5 Some jurisdictions do not allow the exclusion of implied warranties in consumer contracts, so some or all of the above exclusions may not apply to all Users.",
  7.2: "Article 7.2 – Services content",
  "7.2.1": "7.2.1 As a hosting provider, Tradergpt is subject to a limited liability regime as provided by the EU Directive of the 8 june 2000 (2000/31/EC) on E-commerce.",
  "7.2.2":
    "7.2.2. Therefore, Tradergpt cannot be held liable for the content released by the Users as well as the contents exchanged between them and gives no guarantee, express or implicit, in this respect. Tradergpt is a third party to Users relationships and thereby excludes any liability in this regard.",
  7.3: "Article 7.3 – Liability",
  "7.3.1":
    "7.3.1 Tradergpt undertakes to implement all the necessary means to ensure the best delivery of the Services provided. However, Tradergpt can only be bound by an obligation of best endeavour.",
  "7.3.2":
    "7.3.2 In the event of failure of Tradergpt, it shall be liable only for direct and reasonably foreseeable damage, unless otherwise provided by law or regulation. Indirect damages that may result from failure or use of the Service are expressly excluded.",
  "7.3.3": "7.3.3 In any event, Tradergpt may not, unless otherwise provided by law or regulation, be held liable beyond the amounts invoiced to the User during the last twelves months.",
  "7.3.4": "7.3.4 In particular, unless otherwise provided by law or regulation, Tradergpt shall not be held liable for:",
  "7.3.4.item1": "breakdowns or malfunctions of the Services lasting less than 24 hours or that are not the responsibility of Tradergpt;",
  "7.3.4.item2":
    "data, Collectibles or Cryptocurrencies losses of Users not being imputable to Tradergpt, in particular, but not exclusively, in the event of hacking of the User's account due to negligence in the conservation of his connection details.;",
  "7.3.4.item3": "an unusual or illegal use of the services offered, by a third party or by a User;",
  "7.3.4.item4": "direct or indirect damage resulting from the use of the Services offered;",
  "7.3.4.item5": "a malfunction or cyberattack;",
  "7.3.4.item6": "the harmful consequences of an account suspension carried out under the conditions set out in Article 3.3 of these T&Cs;",
  "7.3.4.item7": "an adverse event during the auction, both in the course of the process and at the stage of establishing the floor price and the final price ;",
  "7.3.4.item8": "any case of force majeure, within the meaning of Article 1218 of the UK civil code, including a failure of the blockchain protocol used to run the Services.",

  "8.1.1": "8.1.1 The User may terminate these T&Cs at any time by requesting the deletion of his/her account pursuant to Article 3.2 of these T&Cs.",
  "8.1.2":
    "8.1.2 Tradergpt may at any time, for any reason and in its sole discretion, terminate these T&Cs and suspend the Services without notice. Users agree, to the fullest extent permitted by law, that any suspension or termination of access to the Services may be without notice, and that Tradergpt shall not be liable for the consequences of any such suspension or termination.",
  "8.1.3":
    "8.1.3 In case of termination of these T&Cs or suspension of a User's account, Tradergpt will provide Users with the opportunity to withdraw their Collectibles and Cryptocurrencies, unless the termination or suspension of the account was decided due to a violation of these T&Cs by the User or due to any alleged fraudulent, abusive or illegal activity. In the latter case, the termination and/or suspension of the account will be without prejudice to any other legal action that Tradergpt may take against the User.",

  "9.1.1":
    "9.1.1 All intellectual property rights on the Website content, excluding the Users generated content, are Tradergpt’s property. They are provided free of charge within the exclusive framework of the use of the Services. Therefore, any use of these contents without the prior permission of Tradergpt is unlawful.",
  "9.1.2": "9.1.2 Tradergpt is the service’s database producer. Any unauthorized extraction or reproduction is prohibited.",
  "9.1.3": "9.1.3 The User is the definitive owner of the NFT that he purchases in accordance with these T&Cs, in a legitimate manner and in good faith.",
  "9.1.4": "9.1.4 Notwithstanding any contrary provisions in these T&Cs, the User has the right to freely dispose of the NFTs he/she owns (sale, loan, donation, transfer, etc.).",
  "9.1.5":
    "9.1.5 Except as otherwise expressly provided, use of the Services or purchase of a Collectible does not confer any ownership or other rights in the content, code, data or other materials on the Website that may be accessed through it nor over the Services or over Tradergpt's rights or Third-Party Rights that may be associated with the Services.",
  "9.1.6":
    "9.1.6 Subject to compliance with these T&Cs, the User is authorised, on a non-exclusive basis, to use, reproduce and display the Collectible Related Rights associated with the Collectibles represented by the NFTs that he/she legitimately owns, for personal and non-commercial purposes only.",
  "9.1.7":
    "9.1.7 Under no circumstances may the User, without Tradergpt's prior written consent, do or attempt to do any of the following to the Collectibles, whether or not owned by the User: (i) use the Collectible and all Collectible Related Rights associated with Collectibles owned by him/her for commercial purposes, advertising or promotion of a third party product or service; (ii) market merchandise, physical or digital, that represents the purchased Collectible; (iii) alter the image associated with the Collectible; (iv) attempt to claim any additional intellectual property rights relating to the Collectible.",
  "9.1.8":
    "9.1.8 Furthermore, the User understands and agrees that additional restrictions on the use of Collectibles representing Third-Party Rights may be later imposed to ensure compliance with the license granted by the owner of the relevant rights. User expressly agrees to comply with all such restrictions from the date of email notification of such restrictions by Tradergpt.",

  10.1: "Article 9.1 – Personal data",
  "10.1.1":
    '9.1.1 Information which directly or indirectly identifies Users (hereinafter "Personal data") is processed by Tradergpt in connection with User’s use of the Services, including by means of cookies.',
  "10.1.2": "9.1.3 Tradergpt's privacy policy is available here: https://Tradergpt.ai/privacy-policy.",
  "10.1.3": "",
  10.2: "Article 9.2 – Cookies",
  "10.2.1":
    "9.2.1 During the consultation of the Website, cookies may be registered on the User's electronic communications equipment to make the website use easier and to collect statistics relating to attendance and access.",
  "10.2.2":
    "9.2.2 Cookies are text files that the website records on the terminal in order to store various technical data to facilitate the Website use and to collect statistical information about its Users.",
  "10.2.3":
    "9.2.3 Users are informed beforehand of the store on their terminal information. Users are free to accept or refuse the storage of cookies. Continuing to navigate on the Website after having been informed means that the User accepts the data collection.",

  11.1: "Article 11 – Hyperlink",
  "11.1.1":
    "The Service may contain hypertext links redirecting to third party sites. The Service has no control over these sites and declines all responsibility for access, content or use of these sites.",

  12.1: "Article 12 – Amendment",
  "12.1.1":
    "11.1.1 Tradergpt may modify these T&Cs at any time. The applicable version of the T&Cs is the latest version published on the date of use of the Services. The version number and effective date of the T&Cs are indicated at the beginning of the document. Tradergpt will provide Users with notice of any material changes by email at least 15 days before the date they become effective. If Users do not agree with the revised T&Cs, they have the right to terminate the T&Cs immediately and cease using the Services, by sending an unambiguous email to that effect to contact@Tradergpt.com or by deleting their account under the terms of these T&Cs. For Users who have not terminated these T&Cs prior to the effective date of the revised T&Cs, the continuation of use of the Services shall constitute acceptance of the revised T&Cs.",
  "12.1.2": "11.1.2 Tradergpt reserves the right to modify or stop the accessibility of all or part of the Services and/or the Website or applications at any time.",

  13.1: "Article 13 – Non waiver",
  "13.1.1":
    "12.1.1 Failure by Tradergpt at any time to require the other party to perform any of its obligations under these T&Cs will not affect the right of Tradergpt to request their execution thereafter.",
  "13.1.2": "12.1.2 The nullity of any clause of these T&Cs does not affect the validity and the obligation to respect the other clauses.",

  14.1: "13.1 – Applicable law",
  "14.1.1": "13.1.1 These T&Cs are governed by UK law solely.",
  14.2: "13.2 – Dispute and Mediation",
  "14.2.1":
    "13.2.1 In the event of a dispute arise between Tradergpt and a User, the latter undertakes to inform the customer service department at the following address: contact@Tradergpt.com taking care to specify his contact details and to provide any information allowing to appreciate the origin and the implications of the dispute.",
  "14.2.2":
    "13.2.2 Consumers have the possibility to call an independent Ombudsman for free by sending a request to this effect, by email, to the address contact@Tradergpt.com or by mail to the Company’ address. Upon receipt, Tradergpt undertakes to organize a mediation as soon as possible.",
  "14.2.3": "13.2.3 The User can also contact the online dispute resolution service of the European Commission at the following address: ec.europa.eu/consumers/odr.",
  14.3: "13.3 – Jurisdiction",
  "14.3.1":
    "In case of dispute, the Consumer may seize the competent jurisdiction with territorial jurisdiction under the law. In the event of no mandatory jurisdiction by the law, only the courts of the jurisdiction of the Court of Appeal of Paris will be competent.",
  "14.3.2": "13.3.2 The User can also contact the online dispute resolution service of the European Commission at the following address: https://ec.europa.eu/consumers/odr.",
  14.4: "13.4 – Jurisdiction",
  "14.4.1":
    "In case of dispute, the Consumer may seize the competent jurisdiction with territorial jurisdiction under the law. In the event of no mandatory jurisdiction by the law, only the courts of the jurisdiction of the Court of Appeal of Paris will be competent.",
};

export default termsAndConditionEn;
