import React from 'react';
// import Image from 'react-image-webp';
// import stress from './assets/stress.png';
// import stressWebp from './assets/stress.webp';
import { styles } from './styles';
import { Box, Grid, Typography } from '@mui/material';

const TradingStress = () => {
    const isIos = () => /iPad|iPhone|iPod/.test(navigator.userAgent)
    return (
        <Box sx={styles.wrapper}>
            <Grid container spacing={3} direction='row' alignItems='center'>
                <Grid item xs={12} md={6}>
                    <Typography sx={styles.title}>TraderGPT Makes your Trading <span> Stress Free!</span></Typography>
                    <Typography sx={styles.description}>TraderGPT is the ultimate trading tool for anyone who wants to make money in the financial markets. With TraderGPT, you can trade forex, stocks and all other markets with ease and make huge profits.</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{position: 'relative'}}>
                        <Box sx={styles.securityImage}>
                            {/* <Image src={stress} webp={stressWebp} alt='' /> */}
                            {!!isIos 
                                ? 
                                    <video controls autoPlay style={{borderRadius: '24px'}} muted loop playsinline>
                                        <source src="https://tradergpt.fra1.cdn.digitaloceanspaces.com/tradergpt_1.1%20.mp4" type="video/mp4"/>
                                    </video>        
                                :
                                    <video controls autoPlay style={{borderRadius: '24px'}} muted loop>
                                        <source src="https://tradergpt.fra1.cdn.digitaloceanspaces.com/tradergpt_1.webm" type="video/webm"/>
                                    </video>
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TradingStress;