import React, { useState } from 'react';
import QRCode from "qrcode.react";
import Clipboard from "react-clipboard.js";
import { styled } from "@mui/system";
import { Box, ClickAwayListener, IconButton, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material';
import { ContentCopy, QrCodeScannerRounded } from '@mui/icons-material';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFF',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    },
}));

const ClipboardCustom = styled(Clipboard)({
    display: "flex",
    cursor: "pointer",
});

const DepositCard = ({text, qr=null}) => {
    const [copied, setCopied] = useState(false);
    const [open, setOpen] = useState(false);
    
    const onCopy = () => {
      setCopied(true);
    };

    const handleTooltipClose = () => {
      setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(!open);
    };
  
    return (
        <Stack sx={{my: 2}}>
            <Typography sx={{mb: 1}}>Deposit Address</Typography>
            <Box
                sx={{
                    display: "flex",
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 1,
                }}
            >
                <Box sx={{
                    bgcolor: 'primary.light',
                    px: 3,
                    py: 1,
                    borderRadius: 12
                }}>
                    <Typography sx={{
                        wordBreak: 'break-all',
                    }}>
                        {text}
                    </Typography>
                </Box>
                <ClipboardCustom
                    component="span"
                    data-clipboard-text={text}
                    onSuccess={onCopy}
                >
                    <Tooltip title={copied ? "Copied" : "Copy to clipboard"} arrow>
                        <IconButton sx={{bgcolor: 'primary.light', width: 40, height: 40}}>
                            <ContentCopy sx={{ fontSize: 20 }} />
                        </IconButton>
                    </Tooltip>
                </ClipboardCustom>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <LightTooltip 
                        open={open}
                        onClose={handleTooltipClose}
                        placement="top"
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                        title={
                            <Box sx={{pt: 0.8}}>
                                <QRCode value={qr ?? text} level="Q" size={120} />
                            </Box>
                        }
                    >
                        <IconButton onClick={handleTooltipOpen} sx={{bgcolor: 'primary.light', width: 40, height: 40}}>
                            <QrCodeScannerRounded sx={{ fontSize: 20 }} />
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
            </Box>
        </Stack>
    );
};

export default DepositCard;