import Box from "@mui/material/Box";
import usdtIcon from "assets/icons/cryptoicon/usdt.png";
import { t } from "locales";
import { styles } from "./styles";
import { XTAKER_COIN } from "library/constants";
import { Avatar, Typography, Button, Table, TableHead, TableCell, TableBody, TableRow } from "@mui/material";

export default function CustomTable({ columns, rows }) {
  return (
    <Box sx={styles.tableWrapper}>
      <Table sx={{ minWidth: 340, overflowX: "auto" }}>
        <TableHead>
          {columns.map((column) =>
            column.id === "icon" ? null : (
              <TableCell key={column.id} align={column.align}>
                <Typography variant="body2" fontWeight="600" sx={styles.tableHeader}>
                  {t(column.label)}
                </Typography>
              </TableCell>
            )
          )}
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            console.log({row},"rowrow");
            return (
              <TableRow
                key={row.id}
                sx={styles.tableCell}
              >
                {columns.map((column, index) => {
                  const value = row[column.id];

                  if (column.id === "button") return ""
                  if (column.id === "duration") {
                    return (
                      <TableCell align={column.align}>
                        <Button
                          variant="contained"
                          sx={[{
                              background: (theme) => theme.palette.secondary.light,
                              color: "#000",
                              "&:hover": {
                                color: "#fff",
                              },
                            },
                            {
                              borderRadius: 2.4,
                              py: "2px",
                              width: "fit-content",
                              minWidth: "40px",
                            },
                          ]}
                        >
                          {value}
                        </Button>
                      </TableCell>
                    );
                  }
                  if (column.id === "name")
                    return (
                      <TableCell key={index}>
                        <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                          <Box
                            sx={{
                              img: {
                                width: 32,
                                height: 32,
                                borderRadius: "50%",
                                display: "flex",
                                objectFit: "contain",
                              },
                            }}
                            key={index}
                          >
                            <Avatar src={row?.icon || usdtIcon} alt="" />
                          </Box>
                          <Typography variant="body1" fontWeight="500">
                            {value}
                          </Typography>
                        </Box>
                      </TableCell>
                    );
                  if (column.id === "icon") {
                    return null;
                  }
                  if (column.id === "interest") {
                    return (
                      <TableCell key={column.id + index} align={column.align}>
                        <Typography variant="body1" fontWeight="500">
                          {column.format && typeof value === "number" ? column.format(value) : value}%
                        </Typography>
                      </TableCell>
                    );
                  }
                  if (column.id === "invesment") {
                    return (
                      <TableCell key={column.id + index} align={column.align}>
                        <Typography variant="body1" fontWeight="500">
                          {column.format && typeof value === "number" ? column.format(value) : value} {XTAKER_COIN}
                        </Typography>
                      </TableCell>
                    );
                  } else {
                    return null;
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}
