export const termsAndConditionContent = {
  header: { title: "headerTitle", desc: "headerDesc" },
  body: {
    en: [
      {
        title: "article1",
        items: [
          {
            title: "1.1.1",
            list: [
              "1.1.1.item1",
              "1.1.1.item2",
              "1.1.1.item3",
              "1.1.1.item4",
              "1.1.1.item5",
              "1.1.1.item6",
              // "1.1.1.item7",
              "1.1.1.item8",
              "1.1.1.item9",
              "1.1.1.item10",
              "1.1.1.item11",
              "1.1.1.item12",
              "1.1.1.item13",
              "1.1.1.item14",
            ],
          },
        ],
      },
      {
        title: "article2",
        items: ["2.1.1", "2.1.2", "2.1.3", "2.1.4", "2.1.5", "2.1.6", "2.1.7"],
      },
      {
        title: "article3",
        items: [
          {
            title: "3.1",
            items: [
              {
                title: "3.1.1",
                list: [
                  "3.1.1.item1",
                  "3.1.1.item2",
                  "3.1.1.item3",
                  "3.1.1.item4",
                ],
              },
              "3.1.2",
              "3.1.3",
              "3.1.4",
            ],
          },
          {
            title: "3.2",
            items: ["3.2.1", "3.2.2"],
          },
          {
            title: "3.3",
            items: [
              {
                title: "3.3.1",
                list: [
                  "3.3.1.item1",
                  "3.3.1.item2",
                  "3.3.1.item3",
                  "3.3.1.item4",
                  "3.3.1.item5",
                  "3.3.1.item6",
                  "3.3.1.item7",
                ],
              },
              "3.3.2",
              "3.3.3",
              "3.3.4",
            ],
          },
          {
            title: "3.4",
            items: [
              "3.4.1",
              {
                title: "3.4.2",
                list: ["3.4.2.item1", "3.4.2.item2", "3.4.2.item3"],
              },
              "3.4.3",
              "3.4.4",
              "3.4.5",
              "3.4.6",
              "3.4.7",
            ],
          },
          {
            title: "3.5",
            items: [
              "3.5.1",
              {
                title: "3.5.2",
                list: [
                  "3.5.2.item1",
                  "3.5.2.item2",
                  "3.5.2.item3",
                  "3.5.2.item4",
                  "3.5.2.item5",
                ],
              },
              "3.5.3",
              "3.5.4",
              "3.5.5",
              "3.5.6",
              "3.5.7",
              "3.5.8",
              "3.5.9",
              "3.5.10",
            ],
          },
        ],
      },
      {
        title: "article4",
        items: [
          {
            title: "4.1",
            items: ["4.1.1", "4.1.2"],
          },
          {
            title: "4.2",
            items: ["4.2.1", "4.2.2", "4.2.3", "4.2.4", "4.2.5"],
          },
          {
            title: "4.3",
            items: ["4.3.1", "4.3.2"],
          },
          {
            title: "4.4",
            items: ["4.4.1", "4.4.2"],
          },
          {
            title: "4.5",
            items: ["4.5.1", "4.5.2"],
          },
        ],
      },
      {
        title: "article5",
        items: [
          {
            title: "5.1",
            items: ["5.1.1", "5.1.2", "5.1.3"],
          },
          {
            title: "5.2",
            items: ["5.2.1", "5.2.2", "5.2.3"],
          },
          {
            title: "5.3",
            items: [
              "5.3.1",
              {
                title: "5.3.2",
                list: [
                  "5.3.2.item1",
                  "5.3.2.item2",
                  "5.3.2.item3",
                  "5.3.2.item4",
                ],
              },
            ],
          },
          {
            title: "5.4",
            items: [
              "5.4.1",
              {
                title: "5.4.2",
                list: [
                  "5.4.2.item1",
                  "5.4.2.item2",
                  "5.4.2.item3",
                  "5.4.2.item4",
                ],
              },
              "5.4.3",
            ],
          },
          {
            title: "5.5",
            items: ["5.5.1", "5.5.2", "5.5.3", "5.5.4", "5.5.5"],
          },
          {
            title: "5.6",
            items: ["5.6.1", "5.6.2", "5.6.3"],
          },
        ],
      },
      {
        title: "article6",
        items: [
          {
            items: ["6.1.1", "6.1.2", "6.1.3"],
          },
        ],
      },
      {
        title: "article7",
        items: [
          {
            title: "7.1",
            items: ["7.1.1", "7.1.2", "7.1.3", "7.1.4", "7.1.5"],
          },
          {
            title: "7.2",
            items: ["7.2.1", "7.2.2"],
          },
          {
            title: "7.3",
            items: [
              "7.3.1",
              "7.3.2",
              "7.3.3",
              {
                title: "7.3.4",
                list: [
                  "7.3.4.item1",
                  "7.3.4.item2",
                  "7.3.4.item3",
                  "7.3.4.item4",
                  "7.3.4.item5",
                  "7.3.4.item6",
                  "7.3.4.item7",
                  "7.3.4.item8",
                ],
              },
            ],
          },
        ],
      },
      {
        title: "article8",
        items: [
          {
            items: ["8.1.1", "8.1.2", "8.1.3"],
          },
        ],
      },
      // {
      //   title: "article9",
      //   items: [
      //     {
      //       items: [
      //         "9.1.1",
      //         "9.1.2",
      //         "9.1.3",
      //         "9.1.4",
      //         "9.1.5",
      //         "9.1.6",
      //         "9.1.7",
      //         "9.1.8",
      //       ],
      //     },
      //   ],
      // },
      {
        title: "article9",
        items: [
          { title: "10.1", items: ["10.1.1", "10.1.2", "10.1.3"] },
          { title: "10.2", items: ["10.2.1", "10.2.2", "10.2.3"] },
        ],
      },
      {
        title: "article10",
        items: [{ items: ["11.1.1"] }],
      },
      {
        title: "article11",
        items: [{ items: ["12.1.1", "12.1.2"] }],
      },
      {
        title: "article12",
        items: [{ items: ["13.1.1", "13.1.2"] }],
      },
      {
        title: "article13",
        items: [
          { title: "14.1", items: ["14.1.1"] },
          { title: "14.2", items: ["14.2.1", "14.2.2", "14.2.3"] },
          { title: "14.3", items: ["14.3.1", "14.3.2"] },
          { title: "14.4", items: ["14.4.1"] },
        ],
      },
    ],
  },
};
