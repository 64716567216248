const userFa = {
  // Setting
  publicProfile: "مشخصات عمومی",
  accountSetting: "تنظیمات حساب",
  notifications: "اعلان ها",
  security: "امنیت",
  paymentMethods: "روش های پرداخت",
  connectYourDiscordAccount: "اتصال به Discord شما",
  connectYourTwitterAccount: "اتصال به توئیتر شما",
  clubName: "نام باشگاه",
  uploadAnImage: "بارگذاری عکس",
  updateProfile: "ارتقا صفحه",
  update: "ارتقا",
  accountSettings: "تنظیمات مشخصات",
  change: "تعویض",
  add: "اضافه کردن",
  submit: "ارسال",
  changePassword: "تغییر رمز عبور",
  currentPassword: "رمز عبور فعلی",
  oldPassword: "رمز عبور قبلی",
  newPassword: "رمز عبور جدید",
  newPasswordConfirm: "تایید رمز عبور جدید",
  addEmail: "اضافه کردن ایمیل",
  addMobile: "اضافه کردن موبایل",
  status: "وضعیت",
  overview: "بررسی اجمالی",
  cards: "کارت ها",
  achievements: "امتیاز ها",
  squads: "تیم ها",
  myAuctions: "مشخصات من",
  offersReceived: "پیشنهادات دریافت شده",
  offersSent: "پیشنهادات ارسال شد",
  transactions: "معاملات",
  delete: "حذف",
  edit: "ویرایش",
  editAuction: "ویرایش حراج",
  notificationRecords: "تاریخچه اعلان ها",
  markAllAsRead: "همه را به عنوان خوانده شده علامت بزن",
  noNotification: "هیچ اعلانی یافت نشد!",
  depositHistory: "تاریخچه سپرده گذاری",
  withdrawHistory: "تاریخچه برداشت",
  paymentHistory: "تاریخچه پرداخت",
  submittedOffers: "آفرهای ارسال شده",
  auctionDetail: "اطلاعات حراجی",
  seeAuctionDetail: "مشاهده",
  FINISHED: "پایان یافته",
  CANCELED: "کنسل شده",
  REGISTERED: "ثبت شده",
  WON: "برنده",
  NOTWON: "برنده نشدید",
  action: "عملیات",
  deletedOfferSuccessfully: "آفر شما با موفقیت حذف شد.",
  failedDeletingOffer: "حذف آفر شما با خطا مواجه شد.",
  accountBalance: "موجودی حساب",
};

export default userFa;
