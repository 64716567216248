import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { forgotPassword } from "store/reducers/auth/asyncActions";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordWithEmailValidation } from "library/validations/authValidation";
import { useState } from "react";
import EmailTab from "./EmailTab";
import { useNavigate } from "react-router-dom";
import { setAuthData } from "store/reducers/auth/authSlice";
import { t } from "locales";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { openToast } from "components/Toast";
import AuthLayout from "components/AuthLayout";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validation] = useState(forgotPasswordWithEmailValidation);
  const resolver = yupResolver(validation);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { email: "", mobile: "" },
    resolver,
  });

  const onSubmit = async (data) => {
    try {
      const gToken = await executeRecaptcha();
      const typeName = "email";
      const requestData = {
        [typeName]: data[typeName],
        gRecaptchaResponse: gToken,
      };

      dispatch(setAuthData(requestData));
      const result = await dispatch(forgotPassword(requestData));

      if (!result.error) {
        navigate("/auth/verify");
      } else {
        openToast("error", result.payload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthLayout>
      <Helmet>
        <title>Forgot Password | Tradergpt</title>
        <meta name="description" content="Forgot Password | Tradergpt" />
      </Helmet>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            mt: 12,
            mb: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          gap={2}
        >
          <Typography
            variant="h2"
            fontWeight="800"
            style={{
              fontSize: "2rem",
              lineHeight: "1",
              letterSpacing: "-0.025em",
            }}
          >
            {t("forgotPassword")}
          </Typography>

          <EmailTab onSubmit={handleSubmit(onSubmit)} control={control} errors={errors} loading={isSubmitting} />
        </Box>
      </Container>
    </AuthLayout>
  );
};

export default ForgotPassword;
