import usdtLogo from "assets/coins/usdt.png";
import bnbLogo from "assets/coins/bnb.png";
import starzLogo from "assets/coins/starz.png";
import gstarzLogo from "assets/coins/gstarz.png";
import busdLogo from "assets/coins/busd.png";

export const menuItems = [
  {
    link: "/portfolio",
    title: "portfolioTracker",
    styles: {
      backgroundColor: "#7b6fff",
      borderRadius: 3,
      padding: "6px 12px",
      transition: "background .25s ease-in-out",
      span: {
        color: "white",
      },
      "&:hover": {
        backgroundColor: (theme) => (theme.palette.mode === "dark" ? theme.palette.primary.main : "#7b6fff"),
        "& span": {
          color: "white",
        },
      },
    },
  },

  {
    link: "/cryptocurrencies",
    title: "cryptocurrencies",
  },
  {
    link: "/pricing",
    title: "pricing",
  },
];

export const RTLS = ["fa", "ar"];

const MAIN_COIN = "BNB";
const IN_APP_COIN = "STARZ"; // for rewards and prizes
const GOVERNANCE_TOKEN = "GSTARZ";
const MARKETPLACE_COIN = "BNB";
const XTAKER_COIN = "USDT";
const EXTRA_COIN = "BUSD";
const PORTFI_TABLE_SIZE = 20;

export { MAIN_COIN, IN_APP_COIN, MARKETPLACE_COIN, XTAKER_COIN, EXTRA_COIN, PORTFI_TABLE_SIZE, GOVERNANCE_TOKEN };

export const EMPTY_WALLETS = {
  USDT: {
    name: "Tether",
    coin: "USDT",
    icon: usdtLogo,
    amount: "0",
    assetId: null,
    precision: 2,
  },
  // BNB: {
  //   name: "Binance Coin",
  //   coin: "BNB",
  //   icon: bnbLogo,
  //   amount: "0",
  //   assetId: null,
  //   precision: 2,
  // },
  // STAR: {
  //   name: "Star",
  //   coin: "STAR",
  //   icon: starzLogo,
  //   amount: "0",
  //   assetId: null,
  //   precision: 2,
  // },
  // STARZ: {
  //   name: "Starz",
  //   coin: "Starz",
  //   icon: gstarzLogo,
  //   amount: "0",
  //   assetId: null,
  //   precision: 2,
  // },
  // BUSD: {
  //   name: "BUSD",
  //   coin: "BUSD",
  //   icon: busdLogo,
  //   amount: "0",
  //   assetId: null,
  //   precision: 2,
  // },
};

export const WALLETS_SCHEMA = [EMPTY_WALLETS.BNB, EMPTY_WALLETS.BUSD, EMPTY_WALLETS.USDT, EMPTY_WALLETS.STAR, EMPTY_WALLETS.STARZ];

export const FAQ_LIST = [
  {
    id: 1,
    title: "Why should I buy the Astronaut NFTs?",
    description:
      "Each astronaut is a ticket to join our daily trading tournaments and you will be given the Tradergpt subscription in order to receive our trading courses and analytics tools. We will send you weekly analyses and monitor your trading results.",
  },
  {
    id: 2,
    title: "How to buy astronauts?",
    description: "You can easily buy an Astronaut NFT on our marketplace once you have signed in.",
  },
  {
    id: 3,
    title: "How can I make a profit with Astronaut NFTs?",
    description: "By joining trading tournaments, you will be receiving in-app tokens as prize. You can then burn it or swap it for BNB.",
  },
  {
    id: 4,
    title: "How much are the Astronaut NFTs worth?",
    description: "Prices vary for each individual Astronaut.",
  },
  {
    id: 5,
    title: "WHAT CRYPTOCURRENCY WILL BE USED ON THE MARKETPLACE?",
    description: "BNB",
  },
  {
    id: 6,
    title: "how do I get the Astronauts at the initial price?",
    description: "The first 1000 NFTs are being sold at the initial price.",
  },
  {
    id: 7,
    title: "Is it possible to get NFTs or Tokens for free?",
    description: "Yes. We may offer airdrops on our social media channels.",
  },
  {
    id: 8,
    title: "Where can I buy tokens?",
    description: "You can buy Tradergpt tokens on Pancakeswap.finance",
  },
  {
    id: 9,
    title: "are there any apps?",
    description: "Mobile applications will be available soon on Google Play & Appstore.",
  },
  {
    id: 10,
    title: "what else does Tradergpt offer?",
    description:
      "Tradergpt is planning to be one of the biggest cryptocurrency and trading communities, as well as providing free tools for traders.",
  },
  {
    id: 11,
    title: "Do you have a referral program?",
    description: "Yes. You can find your invite link at the top menu -> invite friends",
  },
];

export const BSC_ADDRESS_KEY = "STARLEX:USER_BSC_ADDRESS";
