import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { auth, profile } from "./GeneralRoutes/routes";

export const PrivateRoute = ({ children, ...rest }) => {
  const isAuthorized = useSelector((state) => !!state.auth.user);

  return isAuthorized ? children : <Navigate to={{ pathname: auth.login }} />;
};

export const AuthRoute = ({ children, ...rest }) => {
  const isAuthorized = useSelector((state) => !!state.auth.user);

  return isAuthorized ? <Navigate to={profile.overview} /> : children;
};
