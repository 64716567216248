import { updateWalletsFromSocket } from "store/reducers/auth/asyncActions";
import { setNotifications } from "store/reducers/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "context/socket/socket";
import { useContext, useEffect } from "react";
import axios from "library/http";
import { store } from "store/store";

const setWalletListener = (socket, dispatch = store.dispatch) => {
  if (!socket) return;

  socket.setListener("wallet", (data) => {
    if (!!data) {
      const wallet = JSON.parse(data);

      dispatch(updateWalletsFromSocket(wallet));
    }
  });
};

const setNotificationListener = (socket, dispatch = store.dispatch) => {
  if (!socket) return;

  socket.setListener("notification", (data) => {
    if (!!data) {
      const notifications = JSON.parse(data);

      dispatch(setNotifications(notifications));
    }
  });
};

export const LISTENERS_METHODS = [setWalletListener, setNotificationListener];
export const PRIVATE_EVENTS = ["notification", "wallet"];

const removeListeners = (socket) => {
  socket.removeListener?.("wallet");
  socket.removeListener?.("notification");
};

const useUserSocket = () => {
  const { accessToken } = useSelector((state) => state.auth);
  const { socket } = useContext(SocketContext);
  const dispatch = useDispatch();

  // Fetch unread notifications
  useEffect(() => {
    if (!accessToken) return;

    axios
      .get("user/notifications", {
        params: {
          limit: 500,
          type: "private",
          status: false,
        },
      })
      .then((res) => {
        dispatch(setNotifications(res?.data?.data?.data || []));
      })
      .catch(() => {
        dispatch(setNotifications([]));
      });
  }, [accessToken, dispatch, socket]);

  // Set wallet & notification listener
  useEffect(() => {
    setWalletListener(socket);
    setNotificationListener(socket);

    return () => removeListeners(socket);
  }, [accessToken, dispatch, socket]);
};

export default useUserSocket;
