import React from "react";
// import { useLocation } from "react-router-dom";
import MainSidebarContent from "./MainSidebarContent";

function MainSidebar() {
  // const { pathname } = useLocation();

  return <MainSidebarContent />;
}

export default MainSidebar;
