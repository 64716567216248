import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';

const Analytics = () => {
    const tableData = [
        {
            id: 1,
            title: "CME",
            value: "ALL",
        },
        {
            id: 2,
            title: "EEX",
            value: "F1BM, F1BQ, F1BY, F7BM, F7BQ, F7BY, FBBM, FBBQ, FBBY, FDBM, FDBQ, FDBY, FEBM, FEBQ, FEBY, O1BM, O1BQ, O1BY, O7BM, O7BQ, O7BY, OBBM, OBBQ, OBBY, ODBM, ODBQ, ODBY, OEBM, OEBQ, OEBY, EUA, OEUA",
        },
        {
            id: 3,
            title: "Eurex",
            value: "FDAX, ODAX, ODX1, ODX2, ODX3, ODX4, ODX5, FESX, OESX, OES1, OES2, OES3, OES4, OES5, OMSX, FGBL, OGBL, OGB1, OGB2, OGB3, OGB4, OGB5, FGBL, OGBL, FGBS, OGBS, FGBM, OGBM, OGBL",
        },
        {
            id: 4,
            title: "Euronext",
            value: "yEBM, yECO, yEMA, yRSM, yRSO, kPSX, fBEL, yOBM, yOBS, yOSO, yOCO, yOSM, yOMA, zPDX, zPEX, zFPE, jPXA, jFCE, cPX1, kAEX, kAX1, kAX2, kAX4, kAX5, gAEX, kFMX",
        },
        {
            id: 5,
            title: "ICE",
            value: "AFR, ATW, BRN, BW, C, CC, CER, CT, DW, DX, EC, ECF, G, GNM, GWM, IBO, ICN, ILB, IS, ISM, IW, KC, NCF, OJ, RC, RF, RS, SB, T, TF, TFM, UCA, UCP, UCX, UHO, UHU, W, WA, WBS, RC, CAX P, CAY P, EFO, UKA, CAZ P, RGT P, RGU, RGU P, CB0 P, I, K, K2, K3, K4, K5, M, M2, M3, M4, M5, L, R, YFS I, ESX I, FLX I",
        },
    ]
    return (
        <Box className="container">
            <Box sx={styles.wrapper}>
                <Typography sx={styles.title} data-aos="fade-up" data-aos-duration="500"><span>ANALYTICS</span></Typography>
                <Typography sx={styles.subtitle}>
                    We provide advanced analytics (Greeks), including Delta, Gamma, Theta, Vega, Rho, Delta Decay Vomma, Vanna, Zomma, PctGamma and OEV for the following exchanges and products:
                </Typography>
            </Box>
            <Box sx={styles.table}>
                <Grid container sx={styles.tableItemWrapper}>
                    <Grid item xs={4} md={2} sx={styles.tableItem}>
                        <Typography sx={styles.headTitle}>Exchange</Typography>
                    </Grid>
                    <Grid item xs={8} md={10}>
                        <Typography sx={styles.headTitle}>Product Symbol</Typography>
                    </Grid>
                </Grid>
                {tableData?.map((item) => (
                    <Grid container key={item.id} sx={styles.tableItemWrapper}>
                        <Grid item xs={4} md={2} sx={styles.tableItem}>
                            <Typography sx={styles.headbody}>{item.title}</Typography>
                        </Grid>
                        <Grid item xs={8} md={10}>
                            <Typography sx={styles.headbody}>{item?.value}</Typography>
                        </Grid>
                    </Grid>
                ))
                }
            </Box>
        </Box>
    );
};

export default Analytics;