import * as yup from "yup";

export const contactUsFormValidation = yup.object({
  title: yup.string().required("Title is Required"),
  description: yup.string().required("Description is Required"),
  email: yup
    .string()
    .required("Email is Required")
    .email("Email is not correct"),
});
