const pages = {
  //auth ::
  //register :
  createAcc: "Create Account",
  emailSignup: "Sign Up With Email",
  createPassword: "Create Passowrd",
  // login :
  forgetPass: "Forgot password?",
  noAccSignup: "Don't have an account? sign up",
  // verify :
  REGISTER: "Register",
  verification: "Verification",
  verifyDesc: "Please enter the 4-digit verification code that was sent to (#). The code is valid for 1 minute.",

  // recovery :
  passRecovery: "Password Recovery",

  // forgot password
  forgotPassword: "Forgot Password",
  // reset password

  //from volex
  email: "Email",
  mobile: "Mobile",
  password: "Password",
  passwordConfirmation: "Password confirmation",
  name: "Name",
  login: "Login",
  signin: "Login",
  signIn: "Login",
  signup: "Sign up",
  signUp: "Sign up",
  LOGIN: "Login",
  SIGNUP: "Sign Up",
  signupAgreement: "I have read and agree to the #",
  termsOfService: "Terms of Service",
  verify: "Verify",
  alreadyHaveAnAccount: "Already have an account ?",
  freeRegistration: "Free registration",
  back: "Back",
  next: "Next",
  FORGOT_PASSWORD: "Forgot Password",
  resetPassword: "Reset Password",
  resend: "Resend",
  mobileNotValid: "Mobile isn't valid!",
  referredCodeLabel: "Referral Id (Optional)",
  signupRecieveEmailAgreement: "I want to receive email offers from Tradergpt partnering clubs.",
};

export default pages;
