import React from "react";
import GlobalStyle from "./useAuthLayoutStyle";
import { useEffect } from "react";

const AuthLayout = (props) => {
  const { children } = props;
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <GlobalStyle />
      <div>{children}</div>
    </>
  );
};

export default AuthLayout;
