import React from "react";
import { styles } from "./styles";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";

const Card = ({data, loading}) => {
  const accountType = data?.traders?.accountType?.split('-');
  const risk = data?.traders?.risk?.split('-');
  return (
    <Box sx={styles.card}>
      <Stack gap={0.5}>
        {loading ? 
        <>
          <Skeleton variant="rectangular" width={72} height={16} sx={{borderRadius: 3}} />
          <Skeleton variant="rectangular" width={128} height={16} sx={{borderRadius: 3}} />
        </>
        :
        <>
          <Typography sx={styles.cardTitle}>{data?.traders?.title}</Typography>
          <Typography sx={styles.cardsubTitle}>{data?.traders?.description}</Typography>
        </>
        }
      </Stack>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={styles.cardsubTitle}>Risk</Typography>
            {loading ? 
              <Stack gap={0.5}>
                <Skeleton variant="rectangular" width={72} height={16} sx={{borderRadius: 3}} />
                <Skeleton variant="rectangular" width={128} height={16} sx={{borderRadius: 3}} />
              </Stack>
              :
              <Typography sx={styles.cardTitle}>
                {risk?.map((item) => (
                    <>{item} <br /></>
                ))}
              </Typography>
            }
          </Grid>
          <Grid item xs={6}>
            <Typography sx={styles.cardsubTitle}>Account Type</Typography>
            {loading ? 
              <Stack gap={0.5}>
                <Skeleton variant="rectangular" width={72} height={16} sx={{borderRadius: 3}} />
                <Skeleton variant="rectangular" width={128} height={16} sx={{borderRadius: 3}} />
              </Stack>
              :
              <Typography sx={styles.cardTitle}>
                {accountType?.map((item) => (
                  <>{item} <br /></>
                ))}
              </Typography>
            }
          </Grid>
          <Grid item xs={6}>
            <Typography sx={styles.cardsubTitle}>Average Score</Typography>
            {loading ? 
              <Stack gap={0.5}>
                <Skeleton variant="rectangular" width={72} height={16} sx={{borderRadius: 3}} />
              </Stack>
              :
              <Typography sx={styles.blueCardTitle}>{data?.traders?.average}pts</Typography>
            }
          </Grid>
          <Grid item xs={6}>
            <Typography sx={styles.cardsubTitle}>Minimum Investment Capital</Typography>
            {loading ? 
              <Stack gap={0.5}>
                <Skeleton variant="rectangular" width={72} height={16} sx={{borderRadius: 3}} />
              </Stack>
              :
              <Typography sx={styles.cardTitle}>{data?.traders?.minInvestment}</Typography>
            }
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Card;
