import React from 'react';
import CommentCard from './CommentCard';
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller, Parallax } from "swiper";
import { Box, Typography } from '@mui/material';
import { styles } from './styles';

const Comments = () => {
    const sliderSettings1 = {
        freeMode: false,
        modules: [Parallax, Controller],
        className: "mySwiper",
        slidesPerView: 3,
        spaceBetween: 32,
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 1, 
            pauseOnMouseEnter: true,
        },
        initialSlide: 3,
        breakpoints: {
          1800: { slidesPerView: 3 },
          1600: { slidesPerView: 3 },
          1400: { slidesPerView: 3 },
          1200: { slidesPerView: 3 },
          900: { slidesPerView: 2 },
          600: { slidesPerView: 2 },
          500: { slidesPerView: 1 },
          1: { slidesPerView: 1 },
        },
      };
    const commentsData = [
        {
            id: 'comment-2',
            owner: 'Luise Martin',
            description: '"I have been looking for a tool to help me better understand the markets and make more informed decisions. I have had my eye on The TraderGPT Indicator for a while and it has been invaluable in my trading strategy."',
            rating: 4,
        },
        {
            id: 'comment-3',
            owner: 'Debra Reyes',
            description: `"As a trader, I know how important it is to be able to see the market and predict its next moves. This is exactly what TraderGPT does for me. I can't imagine trading without it."`,
            rating: 4.5,
        },
        {
            id: 'comment-1',
            owner: 'Dieter Friedrichs',
            description: '"TraderGPT has been essential in my trading. I have used a lot of different trading software and TraderGPT is by far the best. It really shows me what stocks will be good for making money, stocks that need to be sold, and stocks that are about to crash."',
            rating: 4.5,
        },
        {
            id: 'comment-4',
            owner: 'Hugo Aubry',
            description: `"I have been trading for a long time and I must say, the TraderGPT Indicator is the most accurate indicator. It's easy to use and it's just what I need."`,
            rating: 5,
        },
    ];
    return (
        <Box sx={{
            '.swiper-wrapper': {
                height: '100%',
                '.swiper-slide': {
                    height: '100%',
                }
            }
        }}>
            <Typography 
                data-aos="fade-up"
                data-aos-duration="600"
                data-aos-delay="200" 
                sx={styles.title}
            >
                Here's what our <span>customers</span> say!
            </Typography>
            <Swiper {...sliderSettings1}>
                {commentsData?.map((item) =>  (
                    <SwiperSlide>
                        <CommentCard data={item} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
};

export default Comments;