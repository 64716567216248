import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useSwiper } from "swiper/react";

const CustomPrevArrow = () => {
  const swiper = useSwiper();
  return (
    <Stack
      onClick={() => swiper.slidePrev()}
      alignItems="center"
      justifyContent="center"
      sx={{
        cursor: "pointer",

        zIndex: "1000",

        width: "40px",
        height: "40px",
        borderRadius: "50%",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "border.default" : "#D6D6D6",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#212020" : "#F5F5F5",
        transition: "all 0.2s ease 0s",
        color: (theme) => (theme.palette.mode === "dark" ? "#fff" : "#000"),
        "&:hover": {
          filter: (theme) =>
            theme.palette.mode === "dark" ? "brightness(1.5)" : "",
        },
      }}
    >
      <ChevronLeft />
    </Stack>
  );
};

const CustomNextArrow = () => {
  const swiper = useSwiper();
  return (
    <Stack
      onClick={() => swiper.slideNext()}
      alignItems="center"
      justifyContent="center"
      sx={{
        cursor: "pointer",

        zIndex: "1000",

        width: "40px",
        height: "40px",
        borderRadius: "50%",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "border.default" : "#D6D6D6",
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#212020" : "#F5F5F5",
        transition: "all 0.2s ease 0s",
        color: (theme) => (theme.palette.mode === "dark" ? "#fff" : "#000"),
        "&:hover": {
          filter: (theme) =>
            theme.palette.mode === "dark" ? "brightness(1.5)" : "",
        },
      }}
    >
      <ChevronRight />
    </Stack>
  );
};

export { CustomNextArrow, CustomPrevArrow };
