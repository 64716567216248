const cryptoWarsEn = {
  mainPageHeader: "TRADERGPT",
  mainPageBtn: "Start the journey",
  "crypto-card1": "Select",
  "crypto-card2": "Select",
  "crypto-card3": "Select",
  createTeamBtn: "Submit cards",
  events: "Events",
  topCoins: "Top Coins",
  sumOfPercentShouldBe100: "The sum of the selected percentages must be equal to 100",

  feedTitle: "PLAY Feed",
  feedDesc: "What is new on Tradergpt ?",
  feedBtn: "HOW TO PLAY ?",
  newPackTitle: "New Packs",
  newPackDesc: "Announced New Cards",
  tradeCardsTitle: "Collect & Trade",
  tradeCardsDesc: "NFT Collectable digital cards",
  bannersTitle: "All new games this year",
  bannersDesc: "dolor sit amet consectetur adipisicing elit. Doloremque sit cupiditate magnam natus ratione distinctio doloribus omnis!",
  bannersBtn: "Learn more",
  language: "Language",

  // WIZARD
  createYourClub: "Create your club",
  selectYourFavoritTeams: "Select your favorite teams",
  yourClub: "Your club",
  chooseClubBadge: "Choose club badge",
  badgeRequiredError: "Please select your club badge!",
  clubNameRequired: "Club name is a required field!",
  clubBadgeError: "Please select your club badge!",
  noBadgeFound: "No badge found!!",

  // HOW TO PLAY
  howToPlayLeagueHeader: "Join your first league",
  howToPlayPlayerHeader: "Follow your first player",

  next: "Next",
  leagueInfoTitle: "What are leagues?",
  leagueInfoDesc: "There are multiple competitions in , each with their own entry criteria. Here are three examples:",
  leagueInfoItem1Title: "All Start",
  leagueInfoItem2Title: "Under 23",
  leagueInfoItem3Title: "Europe",
  leagueInfoItem1Type: "Global",
  leagueInfoItem2Type: "Global",
  leagueInfoItem3Type: "Champion",
  leagueInfoItem1Desc: "Create a dream team of players from around the world.",
  leagueInfoItem2Desc: "Build a team of players under the age of 23.",
  leagueInfoItem3Desc: "Select players playing in Europe's top 5 leagues.",

  leagueWinningTitle: "Winning a league",
  leagueWinningDesc: "Managers who compete in a tournament are ranked in a leaderboard on their team’s performance.",
  leagueWinningHint: "Points are earned through the real-life performances of the players on your team. Goals, assists, clean sheets, and more all contribute to your points total.",
  leagueWinningBtn: "What do I win?",

  leagueRewardsTitle: "Earning rewards",
  leagueRewardsDesc: "Top performing Managers win rewards, often powerful cards. You can view potential rewards for each tournament in the Prize Pool.",
  leagueRewardsBtn: "Next",
  viewLess: "View less",
  viewMore: "View more",

  cardDetailTitle: "Player Card details",
  cardDetailDesc: "When you select a card, you will find valuable information such as a player’s average score, any bonus and experience points.",
  cardDetailHint: "When cards are played in tournaments, they gain experience points (XP), and level up. These give bonus points and allow you to climb up the rankings.",
  cardDetailItem1Title: "Average score",
  cardDetailItem2Title: "Bonus",
  cardDetailItem1Desc: "Based on the 5 last games, the average can go from 0 to 100",
  cardDetailItem2Desc: "Boosts the performance of the player, and gives additional points.",
  cardDetailItem3Desc: "Eligible in the Under 23 League",
  cardDetailBtn: "Start Playing",

  cardTypeTitle: "Player Card scarcities",
  cardTypeDesc: "Player Cards in Tradergpt are scarce, there is a limited amount of them in circulation. They come in 4 differents scarcities.",
  cardTypeHintTitle: "New cards are created each season",
  cardTypeBodyTitle: "Each player is licensed by a club or a league.",
  cardTypeBodyDesc: "And generated on a Blockchain. There’s provable scarcity. Cards cannot be duplicated.",

  wizardStartPlayTitle: "Let's get started",
  wizardStartPlayDesc: "As a new manager, you can compete in the Casual League. Register your team to complete this task!",

  done: "Done",
  revealAll: "Reveal all",
  openPackTitle: "Open your pack",
  openPackDesc: "Let's introduce you to your squad",
  openPackBtn: "Open pack",

  noSignalFound: "No signal found!",
  coin: "Coin",
  position: "Position",
  status: "Status",
  score: "Score",
  takeProfit: "Take Profit",
  stopLoss: "Stop Loss",
  startPrice: "Start Price",
  endPrice: "End Price",
};

export default cryptoWarsEn;
