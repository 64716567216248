import React, { useEffect, useState } from "react";
import AuthLayout from "components/AuthLayout";
import ErrorAlert from "components/ErrorAlert";
import Checkbox from "@mui/material/Checkbox";
import CountryInput from "components/Inputs/CountryInput";
import CustomButton from "components/CustomButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import { t } from "locales";
import { Helmet } from "react-helmet";
import { register } from "store/reducers/auth/asyncActions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { inputAutoFillStyles } from "assets/styles/styles";
import { useForm, Controller } from "react-hook-form";
import { useSearchParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { registerWithEmailValidation } from "library/validations/authValidation";
import { setAuthData, leaveRegisterPage } from "store/reducers/auth/authSlice";
import { Container, TextField, Typography, Box, FormHelperText, Grid, FormControl, InputAdornment, IconButton } from "@mui/material";

const Index = () => {
  const [searchParams] = useSearchParams();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { registerError, status } = useSelector((state) => state.auth);

  const [ShowPasswordState, setShowPasswordState] = useState({
    password: false,
    confirmPassword: false,
  });

  const referredCode = searchParams.get("ref");
  const linkCode = searchParams.get("link");
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      // mobile: "",
      password: "",
      countryId: undefined,
      passwordConfirmation: "",
      agree: false,
      referredCode,
    },
    resolver: yupResolver(registerWithEmailValidation),
  });
  const onSubmit = async (data) => {
    const gToken = await executeRecaptcha();
    const registerData = {
      email: data.email,
      name: data.name,
      // mobile: data?.mobile,
      password: data?.password,
      referredCode: data?.referredCode || undefined,
      gRecaptchaResponse: gToken,
      countryId: data?.countryId,
      link: linkCode || undefined,
    };

    try {
      dispatch(setAuthData(registerData));

      await dispatch(register(registerData));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (status === "fulfilled-register" && !registerError) {
      navigate("/auth/verify");
    }
  }, [status, navigate, registerError]);

  // Reset error and status after leaving page
  useEffect(() => {
    return () => {
      dispatch(leaveRegisterPage());
    };
  }, [dispatch]);

  return (
    <AuthLayout>
      <Helmet>
        <title>Sign Up | Tradergpt</title>
        <meta name="description" content="Sign Up | Tradergpt" />
      </Helmet>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            mb: "48px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",

            "& .phonenumber-box": {
              pt: 2,
              pb: 3,
              "& input": {
                p: "8.5px 14px",
              },
            },
          }}
          gap={0}
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit, (errors, e) => console.log(errors, e))}
        >
          <Typography
            variant="h2"
            fontWeight="800"
            style={{
              fontSize: "2rem",
              lineHeight: "1",
              letterSpacing: "-0.025em",
            }}
          >
            {t("createAcc")}
          </Typography>
          <Box sx={{ width: "100%", mt: 4, mb: 1 }}>{registerError && <ErrorAlert text={registerError} />}</Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ "& .MuiFormControl-root": { my: 0 } }}>
              <Typography sx={{ mb: 0.5, fontSize: 14, fontWeight: 500 }}>{t("Name")}</Typography>
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    error={errors?.name}
                    size="small"
                    helperText={t(errors?.name?.message)}
                    margin="normal"
                    fullWidth
                    id="name"
                    placeholder={t("Name")}
                    name="name"
                    autoComplete="name"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    sx={[
                      { ...inputAutoFillStyles },
                      // (theme) => theme.direction === "rtl" && rtlTextFieldStyle,
                    ]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ "& .MuiFormControl-root": { my: 0 } }}>
              <Typography sx={{ mb: 0.5, fontSize: 14, fontWeight: 500 }}>{t("Email")}</Typography>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    error={errors?.email}
                    size="small"
                    helperText={t(errors?.email?.message)}
                    margin="normal"
                    fullWidth
                    id="email"
                    placeholder={t("Email")}
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    sx={[
                      { ...inputAutoFillStyles, mb: "22px" },
                      // (theme) => theme.direction === "rtl" && rtlTextFieldStyle,
                    ]}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} sx={{ "& .MuiFormControl-root": { my: 0 } }}>
              <Typography sx={{ mb: 0.5, fontSize: 14, fontWeight: 500 }}>{t("Mobile")}</Typography>
              <Controller
                control={control}
                name="mobile"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <PhoneField
                    errors={errors}
                    id="mobile"
                    specialLabel={"mobile"}
                    name="mobile"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} sx={{ "& .MuiFormControl-root": { my: 0 } }}>
              <Typography sx={{ mb: 0.5, fontSize: 14, fontWeight: 500 }}>{t("Country")}</Typography>
              <Controller
                control={control}
                name="countryId"
                render={({ field: { onChange, onBlur, value, ref } }) => <CountryInput name="countryId" onChange={onChange} value={value} ref={ref} size="small" />}
              />
            </Grid>
            <Grid item xs={12} sx={{ "& .MuiFormControl-root": { my: 0 } }}>
              <Typography sx={{ mb: 0.5, fontSize: 14, fontWeight: 500 }}>{t("Password")}</Typography>
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPasswordState({ ...ShowPasswordState, password: !ShowPasswordState.password })} edge="end">
                            {ShowPasswordState.password ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    error={errors?.password}
                    helperText={t(errors?.password?.message)}
                    margin="normal"
                    fullWidth
                    id="password"
                    placeholder={t("Password")}
                    name="password"
                    autoComplete="password"
                    type={ShowPasswordState.password ? "text" : "password"}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    sx={[
                      { ...inputAutoFillStyles },
                      { mt: 3 },
                      // (theme) => theme.direction === "rtl" && rtlTextFieldStyle,
                    ]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ "& .MuiFormControl-root": { my: 0 } }}>
              <Typography sx={{ mb: 0.5, fontSize: 14, fontWeight: 500 }}>{t("Confirm Password")}</Typography>
              <Controller
                control={control}
                name="passwordConfirmation"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPasswordState({ ...ShowPasswordState, confirmPassword: !ShowPasswordState.confirmPassword })} edge="end">
                            {ShowPasswordState.confirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    error={errors?.passwordConfirmation}
                    helperText={t(errors?.passwordConfirmation?.message)}
                    margin="normal"
                    fullWidth
                    id="passwordConfirmation"
                    placeholder={t("Confirm Password")}
                    name="passwordConfirmation"
                    autoComplete="password"
                    type={ShowPasswordState.confirmPassword ? "text" : "password"}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    sx={[
                      { ...inputAutoFillStyles },
                      // (theme) => theme.direction === "rtl" && rtlTextFieldStyle,
                    ]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ "& .MuiFormControl-root": { my: 0 } }}>
              <Typography sx={{ mb: 0.5, fontSize: 14, fontWeight: 500 }}>Referral Code</Typography>
              <Controller
                control={control}
                name="referredCode"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    size="small"
                    error={errors?.referredCode}
                    helperText={t(errors?.referredCode?.message)}
                    margin="normal"
                    fullWidth
                    id="referredCode"
                    placeholder={t("referredCodeLabel")}
                    name="referredCode"
                    autoComplete="referredCode"
                    type={"text"}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    disabled={referredCode}
                    sx={{ ...inputAutoFillStyles }}
                  />
                )}
              />
            </Grid>
          </Grid>
          
          <Grid container spacing={1} sx={{ mb: 3, mt: 2 }}>
            <Grid item xs={12}>
              <FormControl sx={{ label: { margin: "auto" } }}>
                <Controller
                  control={control}
                  name="agree"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <FormControlLabel
                      control={<Checkbox size="small" color="primary" name="agree" id="agree" onChange={onChange} onBlur={onBlur} value={value} />}
                      label={
                        <Typography sx={{ fontSize: 14 }}>
                          {t("signupAgreement")}
                          <Typography
                            as={Link}
                            target="_blank"
                            to="/terms-and-condition"
                            variant="body1"
                            sx={{
                              cursor: "pointer",
                              fontSize: 14,
                              textDecoration: "none",
                              color: "primary.main",
                            }}
                          >
                            {t("termsOfService")}
                          </Typography>
                        </Typography>
                      }
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    />
                  )}
                />
              </FormControl>
              {errors?.agree && (
                <FormHelperText
                  sx={[
                    { color: "#f44336" },
                    (theme) =>
                      theme.direction === "rtl" && {
                        textAlign: "right",
                        direction: "rtl",
                      },
                  ]}
                >
                  {t(errors?.agree?.message)}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ label: { margin: "auto" } }}>
                <Controller
                  control={control}
                  name="emailAgree"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <FormControlLabel
                      control={<Checkbox size="small" color="primary" name="emailAgree" id="emailAgree" onChange={onChange} onBlur={onBlur} value={value} />}
                      label={<Typography sx={{ fontSize: 14 }}>{t("signupRecieveEmailAgreement")}</Typography>}
                    />
                  )}
                />
              </FormControl>
              {errors?.emailAgree && (
                <FormHelperText
                  sx={[
                    { color: "#f44336" },
                    (theme) =>
                      theme.direction === "rtl" && {
                        textAlign: "right",
                        direction: "rtl",
                      },
                  ]}
                >
                  {t(errors?.emailAgree?.message)}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={isSubmitting}
                withSpinner
                extraSx={{
                  borderRadius: 2.4,
                  width: "100%",
                  color: "#FFF",
                  py: "11.15px",
                  mt: 1,
                }}
              >
                Create Account
              </CustomButton>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </AuthLayout>
  );
};

export default Index;
