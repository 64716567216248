import { IMAGE_URL } from "./config";
import axios from "axios";

import binance from "assets/wallets/binance_light.png";
import metamask from "assets/wallets/metamask_light.png";
import kucoin from "assets/wallets/kucoin.png";

export function replaceJSX(str, find, replace) {
  let parts = str.split(find);
  let result = [];
  for (let i = 0; i < parts.length; i++) {
    result.push(parts[i]);
    result.push(replace);
  }
  result.pop();
  return result;
}

export const getPowerBadgeColor = (power, themeMode) => {
  if (power === "DNP")
    return themeMode === "dark" ? "rgb(102, 102, 102)" : "#eee";
  else if (power < 45) return "#d4c916";
  else if (power < 75) return "#24c3a2";
  else return "#24c358";
};

export const stringQueriesToObj = (stringQueries = "", removeQuery) => {
  const queriesArray = stringQueries?.split("&");

  const queries = {};

  queriesArray?.forEach((q) => {
    const [key, value] = q?.split("=");
    if (!!key && !!value && key !== removeQuery) queries[key] = value;
  });

  return queries;
};

export function calcTotalPages(total, pageSize) {
  const value = Math.ceil(Number(total) / Number(pageSize));

  return isNaN(value) ? 1 : value;
}

export const replaceComma = (value) => {
  if (!value) return 0;

  if (!isNaN(+value)) return value;

  const number = +value?.replaceAll?.(",", "");

  return !isNaN(number) ? number : 0;
};

export const truncateToDecimals = (num, dec = 2) => {
  if (!num || isNaN(+num)) return;

  const stringNum = String(num);
  const [, after] = stringNum?.split?.(".");

  if (!after || after?.length <= dec) {
    return +num;
  }

  const calcDec = Math.pow(10, dec);
  return Math.trunc(+num * calcDec) / calcDec;
};

export const getImageUrl = (image, key) => {
  if (typeof image === "string") return image;
  if (image && image.length > 0 && typeof image?.[0] === "string")
    return image?.[0];
  if (!image?.[0]?.key && !key) return null;
  // return IMAGE_URL + (image?.[0]?.key || key);
  return (image?.[0]?.location || key);
};

export const convertScientific = (value) => {
  if (Math.abs(value) < 1.0) {
    const e = parseInt(value.toString().split("e-")[1]);
    if (e) {
      value *= Math.pow(10, e - 1);
      value = "0." + new Array(e).join("0") + value.toString().substring(2);
    }
  } else {
    let e = parseInt(value.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      value /= Math.pow(10, e);
      value += new Array(e + 1).join("0");
    }
  }

  // return precision ? +value : value;
  return value;
};

export const fixNumber = (value, digits = 3, addingComma = false) => {
  let output = 0;

  if (!value || isNaN(+value)) return output;

  if (digits && digits <= 100 && digits >= 0) {
    output = convertScientific(parseFloat((+value)?.toFixed?.(digits)));
  } else {
    output = parseFloat((+value)?.toFixed?.(3));
  }

  if (addingComma) {
    output = addComma(output);
  }

  return output;
};

export const addComma = (num, dec) => {
  if (!num || isNaN(+num)) return 0;

  let number = convertScientific(num);

  if (dec) {
    number = convertScientific(truncateToDecimals(num, dec));
  }

  const [before, after] = String(number)?.split?.(".");
  let output = before?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (after) {
    output += "." + after;
  }
  return output;
  // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const addOrdinalSuffix = (n) => {
  // return n + ["st", "nd", "rd"][((((+n + 90) % 100) - 10) % 10) - 1] || "th";
  var s = ["th", "st", "nd", "rd"];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
export const b64toBlob = async (base64) => {
  const res = await fetch(base64);
  return res.blob();
};

export const frontPaginate = (array, page_size, page_number) => {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const dateSince = (date) => {
  const newDate = new Date(date);
  const seconds = Math.floor((new Date() - newDate) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " Years AGO";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} Months AGO`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval).toString() + " Days AGO";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " H AGO";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " M AGO";
  }
  return Math.floor(seconds) + " S AGO";
};
export const dateSinceEnd = (date) => {
  var countDownDate = new Date(date).getTime();
  var now = new Date().getTime();
  var distance = countDownDate - now;
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let retValue = "";
  if (days > 0) {
    retValue = `${days} ${days === 1 ? "Day" : "Days"}`;
  }
  if (hours > 0) {
    retValue += ` ${hours} ${hours === 1 ? "Hour" : "Hours"}`;
  }
  if(days < 0 && hours < 0){
    return "The competition will close soon!"
  }
  return retValue;
};
export const getIpfsUrl = (ipfsFile) => {
  return "https://ipfs.io/ipfs/" + ipfsFile?.ipnft + "/metadata.json";
};

export const getIpfsImageFromMetadata = (ipfsImage) => {
  const mainPart = ipfsImage?.split?.("ipfs://")?.[1];

  if (!mainPart) return null;

  return "https://ipfs.io/ipfs/" + mainPart;
};

export const getMaxOffer = (array, field) =>
  Math.max.apply(
    Math,
    array.map(function (o) {
      return o[field];
    })
  );

export const singleCollectionFilter = (filter, id) => {
  const paginationFilter = { ...filter };
  let obj = Object();
  const filtersArray = [];
  if (id) {
    obj.id = id;
    if (paginationFilter.status.length > 0) {
      obj.status = paginationFilter.status.map((item) => item.value);
      filtersArray.push(...paginationFilter.status);
    }
    if (paginationFilter.currency.length > 0) {
      filtersArray.push(...paginationFilter.currency);
    }
    if (paginationFilter.properties.length > 0) {
      let Arr = [];
      paginationFilter.properties.forEach((item) => {
        const keys = Object.keys(item);
        Arr = [
          ...Arr,
          { key: "properties", key2: keys[0], value: item[keys[0]] },
        ];
      });
      filtersArray.push(...Arr);
      obj.properties = Arr.map((item) => {
        return [item.key2, ...item.value].join(",");
      });
    }
    if (paginationFilter.price.length > 0) {
      paginationFilter.price.forEach((item) => {
        const keys = item?.value?.split(":");
        const minMax = keys[1].split("-");
        obj.min = minMax[0].trim();
        obj.max = minMax[1].trim();
      });
      filtersArray.push(...paginationFilter.price);
    }
    if (paginationFilter.search.length > 0) {
      obj.search = paginationFilter.search;
    }
    obj.sort = paginationFilter.sort;
    obj.order = paginationFilter.order;
  }
  return { params: obj, filtersArray: filtersArray };
};

// calc ms remaining to finish that day - until next day 24:00:00
export const calcDistanceFromEndOfDay = (value) => {
  const date = new Date(value);

  const endOfDay = `${
    date.getMonth() + 1
  } ${date.getDate()} ${date.getFullYear()} 23:59:59`;

  return new Date(endOfDay).getTime() - new Date(date).getTime(); // return value in ms
};

// calc ms passed from begining of day - that day 00:00:00
export const calcDistanceFromStartOfDay = (value) => {
  const date = new Date(value);

  const startOfDay = `${
    date.getMonth() + 1
  } ${date.getDate()} ${date.getFullYear()} 00:00:00`;

  return new Date(date).getTime() - new Date(startOfDay).getTime(); // return value in ms
};

export const abbreviateText = (text, size = 5) => {
  return (
    text?.slice(0, size) +
    "..." +
    text?.slice(text?.length - size, text?.length)
  );
};

export const getFixed = (number, max = 16) => {
  if (typeof number === "number") {
    return Number(number)
      .toFixed(max)
      .replace(/\.?0+$/, "");
  } else {
    return number;
  }
};

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
export const nftLazyLoadImage = (imageMainFile) =>
  new Promise((resolve) => {
    const img = new Image();

    img.onload = function () {
      resolve(true);
    };
    img.src = imageMainFile;
  });
export const toDataURL = (url) =>
  axios
    .get(url, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/octet-stream",
      },
      credentials: "include",
    })
    .then((response) => response);

export function toMoney(amount) {
  if (!amount || isNaN(+amount)) return 0;

  return new Intl.NumberFormat().format(amount);
}
export const bscWalletValidation = (value) => {
  let isValid = false;
  if (value.substring(0, 2) === "0x" && value.length === 42) {
    isValid = true;
  } else {
    isValid = false;
  }
  return isValid;
};

export const decodeCamelCase = (value) =>
  value.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
export const exchangerIconDetect = (key) => {
  let icon = binance;
  switch (key.toLowerCase()) {
    case "binance":
      icon = binance;
      break;
    case "metamask":
      icon = metamask;
      break;
    case "kucoin":
      icon = kucoin;
      break;

    case "coinbase":
      icon = "/assets/wallets/coinbase.png";
      break;

    case "coinbasepro":
      icon = "/assets/wallets/coinbasePro.png";
      break;

    case "gateio":
      icon = "/assets/wallets/gateIo.png";
      break;

    case "allAssets":
      icon = "/assets/wallets/starlex.png";
      break;




    default:
      break;
  }
  return icon;
};
export const networkScanGen = (network, isAddress, txid) => {
  let networkLink = "https://etherscan.io/address/";
  switch (network?.toLowerCase()) {
    case "trc20":
      if (isAddress) {
        networkLink = `https://tronscan.org/#/contract/${txid}/transactions`;
      } else {
        networkLink = "https://tronscan.org/#/transaction/" + txid;
      }
      break;
    case "erc20":
      if (isAddress) {
        networkLink = "https://etherscan.io/address/" + txid;
      } else {
        networkLink = "https://etherscan.io/tx/" + txid;
      }

      break;
    case "bsc":
    case "bep20":
      if (isAddress) {
        networkLink = "https://bscscan.com/address/" + txid;
      } else {
        networkLink = "https://bscscan.com/tx/" + txid;
      }
      break;
    case "matic20":
      if (isAddress) {
        networkLink = "https://polygonscan.com/address/" + txid;
      } else {
        networkLink = "https://polygonscan.com/tx/" + txid;
      }
      break;
    case "matic":
      if (isAddress) {
        networkLink = "https://polygonscan.com/address/" + txid;
      } else {
        networkLink = "https://polygonscan.com/tx/" + txid;
      }
      break;
    default:
      if (isAddress) {
        networkLink = "https://etherscan.io/address/" + txid;
      } else {
        networkLink = "https://etherscan.io/tx/" + txid;
      }
      break;
  }
  return networkLink;
};
export const fetchImage = (coin) => {
  let resCoin = "";
  if (coin) {
    try {
      resCoin = require(`assets/icons/cryptoiconpng/${coin.toLowerCase()}.png`);
    } catch (e) {
      resCoin = require(`assets/icons/cryptoiconpng/unknowncoin.png`);
    }
  }
  return resCoin?.default;
};


export function numberWithCommas(x) {
  x = String(x) || "0"
  const [a, b] = x?.split?.(".");
  const c = a?.toString?.().replace?.(/\B(?=(\d{3})+(?!\d))/g, ",")
  return b ? [c, b].join(".") : c
}
export const changePriceFormat = (v, f = 4) => {
  // console.log({ v, f });
  if (!!!v) return 0;
  let decimalSplit = String(v).split(".")
  // let n = Math.floor(Math.log(decimalSplit[1]) / Math.LN10);
  let n = -Math.floor(Math.log(decimalSplit[1]) / Math.log(10) + 1);
  // console.log({ n, v, decimalSplit }, Math.LN10, Math.log(v), ((n) + decimalSplit[1]?.length) + f);
  if (decimalSplit[1]?.length) {
    return numberWithCommas(decimalSplit[0] + "." + decimalSplit[1].substring(0, ((n) + decimalSplit[1]?.length) + f))
  } else return numberWithCommas(decimalSplit[0])
}