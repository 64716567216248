import { useEffect, useState } from "react";
import { Typography, Box, CircularProgress, Stack } from "@mui/material";
import { useGetClaimRewardHistory } from "_hooks/Xtakers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { format } from "date-fns";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ClaimHistoryChart = ({ id }) => {
  const isLight = useSelector((state) => state.setting.theme === "light");
  const [formattedData, setFormattedData] = useState({
    labels: [],
    datasets: [],
  });
  const { data: chartData, isLoading: loading } = useGetClaimRewardHistory(id, {
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    if (loading) return;

    const data = {
      labels: [],
      datasets: [
        {
          label: "Claim History",
          data: [],
          backgroundColor: "#7917f3",
          maxBarThickness: 15,
        },
      ],
    };

    chartData?.list?.forEach((item) => {
      data.labels.push(format(new Date(item.createdAt), "y MMM d"));
      data.datasets[0].data.push(item?.amount ? +item.amount : 0);
    });

    setFormattedData(data);
  }, [chartData, loading]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 3,
        bgcolor: (theme) => `card.${theme.palette.mode}`,
        p: "20px",
        borderRadius: "12px",
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "500" }}>
        Your Claim Profit
      </Typography>

      {loading && !formattedData?.labels?.length ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <CircularProgress size={50} />
        </Stack>
      ) : (
        <Bar
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                grid: { color: isLight ? "#dadada" : "#1f1f1f" },
              },
              x: {
                grid: { color: isLight ? "#dadada" : "#1f1f1f" },
              },
            },
          }}
          data={formattedData}
        />
      )}
    </Box>
  );
};

export default ClaimHistoryChart;
