import { CloseRounded } from "@mui/icons-material";
import { openToast } from "components/Toast";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { styles } from "./styles";
// import { updateWallets } from 'store/reducers/auth/asyncActions';
// import { getHumanError } from 'library/translateServerErrors';
import { Alert, Box, Button, Chip, Divider, Grid, IconButton, Modal, Stack, Typography } from "@mui/material";
import { getImageUrl } from "library/helper";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
// import { useGetSingleUserPlan, useTerminatePlanProfitOfXtakerPlan, useWithdrawPlanProfitOfXtakerPlan } from '_hooks/Xtakers';
import { useNavigate } from "react-router-dom";
// import { mainRoutes } from 'routing/GeneralRoutes/routes';
// import CancelModal from './CancelModal';
import { LoadingButton } from "@mui/lab";
import { addDays } from "date-fns";
import { deleteStableStakingPlan } from "../api";

import { singleTypeStyles } from "assets/styles/styles";
import moment from "moment";

const renderTime = (dimension, time) => {
  return (
    <Box className="time-wrapper">
      <Typography sx={{ fontSize: 18, textAlign: "center" }}>{time}</Typography>
      <Typography sx={{ fontSize: 14, textAlign: "center" }}>{dimension}</Typography>
    </Box>
  );
};

// const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 80,
  strokeWidth: 3,
};
const DetailModal = ({ data, onClose, refetchPlans }) => {
  const modalStyle = {
    width: "92%",

    maxWidth: 600,

    maxHeight: "97vh",

    overflow: "auto",

    position: "absolute",

    top: "50%",

    left: "50%",

    transform: "translate(-50%, -50%)",

    bgcolor: "background.paper",

    borderRadius: 3,

    boxShadow: 24,

    p: 4,
  };
  const [open, setOpen] = useState(false);
  // const { data: planData, isLoading: loadingData } = useGetSingleUserPlan({id: data?.id})
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ShowWarningModal, setShowWarningModal] = useState({
    show: false,
    id: -1,
  });
  const [DeleteStakingPlanLoading, setDeleteStakingPlanLoading] = useState(false);

  const WithdrawPlanHandler = () => {
    setShowWarningModal({
      show: true,
      id: data?.id,
    });
  };
  const handleClose = () => {
    setOpen(false);
    setShowWarningModal({
      show: false,
      id: -1,
    });
  };
  const openCancleModal = (image) => setOpen(true);
  // const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
  const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
  const getTimeDays = (time) => (time / daySeconds) | 0;

  const startTime = new Date(data?.createdAt) / 1000;
  const endTime = new Date(addDays(new Date(data?.createdAt), data?.duration))?.getTime() / 1000;
  const remainingTime = endTime - startTime;
  const startTimeFromToday = Date.now() / 1000;
  const remainingTimeForHours = endTime - startTimeFromToday;
  const daysDuration = Number(data?.moneyStakePlan?.duration) * daySeconds;
  const lastDay = getTimeDays(remainingTime) === 1 || getTimeDays(remainingTime) === 0;

  const handleDeleteStackingPlan = (id) => {
    setDeleteStakingPlanLoading(true);
    deleteStableStakingPlan({ id: ShowWarningModal.id })
      .then(({ data }) => {
        openToast("success", "Your plan has been successfully deleted");
        refetchPlans();
        setDeleteStakingPlanLoading(false);
        setShowWarningModal({ show: false, id: -1 });
        onClose();
      })
      .catch((err) => {
        openToast("error", "Error");
        setDeleteStakingPlanLoading(false);
      });
  };

  return (
    <>
      <Modal
        open={ShowWarningModal.show}
        onClose={() => setShowWarningModal({ show: false, id: -1 })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...modalStyle,
            maxWidth: "400px",
          }}
        >
          <Stack direction={"row"} justifyContent={"center"} gap={1}>
            <Typography id="modal-modal-title" fontSize={23} fontWeight={600} textAlign={"center"} color={"orange"}>
              Warning
            </Typography>
          </Stack>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }} textAlign={"center"}>
            Careful! If you cancel the plan, you will not get any reward
          </Typography>

          <Stack direction={"row"} gap={2}>
            <Button
              variant="contained"
              // color="secondary"
              style={{
                width: "100%",
                margin: "15px auto 0",
              }}
              onClick={() => setShowWarningModal({ show: false, id: -1 })}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="contained"
              color="error"
              style={{
                width: "100%",
                margin: "15px auto 0",
              }}
              loading={DeleteStakingPlanLoading}
              disabled={DeleteStakingPlanLoading}
              onClick={handleDeleteStackingPlan}
            >
              Delete
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
      <Box sx={styles.modalWrapper}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" gap="0.5rem">
            <Box sx={singleTypeStyles}>
              <img src={getImageUrl(data?.moneyStakePlan?.icon)} alt="" />
            </Box>
            {/* <Avatar src={getImageUrl(data?.assignedCard?.card?.cardType?.image)} /> */}
            <Typography sx={{ fontSize: 20, fontWeight: 600 }}>{data?.moneyStakePlan?.title}</Typography>
          </Stack>
          <Stack alignItems="flex-end">
            <IconButton onClick={onClose} sx={styles.closeIcon}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </Stack>

        {/* {remainingTime > 0 && planData?.initialAmount === planData?.currentAmount ? */}
        {remainingTime > 0 && parseInt(data?.duration) > 0 && data?.status !== "DELETED" ? (
          <Box sx={{ my: 4, display: "flex", alignItems: "center", gap: { xs: "10px", sm: "80px" } }}>
            <Typography sx={{ pl: 2 }}>Remaining Days:</Typography>

            <CountdownCircleTimer {...timerProps} colors="#7b6fff" duration={daysDuration} initialRemainingTime={remainingTime}>
              {({ elapsedTime, color }) => <span style={{ color }}>{renderTime("days", getTimeDays(daysDuration - elapsedTime + 1000))}</span>}
            </CountdownCircleTimer>

            {/* <CountdownCircleTimer
              {...timerProps}
              colors="#D14081"
              duration={daySeconds}
              initialRemainingTime={remainingTimeForHours % daySeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat: remainingTimeForHours - totalElapsedTime > hourSeconds,
              })}
            >
              {({ elapsedTime, color }) => <span style={{ color }}>{renderTime("hours", getTimeHours(daySeconds - elapsedTime))}</span>}
            </CountdownCircleTimer> */}
          </Box>
        ) : null}
        <Box>
          {(() => {
            if (parseInt(data?.duration) === 1) {
              return (
                <>
                  <Alert severity="info" sx={{ textAlign: "center", fontSize: 14, my: 3, borderRadius: 3 }}>
                    You will receive profit, coming soon!
                  </Alert>
                </>
              );
            } else if (parseInt(data?.duration) === 0) {
              return (
                <>
                  <Alert severity="success" sx={{ textAlign: "center", fontSize: 14, my: 3, borderRadius: 3 }}>
                    You received your profit!
                  </Alert>
                </>
              );
            } else if (data?.status === "DELETED") {
              return (
                <>
                  <Alert severity="error" sx={{ textAlign: "center", fontSize: 14, my: 3, borderRadius: 3 }}>
                    You have deleted your plan!
                  </Alert>
                </>
              );
            }
          })()}
        </Box>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Box
              sx={{
                ...styles.cardWrapper,
                bgcolor: (theme) => (theme.palette.mode === "dark" ? "#151515" : "#fff"),
              }}
            >
              <Typography sx={styles.cardPlanName}>{data?.moneyStakePlan?.title}</Typography>
              <Divider />
              <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1.5 }}>
                <Typography>Investing Amount</Typography>
                <Typography>{data?.amount}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1.5 }}>
                <Typography>Plan Duration</Typography>
                <Typography>{`${data?.moneyStakePlan?.duration} Days`}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1.5 }}>
                <Typography>Start Date</Typography>
                <Typography>{moment(data?.createdAt).format("MMM Do YY")}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
                <Typography>Status</Typography>
                <Chip
                  label={data?.status}
                  color={data?.status === "OPEN" ? "info" : data?.status === "COMPLETED" ? "success" : "error"}
                  sx={{ fontSize: 13, color: "white" }}
                />
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box
              sx={{
                ...styles.cardWrapper,
                bgcolor: (theme) => (theme.palette.mode === "dark" ? "#151515" : "#fff"),
              }}
            >
              <Typography sx={styles.cardTitle}>Min Profit</Typography>
              <Typography sx={styles.cardDescription}>${((data?.amount * data?.moneyStakePlan?.minProfit) / 100).toFixed(2)}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                ...styles.cardWrapper,
                bgcolor: (theme) => (theme.palette.mode === "dark" ? "#151515" : "#fff"),
              }}
            >
              <Typography sx={styles.cardTitle}>Max Profit</Typography>
              <Typography sx={styles.cardDescription}>${((data?.amount * data?.moneyStakePlan?.maxProfit) / 100).toFixed(2)}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                ...styles.cardWrapper,
                bgcolor: (theme) => (theme.palette.mode === "dark" ? "#151515" : "#fff"),
              }}
            >
              {/* <Typography sx={styles.cardPlanName}>{data?.moneyStakePlan?.title}</Typography>
              <Divider /> */}
              <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1.5 }}>
                <Typography>Profit to date</Typography>
                <Typography>${data?.lockedProfit}</Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Stack direction="row" gap="1em">
          <Button
            // withSpinner
            // disabled={withdrawLoading || planData?.initialAmount !== planData?.currentAmount}
            variant="contained"
            // color="secondary"
            sx={{
              flex: 1,
            }}
            onClick={onClose}
          >
            Close Modal
          </Button>

          {data?.status === "OPEN" && (
            <>
              <Button
                variant="contained"
                color="error"
                loading={DeleteStakingPlanLoading}
                // disabled={withdrawLoading || planData?.initialAmount === planData?.currentAmount}
                disabled={DeleteStakingPlanLoading}
                sx={{
                  flex: 1,
                }}
                onClick={WithdrawPlanHandler}
              >
                Delete Plan
              </Button>
            </>
          )}
        </Stack>
        {/* <CancelModal
                    {...{open, handleClose, id: data?.id, refetchPlans, onClose}}
                /> */}
      </Box>
    </>
  );
};

export default DetailModal;
