import { LoadingButton } from "@mui/lab";
import { Box, Button, Chip, Grid, Modal, Pagination, Skeleton, Stack, Typography } from "@mui/material";
import cardBg1 from "assets/stakingBg/card1.svg";
import cardBg2 from "assets/stakingBg/card2.svg";
import cardBg3 from "assets/stakingBg/card3.svg";
import { openToast } from "components/Toast";
import { calcTotalPages, fixNumber } from "library/helper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteStableStakingPlan, getStakingStableHistory } from "../api";
import DetailModal from "./DetailModal";

const StableCoinStakingHistory = () => {
  const modalStyle = {
    width: "92%",

    maxWidth: 600,

    maxHeight: "97vh",

    overflow: "auto",

    position: "absolute",

    top: "50%",

    left: "50%",

    transform: "translate(-50%, -50%)",

    bgcolor: "background.paper",

    borderRadius: 3,

    boxShadow: 24,

    p: 4,
  };

  const timerProps = {
    isPlaying: true,
    size: 80,
    strokeWidth: 3,
  };

  const minuteSeconds = 60;
  const hourSeconds = 3600;
  const daySeconds = 86400;

  const { theme } = useSelector((state) => state.setting);
  const modal = useSelector((state) => state.app.modal);

  const [StakingHistoryData, setStakingHistoryData] = useState({});
  const [StakingHistoryLoading, setStakingHistoryLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [ShowWarningModal, setShowWarningModal] = useState({
    show: false,
    id: -1,
  });
  const [ShowDetailModal, setShowDetailModal] = useState({
    show: false,
    data: {},
  });

  const [DeleteStakingPlanLoading, setDeleteStakingPlanLoading] = useState(false);

  const handleGetStakingNftHistory = async () => {
    setStakingHistoryLoading(true);
    const { data } = await getStakingStableHistory(page);
    setStakingHistoryData(data?.data);
    setStakingHistoryLoading(false);
  };

  const handleDeleteStackingPlan = (id) => {
    setDeleteStakingPlanLoading(true);
    deleteStableStakingPlan({ id: ShowWarningModal.id })
      .then(({ data }) => {
        openToast("success", "Your plan has been successfully deleted");
        handleGetStakingNftHistory();
        setDeleteStakingPlanLoading(false);
        setShowWarningModal({ show: false, id: -1 });
      })
      .catch((err) => {
        openToast("error", "Error");
        setDeleteStakingPlanLoading(false);
      });
  };

  const handleSetDetailModal = (planData) => {
    modal.show(
      <DetailModal onClose={modal.hide} refetchPlans={handleGetStakingNftHistory} data={planData} changeShowWarningModal={setShowWarningModal} />
    );

    // setShowDetailModal({
    //     show: true,
    //     data: planData
    // })
  };

  const handleGetDiffTime = () => {};

  const onPageChange = (_, value) => setPage(value);

  // useEffect(() => {
  //     handleGetStakingNftHistory();
  // }, [])

  useEffect(() => {
    handleGetStakingNftHistory();
  }, [page]);

  const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
  const getTimeDays = (time) => (time / daySeconds) | 0;

  const renderTime = (dimension, time) => {
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };

  const bgs = [
    `url(${cardBg1}), linear-gradient(247.01deg, #5682f1 0.47%, #483aeb 94.19%)`,
    `url(${cardBg2}), linear-gradient(247.42deg, #637fe1 1.17%, #63dae1 93.56%)`,
    `url(${cardBg3}), linear-gradient(247.42deg, #93ea5e 1.17%, #2ec52b 93.56%)`,
  ];

  return (
    <>
      {ShowDetailModal.show && (
        <>
          <Modal
            open={ShowDetailModal.show}
            onClose={() => setShowDetailModal({ show: false, data: {} })}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...modalStyle,
                maxWidth: "400px",
              }}
            >
              <Stack direction={"row"} justifyContent={"center"} gap={1}>
                <Typography id="modal-modal-title" fontSize={23} fontWeight={600} textAlign={"center"} color={"orange"}>
                  {ShowDetailModal?.data?.assignedCard?.card?.name} - {ShowDetailModal?.data?.assignedCard?.card?.cardType?.name}
                </Typography>
              </Stack>

              <Stack direction={"row"}>
                {/* <CountdownCircleTimer
                                    {...timerProps}
                                    colors="#7E2E84"
                                    duration={daySeconds}
                                    initialRemainingTime={remainingTime % daySeconds}
                                >
                                    {({ elapsedTime , color }) => {
                                        //console.log(hourSeconds - elapsedTime / 1000);
                                        <span style={{ color }}>
                                            {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
                                        </span>
                                    }}
                                </CountdownCircleTimer> */}
              </Stack>

              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }} textAlign={"center"}>
                Careful! If you cancel the plan, you will not get any reward
              </Typography>

              <Stack direction={"row"} gap={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    width: "100%",
                    margin: "15px auto 0",
                  }}
                  onClick={() => setShowDetailModal({ show: false, data: {} })}
                >
                  Cancel
                </Button>

                <LoadingButton
                  variant="contained"
                  color="warning"
                  style={{
                    width: "100%",
                    margin: "15px auto 0",
                  }}
                  loading={DeleteStakingPlanLoading}
                  disabled={DeleteStakingPlanLoading}
                  onClick={handleDeleteStackingPlan}
                >
                  Delete
                </LoadingButton>
              </Stack>
            </Box>
          </Modal>
        </>
      )}

      <Modal
        open={ShowWarningModal.show}
        onClose={() => setShowWarningModal({ show: false, id: -1 })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...modalStyle,
            maxWidth: "400px",
          }}
        >
          <Stack direction={"row"} justifyContent={"center"} gap={1}>
            <Typography id="modal-modal-title" fontSize={23} fontWeight={600} textAlign={"center"} color={"orange"}>
              Warning
            </Typography>
          </Stack>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }} textAlign={"center"}>
            Careful! If you cancel the plan, you will not get any reward
          </Typography>

          <Stack direction={"row"} gap={2}>
            <Button
              variant="contained"
              color="secondary"
              style={{
                width: "100%",
                margin: "15px auto 0",
              }}
              onClick={() => setShowWarningModal({ show: false, id: -1 })}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="contained"
              color="warning"
              style={{
                width: "100%",
                margin: "15px auto 0",
              }}
              loading={DeleteStakingPlanLoading}
              disabled={DeleteStakingPlanLoading}
              onClick={handleDeleteStackingPlan}
            >
              Delete
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>

      {!StakingHistoryLoading && (
        <>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              gap: 1,
              // height: "100%",
              // border: "1px solid",
              // borderColor: (theme) => theme.palette.mode === "dark" ? "#939393" : "#151515",
              borderRadius: 2,
              mb: 2,
            }}
          >
            <Box
              sx={{
                flex: 1,
                backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#0d0d0d" : "#f2f2f2"),
                borderRadius: 2,
                p: 1.5,
              }}
            >
              <Typography>
                In Plan
                {/* <span style={{ fontSize: "13px" }}>(Stable coin)</span> */}
              </Typography>
              <Typography sx={{ textAlign: "right", fontSize: 18, fontWeight: 700, color: "#ffa500", mt: 1 }}>
                {fixNumber(StakingHistoryData?.details?.inStake, 2)}
              </Typography>
            </Box>

            <Box
              sx={{
                flex: 1,
                backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#0d0d0d" : "#f2f2f2"),
                borderRadius: 2,
                p: 1.5,
              }}
            >
              <Typography>Locked profit so far</Typography>
              <Typography sx={{ textAlign: "right", fontSize: 18, fontWeight: 700, color: "#ffa500", mt: 1 }}>
                ${StakingHistoryData?.details?.totalLockedProfit}
              </Typography>
            </Box>

            <Box
              sx={{
                flex: 1,
                backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#0d0d0d" : "#f2f2f2"),
                borderRadius: 2,
                p: 1.5,
              }}
            >
              <Typography>Finished Plans</Typography>
              <Typography sx={{ textAlign: "right", fontSize: 18, fontWeight: 700, color: "#66bb6a", mt: 1 }}>
                {fixNumber(StakingHistoryData?.details?.finishedStake, 2)}
              </Typography>
            </Box>

            <Box
              sx={{
                flex: 1,
                backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#0d0d0d" : "#f2f2f2"),
                borderRadius: 2,
                p: 1.5,
              }}
            >
              <Typography>Profit earned</Typography>
              <Typography sx={{ textAlign: "right", fontSize: 18, fontWeight: 700, color: "#66bb6a", mt: 1 }}>
                ${StakingHistoryData?.details?.totalProfitEarned}
              </Typography>
            </Box>
          </Box>
        </>
      )}

      <Grid container>
        {StakingHistoryLoading ? (
          <>
            {Array(3)
              .fill("")
              .map((item) => (
                <>
                  <Grid
                    xs={12}
                    sm={4}
                    md={6}
                    lg={4}
                    xl={3}
                    sx={{
                      p: 1,
                    }}
                  >
                    <Skeleton variant="rectangular" animation="wave" sx={{ borderRadius: 2, mb: 2 }} width={"100%"} height={"130px"} />
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: "1rem" }} width={"100%"} height={"50px"} />
                  </Grid>
                </>
              ))}
          </>
        ) : (
          <>
            {StakingHistoryData?.data?.map((item, index) => (
              <>
                <Grid
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{
                    p: 1,
                  }}
                >
                  <Stack
                    justifyContent="space-between"
                    sx={{
                      background: item?.status === "OPEN" ? bgs[2] : item?.status === "COMPLETED" ? bgs[0] : bgs[1],
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      borderRadius: "8px",
                      height: "156px",
                      padding: "1rem 2rem",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSetDetailModal(item)}
                    className="slider-item"
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#fff",
                        }}
                      >
                        {item?.moneyStakePlan?.title}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#fff",
                          }}
                        >
                          ${!isNaN(+item?.amount) ? fixNumber(+item?.amount, 3) : 0}
                        </Typography>

                        <Typography
                          variant="body1"
                          sx={{
                            color: "#fff",
                          }}
                        >
                          {/* {new Date(end) - new Date() > 0
                          ? formatDistanceToNowStrict(new Date(end), {
                              locale: "en",
                            })
                          : t("finished")} */}
                        </Typography>
                      </Box>
                      <Box>
                        <Chip
                          label={item?.status}
                          color={item?.status === "OPEN" ? "info" : item?.status === "COMPLETED" ? "success" : "error"}
                          sx={{ fontSize: 13, cursor: "pointer", minWidth: "100px", color: "white" }}
                        />
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
              </>
            ))}
          </>
        )}
      </Grid>

      {StakingHistoryData?.data?.length === 0 && (
        <>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: 600,
              textAlign: "center",
              my: 2,
            }}
          >
            You don't have any investing plan yet
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              py: 4,
            }}
          >
            <Link to="/?plans=active">
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: "150px",
                  margin: "15px auto 0",
                }}
              >
                Add Plan
              </Button>
            </Link>
          </Box>
        </>
      )}

      {StakingHistoryData?.data?.length > 0 && (
        <Stack direction="row" justifyContent="center" mt={2} mb={2}>
          <Box sx={{ mx: "auto" }}>
            <Pagination
              sx={{ width: "fit-content" }}
              count={calcTotalPages(StakingHistoryData.total, StakingHistoryData.pageSize)}
              page={page}
              onChange={onPageChange}
            />
          </Box>
        </Stack>
      )}
    </>
  );
};

export default StableCoinStakingHistory;
