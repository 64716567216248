export const styles = {
  wrapper: {
    p: 6,
    mt: 10,
    bgcolor: (theme) => theme.palette.primary.light,
  },
  subTitle: {
    mt: 1,
    fontWeight: 600,
    color: (theme) => theme.palette.primary.contrastText,
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    color: (theme) => theme.palette.primary.main,
  },
  buttonWrapper: {},
  button: {
    color: "#fff",
    backgroundColor: "primary.main",
    px: "24px",
    py: "12px",
    borderRadius: "12px",
    textDecoration: "none",
    textAlign: "center",
    mr: "12px",
    boxShadow: `none`,
    transition: "all 0.2s ease-out",
    "&:hover": {
      color: "#fff",
      backgroundColor: "primary.main",
      boxShadow: `4px 4px 15px rgba(41,  98, 255, 0.3)`,
      transform: "scale(1.04)",
    },
  },
};
