import React from "react";
import {
  Typography,
  Grid,
  Alert,
  Skeleton,
  Stack,
  Tabs,
  Tab,
  Avatar,
  AlertTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "components/CustomButton";
import ErrorAlert from "components/ErrorAlert";
import { replaceJSX } from "library/helper";
import { t } from "locales";
import CustomReactSelect from "components/CustomReactSelect";
import DepositCard from "./DepositCard";
import USDTImage from "assets/coins/usdt.png"

const BodyRow = ({
  active = [],
  asset,
  loading = false,
  walletDepositConfig,
  changeNetwork,
  error,
  success,
  address,
  getAddress,
  disable,
  tag,
  confirm,
  setConfirm,
  walletDeposit,
  onSelect,
}) => {
  const iconHandler = () => {
    if(asset?.coin === 'USDT') return USDTImage;
  };
  return (
    <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: 2,
            borderRadius: "24px",
            bgcolor: (theme) => `card.${theme.palette.mode}`,
            border: "1px solid",
            borderColor: "#eee",
            p: "24px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 20, fontWeight: 600 }}>Deposit</Typography>
          </Box>
          <Box>
            <Typography variant="body2" component="label">
              Choose your coin
            </Typography>
            <Box>
              {walletDeposit.length < 0 || !asset?.coin ? (
                <Skeleton height="38px" variant="rectangular" width="100%" sx={{borderRadius: 2}} />
              ) : (
                <CustomReactSelect
                  isSearchable
                  defaultValue={{
                    value: asset.coin,
                    name: asset.name,
                    label: `${asset.coin} ( ${asset.name} )`,
                  }}
                  options={walletDeposit || []}
                  onChange={onSelect}
                  containerSx={{ fieldset: { border: "none !important" } }}
                  selectSx={{
                    bgcolor: "rgba(0,0,0,.2)",
                    borderRadius: "0",
                  }}
                />
              )}
            </Box>
          </Box>
          <Box sx={{ mt: 2}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={active} aria-label="basic tabs example">
                {walletDepositConfig?.map((type) => (
                  <Tab
                    value={type}
                    label={type?.protocolType}
                    id={type?.protocolType}
                    onClick={() => changeNetwork(type)} 
                  />
                ))}
              </Tabs>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                gap: 2,
                mt: 2
              }}
            >
              {error && <ErrorAlert text={error} sx={{borderRadius: 3}} />}
              {success && (
                <Alert severity="success" sx={{borderRadius: 3}}>
                  {t(success)}
                </Alert>
              )}
              
              {address == null ? (
                <>
                  <Box>
                  </Box>
                  <Alert severity="info" sx={{textAlign: 'center', fontSize: 14}}>To deposit {asset?.coin} for your TraderGPT wallet, need to create a deposit address.</Alert>
                  <Stack justifyContent='center' direction='row' sx={{mt: 6, mb: 3}}>
                    {loading ? (
                      <Skeleton height="36.5px" width="135px" variant="rectangular" sx={{borderRadius: 2}} />
                    ) : (
                      <CustomButton
                        disabled={!confirm || !active || disable}
                        type={"primary"}
                        onClick={getAddress}
                        variant="contained"
                        loaderHeight="40"
                        extraSx={{
                          width: 'fit-content',
                          px: 2
                        }}
                      >
                        Create Wallet
                      </CustomButton>
                    )}
                  </Stack>
                  
                  <Stack direction='row' alignItems='center' gap='12px' sx={{mt: 2}}>
                    <Box>
                      <Avatar src={iconHandler()} alt="" sx={{width: 48, height: 48}} />
                    </Box>
                    <Box>
                      <Typography>
                        The address is ONLY available for {asset?.coin} deposit.
                      </Typography>
                      <Typography sx={{fontSize: 14, opacity: 0.8}}>Deposit of other assets will lead to permanent asset loss.</Typography>
                    </Box>
                  </Stack>
                </>
              ) : (
                <>
                  <Alert severity="info" sx={{borderRadius: 3}}>
                    {replaceJSX(
                      t("depositInfo"),
                      "#",
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{ fontWeight: "500" }}
                      >
                        {active?.network}
                      </Typography>
                    )}
                  </Alert>
                  <DepositCard text={address} />
                </>
              )}
              <Alert severity="warning" sx={{borderRadius: 3, mt: 2}}>
                <AlertTitle>{t("attention")}</AlertTitle>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      p: {
                        opacity: ".8",
                      },
                      ".keyword": {
                        fontWeight: "bold",
                        color: (theme) =>
                          theme.palette.mode === "dark"
                            ? "primary.light"
                            : "primary.dark",
                      },
                    }}
                  >
                    <Typography variant="body2">
                      1.{" "}
                      {replaceJSX(
                        t("attentionDeposit1"),
                        "[network]",
                        <span className="keyword">
                          {asset?.coin + (active?.type ? "-" + active?.type : "")}
                        </span>
                      )}
                    </Typography>
                    <Typography variant="body2">
                      2.{" "}
                      {replaceJSX(
                        t("attentionDeposit2"),
                        "[min]",
                        <span className="keyword">{active?.depositMin}</span>
                      )}
                    </Typography>
                    <Typography variant="body2">
                      3.{" "}
                      {replaceJSX(
                        t("attentionDeposit3"),
                        "[confirm]",
                        <span className="keyword">{active?.minConfirm}</span>
                      )}
                    </Typography>
                    <Typography variant="body2">
                      4.{" "}
                      {replaceJSX(
                        t("attentionDeposit4"),
                        "[unlock]",
                        <span className="keyword">{active?.unlockConfirm}</span>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Alert>
            </Box>
          </Box>
        </Box>
    </Box>
  );
};

export default BodyRow;
