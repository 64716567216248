import { Box, Avatar, Stack, Typography } from "@mui/material";
import { getImageUrl } from "library/helper";
import { t } from "locales";
import { useEffect, useState } from "react";
import Select from "react-select";

export const SingleLabelValue = ({ item }) => (
  <Stack direction="row" alignItems="center">
    <Avatar
      src={getImageUrl(item?.trader?.avatar)}
      sx={{
        width: 36,
        height: 36,
        fontSize: 16,
        mr: 1,
      }}
    >
      {item?.trader?.name?.[0]?.toUpperCase?.()}
    </Avatar>
    <Stack direction="row" alignItems="center" sx={{ width: "100%", py: 0.5 }}>
      <Stack>
        <Typography variant="body1" sx={{color: '#000'}}>{item?.trader?.name}</Typography>
        {/* <Typography variant="body2" sx={{ opacity: "0.7",color: "#000" }}>
          Average Score: {item?.average}
        </Typography> */}
      </Stack>
    </Stack>
  </Stack>
);


const TraderSelect = ({
  options,
  onChange,
  value,
  disabled,
  defaultValue,
  className,
  label = "",
  error,
  renderError = null,
  placeholder = "Select",
}) => {
  const [inValue, setInValue] = useState(defaultValue ?? options?.[0]);

  useEffect(() => {
    if (!inValue) {
      changeHandler(options?.[0]);
    } // eslint-disable-next-line
  }, [options]);

  useEffect(() => {
    if (inValue?.id !== value?.id) {
      changeHandler(value);
    } // eslint-disable-next-line
  }, [value]);

  const renderedError = error
    ? renderError
      ? renderError(t(error?.message))
      : t(error.message)
    : null;

  const changeHandler = (newValue) => {
    onChange(newValue);
    setInValue(newValue);
  };

  useEffect(() => {
    onChange(options[0])
  }, [options])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ".css-1t2xo5-option:active": {
          bgcolor: "transparent",
        },
      }}
    >
      {label && (
        <label className={"form-label"} htmlFor="priority">
          {t(label)}
        </label>
      )}
      <Select
        placeholder={placeholder}
        className={className}
        options={options}
        onChange={changeHandler}
        value={value ?? inValue}
        isDisabled={disabled}
        defaultValue={defaultValue}
        // styles={getStyle(theme)}
        isSearchable={false}
      />
      <div className="d-flex my-2">
        <div className="flex-1">
          {renderedError && (
            <>
              <span className="is-invalid" />
              <div className={`invalid-feedback m-0`}>
                {renderedError ? renderedError : null}
              </div>
            </>
          )}
        </div>
      </div>
    </Box>
  );
};

export default TraderSelect;
