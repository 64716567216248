const styles = {
    cardWrapper: {
        display: "flex",
        flexDirection: "column",
        bgcolor: "#fff",
        border: "1px solid rgba(0,0,0,0.1)",
        boxShadow: "none",
        borderRadius: "24px",
        height: '100%',
        p: {xs: 2, md: 3},
        transition: "all 0.2s ease-out",
        '&:hover': {
            border: "1px solid transparent",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
    },

    commentText: {
        fontSize: 16,
        fontWeight: 400,
        color: (theme) => theme.palette.solid.dark2,
        lineHeight: '22px',
    },
    
    ratingBox: {
        px: 1,
        width: 'fit-content',
        background: (theme) => theme.palette.primary.light,
        borderRadius: 15,
      },
    
    ownerText: {
        fontSize: 18,
        fontWeight: 700,
        lineHeight: '18px', 
    },
}

export default styles;