import { styled } from "@mui/system";
import OtpInput from "react-otp-input";

export const inputAutoFillStyles = {
  "& .MuiInputBase-root": {
    background: "rgb(234, 240, 247)",
    borderRadius: "0.6rem",
    "& .MuiInputBase-input": {
      py: 1.6,
    },
  },
  "& .MuiFormHelperText-contained": {
    color: "red",
    mx: 0,
  },
  border: "none",
  "input:-webkit-autofill": {
    borderRadius: "0.6rem",
    WebkitBoxShadow: "0 0 0 1000px rgb(234, 240, 247) inset",
    WebkitTextFillColor: (theme) => (theme.palette.mode === "dark" ? "#fff!important" : "#000!important"),
  },
};

export const accordionStyle = {
  border: "none",
  boxShadow: "none",
  background: "transparent",

  "&.Mui-expanded": {
    margin: "0",
  },
  ".Mui-expanded": {
    margin: "12px 0",
    minHeight: 0,
  },
  ".MuiAccordionSummary-root": {
    padding: 0,
    margin: 0,
    lineHeight: "22px",
  },
  ".MuiAccordionDetails-root": {
    padding: "0 0 8px 0",
  },
};

export const numberInputStyle = {
  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    display: "none",
  },
};

export const rtlTextFieldStyle = {
  ".MuiFormHelperText-root": {
    textAlign: "right",
    direction: "rtl",
  },

  "& label": {
    transformOrigin: "top right",
    right: "28px",
    top: "8px",
  },

  "&": {
    padding: "8px 0 !important",
  },
  "& div input": {
    height: "100% !important",
  },

  "& legend": {
    textAlign: "right !important",
  },
};

export const oneLineText = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const OtpInputCustom = styled(OtpInput)(({ theme }) => ({
  direction: "ltr",
  input: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "1px solid gray",
    fontSize: "18px",
    width: "3rem !important",
    textAlign: "center",
    padding: "0.5rem",
    margin: "0 .5rem",
    borderRadius: "8px",
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
    "&:focus-visible": {
      outline: "none",
    },
    "&:focus": {
      backgroundColor: "transparent",
      outline: 0,
      boxShadow: "0 0 0 0.25rem rgb(162 111 255 / 25%)",
    },
  },
}));
export const noTextOverflow = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: "100%",
};

export const singleTypeStyles = {
  width: "30px",
  height: "30px",
  img: {
    width: "100%",
    objectFit: "contain",
    filter: (theme) => (theme.palette.mode === "dark" ? "invert(100%)" : "unset"),
  },
};

export const highlightTextStyle = {
  textTransform: "uppercase",
  width: "fit-content",
  position: "relative",
  fontWeight: "600",
  letterSpacing: "0.5px",

  "&::before": {
    bgcolor: (theme) => (theme.palette.mode === "light" ? "#dec8ff" : "#6728c5"),
    content: '""',
    position: "absolute",
    width: "calc(100% + 4px)",
    height: "60%",
    left: "-2px",
    bottom: "0",
    zIndex: "-1",
    transform: "rotate(-2deg)",
  },
};
