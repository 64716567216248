export const styles = {
  title: {
    fontSize: {
        xs: "1.2rem",
        lg: "2.0rem",
      },
    fontWeight: 600,
    color: (theme) => theme.palette.primary.contrastText,
    mb: 3,
    textAlign: "center",
  },
};
