export const styles = {
    titleWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        mb: 2,
    },
    title: {
        mt: 0.5,
        fontSize: {
            xs: "1.6rem",
            lg: "2.0rem",
          },
        fontWeight: 600,
        color: (theme) => theme.palette.primary.contrastText,
    },
    paragraphWrapper: {
        display: "flex",
        flexDirection: "column",
        bgcolor: "#fff",
        border: "1px solid rgba(0,0,0,0.1)",
        boxShadow: "none",
        borderRadius: "24px",
        p: 4,
        mt: 4,
        transition: "all 0.2s ease-out",
        '&:hover': {
          border: "1px solid transparent",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
    },
    paragraph: {
        fontSize: {
            xs: "14px",
            lg: "16px",
          },
        color: (theme) => theme.palette.primary.contrastText,
        mb: 1.5,
    },
    subTitle: {
        px: 2,
        py: 0.5,
        background: (theme) => theme.palette.primary.light,
        color: (theme) => theme.palette.primary.main,
        fontWeight: 600,
        borderRadius: 15,
    },
    planWrapper: {
        display: "flex",
        flexDirection: "column",
        bgcolor: "#fff",
        border: "1px solid rgba(0,0,0,0.1)",
        boxShadow: "none",
        borderRadius: "24px",
        p: 3,
        transition: "all 0.2s ease-out",
        '&:hover': {
          border: "1px solid transparent",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
    },
    planNumberWrapper: {
        maxWidth: "32px",
        height: 32,
        mb: 1,
    },
    planNumber: {
        width: "14px",
        height: "14px",
        borderRadius: "50%",
        bgcolor: (theme) => theme.palette.primary.main,
    },
    planTitle: {
        fontWeight: 600,
        mb: 1,
    },
    planDescription: {
        fontSize: 14,
        color: "#5E6282",
    },
}