const inviteEn = {
  inviteFirstHeader: "Invite a friend to Tradergpt",
  inviteSecondHeader: "to get awarded!",
  inviteDesc: "Know someone passionate about trade? invite them over with your unique referral link",
  inviteModalDesc: "Invite your friends and get a free card.",
  inviteModalCodeTitle: "Invitation code",
  user: "User",
  cardNumbers: "Card numbers",
  COMPELETED: "Completed",
  COMPLETED: "Completed",
  EXPIRED: "Expired",
  IN_PROGRESS: "In progress",
  noInvitedFriend: "Nothing found",
  inviteSidebarHeader: "How it works?",
  levelOnePrizeTitle: "If your friend buy 5 cards you win",
  milestoneInviteTitle: "Milestones",
  milestoneInviteCondition: "30TH FRIEND",
  completedReferrals: "Completed referrals",
  friendsInvited: "Friends invited",
  inviteHistoryTitle: "Invitations sent",
  inviteFriendsNavLink: "Invite friends",
  inviteFriends: "Invite friends",
  reward: "Reward",
};

export default inviteEn;
