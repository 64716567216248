import React, { useEffect } from "react";
import Components from "./Components";
import { mainRoutes } from "routing/GeneralRoutes/routes";
import { Route, Routes } from "react-router-dom";
import { useGetAllPlans } from "_hooks/Xtakers";
const PlansRoutes = () => {
  const { data: plans } = useGetAllPlans();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Routes>
      {plans?.map((item) => {
        const replacePath = `${mainRoutes.plans.base}/${item?.name?.split(" ").join("-")}`;
        return <Route key={replacePath} exact={false} path={replacePath} element={<Components data={item} />} />;
      })}
    </Routes>
  );
};

export default PlansRoutes;
