import { useState } from "react";
import { t } from "locales";
import DataTable from "components/DataTable";
import { formatDateTime } from "library/formatDate";
import { Chip, Pagination, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useGetUserPlans } from "_hooks/Xtakers";
import NoData from "components/NoData";
import { calcTotalPages, getFixed } from "library/helper";

const STATUS = {
  0: "pending",
  "-1": "canceled",
  1: "successfull",
};
const LOADING_LIST = Array(10).fill({
  time: "",
  coin: "",
  amount: "",
  status: "",
  depositAddress: "",
  txid: "",
});
const LIMIT = 10;

const PlansHistory = () => {
  const [page, setPage] = useState(1);
  const { data: requestData, isLoading: loading } = useGetUserPlans({
    page,
    limit: LIMIT,
    status: "ALL",
  });
  console.log({requestData}, "requestData");
  const onPageChange = (_, value) => setPage(value);

  return (
    <Box
      sx={{
        borderRadius: "24px",
        bgcolor: (theme) => `card.${theme.palette.mode}`,
        border: "1px solid",
        borderColor: "#eee",
        p: "20px",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "600", marginBottom: 3 }}>
        {t("plansHistory")}
      </Typography>
      <Box>
        <DataTable
          columns={[
            {
              title: t("name"),
              field: "name",
              render: ({ name }) =>
                loading ? (
                  <Skeleton width="40px" height="15px" variant="rectangular" sx={{borderRadius: 2}} />
                ) : (
                  <Typography sx={{fontSize: 13}}>{name ?? "-"}</Typography>
                ),
            },
            {
              title: t("initialAmount"),
              field: "initialAmount",
              render: ({ initialAmount }) =>
                loading ? (
                  <Skeleton width="40px" height="15px" variant="rectangular" sx={{borderRadius: 2}} />
                ) : (
                  <Typography sx={{fontSize: 13}}>{getFixed(Number(initialAmount), 4) || 0}</Typography>
                ),
            },
            {
              title: t("currentAmount"),
              field: "currentAmount",
              render: ({ currentAmount }) =>
                loading ? (
                  <Skeleton width="40px" height="15px" variant="rectangular" sx={{borderRadius: 2}} />
                ) : (
                  <Typography sx={{fontSize: 13}}>{getFixed(Number(currentAmount), 4) || 0}</Typography>
                ),
            },

            {
              title: t("status"),
              field: "status",
              render: ({ status }) =>
                loading ? (
                  <Skeleton width="40px" height="15px" variant="rectangular" sx={{borderRadius: 2}} />
                ) : (
                  <Chip
                    label={
                      <Typography sx={{fontSize: 12}}>
                        { typeof status === "number" ? t(STATUS[status]) : t(status.toLowerCase())}
                      </Typography>
                    }
                    color={status.toLowerCase() === "terminated" ? "error" : "success"}
                  />
                    
                ),
            },
            {
              title: t("start"),
              field: "start",
              render: ({ start }) =>
                loading ? (
                  <Skeleton width="40px" height="15px" variant="rectangular" sx={{borderRadius: 2}} />
                ) : (
                  <Typography sx={{fontSize: 13}}>
                    {start ? formatDateTime(start) : "-"}
                  </Typography>
                ),
            },
            {
              title: t("end"),
              field: "end",
              render: ({ end }) =>
                loading ? (
                  <Skeleton width="40px" height="15px" variant="rectangular" sx={{borderRadius: 2}} />
                ) : (
                  <Typography sx={{fontSize: 13}}>
                    {end ? formatDateTime(end) : "-"}
                  </Typography>
                ),
            },
          ]}
          data={loading ? LOADING_LIST : requestData?.list?.plans}
        />
        {!loading && requestData?.list?.plans?.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 5,
            }}
          >
            <NoData title={"No plan found!"} />
          </Box>
        ) : (
          <Box sx={{ mx: "auto", width: "fit-content", mt: 3 }}>
            <Pagination
              sx={{ width: "fit-content" }}
              count={calcTotalPages(requestData?.list?.total, LIMIT) || 1}
              page={page}
              onChange={onPageChange}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default PlansHistory;
