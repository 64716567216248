import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Header from "./components/Header";
import Details from "./components/Details";
import Plans from "./components/Plans";
import { useBuyToken, useGetAllIcoPlans } from "_hooks/Xtakers";
import { useDispatch, useSelector } from "react-redux";
import { getStakingStableHistory } from "pages/Profile/Body/Xtaker/api";
import { updateWallets } from "store/reducers/auth/asyncActions";
import One from '../../assets/presale/1.svg';
import Two from '../../assets/presale/2.svg';
import Three from '../../assets/presale/3.svg';
import Four from '../../assets/presale/4.svg';
import { styles } from "./components/Header/styles";
import AdditionalData from "./components/AddtionalData";
import Steps from "./components/Steps";


const ICO = () => {
  const { data, isFetching: isLoading } = useGetAllIcoPlans(null);
  const isAuthorized = useSelector((state) => !!state.auth.user);
  const wallet = useSelector((state) => state.auth?.wallets?.USDT?.amount);
  const [ stakingHistoryData, setStakingHistoryData] = useState({});
  const [ tableAction, setTableAction] = useState(null);
  const { mutateAsync: postPlan , isSuccess } = useBuyToken();
  const dispatch = useDispatch();
  const smallDevice = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(()=> {
    if(isSuccess) {
      dispatch(updateWallets());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isSuccess])
  
  
  const handleGetStakingNftHistory = async () => {
    const { data } = await getStakingStableHistory(1);
    setStakingHistoryData(data?.data?.details);
  };
  
  
  useEffect(() => {
    handleGetStakingNftHistory();
  }, [isAuthorized]);

  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);


  const theme = useTheme();
  const plans = [
    {
      id: 1,
      image: One,
      title: "Tokenomics",
      description: "Crafting a Winning Strategy for Trex Token",
    },
    {
      id: 2,
      image: Two,
      title: "Strategic Marketing",
      description: "Drive Awareness and Adoption with Precision",
    },
    {
      id: 3,
      image: Three,
      title: "Community Building",
      description: "Create Thriving Networks For Better Adoption ",
    },
    {
        id: 4,
        image: Four,
        title: "Security Audit",
        description: "Protect Your Project with Thorough Audits",
      },
  ];

  const dynamicSpacing = {
    xs: 8,
    sm: 12,
    md: 20,
    lg: 27,
    // xl: 27,
  };

  return (
    <>
      <Helmet>
        <title>Trader GPT | Presale page</title>
        <meta name="description" content="Trader GPT | Presale | ICO" />
      </Helmet>
      <Box className="container" 
      
        sx={{
          borderRadius: "15px",
          margin: "auto",
          mb: 12,
          padding: "25px",
          // overflowX: "hidden",
        }}
      >
      <Grid container spacing={3} sx={{overflowY: 'none'}}>
        <Grid item lg={8} md={12} sm={12} sx={{mb: 1}} xs={12}>
          <Header />
          {!!smallDevice && 
          <Details isAuthorized={isAuthorized} plans={data?.plans} total={data?.total} wallet={wallet} stakingHistoryData={stakingHistoryData} postPlan={postPlan} tableAction={tableAction} holders={data?.holders}/>
          }
          <AdditionalData />
          <Plans plans={data?.plans} isLoading={isLoading} setTableAction={setTableAction}/>
      <Box sx={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", borderRadius: "15px" }}>
        <Grid container spacing={dynamicSpacing[theme.breakpoints.down("xl")]}>
          {plans?.map(({ id, title, image, description },index) => (
            <Grid item lg={3} md={3} sm={6} xs={12} key={index}>
              <Box
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-delay={index*200}
              sx={styles.planWrapper}
              >
                <Box sx={styles.iconWrapper}>
                    <img src={image} alt=""/>
                </Box>
                <Box>
                  <Typography sx={styles.planTitle}>{title}</Typography>
                  <Typography sx={styles.planDescription}>{description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
        </Grid>
        <Grid item lg={4} md={12} sm={11}>
          {!smallDevice && <Details isAuthorized={isAuthorized} plans={data?.plans} total={data?.total} wallet={wallet} stakingHistoryData={stakingHistoryData} postPlan={postPlan} tableAction={tableAction} holders={data?.holders}/>}
          <Steps/>
        </Grid>
      </Grid>
      </Box>
    </>
  );
};

export default ICO;
