
export const styles = {
    wrapper: {
        pt: 12,
    },
    title: {
        fontSize: {
            xs: "1.6rem",
            sm: "2rem",
            md: "2.3rem",
            lg: "2.6rem",
          },
          fontWeight: "700",
          color: (theme) => theme.palette.primary.contrastText,
          lineHeight: 1.4,
          "& span": {
            color: (theme) => theme.palette.primary.main,
          },
    },
    description: {
        mt: 2,
        color: "#5E6282", 
        fontSize: 17,
        lineHeight: '32px',
    },
    securityImage: {
        '& video': {
            width: '100%',
            borderRadius: '24px',
        }
    },
   
};