import React from "react";
import banner from "assets/images/banner.png";
import { Box, Typography } from "@mui/material";

const Header = () => {
  return (
    <Box sx={{backgroundImage: `url(${banner})`, backgroundSize: "cover", backgroundPosition: "center" }}>
      <Box classname="container">
        <Box sx={{py: 6}}>
          <Typography 
            data-aos="fade-up" 
            data-aos-duration="500" 
            sx={{
                textAlign: "center",
                color: "#FFF",
                fontSize: {
                    xs: "1.6rem",
                    sm: "2rem",
                    md: "2.3rem",
                    lg: "2.7rem",
                    xl: "3.2rem",
                },
                fontWeight: 700
            }}
          >
            ORDER MANAGEMENT
          </Typography>
          <Typography 
            data-aos="fade-up" 
            data-aos-duration="500" 
            data-aos-delay="100" 
            sx={{
                color: "#FFF",
                textAlign: "center",
                fontSize: {xs: 16, md: 18},
          }}>
            The most advanced trading tools delivering a more cost-effective order management system.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default Header;
