import { store } from "store/store";
import moment from "moment";

export function Utc2BeijingDateTime(utc_datetime) {
  const T_pos = utc_datetime.indexOf("T");
  const Z_pos = utc_datetime.indexOf("Z");
  const year_month_day = utc_datetime.substr(0, T_pos);
  const hour_minute_second = utc_datetime.substr(T_pos + 1, Z_pos - T_pos - 1);

  // eslint-disable-next-line
  const new_datetime =
    year_month_day.replace(/-/g, "/") + " - " + hour_minute_second;

  return new_datetime;
}
const localesDate = {
  fa: "fa",
  en: "en-US",
  tr: "tr",
  ar: "ar-sa",
};

export default function formatDate(date, config) {
  const { setting } = store.getState();
  const locale = config?.lang || localesDate[setting?.lang] || "en-US";

  if (!date) return "-";

  return new Date(date).toLocaleDateString(locale, config);
}
export function formatDateTime(date) {
  const val = new Date(date ? date : null).toISOString().split(".")[0] + "Z";
  const valueSplited = Utc2BeijingDateTime(val);
  return valueSplited;
}
export const timeFormat = (date, isGeorgian) => {
  let retDate;
  let m = moment(date, "YYYY-MM-DD");
  m.locale(isGeorgian ? "en" : "fa");
  retDate = m.format("HH:mm:ss");
  return retDate;
};
export const addDate = (number, dateType = "days") => {
  return moment().add(number, dateType).format();
};
export const dateToTimeSpanConvert = (date) => {
  return moment(date).unix();
};
export const getTimeRemaining = (endtime) => {
  const total = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
};
