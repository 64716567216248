import AccountSidebar from "./Setting/Sidebar";
import AccountTopbar from "./Setting/Topbar";
import UserDetail from "./UserDetail";
import { Box, Grid } from "@mui/material";
import { styles } from "./styles";
import { useLocation } from "react-router-dom";
import { PROFILE_ITEMS, UserProfileRoutes } from "./profile.routes";


const DashboardBody = () => {
  const { pathname } = useLocation();
  const splitPathname = pathname?.split('/')[2];
  return (
    <Box className="container">
      <Box sx={{mt: 3}}>
        <AccountTopbar menu={PROFILE_ITEMS?.filter((item) => item?.isTab)} findActiveCallback={(link = "") => link.includes(splitPathname)} />
      </Box>
      <Grid
        container
        spacing={1}
        sx={{mt: { xs: 0, md: 4 },  mb: 4}}
      >
        <Grid item xs={12} md={4} lg={3}
         sx={{ position: "sticky", top: "5rem" }}
         >
          <Box sx={styles.wrapper}>
            <UserDetail />
            <AccountSidebar menu={PROFILE_ITEMS?.filter((item) => item?.isTab)} findActiveCallback={(link = "") => link.includes(splitPathname)} />
          </Box>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <UserProfileRoutes />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardBody;
