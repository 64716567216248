import layout from "./layout";
import pages from "./pages";
import validation from "./validation";
import auth from "./auth";
import app from "./app";
import common from "./common";
import landing from "./landing";
import mainEn from "./main-en";
import invite from "./invite";
import user from "./user";
import assets from "./assets";
import errors from "./errors";
import cryptoWars from "./crypto-wars";
import xtakerEn from "./xtaker";
import gameEn from "./game";
import marketEn from "./market";
import starlexErrors from "./starlexErrors";
import termsAndCondition from "./termsAndCondition";
import ticket from "./ticket";
import { plans } from "./plan";

const en = {
  ...layout,
  ...pages,
  ...validation,
  ...auth,
  ...app,
  ...common,
  ...landing,
  ...mainEn,
  ...invite,
  ...user,
  ...assets,
  ...errors,
  ...cryptoWars,
  ...xtakerEn,
  ...gameEn,
  ...marketEn,
  ...starlexErrors,
  ...termsAndCondition,
  ...ticket,
  ...plans,
};

export default en;
