import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import BodyRow from "./BodyRow";
import axios from "library/http";
import _ from "lodash";
import { t } from "locales";
import { getHumanError } from "library/translateServerErrors";
import { findDefaultCoin } from "../config";
import { Helmet } from "react-helmet";
import DepositHistory from "./DepositHistory";

const Deposit = ({ isModal = false, noPt = false }) => {
  const [active, setActive] = useState(null);
  const [confirm, setConfirm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);
  const [tag, setTag] = useState(null);
  const [walletDeposit, setWalletDeposit] = useState([]);
  const [walletDepositConfig, setWalletDepositConfig] = useState([]);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [wallet, setWallet] = useState();
  const [disable, setDisable] = useState(false);
  const params = useParams();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [selectedAsset, setSelectedAsset] = useState(() => {
    if (params.coin) {
      const [name, coin] = params.coin.split("-");
      const isValid = _.find(walletDeposit, { name, coin });

      if (isValid) {
        return selectedAsset;
      }
    }

    return walletDeposit?.[0] || null;
  });

  function resetData() {
    setActive(null);
    setError(null);
    setWallet(null);
    // setConfirm(null);
    setAddress(null);
    setSuccess(null);
  }

  // Handle changing dropdown
  const onSelect = (value) => {
    resetData();
    setSelectedAsset({ naem: value.name, coin: value.coin });
  };

  // Set default protocol - when config changed
  useEffect(() => {
    if (walletDepositConfig.length > 0) setActive(walletDepositConfig[0]);
  }, [walletDepositConfig]);

  // Chack for coin param and validate coin
  useEffect(() => {
    if (isModal) return; // fixed check & select network bug - reset data

    resetData();

    if (params.coin && walletDeposit.length > 0) {
      const [name, coin] = params.coin.split("-");
      const assetData = { coin, name };
      const isValid = _.find(walletDeposit, assetData);

      if (isValid) {
        setSelectedAsset(assetData);
      } else {
        findDefaultCoin(walletDeposit, (val) => setSelectedAsset(val));
      }
    }
  }, [params, walletDeposit, isModal]);

  // Getting all listed coin - just for first time
  useEffect(() => {
    axios
      .get("wallet/list?type=deposit")
      .then((res) => {
        const depositList = res?.data?.data.map((item) => ({
          coin: item.coin,
          name: item.name,
          value: item.coin,
          label: `${item.coin} ( ${item.name} )`,
        }));
        setWalletDeposit(depositList);
        // setWalletDeposit(depositList.filter((item) => item?.coin !== "VLX"));

        if (!params?.coin) {
          findDefaultCoin(depositList, (val) => setSelectedAsset(val));
        }
      })
      .catch((error) => {});
    // eslint-disable-next-line
  }, []);

  // Set wallet based on active protocol - when protocol changed
  useEffect(() => {
    if (active?.wallet) {
      setWallet(active.wallet);
    }
  }, [active]);

  // Fetching config of selected asset(coin or fiat) - when asset changed
  useEffect(() => {
    setActive(null);
    setLoading(true);

    if (!selectedAsset?.coin) return;

    axios
      .get(`wallet/config?type=deposit&coin=${selectedAsset.coin}`)
      .then((response) => {
        const data = response?.data?.data;

        setWalletDepositConfig(data);

        if (data?.length > 0) {
          const wallet = data.find((w) => w?.id === active?.id)?.wallet;

          setWallet(wallet);
        }
        setLoading(false);
        setDisable(false);
      })
      .catch((error) => {
        setWalletDepositConfig([]);
        setActive("no protocol");
        setLoading(false);

        setDisable(true);
        setError(t("noDepositConfigBecauseOfUpdate"));
      });
    // eslint-disable-next-line
  }, [selectedAsset]);

  // Submiting form for coins -> getting address
  const getAddress = () => {
    setLoading(true);
    setSuccess(null);
    setError(null);
    axios
      .post(`asset/deposit`, {
        assetNetworkId: active.id,
      })
      .then((response) => {
        const { address, tag = "" } = response?.data?.data;
        setAddress(address);
        setTag(tag);
        // refreshWallet();
        // setConfirm(false);

        setSuccess("successDeposit");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const hError = getHumanError(error);
        setError(hError);
      });
  };

  // Handle network-protocol
  const changeNetwork = (type) => {
    setActive(type);
    // reset form
    setAddress(null);
    // setConfirm(null);

    setError(null);
    setSuccess(null);
  };

  return (
    <>
      <Helmet>
        <title>Deposite | Tradergpt</title>
        <meta name="description" content="Deposite | Tradergpt" />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          pt: isModal || noPt ? 0 : 6,
        }}
        className="container"
      >
        {/* <HeaderRow 
          wallet={wallet} 
          walletDeposit={walletDeposit} 
          asset={selectedAsset} 
          loading={loading} 
          onSelect={onSelect} 
          isModal={isModal} 
        /> */}
        <BodyRow
          wallet={wallet}
          active={active}
          asset={selectedAsset}
          loading={loading}
          walletDeposit={walletDeposit} 
          onSelect={onSelect} 
          isModal={isModal} 
          walletDepositConfig={walletDepositConfig}
          changeNetwork={changeNetwork}
          error={error}
          success={success}
          address={address}
          getAddress={getAddress}
          disable={disable}
          tag={tag}
          confirm={confirm}
          setConfirm={setConfirm}
        />
        <DepositHistory />
      </Box>
    </>
  );
};

export default Deposit;
