import React from 'react';
import { Box, Typography } from '@mui/material';
import { styles } from './styles';
import { t } from 'locales';

const Paragraph = ({title}) => {
    return (
        <Box sx={{mb: 10}}>
            <Box sx={styles.titleWrapper}>
                <Typography sx={styles.subTitle} data-aos="fade-up" data-aos-duration="500">
                    {t(`${title?.split(" ").join("")}Content`)}
                </Typography>
                <Typography sx={styles.title} data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
                    {t(`${title?.split(" ").join("")}secendTitle`)}
                </Typography>
            </Box>
            <Box sx={styles.paragraphWrapper}>
                {t(`${title?.split(" ").join("")}descriptions`).split('<br />')?.map((item) => (
                    <Typography sx={styles.paragraph} data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
                        {item}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default Paragraph;