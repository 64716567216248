export const styles = {
    wrapper: {
        mt: 10,
        bgcolor: '#F2F2F2',
        pb: 3,
    },
    title: {
        mt: 4,
        mb: 2,
        fontSize: {
            xs: "1.2rem",
            lg: "2.0rem",
          },
        fontWeight: 600,
        color: (theme) => theme.palette.primary.contrastText,
    },
    subTitle: {
        px: 2,
        py: 0.5,
        background: (theme) => theme.palette.primary.light,
        color: (theme) => theme.palette.primary.main,
        fontWeight: 600,
        borderRadius: 15,
    },
    image: {
        width: '100%',
        '& img': {
            width: '100%',
        }
    }
}