import { Stack, Typography, Box, Button, Avatar } from "@mui/material";
import { DownloadRounded, UploadRounded } from "@mui/icons-material";
import { getImageUrl, truncateToDecimals } from "library/helper";
import { useSelector } from "react-redux";
import FundAccountModal from "components/WalletActions/FundAccount";
import { useNavigate } from "react-router-dom";
import { t } from "locales";
import { profile } from "routing/GeneralRoutes/routes";

const actions = [
  // { title: "swap", icon: SwapHorizRounded, content: null, link: profile.swap },
  { title: "deposit", icon: DownloadRounded, content: null, link: profile.deposid },
  { title: "withdraw", icon: UploadRounded, content: null, link: profile.withdraw },
];

const Content = ({ toggleHandler }) => {
  const navigate = useNavigate();
  const modal = useSelector((state) => state.app.modal);
  const wallets = useSelector((state) => state.auth.wallets);
  const openFundAccountModal = () => {
    toggleHandler?.();
    modal.show(<FundAccountModal onClose={modal.hide} />);
  };

  return (
    <Stack>
      <Stack p={2} alignItems="center" width="100%" sx={{ overflow: "hidden" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          sx={{
            border: "1px solid",
            borderColor: "primary.light",
            borderRadius: "24px",
          }}
        >
          <Typography variant="body1" py={2} sx={{ opacity: "0.9" }}>
            My Wallets
          </Typography>
          <Stack sx={{ width: "100%" }}>
            {Object.values(wallets || {}).map((wallet) => (
              <Stack
                key={wallet?.assetId || wallet?.coin}
                sx={{
                  alignItems: "center",
                  borderTop: "1px solid",
                  borderColor: "primary.light",
                  py: 2,
                  img: {
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    objectFit: "contain",
                    mb: 1,
                  },
                }}
              >
                <Avatar src={getImageUrl(wallet?.icon)} alt="" />

                <Typography variant="body2" sx={{ opacity: "0.6" }}>
                  {wallet.name} Wallet
                </Typography>

                <Stack direction="row" alignItems="flex-end" gap={0.5}>
                  <Typography variant="body1">{truncateToDecimals(wallet.amount, 4)}</Typography>
                  <Typography variant="body2">{wallet.coin}</Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>

        {/* <Web3Wallet /> */}

        <Box  mt={2}
          sx={{
            width:"100%",
            border: "1px solid",
            borderColor: "primary.light",
            borderRadius: "24px",
            px: 2,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-around"
            p={2}
          >
            {actions.map(({ title, icon: Icon, content: Content, link }) => (
              <Stack
                key={title}
                sx={{
                  justifyContent: "center",
                }}
              >
                <Box
                  onClick={() => {
                    toggleHandler?.();

                    if (link) navigate(link);
                    else !!Content && modal?.show(<Content onClose={modal.hide} />);
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                    p: "10px",
                    textDecoration: "none",
                  }}
                >
                  <Icon fontSize="large" sx={{ color: "primary.main" }} />
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "center",
                      color: (theme) => (theme.palette.mode === "dark" ? "white" : "#111"),
                    }}
                  >
                    {t(title)}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </Stack>
          <Button 
            onClick={openFundAccountModal} 
            variant="contained" 
            color="primary" 
            fullWidth 
            sx={{ 
              borderRadius: "24px", 
              py: 1,
              mb: 2 
            }}>
            Fund my account
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Content;
