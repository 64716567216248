import { io } from "socket.io-client";
import { LISTENERS_METHODS, PRIVATE_EVENTS } from "_hooks/app/useUserSocket";

class SocketService {
  constructor(socketUrl, accessToken) {
    this._apiUrl = socketUrl;
    this.accessToken = accessToken;

    this.socket = io(`${socketUrl}`, {
      query: {},
      auth: { authorization: "Bearer " + this.accessToken },
    });
  }

  setListener(topic, handler) {
    this.socket?.on?.(topic, handler);
  }

  emitData(topic, data) {
    this.socket?.emit?.(topic, data);
  }

  refreshAccessToken(accessToken) {
    this.socket?.disconnect?.();

    this.accessToken = accessToken;
    this.socket = io(`${this._apiUrl}`, {
      query: {},
      auth: { authorization: "Bearer " + accessToken },
    });

    PRIVATE_EVENTS?.forEach((event) => {
      this.socket?.removeListener(event);
    });
    LISTENERS_METHODS?.forEach((func) => func(this));
  }

  removeListener(topic, handler = () => {}) {
    this.socket?.removeAllListeners?.(topic);
    this.socket?.off?.(topic, handler);
  }

  disconnectSocket() {
    this.socket?.disconnect?.();
  }
}

export default SocketService;
