import React ,{ useEffect, useState } from "react";
import _ from "lodash";
import axios from "library/http";
import BodyRow from "./BodyRow";
import WithdrawHistory from "./WithdrawHistory";
import { Box } from "@mui/system";
import { useParams } from "react-router";
import { findDefaultCoin } from "../config";

const Withdraw = ({ isModal = false, noPt = false }) => {
  const params = useParams();
  const [active, setActive] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [walletWithdraw, setWalletWithdraw] = useState([]);
  const [walletWithdrawConfig, setWalletWithdrawConfig] = useState([]);
  const [wallet, setWallet] = useState();
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
  const [selectedAsset, setSelectedAsset] = useState(() => {
    if (params.coin) {
      const [name, coin] = params.coin.split("-");
      const isValid = _.find(walletWithdraw, { name, coin });

      if (isValid) {
        return selectedAsset;
      }
    }

    return walletWithdraw?.[0] || null;
  });

  function resetData() {
    setActive(null);
    setWallet(null);
    setConfirm(null);
  }

  // Handle changing dropdown
  const onSelect = (value) => {
    resetData();
    setSelectedAsset({ naem: value.name, coin: value.coin });
  };

  // Set default protocol - when config changed
  useEffect(() => {
    if (walletWithdrawConfig.length > 0) setActive(walletWithdrawConfig[0]);
  }, [walletWithdrawConfig]);

  // Getting all listed coin - just for first time
  useEffect(() => {
    axios
      .get("wallet/list?type=withdraw")
      .then((res) => {
        const withdrawList = res?.data?.data.map((item) => ({
          coin: item.coin,
          name: item.name,
          value: item.coin,
          label: `${item.coin} ( ${item.name} )`,
        }));

        setWalletWithdraw(withdrawList);
        // setWalletWithdraw(withdrawList.filter((item) => item?.coin !== "VLX"));

        if (!params?.coin) {
          findDefaultCoin(withdrawList, (val) => setSelectedAsset(val));
        }
      })
      .catch((error) => {});
    // eslint-disable-next-line
  }, []);

  // Set wallet based on active protocol - when protocol changed
  useEffect(() => {
    if (active?.wallet) {
      setWallet(active.wallet);
    }
  }, [active]);

  // Fetching config of selectedAsset(coin or fiat) - when selectedAsset changed
  useEffect(() => {
    setActive(null);
    setLoading(true);

    if (!selectedAsset?.coin) return;

    axios
      .get(`wallet/config?type=withdraw&coin=${selectedAsset.coin}`)
      .then((response) => {
        const data = response?.data?.data;

        setWalletWithdrawConfig(data);

        if (data?.length > 0) {
          const wallet = data.find((w) => w?.id === active?.id)?.wallet;

          setWallet(wallet);
        }
        setLoading(false);
        setDisable(false);
      })
      .catch((error) => {
        setWalletWithdrawConfig([]);
        setActive("no protocol");
        setLoading(false);

        setDisable(true);
      });
    // eslint-disable-next-line
  }, [selectedAsset]);

  // Handle network-protocol
  const changeNetwork = (type) => {
    setActive(type);
    setConfirm(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          pt: isModal || noPt ? 0 : 6,
        }}
        className="container"
      >
        {/* <HeaderRow 
          wallet={wallet} 
          walletWithdraw={walletWithdraw} 
          asset={selectedAsset} 
          loading={loading} 
          onSelect={onSelect} 
          isModal={isModal} 
        /> */}
        <BodyRow
          wallet={wallet}
          active={active}
          isModal={isModal} 
          asset={selectedAsset}
          walletWithdraw={walletWithdraw} 
          onSelect={onSelect} 
          loading={loading}
          walletWithdrawConfig={walletWithdrawConfig}
          changeNetwork={changeNetwork}
          disable={disable}
          confirm={confirm}
          setLoading={setLoading}
          setWallet={setWallet}
        />
        <WithdrawHistory />
      </Box>
    </>
  );
};

export default Withdraw;
