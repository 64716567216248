import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import React from "react";
import { useSelector } from "react-redux";
import { termsAndConditionContent } from "./termsAndCondition";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const TermsAndCondition = () => {
  const lang = useSelector((state) => state.setting.lang);

  const headerContent = termsAndConditionContent.header;
  const bodyContent = termsAndConditionContent.body[lang] || termsAndConditionContent.body.en;
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Tradergpt</title>
        <meta name="description" content="Terms and Conditions | Tradergpt" />
      </Helmet>
      <Box sx={{ display: "flex", flexDirection: "column", my: 5 }} className="container">
        <Box sx={{ mb: 3 }}>
          <Typography variant="h3" sx={{ mb: 3 }}>
            {t(headerContent.title)}
          </Typography>
          <Typography variant="body1">{t(headerContent.desc)}</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {bodyContent?.map?.(({ title, items }) => (
            <Box
              sx={{
                bgcolor: (theme) => `card.${theme.palette.mode}`,
                p: "20px 10px",
                borderRadius: "5px",
                li: {
                  mb: 1,
                  listStyleType: "square",
                },
              }}
            >
              <Typography variant="h4" sx={{ mb: 2 }}>
                {t(title)}
              </Typography>
              <Box sx={{ pl: 1 }}>
                {items?.map?.((item) =>
                  typeof item === "string" ? (
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {t(item)}
                    </Typography>
                  ) : (
                    <>
                      {item.title && (
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          {t(item.title)}
                        </Typography>
                      )}
                      {!!item?.list ? (
                        <ul>
                          {item?.list?.map?.((item) => (
                            <li>
                              <Typography variant="body2">{t(item)}</Typography>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        item.items?.map?.((item) =>
                          typeof item === "string" ? (
                            <Typography variant="body2" sx={{ mb: 2 }}>
                              {t(item)}
                            </Typography>
                          ) : (
                            <>
                              {item.title && <Typography variant="body1">{t(item.title)}</Typography>}
                              {!!item?.list && (
                                <ul>
                                  {item?.list?.map?.((item) => (
                                    <li>
                                      <Typography variant="body2">{t(item)}</Typography>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </>
                          )
                        )
                      )}
                    </>
                  )
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default TermsAndCondition;
