import { Typography, TextField, Box, Stack, ButtonBase } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import { bscWalletValidation } from "library/helper";
import { t } from "locales";
import { Close } from "@mui/icons-material";
import { usePostBscAddress } from "_hooks/Shared/market";
import { updateUserData } from "store/reducers/auth/asyncActions";
import { useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { inputAutoFillStyles } from "assets/styles/styles";

const GetBscModal = ({ onWalletSeted, onClose }) => {
  const dispatch = useDispatch();
  const { mutateAsync: saveBscAddress, isLoading } = usePostBscAddress();
  const [value, setValue] = useState("");
  const [errorValue, setErrorValue] = useState("");

  const saveBsc = async () => {
    const isValid = bscWalletValidation(value);
    if (isValid) {
      const result = await saveBscAddress({
        address: value,
      });
      if (result?.status >= 200 && result?.status <= 300) {
        dispatch(updateUserData());
      }
      onWalletSeted(value);
    }
  };

  const onTextChange = (event) => {
    setValue(event?.target?.value);
    const isValid = bscWalletValidation(event?.target?.value);
    if (isValid) {
      setErrorValue("");
    } else {
      setErrorValue("Wallet address is not valid");
    }
  };

  return (
    <Box
      p={3}
      sx={{
        borderRadius: 6,
        minWidth: { xs: "90vw", sm: "60vw", md: "50vw", lg: "500px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Add Address</Typography>
        <ButtonBase
          onClick={onClose}
          sx={{
            width: "38px",
            height: "38px",
            borderRadius: "6px",
            border: "1px solid",
            borderColor: (theme) => (theme.palette.mode === "dark" ? "#2a2e34" : "#eee"),
          }}
        >
          <Close fontSize="small" />
        </ButtonBase>
      </Box>

      <Typography variant="body1" mb={2}>
        Please be careful in entering the address. Your Assets will be transferred to this address. The address must be Tether on Tron Network. For more info read our{" "}
        <Typography
          as={Link}
          target="_blank"
          to="/terms-and-condition"
          variant="body1"
          sx={{
            cursor: "pointer",
            textDecoration: "none",
            color: "primary.main",
          }}
        >
          {t("termsOfService")}
        </Typography>
      </Typography>
      <TextField
        error={errorValue !== ""}
        helperText={errorValue}
        margin="normal"
        fullWidth
        id="email"
        placeholder="USDT Address"
        name="address"
        autoFocus
        onChange={onTextChange}
        value={value}
        sx={{ ...inputAutoFillStyles }}
      />
      <Stack direction="row" justifyContent="center" mt={4}>
        <LoadingButton variant="contained" loading={isLoading} onClick={saveBsc} disabled={errorValue !== ""} fullWidth>
          Save
        </LoadingButton>
      </Stack>
    </Box>
  );
};
export default GetBscModal;
