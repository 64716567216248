import { yupResolver } from "@hookform/resolvers/yup";
import Close from "@mui/icons-material/Close";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import {
  ButtonBase,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import { t } from "locales";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomButton from "components/CustomButton";
import ErrorAlert from "components/ErrorAlert";
import _ from "lodash";
import axios from "library/http";
import { getHumanError } from "library/translateServerErrors";
import { openToast } from "components/Toast";
import { getImageUrl } from "library/helper";
import { useGetSwapAssets, useGetAssetNetworks, useSwapPriceTokens } from "_hooks/User/queries";
import { updateWallets } from "store/reducers/auth/asyncActions";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import { useRef } from "react";
import { detect } from 'detect-browser';
import SwapForm from "./SwapForm";
import { getSwapValidation } from "library/validations/marketValidation";

const changeStep = 0.01;
const splitCoin = {
  from: 0,
  to: 1
}
const splittingCoin = (data) => {
  const [from, to] = data?.split("->");
  return { from, to, data }
}

const Swap = ({ onClose }) => {
  const browser = detect();
  // ------------------------------------------------------------------------------------------ 
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [wallets, setWallets] = useState();
  const [fromCoin, setFromCoin] = useState();
  const [toCoin, setToCoin] = useState();
  const [coinObject, setCoinObject] = useState();
  const [uniqOptions, setUniqOptions] = useState();
  const [currentInputInfo, setCurrentInputInfo] = useState();
  const [validationListData, setValidationListData] = useState();
  const [allFromObject, setAllFromObject] = useState({});
  const [toCoinList, setToCoinList] = useState([]);
  const [walletDataObject, setWalletDataObject] = useState({});
  const activeSwitch = useMemo(() => {
    if (!fromCoin) return false;
    const { to } = splittingCoin(fromCoin?.key);
    return !!allFromObject[to]
  }, [fromCoin])
  // --------------------------------------------- 
  const { data, isLoading: swapDataLoading } = useGetSwapAssets({
    page: 1,
    limit: 50,
  });
  // --------------------------------------------- 
  const { data: walletData, isLoading: walletDataLoading } = useGetAssetNetworks();
  // --------------------------------------------- 
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    clearErrors,
    watch,
    setValue,
    setError: setErrorForm
  } = useForm({
    defaultValues: { from: 0, to: 0 },
    resolver: yupResolver(getSwapValidation(wallets?.from?.options?.min, wallets?.to?.options?.max , wallets?.from?.amount)),
    mode: "onChange"
  });
  // --------------------------------------------- 
  const { mutateAsync: postSwapPriceData, isLoading: swapPriceLoading } = useSwapPriceTokens();
  // ------------------------------------------------------------------------------------------ variables
  let fromAmount = watch("from");
  fromAmount = isNaN(fromAmount) ? 0 : parseFloat(fromAmount)
  let toAmount = watch("to");
  toAmount = isNaN(toAmount) ? 0 : parseFloat(toAmount)
  const toImage = getImageUrl(wallets?.to?.icon);
  const fromImage = getImageUrl(wallets?.from?.icon);
  const isLoading = swapDataLoading || walletDataLoading;
  const auth = useSelector((state) => state.auth);
  const submitDisable = !_.isEmpty(errors, true) || wallets?.from?.amount <= 0

  // ------------------------------------------------------------------------------------------ 
  /***
   * @param origin "in" or "out"
   * @param amount number
   */
  const swapTokenPrice = async ({ amount, origin, from, to }) => {
    //  console.log("-------------------------------------------------------- swapTokenPrice LOG START");
    if (!amount || isNaN(amount)) return 0;
    try {
      const result = await postSwapPriceData({
        fromToken: from,
        toToken: to,
        balanceIn: parseFloat(amount) || 1,
        slippage: 5,
        origin: origin,
      });
      return result?.data?.data?.price;
    } catch (error) {
      openToast("error", t(getHumanError(error)));
    }
  };
  // ------------------------------------------------------------------------------------------ 
  let checkTimeoutTime = 1000;
  let checkTimeoutAmount = useRef(null);
  const checkToAmount = async (e) => {
    // //  console.log("-------------------------------------------------------- checkToAmount LOG START");
    const value = parseFloat(e.target.value, 10)?.toString();
    const amount = parseFloat(wallets?.from?.amount);
    if (!value || value <= 0) return;
    if (checkTimeoutAmount.current) clearTimeout(checkTimeoutAmount.current);
    checkTimeoutAmount.current = setTimeout(async () => {
      setValue("from", value)
      setCurrentInputInfo({ to: true })
      const findToToken = await swapTokenPrice({ amount: value, origin: "out", from: wallets?.from?.apiCode, to: wallets?.to?.apiCode })
      setValue("to", ((findToToken)));
    }, checkTimeoutTime);
  }
  // ------------------------------------------------------------------------------------------  
  const checkFromAmount = async (e) => {
    // //  console.log("-------------------------------------------------------- checkFromAmount LOG START");
    const value = parseFloat(e.target.value, 10)?.toString();
    const amount = parseFloat(wallets?.from?.amount);
    if (!value || value <= 0) return;
    if (checkTimeoutAmount.current) clearTimeout(checkTimeoutAmount.current);
    checkTimeoutAmount.current = setTimeout(async () => {
      setValue("to", value)
      setCurrentInputInfo({ from: true })
      const findFromToken = await swapTokenPrice({ amount: value, origin: "in", from: wallets?.to?.apiCode, to: wallets?.from?.apiCode })
      setValue("from", (findFromToken));
    }, checkTimeoutTime);
  }
  // ------------------------------------------------------------------------------------------  
  const onSubmit = async (data) => {
    try {
      const { from, } = data;
      await axios.post("swap", {
        "fromToken": wallets?.from?.apiCode,
        "toToken": wallets?.to?.apiCode,
        "balanceIn": from,
        "agent": `${browser.name}/${browser.version}/${browser.type}/${browser.os}`
      });
      openToast("success", "Your transaction was successful.");
      dispatch(updateWallets());
      onClose?.();
    } catch (error) {
      const message = getHumanError(error);
      setError(message);
      openToast("error", error?.message);
      // openToast("error", t(getHumanError(error)));
    }
  };
  // ---------------------------------------------
  const whichDataToShow = (from, to) => {
    // //  console.log("-------------------------------------------------------- whichDataToShow LOG START");
    const param = {};
    walletData?.list?.forEach((x) => {
      param[x?.asset?.coin] = x
    });
    let filteredDataA = validationListData?.filter((item) => {
      const valSplitData = item.key?.split("->")
      const valOne = valSplitData[0]
      const valTwo = valSplitData[1]
      if (!param[valOne] || !param[valTwo]) return false;
      return item.key.includes(`${from}->`)
    });
    let filteredDataB = filteredDataA.map((item) => ({ key: `${item.key.split("->")[1]}->${item.key.split("->")[0]}` }));
    setToCoinList(filteredDataB);
  }
  // ---------------------------------------------
  const changeUniqCoin = () => {
    // //  console.log("-------------------------------------------------------- changeUniqCoin LOG START");
    const param = {};
    const allFrom = {};
    walletData?.list?.forEach((x) => {
      let apiCode = x?.apiCode === "BSC" ? "BNB" : x?.apiCode;
      param[x?.asset?.coin] = {
        coin: x?.asset?.coin,
        icon: getImageUrl(x?.asset?.icon),
        precision: x?.asset?.precision,
        assetId: x?.assetId,
        amount: auth?.wallets?.[x?.asset?.coin]?.amount || 0,
        apiCode: apiCode
      };
    });
    const validData = data?.list?.filter((x, index, self) => {
      return index === self.findIndex((y) => (x.key === y.key))
    })
    const newUniqOptions = validData?.filter((x, index, self) => index === self.findIndex((y) => {
      const { from, } = splittingCoin(x.key);
      if (splittingCoin(x.key).from === splittingCoin(y.key).from) return allFrom[from] = from;
    }));
    setAllFromObject(allFrom)
    setUniqOptions(newUniqOptions)
    setValidationListData(validData)
    setWalletDataObject(param)
  }
  // ---------------------------------------------
  const initWallet = async (from, to) => {
    // //  console.log("-------------------------------------------------------- initWallet LOG START");
    try {
      let wallets = {};
      wallets["to"] = walletDataObject[to]
      wallets["from"] = walletDataObject[from]
      const fromOptions = data.list.find((x) => x.key === `${from}->${to}`)
      wallets.from.options = fromOptions;
      setWallets(wallets);
      clearErrors();
      return wallets
    } catch (error) {
    }
  };
  // ---------------------------------------------
  const checkCoin = (from, to) => {
    // //  console.log("-------------------------------------------------------- checkCoin LOG START");
    let findData;
    findData = validationListData?.find(
      (item) => item.key === `${from}->${to}`
    );
    if (!findData) findData = validationListData?.find((item) => splittingCoin(item.key).from === from);
    if (!findData) return;
    const [a, b] = findData?.key?.split("->");
    return {
      from: `${a}->${b}`,
      to: `${b}->${a}`,
      findData
    };
  }
  // ------------------------------------------------------------------------------------------ CORE
  const handleSwitchCoin = (value) => {
    const { to } = splittingCoin(value.key)
    setValue("from", 0);
    setValue("to", 0);
    handleChangeFromCoin(value, to)
  }
  // ---------------------------------------------
  const handleChangeFromCoin = (value, isNewTo) => {
    // //  console.log("-------------------------------------------------------- handleChangeFromCoin LOG START");
    setValue("to", 0);
    setValue("from", 0);
    const splitValue = value?.key?.split("->");
    const fromCoinSplit = fromCoin?.key?.split("->");
    let from = splitValue[splitCoin.from];
    let to = isNewTo || (fromCoinSplit || splitValue)[splitCoin.to];
    const findCoinObject = checkCoin(from, to);
    if (!findCoinObject) return;
    const splitFindCoinObject = findCoinObject.from?.split("->");
    let newFrom = splitFindCoinObject[splitCoin.from];
    let newTo = splitFindCoinObject[splitCoin.to];
    initWallet(newFrom, newTo);
    if (newTo) {
      if (from === newTo) setValue("to", fromAmount);
      else setValue("to", 0);
      if (to === newFrom) setValue("from", toAmount)
    }
    whichDataToShow(newFrom, newTo);
    setFromCoin({ key: `${newFrom}->${newTo}` });
    setToCoin({ key: `${newTo}->${newFrom}` });
    setCoinObject(findCoinObject.findData);
    clearErrors();
  }
  // ---------------------------------------------
  const handleChangeToCoin = (value) => {
    // //  console.log("-------------------------------------------------------- handleChangeToCoin LOG START");
    setValue("to", 0);
    setValue("from", 0);
    const splitValue = value?.key?.split("->");
    let from = splitValue[splitCoin.to];
    let to = splitValue[splitCoin.from];
    const findCoinObject = checkCoin(from, to);
    const splitFindCoinObject = findCoinObject.from?.split("->");
    let newFrom = splitFindCoinObject[splitCoin.from];
    let newTo = splitFindCoinObject[splitCoin.to];

    initWallet(newFrom, newTo);
    setFromCoin({ key: `${newFrom}->${newTo}` });
    setToCoin({ key: `${newTo}->${newFrom}` });
    clearErrors();
  }
  // ------------------------------------------------------------------------------------------ useEffect
  useEffect(() => {
    if (walletData && data) changeUniqCoin()
  }, [walletData, data])
  // ---------------------------------------------
  useEffect(() => {
    if (!walletData) return;
    if (!fromCoin) {
      if (validationListData?.[0]) handleChangeFromCoin(validationListData[0])
    }
  }, [validationListData, walletData]);


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        height: "100%",
        border: '1px solid #E0E3EB',
        p: 2.4,
        borderRadius: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "500" }} className="page-title">
          {t("swap")}
        </Typography>
        {onClose && (
          <ButtonBase
            onClick={onClose}
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: (theme) =>
                theme.palette.mode === "dark" ? "#eee" : "#414244",
              border: "1px solid",
              borderColor: (theme) => `border.${theme.palette.mode}`,
              borderRadius: "3px",
            }}
          >
            <Close fontSize="small" />
          </ButtonBase>
        )}
      </Box>
      {error && (
        <Box sx={{ width: "100%" }}>
          <ErrorAlert text={error} />
        </Box>
      )}
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pb: 3,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          onSubmit={handleSubmit(onSubmit)}
          as="form"
          autocomplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <SwapForm {...{
            title: t("You Pay"),
            control,
            options: uniqOptions,
            image: fromImage,
            walletDataObject,
            handleChangeCoin: handleChangeFromCoin,
            currentCoin: fromCoin,
            swapPriceLoading,
            currentInputInfo: currentInputInfo?.from,
            error: errors?.from,
            walletData,
            changeStep,
            wallets,
            checkAmount: checkToAmount,
            setValue,
            mode: "from"
          }} />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SwapVertOutlinedIcon
              onClick={() => activeSwitch && handleSwitchCoin(toCoin)}
              fontSize="large"
              sx={{ cursor: activeSwitch ? "pointer" : "no-drop", opacity: activeSwitch ? 0.9 : 0.5 }}
            />
          </Box>

          <SwapForm {...{
            title: t("You Receive"),
            control,
            options: toCoinList,
            image: toImage,
            walletDataObject,
            handleChangeCoin: handleChangeToCoin,
            currentCoin: toCoin,
            swapPriceLoading,
            currentInputInfo: currentInputInfo?.to,
            error: errors?.to,
            walletData,
            changeStep,
            wallets,
            checkAmount: checkFromAmount,
            setValue,
            mode: "to"
          }} />



          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >

          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
          </Box>

          <CustomButton
            type="submit"
            fullWidth
            variant="outlined"
            loading={isSubmitting}
            disabled={submitDisable}
            loaderHeight="40"
            extraSx={{ p: "15px 12px", width: "100%" }}
          >
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              {!coinObject ||
                !(wallets?.from?.coin && wallets?.to?.coin)
                ? "Swap on this pair is disable"
                : t("swap")}
            </Typography>
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};


export default Swap;