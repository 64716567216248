const layout = {
  //navbar
  portfolioTracker: "Portfolio Tracker",
  cryptocurrencies: "Cryptocurrencies",
  pricing: "Pricing",
  login: "Login",
  getStarted: "Register",
  theme: "Theme",
  language: "Language",
  connectWallet: "Connect wallet",

  //sidebar
  dashboard: "Dashboard",
  portfolio: "Portfolio",
  tradeSignals: "Trade Signals",
  cryptoWars: "Crypto Wars",
  tradersTools: "Traders Tools",
  affiliatePortal: "Affiliate Portal",
  myAccount: "My Account",
  logout: "Logout",

  //wallets sidebar
  selectPortfolio: "Select Portfolio to Connect",
  connectYourWallet:
    "Connect your wallets and exchanges to manage all your assets together now.",

  //footer
  stayInTouch: "Stay in Touch",
  platform: "Platform",
  learn: "Learn",
  resources: "Resources",
  otherProducts: "Other products",
  company: "Company",
  contactUs: "Contact us",
};

export default layout;
