import React, { useEffect } from "react";
import AOS from 'aos';
import Toast from "components/Toast";
import useTheme from "_hooks/useTheme";
import useModal from "_hooks/app/useModal";
import AppModal from "components/Modal";
import AppRoutes from "./routing/Routes";
import WalletSidebar from "./components/Navbar/WalletSidebar/WalletSidebar";
import useUserSocket from "_hooks/app/useUserSocket";
import FullPageRoutes, { FULL_PAGE_ROUTES } from "./routing/FullPageRoutes";
import useGetUserData from "_hooks/app/useGetUserData";
import useInitialConfigs from "_hooks/app/useInitialConfigs";
import { Box } from "@mui/system";
import { useLocation } from "react-router";
import { ReactQueryDevtools } from "react-query/devtools";
import { GOOGLE_CAPTCHA_SECRET } from "config/Config";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { CssBaseline, ThemeProvider } from "@mui/material";
import 'aos/dist/aos.css';



function App() {
  useUserSocket();
  useGetUserData();
  useInitialConfigs();
  const appTheme = useTheme();
  const modalRef = useModal();
  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init();
  },[])

  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA_SECRET}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={appTheme}>
        <AppModal ref={modalRef} />
        <Toast />
        <CssBaseline />

        <WalletSidebar />

        <Box
          component="main"
          sx={{ flexGrow: 1, paddingTop: { xs: "56px", sm: "64px" } }}
        >
          {pathname !== "/" &&
          FULL_PAGE_ROUTES.findIndex(
            (item) => item.includes(pathname) || pathname.includes(item)
          ) > -1 ? (
            <>
              <FullPageRoutes />
            </>
          ) : (
            <AppRoutes />
          )}{" "}
        </Box>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
