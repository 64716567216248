import React, { useMemo } from "react";
import { TextField, Autocomplete, Stack, Box } from "@mui/material";
// import noImage from "assets/img/unknowncoin.png";
import { useSelector } from "react-redux";
import { getImageUrl } from "library/helper";
import { useState } from "react";
import { useEffect } from "react";

export default function AssetSelect({
  options,
  onChange,
  value,
  size,
  id,
  inputValue,
  walletDataObject,
  ...props
}) {
  // const wallets = useSelector((state) => state.auth.wallets);

  const [currentValue, setCurrentValue] = useState()





  const handleInputChange = (e) => {
    const value = e.target.value;
    setCurrentValue(value)
  }

  useEffect(() => {
    setCurrentValue(value?.key?.split?.("->")?.[0])
  }, [value])

  return (
    <Autocomplete
      disableClearable
      value={value}
      // inputValue={inputValue}
      // onInputChange={onInputChange}
      disabled={props?.disabled}
      onInputChange={props?.disabled ? null : handleInputChange}
      autoHighlight
      getOptionLabel={(option) => option?.key?.split("->")[0]}
      onChange={props?.disabled ? null : onChange}
      componentsProps={{
        paper: {
          sx: {
            width: 150
          }
        }
      }}
      renderOption={(prop, option) => {
        const { className, ...p } = prop;

        const imageSrc = walletDataObject[option?.key?.split("->")[0]]?.icon || "/assets/images/no-image-circle.png";
        const active = option.key === value?.key;
        // console.log({ option, value }, active);
        return (
          <Stack
            component="li"
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
            className={[className.split(" ")[0], active && "active"].join(" ")}
            value={option?.key}
            aria-selected={false}
            {...p}
            sx={(theme) => ({
              width: "100%",
              backgroundColor: "transparent !important",
              "&:hover": {
                backgroundColor: theme.palette.mode === "dark" ? "#616161 !important" : "#E0E0E0 !important",
              },
              "&.active": {
                backgroundColor: theme.palette.primary.main + "!important"
                // theme.palette.mode === "dark" ? theme.palette.background.dark + "!important" : theme.palette.background.light + "!important"
                // color:
              }
            })}
          >
            <Box

              sx={{
                width: 25,
                height: 25,
                overflow: "hidden",
                borderRadius: 50,
                ml: 2,
                my: 1,
                "& img": {
                  width: "25px",
                  height: "25px",
                  // objectFit: "cover",
                },
              }}
            >
              <img
                loading="lazy"
                key={imageSrc}
                src={imageSrc}
                srcSet={imageSrc}
                alt=""
              />
            </Box>

            <Box >
              {option.key?.split("->")[0]}
            </Box>
          </Stack>
        );
      }}
      id={id || "from-to-select"}
      options={options}
      renderInput={(params) => {
        // console.log({ params });
        return (
          <TextField
            {...params}
            {...props}
            inputProps={{
              ...params.inputProps,
              value: currentValue,
            }}
            onFocusOut={() => setCurrentValue(value?.key?.split?.("->")?.[0])}
          />
        )
      }}
    />
  );
}
