import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "library/config";
import { getImageUrl } from "library/helper";
import customAxios from "library/http";
import translateServerErrors from "library/translateServerErrors";

export const register = createAsyncThunk("auth/register", async (data, { rejectWithValue }) => {
  try {
    const { email = undefined, mobile = undefined, name, password, referredCode, gRecaptchaResponse, countryId, link } = data;

    const response = await axios.post(`${BASE_URL}user/signup`, {
      email,
      mobile,
      name,
      password,
      referredCode,
      gRecaptchaResponse,
      countryId,
      link,
    });

    return { ...response.data?.data, verifyType: "REGISTER" };
  } catch (error) {
    console.log({ error }, "signUp error");
    if (error?.response) {
      const errorMessage = translateServerErrors(error?.response);

      return rejectWithValue(errorMessage);
    } else {
      return rejectWithValue("somethingWentWrong");
    }
  }
});

export const login = createAsyncThunk("user/login", async (data, { rejectWithValue }) => {
  try {
    const { email = undefined, name = undefined, password, gRecaptchaResponse } = data;

    const response = await axios.post(`${BASE_URL}user/login`, {
      email,
      name,
      password,
      gRecaptchaResponse,
    });

    // return { ...response.data?.data, verifyType: "LOGIN" };
    return response.data?.data;
  } catch (error) {
    if (error?.response) {
      const errorMessage = translateServerErrors(error?.response);

      return rejectWithValue(errorMessage);
    } else {
      return rejectWithValue("somethingWentWrong");
    }
  }
});

export const logout = createAsyncThunk("user/logout", async (data, { rejectWithValue, getState }) => {
  try {
    const { auth } = getState();

    if (auth.logoutError) return;

    const response = await customAxios.get("user/logout");

    return response.data?.data;
  } catch (error) {
    if (error?.response) {
      const errorMessage = translateServerErrors(error?.response);

      return rejectWithValue(errorMessage);
    } else {
      return rejectWithValue("somethingWentWrong");
    }
  }
});

export const verify = createAsyncThunk("user/verify", async (data, { rejectWithValue }) => {
  try {
    const { token, code, gRecaptchaResponse } = data;

    const response = await axios.post(`${BASE_URL}user/verify`, {
      token,
      code,
      gRecaptchaResponse,
    });

    return response.data?.data;
  } catch (error) {
    if (error?.response) {
      const errorMessage = error?.response; //translateServerErrors()

      return rejectWithValue(errorMessage);
    } else {
      return rejectWithValue("somethingWentWrong");
    }
  }
});

export const updateUserData = createAsyncThunk("user/update-user", async (data, { rejectWithValue }) => {
  try {
    // const response = await customAxios.get("user");
    const response = await customAxios.get("user");
    console.log({ response }, "update user data response");
    return response.data?.data;
  } catch (error) {
    if (error?.response) {
      const errorMessage = translateServerErrors(error?.response);

      return rejectWithValue(errorMessage);
    } else {
      return rejectWithValue("somethingWentWrong");
    }
  }
});

export const refreshUserToken = createAsyncThunk("user/refresh-user-token", async (refreshToken, { rejectWithValue }) => {
  try {
    const response = await customAxios.post("user/refresh-token", {
      refreshToken: "Bearer " + refreshToken,
    });

    return response.data?.data;
  } catch (error) {
    if (error?.response) {
      const errorMessage = translateServerErrors(error?.response);

      return rejectWithValue(errorMessage);
    } else {
      return rejectWithValue("somethingWentWrong");
    }
  }
});

export const forgotPassword = createAsyncThunk("user/forgot-password", async (data, { rejectWithValue }) => {
  try {
    const { email = undefined, name = undefined, gRecaptchaResponse } = data;

    const response = await axios.post(`${BASE_URL}user/password`, {
      email,
      name,
      gRecaptchaResponse,
    });
    return { ...response.data?.data, verifyType: "FORGOT_PASSWORD" };
  } catch (error) {
    if (error?.response) {
      const errorMessage = error?.response?.data.message; // translateServerErrors(error?.response);

      return rejectWithValue(errorMessage);
    } else {
      return rejectWithValue("somethingWentWrong");
    }
  }
});

export const resetPassword = createAsyncThunk("user/reset-password", async (data, { rejectWithValue }) => {
  try {
    const { token, password, gRecaptchaResponse } = data;

    const response = await axios.patch(`${BASE_URL}user/password`, {
      token,
      password,
      gRecaptchaResponse,
    });

    return response.data?.data;
  } catch (error) {
    if (error?.response) {
      const errorMessage = translateServerErrors(error?.response);

      return rejectWithValue(errorMessage);
    } else {
      return rejectWithValue("somethingWentWrong");
    }
  }
});

export const changePassword = createAsyncThunk("user/change-password", async (data, { rejectWithValue }) => {
  try {
    const { newPassword, oldPassword } = data;

    const response = await customAxios.put("user/password", {
      oldPassword,
      newPassword,
    });

    return response.data?.data;
  } catch (error) {
    if (error?.response) {
      const errorMessage = translateServerErrors(error?.response);

      return rejectWithValue(errorMessage);
    } else {
      return rejectWithValue("somethingWentWrong");
    }
  }
});

export const updateWallets = createAsyncThunk("user/update-wallets", async (data, { rejectWithValue }) => {
  try {
    const walletResponse = await customAxios.get("wallet");

    const formattedWallets = {};

    for (let i = 0; i < walletResponse?.data?.data?.length; i++) {
      const coin = walletResponse?.data?.data[i];
      formattedWallets[coin?.asset?.coin] = {
        coin: coin?.asset?.coin,
        name: coin?.asset?.name,
        icon: coin?.asset?.icon?.length || typeof coin?.asset?.icon === "string" ? getImageUrl(coin?.asset?.icon) : coin?.asset?.coin === "TREX" ? "/assets/images/trex.png" : "/assets/images/no-image-circle.png",
        precision: coin?.asset?.precision,
        assetId: coin?.assetId,
        amount: coin?.amount,
      };
    }

    // WALLETS_SCHEMA.forEach((schemaData) => {
    //   const apiData = walletResponse?.data?.data.find(
    //     (w) =>
    //       // coin & coin
    //       schemaData.coin?.toLowerCase?.() ===
    //         w?.asset?.coin?.toLowerCase?.() ||
    //       // name & name
    //       schemaData.name?.toLowerCase?.() ===
    //         w?.asset?.name?.toLowerCase?.() ||
    //       // name & coin
    //       schemaData.name?.toLowerCase?.() ===
    //         w?.asset?.coin?.toLowerCase?.() ||
    //       // coin & name
    //       schemaData.coin?.toLowerCase?.() === w?.asset?.name?.toLowerCase?.()
    //   );

    //   if (!!apiData) {
    //     formattedWallets[apiData?.asset?.coin] = {
    //       coin: apiData?.asset?.coin,
    //       name: apiData?.asset?.name,
    //       icon:
    //         apiData?.asset?.icon?.length ||
    //         typeof apiData?.asset?.icon === "string"
    //           ? getImageUrl(apiData?.asset?.icon)
    //           : schemaData?.icon,
    //       precision: apiData?.asset?.precision,
    //       assetId: apiData?.assetId,
    //       amount: apiData?.amount,
    //     };
    //   } else {
    //     formattedWallets[schemaData?.coin] = schemaData;
    //   }
    // });
    console.log(formattedWallets)

    return formattedWallets;
  } catch (error) {
    if (error?.response) {
      const errorMessage = translateServerErrors(error?.response);

      return rejectWithValue(errorMessage);
    } else {
      return rejectWithValue("somethingWentWrong");
    }
  }
});

export const updateWalletsFromSocket = createAsyncThunk("user/update-wallets-socket", async (data, { rejectWithValue, getState }) => {
  try {
    const newWallets = Object.values(getState()?.auth?.wallets);

    if (data?.length) {
      // socket data is array - like swap
      data?.forEach((item) => {
        const index = newWallets?.findIndex?.((w) => w.assetId === item.assetId);

        if (index !== -1) newWallets[index] = { ...newWallets[index], ...item };
      });
    } else {
      // socket data is object - on wallet - like buy ticket
      const index = newWallets?.findIndex?.((w) => w.assetId === data.assetId);

      if (index !== -1) newWallets[index] = { ...newWallets[index], ...data };
    }

    const finalWallets = {};
    newWallets?.forEach?.((item) => {
      finalWallets[item.coin] = item;
    });

    return finalWallets;
  } catch (error) {
    console.log(error);
    if (error?.response) {
      const errorMessage = translateServerErrors(error?.response);

      return rejectWithValue(errorMessage);
    } else {
      return rejectWithValue("somethingWentWrong");
    }
  }
});

export const getWizardData = createAsyncThunk("auth/get-wizard-data", async (data, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(`${BASE_URL}user/wizard-list`);

    const formattedWizardObj = {};
    response.data?.data?.forEach((w) => (formattedWizardObj[w.type] = w?.step));

    return formattedWizardObj;
  } catch (error) {
    if (error?.response) {
      const errorMessage = translateServerErrors(error?.response);

      return rejectWithValue(errorMessage);
    } else {
      return rejectWithValue("somethingWentWrong");
    }
  }
});
