import { Mail, NotificationsRounded } from "@mui/icons-material";
import { IconButton, Menu, Typography, Box, Stack, Button } from "@mui/material";
import NotificationModal from "components/NotificationModal";
import { formatDistanceToNowStrict } from "date-fns";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readAllNotifications } from "store/reducers/auth/authSlice";
import { Link } from "react-router-dom";
import { useReadAllNotifications } from "_hooks/User/queries";
import { t } from "locales";
import { profile } from "routing/GeneralRoutes/routes";

const NavbarNotification = () => {
  const notifications = useSelector((state) => state.auth.notifications);
  const modal = useSelector((state) => state.app.modal);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { mutate: readAllNotificationsRequest } = useReadAllNotifications();

  const clearAll = () => {
    if (!notifications?.length) return;

    dispatch(readAllNotifications());

    readAllNotificationsRequest();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{
            position: "relative",
          }}
        >
          <NotificationsRounded />
          {!!notifications?.length && (
            <Typography
              variant="body2"
              component="span"
              sx={{
                position: "absolute",
                display: "inline-block",
                top: 0,
                right: 0,
                fontSize: "12px",
                padding: "1px 7px",
                borderRadius: "7px",
                backgroundImage: (theme) => theme.palette.gradient.pink,
                color: "#fff",
              }}
            >
              {notifications?.length}
            </Typography>
          )}
        </IconButton>
      </Box>

      <Menu variant="menu" anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(false)} sx={{ ".MuiList-padding": { p: 0 } }}>
        {"showNotifications" && (
          <Box
            onClick={() => setAnchorEl(false)}
            sx={[
              {
                borderRadius: "4px",
                width: { xs: "90vw", sm: "400px" },
                filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.06))",
                bgcolor: (theme) => (theme.palette.mode === "dark" ? "card.dark" : "card.light"),
                display: "flex",
                flexDirection: "column",
                px: 1
              },
              (theme) => (theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
            ]}
          >
            <Stack
              justifyContent="flex-start"
              sx={{
                "& > div": {
                  py: 2,
                },
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  padding: "0.5rem 1rem",
                }}
              >
                <Typography variant="body1">{t("notifications")}</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "primary.main",
                    cursor: "pointer",
                  }}
                  onClick={clearAll}
                >
                  {t("clearAll")}
                </Typography>
              </Stack>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                {notifications?.length ? (
                  notifications.map((item) => {
                    return (
                      <Box
                        key={item.id}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "flex-start",
                          padding: "0.5rem 1rem",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#3d3e4e" : "#eee"),
                            borderRadius: "0.4rem",
                          },
                        }}
                        onClick={() => modal.show(<NotificationModal item={item} onClose={modal.hide} />)}
                      >
                        <Box>
                          <Mail size={12} />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0 0.7rem",
                            ".title": {
                              color: (theme) => (theme.palette.mode === "dark" ? "white" : "#212429"),
                            },
                            ".title, .desc": {
                              display: "-webkit-box",
                              "-webkitLineClamp": "1",
                              "-webkitBoxOrient": "vertical",
                              wordBreak: "break-word",
                              overflow: "hidden",
                            },
                            "& > *": {
                              width: "fit-content",
                            },
                          }}
                        >
                          <Typography variant="body2" className="title">
                            {item?.title}
                          </Typography>
                          <Typography variant="body2" className="desc">
                            {item?.description}
                          </Typography>
                          <Typography variant="caption">
                            {!!item?.createdAt &&
                              formatDistanceToNowStrict(new Date(item?.createdAt), {
                                addSuffix: true,
                              })}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <Box>
                      <NotificationsRounded size={16} />
                    </Box>
                    <Typography variant="body2">{t("noNewNotif")}</Typography>
                  </Box>
                )}
              </Box>
            </Stack>

            <Button
              onClick={() => setAnchorEl(null)}
              as={Link}
              to={profile.notification}
              sx={{
                width: "100%",
                p: 1,
                bgcolor: (theme) => theme.palette.primary.main,
                textAlign: "center",
                textDecoration: "none",
                borderTop: "1px solid",
                borderColor: "border.default",
                borderRadius: 3,
                color: '#FFF',
                mb: 1,
              }}
            >
              <Typography variant="body2">{t("viewAll")}</Typography>
            </Button>
          </Box>
        )}
      </Menu>
    </>
  );
};

export default NavbarNotification;
