import { Alert } from "@mui/material";
import { t } from "locales";

const ErrorAlert = ({
  severity = "error",
  text = "somethingWentWrong",
}) => {
  return (
    <Alert severity={severity} sx={{borderRadius: 2.4}}>
      {t(text)}
    </Alert>
  );
};

export default ErrorAlert;
