import { yupResolver } from "@hookform/resolvers/yup";
import {
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
  Skeleton,
  Tabs,
  Tab,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "components/CustomButton";
import { addComma, fixNumber, replaceJSX, truncateToDecimals } from "library/helper";
import { t } from "locales";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axios from "library/http";
import { inputAutoFillStyles } from "assets/styles/styles";
import _ from "lodash";
import { getHumanError } from "library/translateServerErrors";
import { getWithdrawValidation } from "./validation";
import { openToast } from "components/Toast";
import CustomReactSelect from "components/CustomReactSelect";
import VerifyModal from "./VerifyModal";
import { useSelector } from "react-redux";
import WAValidator from "multicoin-address-validator";





const BodyRow = ({
  wallet,
  active,
  asset,
  loading = false,
  walletWithdrawConfig,
  changeNetwork,
  getAddress,
  disable,
  confirm,
  setLoading,
  setWallet,
  walletWithdraw,
  onSelect,
  isModal,
}) => {
  const [tagRequired, setTagRequired] = useState(false);
  const [confirmToken, setConfirmToken] = useState("");
  const [validateAddress, setValidateAddress] = useState(false);
  const modal = useSelector((state) => state.app.modal);
  const withdrawData = useRef({});
  let max =
    +wallet?.amount - (active?.withdrawFee ? +active?.withdrawFee : 0);
  max = max < 0 ? 0 : max;
  const resolver = yupResolver(
    getWithdrawValidation(
      max,
      tagRequired,
      active?.withdrawMin,
      active?.network
    )
  );
  const boxStyle = {
    borderRadius: 4,
    p: loading ? 0 : 2,
    bgcolor: "#FFF",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    transition: "all 0.2s ease-out",
    '&:hover': {
        border: "1px solid transparent",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  }
  const frozen = +wallet?.frozen;
  const available = +wallet?.amount;
  const pending = +wallet?.pending;
  const balance = frozen + available + pending;

  const {
    handleSubmit,
    control,
    clearErrors,
    // getValues,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      amount: 0,
      address: "",
    },
    resolver,
  });

  const onSubmit = async (data) => {
    const requestData = {
      amount: data.amount,
      network: active.network.toLowerCase().replace(/^\w/, (c) => c.toUpperCase()),
      address: data.address,
      id: active?.id,
      tag: tagRequired ? data.tag : undefined,
    };

    withdrawData.current = requestData;

    try {
      const respone = await axios.post("asset/withdraw", requestData);
      setConfirmToken(respone?.data?.data?.token);
      modal.show(
        <VerifyModal 
          setConfirmToken={setConfirmToken}
          confirmToken={respone?.data?.data?.token}
          refreshWallet={refreshWallet}
          withdrawData={withdrawData}
          onClose={() => modal.hide()}
        />
      )
    } catch (error) {
      getHumanError(error);
      openToast('error', error?.response?.data?.message);
    }
  };

  const refreshWallet = () => {
    setLoading(true);

    reset({
      amount: 0,
      address: "",
    });

    axios
      .get(`wallet/config?type=withdraw&coin=${asset?.coin}`)
      .then((response) => {
        const data = response?.data?.data;
        if (data.length > 0) {
          const refreshedWallet = data.find(
            (w) => w?.id === active?.id
          )?.wallet;
          setWallet(refreshedWallet);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const amountChangeHandler = (e, onChange) => {
    const value = e?.target?.value;
    if (!value) {
      e.target.value = 0;
      onChange(e);
      return;
    }

    const formattedAmount = truncateToDecimals(
      value,
      asset?.precision <= 6 ? asset?.precision : 6
    );

    if (+formattedAmount === +value) {
      e.target.value = value.replace(/^0+(?=\d+\.{0,1})/, '');
      onChange(e);
    }
  };

  const addressChangeHandler = async (e, onChange) => {
    const value = e?.target?.value;
    if (!value) {
      e.target.value = "";
      onChange(e);
      return;
    }
    onChange(e)
    setValidateAddress(WAValidator.validate(value, active?.network))
  }

  useEffect(()=> {
    setValidateAddress(false);
    reset({
      address: ''  
    })
  },[active])

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        lg={12}
      >
        <Box sx={{
          borderRadius: "24px",
          bgcolor: (theme) => `card.${theme.palette.mode}`,
          border: "1px solid",
          borderColor: "#eee",
          p: "24px",
          height: "100%",
        }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                gap: 2,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: 20, fontWeight: 600 }}>Withdraw</Typography>
                {/* <ButtonGroup variant="contained" sx={{ boxShadow: "none" }}>
                  {loading ? (
                    <Skeleton height="40px" width="80px" variant="rectangular" sx={{borderRadius: 2}} />
                  ) : (
                    walletWithdrawConfig?.map((type, i) => {
                      return (
                        <Button
                          key={type.id ?? i}
                          onClick={() => changeNetwork(type)}
                          sx={{
                            borderColor: "#CCC !important",
                            bgcolor: (theme) =>
                              active?.id !== type.id &&
                              (theme.palette.mode === "dark" ? "#444" : "#aaa"),
                          }}
                        >
                          {type.protocolType}
                        </Button>
                      );
                    })
                  )}
                </ButtonGroup> */}
              </Box>
              <Box>
                <Typography variant="body2" component="label">
                  {t("coin")}
                </Typography>
                <Box>
                  {walletWithdraw.length < 0 || !asset?.coin ? (
                    <Skeleton height="36px" width="100%" variant="rectangular" sx={{borderRadius: 2}} />
                  ) : (
                    <CustomReactSelect
                      isSearchable
                      options={walletWithdraw || []}
                      defaultValue={{
                        value: asset.coin,
                        name: asset.name,
                        label: `${asset.coin} ( ${asset.name} )`,
                      }}
                      onChange={onSelect}
                      containerSx={{ fieldset: { border: "none !important" } }}
                      selectSx={{
                        bgcolor: "rgba(0,0,0,.2)",
                        borderRadius: "0",
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={active} aria-label="basic tabs example">
                    {walletWithdrawConfig?.map((type) => (
                      <Tab
                        value={type}
                        label={type?.protocolType}
                        id={type?.protocolType}
                        onClick={() => changeNetwork(type)} 
                      />
                    ))}
                  </Tabs>
                </Box>
                {/* <Alert severity="info" sx={{ borderRadius: 3 }}>
                  {replaceJSX(
                    t("withdrawInfo"),
                    "#",
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{ fontWeight: "500" }}
                    >
                      {active?.network}
                    </Typography>
                  )}
                </Alert> */}
                <Grid
                  container
                  spacing={2}
                  sx={{mt: 0}}
                >
                  <Grid item xs={12} sm={4}>
                    <Box sx={boxStyle}>
                      {loading ? (
                        <Skeleton height="64px" width="100%" variant="rectangular" sx={{borderRadius: 2}} />
                      ) : (
                        <>
                          <Typography sx={{ opacity: "0.8", fontSize: 14 }}>
                            All Balance
                          </Typography>
                          <span>
                            {!isNaN(balance) ? fixNumber(balance, 2) : 0} {asset?.coin}
                          </span>
                        </>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box sx={boxStyle}>
                      {loading ? (
                        <Skeleton height="64px" width="100%" variant="rectangular" sx={{borderRadius: 2}} />
                      ) : (
                        <>
                          <Box>
                            <Typography variant="body1" sx={{ opacity: ".8", fontSize: 14 }}>
                              Available Balance
                            </Typography>
                          </Box>
                          <span>
                            {!isNaN(available) ? fixNumber(available, 2) : 0} {asset?.coin}
                          </span>
                        </>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box sx={boxStyle}>
                      {loading ? (
                        <Skeleton height="64px" width="100%" variant="rectangular" sx={{borderRadius: 2}} />
                      ) : (
                        <>
                          <Box>
                            <Typography variant="body1" sx={{ opacity: ".8", fontSize: 14 }}>
                              Pending Balance
                            </Typography>
                          </Box>
                          <span>
                            {!isNaN(pending) ? fixNumber(pending, 2) : 0} {asset?.coin}
                          </span>
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  component="form"
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                  sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                >
                  <Box>
                    <Typography
                      variant="body2"
                      component="label"
                      htmlFor="taddressag"
                      sx={{ width: "fit-content" }}
                    >
                      {t("address")}
                    </Typography>
                    <Controller
                      control={control}
                      name="address"
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <TextField
                            id="address"
                            name="address"
                            value={value}
                            placeholder={`Enter valid ${active?.protocolType} wallet address`}
                            onChange={(e) => addressChangeHandler(e, onChange)}
                            onBlur={onBlur}
                            error={errors?.address}
                            helperText={t(errors?.address?.message)}
                            fullWidth
                            ref={ref}
                            sx={inputAutoFillStyles}
                            size="small"
                          />
                        );
                      }}
                    />
                  </Box>
                  {active?.hasTag && (
                    <Box sx={{ opacity: !tagRequired ? ".7" : "1" }}>
                      <Typography
                        variant="body2"
                        component="label"
                        htmlFor="tag"
                        sx={{ display: "flex", gap: 1, width: "fit-content" }}
                      >
                        {t("tag")}
                        <Typography
                          variant="caption"
                          sx={{
                            color: (theme) =>
                              theme.palette.mode === "dark"
                                ? "primary.light"
                                : "primary.dark",
                          }}
                        >
                          ( {t("tagInfoWithdraw")} )
                        </Typography>
                      </Typography>
                      <Controller
                        control={control}
                        name="tag"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <TextField
                              id="tag"
                              name="tag"
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              error={tagRequired && errors?.tag}
                              helperText={t(errors?.tag?.message)}
                              fullWidth
                              ref={ref}
                              sx={inputAutoFillStyles}
                              disabled={!tagRequired}
                              size="small"
                            />
                          );
                        }}
                      />
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isSubmitting}
                              color="primary"
                              name="nullTag"
                              id="nullTag"
                              onChange={() => {
                                clearErrors("tag");
                                setTagRequired((prev) => !prev);
                              }}
                              checked={confirm}
                            />
                          }
                          label="null Tag"
                        />
                      </FormControl>
                    </Box>
                  )}
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body2"
                        component="label"
                        htmlFor="amount"
                        className="flex-1"
                      >
                        {t("actualArrived")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          cursor: "pointer",
                        }}
                        onClick={() => setValue("amount", max || 0)}
                      >
                        <Typography variant="body2" sx={{ opacity: ".9" }}>
                          {t("withdrawable")}:
                        </Typography>

                        <Typography variant="body2" sx={{ fontWeight: "500" }}>
                          {loading ? (
                            <Skeleton height="15px" width="30px" variant="rectangular" sx={{borderRadius: 2}}
                            />
                          ) : !isNaN(max) ? (
                            `${addComma(
                              truncateToDecimals(max, asset?.precision || 5)
                            )} ${asset?.coin || ""}`
                          ) : (
                            0
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Controller
                      control={control}
                      name="amount"
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <TextField
                            id="amount"
                            helperText={replaceJSX(
                              t(errors?.amount?.message),
                              active?.withdrawMin ?? 0
                            )}
                            size="small"
                            fullWidth
                            ref={ref}
                            sx={inputAutoFillStyles}
                            disabled={isSubmitting}
                            value={value}
                            onChange={(e) => amountChangeHandler(e, onChange)}
                            onBlur={onBlur}
                            error={errors?.amount}
                          />
                        );
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 1,
                        fontSize: "80%",
                        p: {
                          opacity: ".9",
                        },
                      }}
                    >
                      {/* <Typography variant="body2">
                        Deduction:{" "}
                        {addComma(
                          (!isNaN(active?.withdrawFee)
                            ? +getValues("amount") + +active?.withdrawFee
                            : getValues("amount")
                          )?.toPrecision(6)
                        )}
                      </Typography> */}
                      <Typography variant="body2">
                        {t("withdrawFee")}:{" "}
                        {`${addComma(
                          active?.withdrawFee ? active?.withdrawFee : 0
                        )} ${asset?.coin || ""}`}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    {loading ? (
                      <Skeleton height="40px" width="100%" variant="rectangular" sx={{borderRadius: 2}} />
                    ) : (
                      <CustomButton
                        loading={isSubmitting}
                        withSpinner
                        disabled={
                          max === 0 ||
                          isSubmitting ||
                          !_.isEmpty(errors) ||
                          disable ||
                          !validateAddress
                        }
                        type={"primary"}
                        onClick={getAddress}
                        fullWidth
                        variant="contained"
                        loaderHeight="40"
                        extraSx={{ mt: "auto" }}
                      >
                        {t("confirm")}
                      </CustomButton>
                    )}
                  </Box>
                </Box>

                <Alert severity="warning" sx={{borderRadius: 3, mt: 3}}>
                  <AlertTitle>{t("attention")}</AlertTitle>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        p: {
                          opacity: ".8",
                        },
                        ".keyword": {
                          fontWeight: "bold",
                          color: (theme) =>
                            theme.palette.mode === "dark"
                              ? "primary.light"
                              : "primary.dark",
                        },
                      }}
                    >
                      <Typography variant="body2">
                        1. {t("attentionWithdraw1")}
                      </Typography>
                      <Typography variant="body2">
                        2. {t("attentionWithdraw2")}
                      </Typography>
                      <Typography variant="body2">
                        3.{" "}
                        {replaceJSX(
                          t("attentionWithdraw3"),
                          "[min]",
                          <span className="keyword">{active?.withdrawMin} {asset?.coin}</span>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Alert>
              </Box>
            </Box>
          
        </Box>
      </Grid>

      {/* <Grid
        item
        xs={12}
        lg={5}
      >
        <Box sx={{
          borderRadius: "24px",
          bgcolor: (theme) => `card.${theme.palette.mode}`,
          border: "1px solid",
          borderColor: "#eee",
          p: "20px",
          height: "100%",
        }}>
          <Typography variant="h6">{t("attention")}</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography
              variant="body1"
              sx={{
                px: "10px",
                py: "8px",
                color: "#222",
                bgcolor: "#ece2ff",
                borderRadius: "10px",
              }}
            >
              {t("attentionInfo")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: {
                  opacity: ".8",
                },
                ".keyword": {
                  fontWeight: "bold",
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "primary.light"
                      : "primary.dark",
                },
              }}
            >
              <Typography variant="body2">
                1. {t("attentionWithdraw1")}
              </Typography>
              <Typography variant="body2">
                2. {t("attentionWithdraw2")}
              </Typography>
              <Typography variant="body2">
                3.{" "}
                {replaceJSX(
                  t("attentionWithdraw3"),
                  "[min]",
                  <span className="keyword">{active?.withdrawMin}</span>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid> */}
    </Grid>
  );
};

export default BodyRow;
