import * as yup from "yup";
import addressValidator from "multicoin-address-validator";

export const verifValidation = yup.object({
  code: yup
    .string()
    .matches(/^\d{4}$/, "codeMin")
    .required("codeRequired"),
});

export const getWithdrawValidation = (max, tagRequired, min, network) => {
  if (tagRequired) {
    return yup.object({
      amount: yup
        .number()
        .typeError("notValidWithdrawAmount")
        .max(+max, "insuffientBalance")
        .min(min ?? 0, "belowMinWitdrawAmount")
        .required("withdrawAmountRequired"),
      address: yup
        .string()
        .required("withdrawAddressRequired")
        .test("checkAddress", "notValidWithdrawAddress", (value) => {
          try {
            return addressValidator.validate(value, network);
          } catch (error) {
            return true;
          }
        }),
      tag: yup.string().required("withdrawTagRequired"),
    });
  } else {
    return yup.object({
      amount: yup
        .number()
        .typeError("notValidWithdrawAmount")
        .max(+max, "insuffientBalance")
        .min(min ?? 0, "belowMinWitdrawAmount")
        .required("withdrawAmountRequired"),
      address: yup
        .string()
        .required("withdrawAddressRequired")
        .test("checkAddress", "notValidWithdrawAddress", (value) => {
          try {
            return addressValidator.validate(value, network);
          } catch (error) {
            return true;
          }
        }),
    });
  }
};
