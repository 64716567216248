import { Typography, Box, Table, TableCell, TableBody, TableRow, TableHead, Button, Avatar, CircularProgress, Stack, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { profile } from "routing/GeneralRoutes/routes";
import SubscribeModal from "../Xtaker/components/ActivePlans/SubscribePlans";
import { getStakingStableHistory } from "../Xtaker/api";
import NoData from "components/NoData";
import { t } from "locales";
import { XTAKER_COIN } from "library/constants";

const PlansTable = () => {
  const [StakingHistoryData, setStakingHistoryData] = useState({});
  const [StakingHistoryLoading, setStakingHistoryLoading] = useState(true);
  const [page, setPage] = useState(1);

  const handleGetStakingNftHistory = async () => {
    setStakingHistoryLoading(true);
    const { data } = await getStakingStableHistory(page);
    setStakingHistoryData(data?.data);
    setStakingHistoryLoading(false);
  };

  const modal = useSelector((state) => state.app.modal);
  const user = useSelector((state) => state.auth.user);
  const onSubscribe = () => {
    modal.show(<SubscribeModal closeModal={modal.hide} refetchHistory={handleGetStakingNftHistory} />);
  };

  useEffect(() => {
    handleGetStakingNftHistory();
  }, [page]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <Typography sx={{ fontSize: 20, fontWeight: 600 }}>My Plans</Typography>
        {StakingHistoryData?.data?.length > 0 && (
          <Link to={"/profile/plans"} style={{ color: "primary.main", fontSize: "0.8rem" }}>
            View All
          </Link>
        )}
      </Stack>

      <Box sx={{ overflowX: "auto" }}>
        {StakingHistoryLoading ? (
          <Stack alignItems="center" py={4}>
            <CircularProgress />
          </Stack>
        ) : (
          <Table sx={{ minWidth: 390 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {StakingHistoryData?.data?.length > 0 ? (
                StakingHistoryData?.data?.map((row) => (
                  <TableRow key={row.moneyStakePlan?.title} sx={{ "&:last-child td, &:last-child th": { border: "none" } }}>
                    <TableCell component="th" scope="row">
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {/* <Avatar src={row?.icon} /> */}
                        <Box>
                          <Typography sx={{ fontSize: 14 }}>{row?.moneyStakePlan?.title}</Typography>
                          {/* <Typography sx={{ fontSize: 13, opacity: 0.8 }}>{row.coin}</Typography> */}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {row?.amount} {XTAKER_COIN}
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
                        <Chip
                          label={row?.status}
                          color={row?.status === "OPEN" ? "info" : row?.status === "COMPLETED" ? "success" : "error"}
                          sx={{ fontSize: 13, cursor: "pointer", minWidth: "100px", color: "white" }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Stack justifyContent="center" my={1}>
                  <Stack alignItems="center" gap={2}>
                    <NoData title={t("noActiveXtakerPlan")} />

                    <Button
                      variant="contained"
                      onClick={onSubscribe}
                      sx={{
                        width: "fit-content",
                        textDecoration: "none",
                        color: "#FFF",
                        borderRadius: "12px",
                        px: 2,
                        boxShadow: `none`,
                        transition: "all 0.2s ease-out",
                        "&:hover": {
                          transform: "scale(1.06)",
                          color: "#fff",
                          backgroundColor: "primary.main",
                          boxShadow: `4px 4px 15px rgba(41,  98, 255, 0.3)`,
                        },
                      }}
                    >
                      {t("subscribeNow")}
                    </Button>
                  </Stack>
                </Stack>
              )}
            </TableBody>
          </Table>
        )}
      </Box>
    </Box>
  );
};

export default PlansTable;
