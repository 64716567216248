import { useEffect } from "react";

import { setRefresh } from "store/reducers/setting/settingSlice";
import { useDispatch, useSelector } from "react-redux";
import i18next, { languages } from "locales";

const useInitialConfigs = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.setting.lang);

  // Configured languages
  useEffect(() => {
    i18next.changeLanguage(lang);
    dispatch(setRefresh(Math.random()));
    const currLang = languages.find((e) => e.symbol === lang);

    document.body.dir = currLang ? currLang.dir : "ltr";
  }, [lang, dispatch]);
};

export default useInitialConfigs;
