export const styles = {
  modalWrapper: {
    minWidth: { xs: "90vw", sm: "100%", md: "50vw", lg: "420px" },
    maxWidth: { xs: "90vw", sm: "100%", md: "50vw", lg: "460px" },
    p: 2,
  },
  modalLoadingWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: { xs: "90vw", sm: "100%", md: "50vw", lg: "420px" },
    maxWidth: { xs: "90vw", sm: "100%", md: "50vw", lg: "460px" },
    py: 6,
  },
  closeIcon: {
    width: 36,
    height: 36,
    border: "1px solid rgb(220,220,220)",
    borderRadius: 2.4,
  },
  TraderCardWrapper: {
    borderRadius: "18px !important",
    bgcolor: "#fff",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    height: "100%",
    p: 2,
    transition: "all 0.2s ease-out",
    "&:hover": {
      border: "1px solid transparent",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  },
  cardWrapper: {
    borderRadius: "18px !important",
    display: "flex",
    flexDirection: "column",
    bgcolor: "#fff",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    height: "100%",
    p: 2,
    transition: "all 0.2s ease-out",
    "&:hover": {
      border: "1px solid transparent",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 3px 10px",
    },
  },
  cardPlanName: {
    fontWeight: 600,
    fontSize: 18,
    color: "primary.main",
    mb: 1,
  },
  cardPlanDetails: {
    color: "primary.contrastText",
    fontSize: 14,
  },
  cardPlanValue: {
    fontSize: 14,
    fontWeight: 500,
    color: "primary.contrastText",
  },
  cardTitle: {
    fontSize: 14,
  },
  cardDescription: {
    fontWeight: 600,
    color: "#54B435",
  },
  cansel: {
    flex: 1,
    borderRadius: 2.4,
    bgcolor: "transparent",
    border: "1px solid",
    borderColor: "redColor.light",
    color: "redColor.light",
    "&:hover": {
      color: "#FFF",
      bgcolor: "redColor.light",
    },
    "&:disabled": {
      borderColor: "rgb(200,200,200)",
      color: "#FFF",
      bgcolor: "rgb(200,200,200)",
    },
  },
  withdraw: {
    flex: 1,
    bgcolor: "greenColor.light",
    color: "#FFF",
    borderRadius: 2.4,
    border: "1px solid",
    borderColor: "greenColor.light",
    "&:hover": {
      bgcolor: "greenColor.light",
      color: "#FFF",
    },
    "&:disabled": {
      borderColor: "rgb(200,200,200)",
      color: "#FFF",
      bgcolor: "rgb(200,200,200)",
    },
  },
  closeModal: {
    flex: 1,
    borderRadius: 2.4,
    bgcolor: "primary.main",
    color: "#FFF",
    "&:hover": {
      bgcolor: "primary.light",
    },
  },
  infoIcon: {
    color: "redColor.light",
    fontSize: 72,
    mb: 2,
  },
};
