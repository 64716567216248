export const styles = {
  wrapper: {
    mt: 10,
  },
  title: {
    fontSize: {
      xs: "1.2rem",
      lg: "2.0rem",
    },
    fontWeight: 600,
    color: (theme) => theme.palette.primary.contrastText,
    "& span": {
      color: (theme) => theme.palette.primary.main,
    },
  },
  roadmapWrapper: {
    py: 4,
    position: "relative",
  },
  roadmap: {
    width: "100%",
    display: "flex",
    alignItems: { xs: "center", md: "flex-start" },
    marginTop: { xs: "1rem", sm: "0" },
  },
  roadmapDescription: {
    fontSize: 14,
    p: 1,
    color: "#5E6282",
    minHeight: { xs: "auto", sm: 140 },
  },
  colorLine: {
    background: (theme) => theme.palette.primary.main,
    width: "100%",
    height: 26,
  },
  sesionRoadmap: {
    fontSize: 32,
    fontWeight: 800,
    ml: 2,
    color: (theme) => theme.palette.primary.contrastText,
  },
  roadmapLinesWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    position: "absolute",
    left: "-3px",
    display: { xs: "none", md: "inline" },
    background: (theme) => theme.palette.primary.light,
  },
  line: {
    display: { xs: "none", sm: "block" },
    height: 200,
    width: 3,
    background: (theme) => theme.palette.primary.light,
  },
};
