import React from 'react';
import { TipsAndUpdatesOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

const Details = ({coin}) => {
    return (
        <Box
            mt={2}
            sx={{
              p: "20px",
              borderRadius: 6,
              border: (theme) => (theme.palette.mode === "dark" ? "" : "1px solid #E0E3EB"),
            }}
          >
            <Stack flexDirection="row" alignItems="flex-end" gap={1}>
              <TipsAndUpdatesOutlined color="primary" />
              <Typography variant="body1" fontWeight="600">
                Reminder:
              </Typography>
            </Stack>
            <ul>
              <li>
                <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                  Swap Fee is {coin?.fee ? (+coin?.fee).toFixed(2) : 0} %. (Click here for more information)
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Minimum {coin?.fromCoin} Amount for swap is {coin?.min || 0} {coin?.fromCoin}.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Maximum {coin?.fromCoin} Amount for swap is {coin?.max || 0} {coin?.fromCoin}.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">Estimated Time of Swap Arrival is 10min - 48h</Typography>
              </li>
            </ul>
          </Box>
    );
};

export default Details;