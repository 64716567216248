import React from "react";
import { Button, TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { t } from "locales";
import { inputAutoFillStyles } from "assets/styles/styles";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Index = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <title>Recovery Password | Tradergpt</title>
        <meta name="description" content="Recovery Password | Tradergpt" />
      </Helmet>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          gap={2}
        >
          <Typography
            variant="h2"
            fontWeight="800"
            style={{
              fontSize: "2rem",
              lineHeight: "1",
              letterSpacing: "-0.025em",
            }}
          >
            {t("passRecovery")}
          </Typography>
          <TextField
            // error={errors?.email}
            // helperText={t(errors?.email?.message)}
            margin="normal"
            fullWidth
            id="email"
            label={t("email")}
            name="email"
            autoComplete="email"
            autoFocus
            // onChange={onChange}
            // onBlur={onBlur}
            // value={value}
            // ref={ref}
            sx={[
              { ...inputAutoFillStyles },
              // (theme) => theme.direction === "rtl" && rtlTextFieldStyle,
            ]}
          />

          <Button
            variant="contained"
            sx={{
              borderRadius: 3,
              boxShadow: "rgb(123 111 255 / 30%) 0px 4px 6px 0px",
              padding: "0.7rem 2rem",
              width: "100%",
            }}
            size="large"
          >
            {t("passRecovery")}
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default Index;
