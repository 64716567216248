import axios from "axios";
import { BASE_URL, BASE_URL_ALGO } from "./config";

export default axios.create({
  baseURL: BASE_URL,
});
export const XtakersAxios = axios.create({
  baseURL: BASE_URL_ALGO,
});
// export const ProtfiAxios = axios.create({
//   baseURL: PORTFI_API,
// });
// export const PortfiAxios = axios.create({
//   baseURL: PORTFI_API,
// });
