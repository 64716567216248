import { Box, Typography, Stack, Button, Avatar } from "@mui/material";
import { t } from "locales";
import { XTAKER_COIN } from "library/constants";
import { useNavigate } from "react-router-dom";
import { mainRoutes } from "routing/GeneralRoutes/routes";
import { getImageUrl } from "library/helper";

const Card = ({data}) => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        p: 4,
        borderRadius: "24px",
        bgcolor: "#fff",
        border: "1px solid rgba(0,0,0,0.1)",
        maxWidth: {xs: '100%', md: 360}
      }}
    >
      <Stack gap={4}>
        <Stack gap={0.5}>
          <Stack direction="row" justifyContent="space-between" data-aos="fade-up" data-aos-duration="600" data-aos-delay="150">
            <Typography
              variant="body1" 
              fontWeight="500"
            >
              {t("results")}
            </Typography>
            <Typography
              variant="body1" 
              fontWeight="500"
            >
              1000 {XTAKER_COIN}
            </Typography>
          </Stack>

          <Stack
            data-aos="fade-up" data-aos-duration="600" data-aos-delay="300"
            direction="row"
            justifyContent="space-between"
            sx={{ "& > *": { opacity: "0.6" } }}
          >
            <Typography variant="body2">{t("todayProfit")}</Typography>
            <Typography variant="body2">7.5 {XTAKER_COIN}</Typography>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" gap={2} data-aos="fade-up" data-aos-duration="600" data-aos-delay="450">
          <Avatar src={getImageUrl(data?.trader?.avatar)} alt="" />
          <Stack
            direction="row"
            flexGrow="1"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Typography variant="body1" fontWeight="500">
                {data?.trader?.name}
              </Typography>
              <Typography variant="body2" sx={{ opacity: "0.6" }}>
                Average socre: {data?.average}
              </Typography>
            </Stack>

            <Typography variant="body1" fontSize="20px" fontWeight="400">
              78%
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          gap={1}
          data-aos="fade-up" data-aos-duration="650" data-aos-delay="500"
          sx={{ button: { borderRadius: "20px", py: 1, px: 3 } }}
          >
          <Button
            onClick={() => navigate(mainRoutes.TraderPortfolio.trader(data?.id))}
            sx={{
              background: (theme) => theme.palette.primary.main,
              borderRadius: "12px !important",
              color: "#FFF",
              border: "1px solid transparent",
              width: '100%',
              '&:hover': {
                borderColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.main,
                background: "transparent !important"
              },
            }}
          >
            View Profile
          </Button>
          {/* <Button
            sx={{
              borderRadius: "12px !important",
            }}
            >
              {t("claimProfit")}
          </Button> */}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Card;
