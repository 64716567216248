const config = {
  BASE_URL: mandatory("REACT_APP_TRADERGPT_BASE_URL_PROFITIO"),
  BASE_URL_ALGO: mandatory("REACT_APP_TRADERGPT_BASE_URL_ALGO"),
  BASE_URL_PROTFOLIO: mandatory("REACT_APP_TRADERGPT_SITE_URL_PROFITIO"),
  IMAGE_URL: mandatory("REACT_APP_TRADERGPT_IMAGE_URL_PROFITIO"),
  SOCKET_URL: mandatory("REACT_APP_TRADERGPT_SOCKET_URL_PROFITIO"),
  SITE_URL: mandatory("REACT_APP_TRADERGPT_SITE_URL_PROFITIO"),
  GOOGLE_CAPTCHA_SECRET: mandatory("REACT_APP_TRADERGPT_GOOGLE_CAPTCHA_SITEKEY"),
  INFURA_KEY: mandatory("REACT_APP_TRADERGPT_INFURA_KEY"),
  BINANCE_CHAIN_ID: mandatory("REACT_APP_TRADERGPT_BINANCE_CHAIN_ID"),
  ETHEREUM_CHAIN_ID: mandatory("REACT_APP_TRADERGPT_ETHEREUM_CHAIN_ID"),
  TRON_CHAIN_ID: mandatory("REACT_APP_TRADERGPT_TRON_CHAIN_ID"),
  POLYGON_CHAIN_ID: mandatory("REACT_APP_TRADERGPT_POLYGON_CHAIN_ID"),

  USDT_BEP20_CONTRACT_ADDRESS: mandatory("REACT_APP_TRADERGPT_USDT_BEP20_CONTRACT_ADDRESS"),
  USDT_MATIC_CONTRACT_ADDRESS: mandatory("REACT_APP_TRADERGPT_USDT_MATIC_CONTRACT_ADDRESS"),
  GSTARZ_BSC_CONTRACT_ADDRESS: mandatory("REACT_APP_TRADERGPT_GSTARZ_BSC_CONTRACT_ADDRESS"),
  STARZ_BSC_CONTRACT_ADDRESS: mandatory("REACT_APP_TRADERGPT_STARZ_BSC_CONTRACT_ADDRESS"),
  USDT_TRC20_CONTRACT_ADDRESS: mandatory("REACT_APP_TRADERGPT_USDT_TRC20_CONTRACT_ADDRESS"),
};

function mandatory(key) {
  const value = process.env[key];

  if (!value) {
    throw new Error(`missing mandatory env value: ${key}`);
  }
  return value;
}

const {
  BASE_URL,
  IMAGE_URL,
  SOCKET_URL,
  SITE_URL,
  CAPTCHA_SITE_KEY,
  CAPTCHA_SECRET,
  GOOGLE_CAPTCHA_SECRET,
  INFURA_KEY,
  BINANCE_CHAIN_ID,
  ETHEREUM_CHAIN_ID,
  TRON_CHAIN_ID,
  USDT_BEP20_CONTRACT_ADDRESS,
  USDT_MATIC_CONTRACT_ADDRESS,
  GSTARZ_BSC_CONTRACT_ADDRESS,
  STARZ_BSC_CONTRACT_ADDRESS,
  POLYGON_CHAIN_ID,
  USDT_TRC20_CONTRACT_ADDRESS,
  BASE_URL_PROTFOLIO,
} = config;

export {
  BASE_URL,
  IMAGE_URL,
  SOCKET_URL,
  SITE_URL,
  CAPTCHA_SITE_KEY,
  CAPTCHA_SECRET,
  GOOGLE_CAPTCHA_SECRET,
  INFURA_KEY,
  BINANCE_CHAIN_ID,
  ETHEREUM_CHAIN_ID,
  TRON_CHAIN_ID,
  USDT_BEP20_CONTRACT_ADDRESS,
  USDT_MATIC_CONTRACT_ADDRESS,
  GSTARZ_BSC_CONTRACT_ADDRESS,
  STARZ_BSC_CONTRACT_ADDRESS,
  POLYGON_CHAIN_ID,
  USDT_TRC20_CONTRACT_ADDRESS,
  BASE_URL_PROTFOLIO,
};
