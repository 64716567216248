import React from 'react';
import img from "assets/images/noData.png"
import { t } from "locales";
import { Box, Typography } from '@mui/material';

const NoData = ({
    lightBg,
    darkBg,
    title,
}) => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                backgroundColor: 
                    (theme) => theme.palette.mode === "dark" ? darkBg || "#131316" : lightBg || "#F2F2FF",
                mb: 1,
                "& img": {
                    width: "96px"
                }
            }}>
                <img src={img} alt="" />
            </Box>
            <Typography variant='h6' sx={{textAlign: "center",fontWeight: 600, fontSize: 16}}>
                {title ? title : t("noDataFound")}
            </Typography>
        </Box>
    );
};

export default NoData;