import React, { useEffect } from "react";
import Header from "./Header";
import { Box } from "@mui/material";
import Access from "./Access";
import Visualize from "./Visualize";
import Create from "./Create";
import Execute from "./Execute";
import Paragraph from "./Paragraph";

const Trading = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Header />
      <Box className="container" sx={{ mb: 10 }}>
        <Paragraph />
        <Access />
        <Visualize />
        <Create />
        <Execute />
      </Box>
    </>
  );
};

export default Trading;
