import React, { useState } from "react";
import { styles } from "./styles";
import { Box, Button, Typography, } from "@mui/material";
// import { DesktopWindowsRounded, SmartphoneRounded, WebAssetRounded } from "@mui/icons-material";
import { CallMade } from "@mui/icons-material";
import header_bg from '../../../../assets/presale/header_bg.png';

const Header = () => {
  const [ hover, setHover] = useState("");


  return (
    <Box 
    sx={styles.wrapper}
    data-aos="fade-up"
    data-aos-duration="600"
    data-aos-delay="0"
    >
      <Box sx={styles.titleWrapper}>
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                fontSize: {
                  xs: "1.6rem",
                  sm: "2rem",
                  md: "2.3rem",
                  lg: "2.7rem",
                  xl: "3rem",
                },
            }}
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="150"
            >
            {/* <img src={Two} alt="" /> */}
            <Typography sx={styles.title} >
                Discover the Future of Finance with TREX Presale!
            </Typography>
        </Box>
        <img     sx={styles.wrapper}
    data-aos="fade-up"
    data-aos-duration="600"
    data-aos-delay="150" 
    style={styles.headerImage} src={header_bg} alt="" />
        <Typography sx={styles.subTitle}
          data-aos="fade-up"
          data-aos-duration="600"
          data-aos-delay="200"
          >

            Secure your spot early and be part of a community shaping the future of blockchain and financial empowerment.
        </Typography>

        <Box 
          sx={{ 
            display: { xs: 'flex', sm: 'row' },
            flexDirection: { xs: 'column', sm: 'row' },
            p: 1 
          }}
        >
          <Button
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="350"
            component="a"  
            href="https://docs.tradergpt.ai/" 
            target="_blank"  
            rel="noopener noreferrer" 
            variant="contained"
            color="primary"
            sx={[
              {
                boxShadow: "none",
                padding: "7px 20px",
                mt: 0.5,
                mr: 3,
              },
            ]}
          >
            Whitepaper<CallMade sx={{fill: "#fff", fontSize: "medium", ml: "2px"}}/>
          </Button>
          <Button
            data-aos="fade-up"
            data-aos-duration="600"
            data-aos-delay="400"
            onMouseEnter={()=>setHover("#fff !important")}
            onMouseOut={()=>setHover("")}
            component="a"  
            href="https://docs.tradergpt.ai/economy/tokenomics" 
            target="_blank"  
            rel="noopener noreferrer" 
            variant="outlined"
            color="primary"
            sx={[
              {
                boxShadow: "none",
                padding: "7px 20px",
                mt: 0.5,
              },
            ]}
          >
            Tokenomics<CallMade sx={{ fill: hover, fontSize: "medium", ml: "2px"}}/>
          </Button>
        </Box>

      </Box>
    </Box>
  );
};

export default Header;
