import React from 'react';
import styles from "./styles";
import { Box, Typography } from '@mui/material';

const Marketplace = () => {
    return (
        <Box className="container">
            <Typography variant='h1'>Marketplace</Typography>
        </Box>
    );
};

export default Marketplace;