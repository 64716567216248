import { IN_APP_COIN } from "library/constants";

const landing = {
  mainPageTag: "CHALLENGE YOUR SKILLS",
  mainPageHeader1: "TALENTED TRADERS",
  mainPageHeader2: "WANTED",
  mainPageDesc1:
    "take part in tournaments & earn. get funding simply through trading.",
  mainPageDesc2: "Trade | Challenge | Learn | Earn",
  mainPageBtn: "START THE CHALLENGE",
  landingAboutTitle: "we're investing in top traders",
  // landingAboutContent:
  //   " is an NFT-based trading platform that allows you to challenge your trading skills and earn without money loss. Through our exclusive NFTs, you are able to participate in daily/weekly tournaments. The top-ranking traders will be funded with a $100K trading account.",
  landingAboutContent:
    "by using our exclusive NFTs, you will be able to participate in daily or weekly tournaments. the top-ranking traders will be funded with a $100k balance trading account.",
  highlightsTitle: "Key highlights",
  highlightsTitle1: "200K USDT Trading account",
  highlightsTitle2: "Scaling Plan",
  highlightsTitle3: `Governance of ${IN_APP_COIN} Token`,
};

export default landing;
