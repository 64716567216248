import React, { useState, useEffect } from 'react';
import moment from 'moment';
import LineChart from 'components/Chart';
import { COIN_VALIDATOR } from './coinName';
import { ArrowDropDownRounded, ArrowDropUpRounded, InfoRounded } from '@mui/icons-material';
import { Avatar, Box, CircularProgress, MenuItem, TextField, Typography, useMediaQuery } from '@mui/material';

import bnbIcon from "assets/coins/bnb.png"
import starzIcon from "assets/coins/gstarz.png"
import { useGetCoinPriceDetails, useGetCurrencyDetails } from '_hooks/Xtakers';


const chartCoinList = [
    {
        id: 3,
        name: "Starz",
        icon: starzIcon,
        coinName: "STARZ",
    },
    {
        id: 2,
        name: "Binance Coin",
        icon: bnbIcon,
        coinName: "BNB",
    },
];

const NewChart = () => {
    const [ currency, setCurrency ] = useState();
    const [ activeCoinName, setActiveCoinName ] = useState("");
    const [ priceArray, setPriceArray ] = useState([]);
    const [ priceTime, setPriceTime ] = useState([]);
    const smallDevice = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const { data, refetch } = useGetCurrencyDetails({coin: activeCoinName,});
    const { data: price, refetch: priceRefetch, isFetched } = useGetCoinPriceDetails({coin: activeCoinName,});
    const priceArreyLength = price?.data?.prices ? Number((price?.data?.prices?.length / 10).toFixed(0)) : 1;
    const coinPrice = price?.data?.prices;

    const handleChange = (value) => {
        setCurrency(value?.target?.value);
        setActiveCoinName(COIN_VALIDATOR(value?.target?.value?.coinName))
    };

    useEffect(() => {
        refetch();
        priceRefetch();
    }, [activeCoinName]);
    useEffect(() => {
        setCurrency(chartCoinList[0]);
        setActiveCoinName(COIN_VALIDATOR(chartCoinList[0]?.coinName))
    }, []);
    useEffect(() => {
        if(!isFetched || !coinPrice) return;
        if(isFetched && coinPrice) {
            try {
                const chartdate = [
                    moment(coinPrice[priceArreyLength * 0][0])?.format("MMM D"),
                    moment(coinPrice[priceArreyLength * 1][0])?.format("MMM D"),
                    moment(coinPrice[priceArreyLength * 2][0])?.format("MMM D"),
                    moment(coinPrice[priceArreyLength * 3][0])?.format("MMM D"),
                    moment(coinPrice[priceArreyLength * 4][0])?.format("MMM D"),
                    moment(coinPrice[priceArreyLength * 5][0])?.format("MMM D"),
                    moment(coinPrice[priceArreyLength * 6][0])?.format("MMM D"),
                    moment(coinPrice[priceArreyLength * 7][0])?.format("MMM D"),
                    moment(coinPrice[priceArreyLength * 8][0])?.format("MMM D"),
                    moment(coinPrice[coinPrice?.length - 1][0])?.format("MMM D"),
                ];
                const chartValue = [
                    coinPrice[priceArreyLength * 0][1]?.toFixed(7),
                    coinPrice[priceArreyLength * 1][1]?.toFixed(7),
                    coinPrice[priceArreyLength * 2][1]?.toFixed(7),
                    coinPrice[priceArreyLength * 3][1]?.toFixed(7),
                    coinPrice[priceArreyLength * 4][1]?.toFixed(7),
                    coinPrice[priceArreyLength * 5][1]?.toFixed(7),
                    coinPrice[priceArreyLength * 6][1]?.toFixed(7),
                    coinPrice[priceArreyLength * 7][1]?.toFixed(7),
                    coinPrice[priceArreyLength * 8][1]?.toFixed(7),
                    coinPrice[coinPrice?.length - 1][1]?.toFixed(7),
                ];
                setPriceArray(chartValue);
                setPriceTime(chartdate);
            } catch (error) {
                console.log(error, "Iam here");
            }
        };
    }, [isFetched, activeCoinName]);


    if(!isFetched) return (
        <Box sx={{
            display: "flex",
            justifyContent: 'center',
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
        }}>
            <CircularProgress />
            <Typography sx={{mt: 4}}>Loading Chart data ... </Typography>
        </Box>
    );
    if(isFetched && price && data && coinPrice) return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 4,
            gridArea: "chart",
            height: "100%",
            border: '1px solid #E0E3EB',
            p: 2.4,
            borderRadius: "24px",
        }}>
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: {xs: "column", lg: "row"}
        }}>
            <Box sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
            }}>
                <Box>
                    <Avatar src={data?.data?.image?.small} />
                </Box>
                <Box>
                    <Typography>{data?.data?.name} Price 
                        <Typography sx={{
                            display: "inline",
                            textTransform: "uppercase",
                            }}>({data?.data?.symbol})
                        </Typography>
                    </Typography>
                    <Box sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                    }}>
                        <Typography sx={{
                            fontSize: 22,
                            fontWeight: 600,
                        }}>${data?.data?.market_data?.current_price?.usd?.toFixed(6)}</Typography>
                        <Typography sx={{
                            background: data?.data?.market_data?.price_change_percentage_24h > 0 ? "green" : "red",
                            pr: 0.8,
                            py: 0.1,
                            borderRadius: 2,
                            fontSize: 13,
                            lineHeight: 1.7,
                            display: "flex",
                            alignItems: "center",
                            fontWeight: 500,
                            color: "#FFF",
                        }}>
                            {
                                data?.data?.market_data?.price_change_percentage_24h > 0 ?
                                <ArrowDropUpRounded /> : <ArrowDropDownRounded />
                            }
                            {data?.data?.market_data?.price_change_percentage_24h?.toFixed(3)}%</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{"svg": {fill: "#000",}, mt: {xs: 2, md: 0}, border: "1px solid #E0E3EB", borderRadius: "12px"}}>
                <TextField
                    id="outlined-select-currency"
                    select
                    sx={{
                        "& svg": {
                            fill: (theme) => (theme.palette.mode === "dark" ? "white !important" : "black"),
                        },
                    }}
                    value={currency}
                    onChange={handleChange}
                    size="small"
                >
                    {chartCoinList?.map((option) => (
                    <MenuItem key={option?.coin} value={option}>
                        <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                        }}
                        >
                        <img
                            src={option?.icon}
                            alt=""
                            style={{
                            width: "24px",
                            height: "24px",
                            }}
                        />
                        {option?.name}
                        </Box>
                    </MenuItem>
                    ))
                    }
                </TextField>
            </Box>
        </Box>
            <Box>
                <LineChart
                    height={smallDevice ? 240 : 320}
                    chartLabels={priceTime}
                    chartData={[
                        {
                            name: 'Price',
                            type: 'area',
                            fill: 'gradient',
                            data: priceArray,
                        },
                    ]}
                />
            </Box>
        </Box>
    );
    return (
        <Box sx={{height: "100%"}}>
            <Box sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
        }}>
                <Box sx={{"svg": {fill: "#000"}}}>
                    <TextField
                        id="outlined-select-currency"
                        select
                        sx={{
                        "& svg": {
                            fill: (theme) => (theme.palette.mode === "dark" ? "white" : "black"),
                        },
                        }}
                        value={currency}
                        onChange={handleChange}
                        size="small"
                    >
                        {chartCoinList?.map((option) => (
                        <MenuItem key={option?.coin} value={option}>
                            <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}
                            >
                            <img
                                src={option?.icon}
                                alt=""
                                style={{
                                width: "24px",
                                height: "24px",
                                }}
                            />
                            {option?.name}
                            </Box>
                        </MenuItem>
                        ))
                        }
                    </TextField>
                </Box>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: 'center',
                alignItems: "center",
                gap: 1,
                height: "100%",
                mt: -4
            }}>
                <Typography color={"#ffa726"}>Data Doesnot Exist</Typography>
                <InfoRounded color='warning' />
            </Box>
        </Box>
    );
};

export default NewChart;