import bg from '../../assets/bg.png';
export const styles = {
    wrapper: {
        backgroundImage: `url(${bg})`,
        backgroundSize: {xs: '100%', lg: '60%'},
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        py: 6,

    },
    blueBox: {
        px: 2,
        py: 0.5,
        background: (theme) => theme.palette.primary.light,
        borderRadius: 15,
        mb: 2,
    },
    subTitle: {
        color: (theme) => theme.palette.primary.main,
        fontWeight: 600,
    },
    title: {
        fontWeight: "700",
        fontSize: {
            xs: "1.6rem",
            sm: "2rem",
            md: "2.3rem",
            lg: "2.7rem",
            xl: "3rem",
        },
        textAlign: 'center',
        color: (theme) => theme.palette.primary.contrastText,
        lineHeight: 1.4,
        "& span": {
            color: (theme) => theme.palette.primary.main,
        },
    },
    description: { 
        mt: 2,
        mx: 'auto', 
        maxWidth: 480, 
        color: "#5E6282",
        textAlign: 'center', 
    },
    linkButton: {
        display: "inline-block",
        borderRadius: 2.4,
        p: {xs: "6px 12px", lg: "12px 24px"},
        mt: 3,
        fontSize: {xs: 12, lg: 14},
        bgcolor: "primary.main",
        border: "1px solid transparent",
        color: '#FFF',
        textDecoration: 'none',
        '&:hover': {
            borderColor: 'primary.main',
            color: 'primary.main',
        },
    },
};