import { fixNumber } from "library/helper";
import { useSelector } from "react-redux";
import { formatDistanceToNowStrict } from "date-fns";
import fa from "date-fns/locale/fa-IR";
import en from "date-fns/locale/en-US";
import ar from "date-fns/locale/ar-SA";
import tr from "date-fns/locale/tr";
import { t } from "locales";
import { Box, Typography, Stack, Chip } from "@mui/material";
import cardBg1 from "assets/images/xtaker/card1.svg";
import cardBg2 from "assets/images/xtaker/card2.svg";
import cardBg3 from "assets/images/xtaker/card3.svg";
import DetailModal from "./DetailModal";

const bgs = [
  `url(${cardBg1}), linear-gradient(247.01deg, #5682f1 0.47%, #483aeb 94.19%)`,
  `url(${cardBg2}), linear-gradient(247.42deg, #637fe1 1.17%, #63dae1 93.56%)`,
  `url(${cardBg3}), linear-gradient(247.42deg, #93ea5e 1.17%, #2ec52b 93.56%)`,
];

const locales = {
  fa,
  en,
  ar,
  tr,
};

const Plan = ({ id, amount, moneyStakePlan, end, index, status }) => {
  const lang = useSelector((state) => state.setting.lang);
  const modal = useSelector((state) => state.app.modal);
  // const openDetailModal = () => {
  //   modal.show(
  //     <DetailModal
  //       onClose={modal.hide}
  //       refetchPlans={refetchPlans}
  //       data={{
  //         id,
  //         currentAmount: amount,
  //         name,
  //         end,
  //         index,
  //         status,
  //         algotrexPlan,
  //       }}
  //     />
  //   );
  // };

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        background: status === "OPEN" ? bgs[2] : status === "COMPLETED" ? bgs[0] : bgs[1],
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: 5,
        height: "156px",
        padding: "1rem 2rem",
        // cursor: "pointer",
      }}
      // onClick={openDetailModal}
      className="slider-item"
    >
      <Box>
        <Typography
          variant="h5"
          sx={{
            color: "#fff",
          }}
        >
          {moneyStakePlan?.title}
        </Typography>
      </Box>

      <Box>
        <Typography
          variant="h6"
          sx={{
            color: "#fff",
          }}
        >
          ${!isNaN(+amount) ? fixNumber(+amount, 3) : 0}
        </Typography>

        {/* <Typography
          variant="body1"
          sx={{
            color: "#fff",
          }}
        >
          {status}
        </Typography> */}
      </Box>
      <Box sx={{ marginLeft: "auto" }}>
        <Chip
          label={status}
          color={status === "OPEN" ? "info" : status === "COMPLETED" ? "success" : "error"}
          sx={{ fontSize: 13, cursor: "pointer", minWidth: "100px", color: "white" }}
        />
      </Box>
    </Stack>
  );
};

export default Plan;
