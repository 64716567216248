import { useEffect, useState } from "react";
import { t } from "locales";
import DataTable from "components/DataTable";
import formatDate from "library/formatDate";
import { Pagination, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useGetWithdrawHistory } from "_hooks/User/queries";
import { networkScanGen } from "library/helper";
import NoData from "components/NoData";

const TYPES = {
  AUDITING: "bg-primary",
  PENDING: "bg-warning",
  REJECTED: "bg-danger",
  DONE: "bg-success",
  0: "bg-warning", // pending
  "-1": "bg-danger", // canceled
  1: "bg-success", // success
};
const STATUS = {
  0: "pending",
  "-1": "canceled",
  1: "successfull",
};
const LOADING_LIST = Array(10).fill({
  time: "",
  coin: "",
  amount: "",
  status: "",
  depositAddress: "",
  txid: "",
});

function WithdrawHistory() {
  const [page, setPage] = useState(1);
  const { data: requestData, isLoading: loading } = useGetWithdrawHistory(page);

  const onPageChange = (_, value) => setPage(value);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Box
        sx={{
          borderRadius: "24px",
          bgcolor: (theme) => `card.${theme.palette.mode}`,
          border: "1px solid",
          borderColor: (theme) => (theme.palette.mode === "dark" ? "transparent" : "#eee"),
          p: "20px",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "600", marginBottom: 3 }}>
          {t("withdrawRecords")}
        </Typography>
        <Box>
          <DataTable
            columns={[
              {
                title: t("time"),
                field: "createdAt",
                render: ({ createdAt }) => {
                  return loading ? (
                    <Skeleton width="50px" height="18px" variant="rectangular" sx={{ borderRadius: 2.4 }} />
                  ) : (
                    <Typography variant="body2">
                      {formatDate(createdAt, {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        lang: "en",
                      })}
                    </Typography>
                  );
                },
              },
              {
                title: t("coin"),
                field: "coin",
                render: (row) => {
                  const asset = row?.assetNetworks?.asset?.coin || row?.currency;

                  return loading ? <Skeleton width="40px" height="18px" variant="rectangular" sx={{ borderRadius: 2.4 }} /> : <Typography variant="body2">{asset ?? "-"}</Typography>;
                },
              },
              {
                title: t("amount"),
                field: "amount",
                render: ({ amount }) => (loading ? <Skeleton width="40px" height="18px" variant="rectangular" sx={{ borderRadius: 2.4 }} /> : <Typography variant="body2">{amount}</Typography>),
              },
              {
                title: t("address"),
                field: "address",
                render: ({ address, assetNetworks }) =>
                  loading ? (
                    <Skeleton width="40px" height="18px" variant="rectangular" sx={{ borderRadius: 2.4 }} />
                  ) : !address ? (
                    "-"
                  ) : (
                    <Typography
                      as="a"
                      target="_blank"
                      href={networkScanGen(assetNetworks?.network?.type, true, address)}
                      sx={{
                        color: "inherit",
                      }}
                      variant="body2"
                    >
                      {address ? address?.slice(0, 3) + "..." + address?.slice(address.length - 3, address.length) : "-"}
                    </Typography>
                  ),
              },
              {
                title: t("status"),
                field: "status",
                render: ({ status }) =>
                  loading ? (
                    <Skeleton width="50px" height="18px" variant="rectangular" sx={{ borderRadius: 2.4 }} />
                  ) : (
                    <Typography variant="body2" className={"badge rounded-pill py-2 px-2 " + TYPES[status]}>
                      {typeof status === "number" ? t(STATUS[status]) : t(status.toLowerCase())}
                    </Typography>
                  ),
              },
              {
                title: t("TXID"),
                field: "txid",
                render: ({ txid, assetNetworks }) =>
                  loading ? (
                    <Skeleton width="80px" height="18px" variant="rectangular" sx={{ borderRadius: 2.4 }} />
                  ) : !txid ? (
                    "-"
                  ) : (
                    <Typography
                      as="a"
                      target="_blank"
                      href={networkScanGen(assetNetworks?.network?.type, false, txid)}
                      variant="body2"
                      sx={{
                        color: "inherit",
                      }}
                    >
                      {txid ? txid?.slice(0, 3) + "..." + txid?.slice(txid.length - 3, txid.length) : "-"}
                    </Typography>
                  ),
              },
            ]}
            data={loading ? LOADING_LIST : requestData?.list}
            rowClass="py-2"
            headClass="table-head"
          />
          {!loading && requestData?.list?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                py: 5,
                minHeight: "300px",
              }}
            >
              <NoData />
            </Box>
          ) : (
            <Box sx={{ mx: "auto", width: "fit-content", mt: 3 }}>
              <Pagination sx={{ width: "fit-content" }} count={requestData?.total || 1} page={page} onChange={onPageChange} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
export default WithdrawHistory;
