import React from "react";
import { styles } from "./styles";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { ChangeHistory, Diamond, HourglassBottomOutlined } from "@mui/icons-material";

const CareOrders = () => {
  const smallDevice = useMediaQuery("(max-width:904px)");

  const data = [
    {
      icon: <HourglassBottomOutlined fontSize="large" />,
      title: "Stage",
      description: "Submit care orders via your FIX client or Tradergpt screen to your broker or internal desk for execution.",
    },
    {
      icon: <ChangeHistory fontSize="large" />,
      title: "Claim",
      description: "Take ownership of care orders that your customer staged and work according to parameters and instructions.",
    },
    {
      icon: <Diamond fontSize="large" />,
      title: "Execute",
      description: "Use your expertise and all of the tools available, including DMA, OTC trades, execution algos and more, to fill your customers’ care orders.",
    },
    {
      icon: <Diamond fontSize="large" />,
      title: "Internalize",
      description: "Fill internal care orders out of the firm’s position or inventory with manual fills and internally match offsetting orders with Position Transfer.",
    },
  ];
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.titleWrapper}>
        <Typography sx={styles.title} data-aos="fade-up" data-aos-duration="500">
          01 CARE ORDERS
        </Typography>
        <Typography sx={styles.subTitle} data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
          Separate the trading decision from the mechanics of execution.
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={smallDevice ? 3 : 1}>
          {data?.map(({ id, title, icon, description }) => (
            <Grid item xs={12} md={4} lg={3} key={id} data-aos="fade-up" data-aos-duration="500" data-aos-delay={id * 50 + 100}>
              <Box sx={styles.planWrapper}>
                <Box sx={styles.iconWrapper}>{icon}</Box>
                <Box>
                  <Typography sx={styles.planTitle}>{title}</Typography>
                  <Typography sx={styles.planDescription}>{description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CareOrders;
