import { ButtonBase, FormHelperText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { replaceJSX } from "library/helper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "library/http";
import { t } from "locales";
import { getHumanError } from "library/translateServerErrors";
import ErrorAlert from "components/ErrorAlert";
import CustomButton from "components/CustomButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { verifValidation } from "./validation";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { openToast } from "components/Toast";
import { OtpInputCustom } from "assets/styles/styles";

let intervalId;

const VerifyModal = ({
    setConfirmToken,
    confirmToken,
    refreshWallet,
    withdrawData,
    onClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const [error, setError] = useState();
  const user = useSelector((state) => state.auth.user);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(verifValidation),
    defaultValues: { code: "" },
  });
  const submitHandler = async (data) => {
    if (!confirmToken) return;

    const code = data?.code;

    try {
      await axios.put("asset/withdraw", {
        token: confirmToken,
        code,
      });

      setError("");
      reset({
        amount: 0,
        address: "",
        code: "",
      });
      setConfirmToken("");
      refreshWallet();
      onClose();
      openToast("success", t('withdrawDetails'));
    } catch (error) {
      const formattedError = getHumanError(error);
      setError(formattedError);
      setLoading(false);
    }
  };

  const cancelConfirm = () => {
    reset({
      amount: 0,
      address: "",
      code: "",
    });
    setConfirmToken("");
    onClose();
  };

  const resendConfirmation = async () => {
    reset({
      code: "",
    });
    setTimer(60);
    setError("");

    setLoading(true);

    try {
      const respone = await axios.post("asset/withdraw", withdrawData.current);
      setConfirmToken(respone?.data?.data?.token);

      setLoading(false);
    } catch (error) {
      const formattedError = getHumanError(error);
      setError(formattedError);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (timer === 0) {
      setTimer(null);
      clearInterval(intervalId);
    }

    if (!timer) return;

    intervalId = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);
    return (
        <Box 
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                p: "20px",
                width: '100%',
                maxWidth: { xs: "90vw", sm: "60vw", md: "50vw", lg: "560px" },
            }}
        >
            <Box
                sx={{ display: "flex", flexDirection: "column", gap: 2, height: "100%" }}
            >
                <Typography variant="h6">{t("confirmWithdraw")}</Typography>
                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    height: "100%",
                    }}
                >
                    {error && <ErrorAlert text={error} />}

                    <Box
                    component="form"
                    autoComplete="off"
                    onSubmit={handleSubmit(submitHandler)}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                        height: "100%",
                    }}
                    >
                    <Typography variant="body2">
                        {replaceJSX(
                        t("confirmWithdrawDesc"),
                        "#",
                        <Typography
                            variant="body1"
                            component="span"
                            sx={{ fontWeight: "500" }}
                        >
                            {user?.email || user?.mobile}
                        </Typography>
                        )}
                    </Typography>
                    <Box
                        sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        }}
                    >
                        <Controller
                        control={control}
                        name="code"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Box sx={{ marginBottom: 3 }}>
                            <OtpInputCustom
                                value={value}
                                isInputNum
                                onChange={onChange}
                                onBlur={onBlur}
                                numInputs={4}
                                separator={<span>-</span>}
                                hasErrored={!!errors.code}
                            />
                            </Box>
                        )}
                        />
                        {errors?.code && (
                        <FormHelperText sx={{ color: "#f44336", textAlign: "center" }}>
                            {t(errors?.code?.message)}
                        </FormHelperText>
                        )}
                    </Box>

                    <Box
                        sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        mt: "auto",
                        }}
                    >
                        <CustomButton
                          type="submit"
                          fullWidth
                          variant="contained"
                          loading={isSubmitting}
                          disabled={!_.isEmpty(errors, true)}
                          loaderHeight="40"
                          sx={{ fontSize: "1.1rem" }}
                          withSpinner
                        >
                        {t("confirm")}
                        </CustomButton>

                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        {/* <Typography
                            onClick={cancelConfirm}
                            variant="body2"
                            sx={{
                            color: "primary.main",
                            textDecoration: "none",
                            cursor: "pointer",
                            }}
                        >
                            cancel
                        </Typography> */}
                        <Box></Box>
                        <ButtonBase
                            disabled={timer !== null}
                            onClick={resendConfirmation}
                            loading={loading}
                        >
                            <Typography
                            variant="body2"
                            sx={{ color: timer !== null ? "gray" : "common.white" }}
                            >
                            {timer !== null ? timer + "s" : t("resend")}
                            </Typography>
                        </ButtonBase>
                        </Box>
                    </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default VerifyModal;