import React from 'react';
import { styles } from './styles';
import { Box, Stack, Typography, } from '@mui/material';
import { TrackChangesRounded } from '@mui/icons-material';

const Paragraph = () => {
    const paragraphData = [
        {
            id: 1,
            content: 'Artificial intelligence (AI) trading mechanisms are computer-based algorithms that use sophisticated mathematical models and statistical analysis to identify trading opportunities in financial markets. These algorithms can analyze vast amounts of data, including historical price trends, news events, and economic indicators, to identify patterns and make predictions about future market movements. AI trading systems can also learn from past trades and continuously improve their performance by refining their algorithms.',
        },
        {
            id: 2,
            content: 'One key advantage of AI trading mechanisms is their ability to operate 24/7, without the need for human intervention. This means that they can respond quickly to changing market conditions and capitalize on trading opportunities as they arise. However, it is important to note that AI trading systems are not foolproof and can still be affected by unpredictable events or market volatility. As such, they should be used in conjunction with other forms of analysis and risk management strategies.',
        },
    ];
    return (
        <Stack sx={styles.wrapper} gap={2}>
            {paragraphData?.map((item) => (
                <Stack direction="row" spacing={2} key={item?.id}>
                    <Stack alignItems="center" spacing={2} sx={styles.iconWrapper}>
                        <TrackChangesRounded />
                        <Box component="div" orientation="vertical" />
                    </Stack>
                    <Box sx={styles.textsWrapper}>
                        <Typography sx={styles.text}>
                            {item?.content}
                        </Typography>
                    </Box>
                </Stack>
            ))}
        </Stack>
    );
};

export default Paragraph;