import React from "react";
import { styles } from "./styles";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { AccountTreeRounded, AppSettingsAltRounded, EmojiObjectsRounded } from "@mui/icons-material";

const Create = () => {
  const smallDevice = useMediaQuery("(max-width:904px)");

  const plans = [
    {
      id: 1,
      Icon: EmojiObjectsRounded,
      title: "Customizable Solutions",
      description: "Write your own rules and tweak the behaviors of powerful automated solutions.",
    },
    {
      id: 2,
      Icon: AccountTreeRounded,
      title: "Design Your Own Algos",
      description: "Rapidly design, test and deploy custom algos without writing a single line of code with ADL®.",
    },
    {
      id: 3,
      Icon: AppSettingsAltRounded,
      title: "Develop Custom Apps",
      description: "Engineer bespoke applications for your unique trading style and requirements with our APIs.",
    },
  ];
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.titleWrapper}>
        <Typography sx={styles.title} data-aos="fade-up" data-aos-duration="500">
          03 CREATE
        </Typography>
        <Typography sx={styles.subTitle} data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
          Gain an edge in your trading with our enhanced suite of tools and APIs
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={smallDevice ? 3 : 1}>
          {plans?.map(({ id, title, Icon, description }) => (
            <Grid item xs={12} md={4} key={id} data-aos="fade-up" data-aos-duration="500" data-aos-delay={id * 50 + 100}>
              <Box sx={styles.planWrapper}>
                <Box sx={styles.iconWrapper}>
                  <Icon />
                </Box>
                <Box>
                  <Typography sx={styles.planTitle}>{title}</Typography>
                  <Typography sx={styles.planDescription}>{description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Create;
