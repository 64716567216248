import { Box, Button, Grid, Typography } from "@mui/material";
import { styles } from "./styles";

const Details = ({ data }) => {
  return (
    <Box>
      <Typography variant="h2" sx={styles.title}>
        TRAINING <span>VIDEOS</span>
      </Typography>
      {data?.map((item, i) => (
        <Grid container spacing={3} sx={{ mb: { xs: 6, sm: 2 } }}>
          <Grid item xs={12} sm={3} lg={2.5}>
            <Box sx={styles.imageWrapper}>
              <img src={item?.icon} alt="" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} lg={9.5}>
            <Typography variant="h5" sx={styles.subtitle}>
              {item?.title}
            </Typography>
            <Typography variant="p" sx={styles.description}>
              {item?.description}
            </Typography>
          </Grid>
        </Grid>
      ))}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", mt: "1rem" }}>
        <Button variant="outlined" color="primary" sx={styles.button}>
          SHOW ALL VIDEOS IN THIS TOPIC
        </Button>
      </Box>
    </Box>
  );
};

export default Details;
