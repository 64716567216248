import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import SingleReply from "./SingleReply";
import { styles } from "./SingleReply/styles";

const RepliesList = ({ replies, ticketData, loading }) => {

  let renderedReplies = loading
    ? Array(6)
        .fill({})
        .map((_, i) => (
          <Box
            sx={{
              ...styles.shared.reply,
              ...styles[i % 2 === 0 ? "user" : "manager"]?.reply,
            }}
          >
            <Box
              sx={{
                ...styles.shared.body,
                ...styles[i % 2 === 0 ? "user" : "manager"]?.body,
              }}
            >
              <Skeleton variant='circular' width="50px" />
              <Skeleton
                variant="rectangular"
                width="100%"
                height="40px"
                sx={{borderRadius: 2.4}}
              />
            </Box>
          </Box>
        ))
    : replies.map((rep, i) => <SingleReply key={rep?.id ?? i} {...rep} />);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", marginBottom: "2rem" }}
    >
      <Typography variant="h5">{t("replies")}</Typography>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {renderedReplies}
      </Box>
    </Box>
  );
};

export default RepliesList;
