import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import { getImageUrl } from "library/helper";
import { useGetAllCountries } from "_hooks/Shared/public";
import { inputAutoFillStyles } from "assets/styles/styles";

export default function CountrySelect({ ref, onChange, value, size }) {
  const { data } = useGetAllCountries();

  return (
    <Autocomplete
      onChange={onChange}
      id="country-select"
      sx={{ width: "100%" }}
      options={data?.data || []}
      autoHighlight
      getOptionLabel={(option) => option.countryName}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
          value={option?.id}
        >
          {/* <img
            loading="lazy"
            width="20"
            src={getImageUrl(option?.countryLogo)}
            alt={option?.countryCode}
          /> */}
          {`+${option?.countryCode} ${option.countryName}`}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Choose a country"
          inputRef={ref}
          value={value}
          inputProps={{
            ...params.inputProps,
            autoComplete: "country", // disable autocomplete and autofill
          }}
          sx={{
            ...inputAutoFillStyles,
            '& .MuiInputBase-input': {
              py: "3.8px !important",
            }
          }}
        />
      )}
    />
  );
}