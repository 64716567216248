import { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Stack, Typography, ButtonBase, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { t } from "locales";
import Deposit from "../Deposit";
import Withdraw from "../Withdraw";
// import Transfer from "../Transfer";

const tabs = [
  // { title: "Transfer", component: Transfer, props: {} },
  { title: "Deposit", component: Deposit, props: { isModal: true } },
  { title: "Withdraw", component: Withdraw, props: { isModal: true } },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FundAccount = ({ isLoading, onClose }) => {
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ width: { xs: "90vw", sm: "80vw", md: "75vw" }, maxWidth: "900px" }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" py={2} px={2} sx={{ borderBottom: "1px solid rgba(123, 130, 145, 0.1)" }}>
        <Typography variant="body1" sx={{ opacity: "0.7", fontWeight: "500" }}>
          {t("addFundHeader")}
        </Typography>

        <ButtonBase
          onClick={onClose}
          sx={{
            width: "38px",
            height: "38px",
            borderRadius: "6px",
            border: "1px solid",
            borderColor: (theme) => (theme.palette.mode === "dark" ? "#2a2e34" : "#eee"),
          }}
        >
          <Close fontSize="small" />
        </ButtonBase>
      </Stack>

      <Box sx={{ borderBottom: "1px solid rgba(123, 130, 145, 0.1)" }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          sx={{
            ".MuiTabs-flexContainer": { gap: 4, justifyContent: "center" },
            button: {
              opacity: "0.65",
              color: "unset",
              p: "0 !important",
              minWidth: "fit-content",
              "&.Mui-selected": { opacity: 1 },
            },
          }}
        >
          {tabs.map(({ title }, index) => (
            <Tab
              sx={{
                py: 0.5,
                flexGrow: "1",
              }}
              key={title}
              label={
                <Typography variant="body1" fontWeight="600">
                  {title}
                </Typography>
              }
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>

      <Box>
        {tabs.map(({ title, component: Component, props }, index) => (
          <TabPanel key={title} value={tab} index={index}>
            <Box px={2}>
              <Component {...props} isLoading={isLoading} onClose={onClose} />
            </Box>
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default FundAccount;
