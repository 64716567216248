import React from 'react';
import banner from "assets/images/banner.png";
import { Box, Typography } from '@mui/material';
import { t } from 'locales';

const Header = ({title}) => {
    return (
        <Box sx={{ backgroundImage: `url(${banner})`, backgroundSize: "cover", backgroundPosition: "center" }}>
            <Box classname="container">
                <Typography  
                    data-aos="fade-up" 
                    data-aos-duration="500" 
                    sx={{
                        textAlign: "center",
                        color: "#FFF",
                        fontSize: {
                            xs: "1.6rem",
                            sm: "2rem",
                            md: "2.3rem",
                            lg: "2.7rem",
                            xl: "3.2rem",
                        },
                        py: 6,
                        fontWeight: 700
                }}>{t(`${title?.split(" ").join("")}Title`)}</Typography>
            </Box>
        </Box>
    );
};

export default Header;