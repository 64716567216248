export const styles = {
  shared: {
    reply: {
      display: "flex",
      flexDirection: "column",
      width: " -fit-content",
      marginBottom: "1rem",
      maxWwidth: { xs: "95%", md: "80%", lg: "70%" },
      minWwidth: { md: "60%", lg: "30%" },
    },
    header: { display: "flex" },
    body: {
      display: "flex",
      gap: "0.75rem",
      "& > img": {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: "1",
    },
    text: {
      padding: "0.75rem 1rem",
      borderRadius: "0.75rem",
      fontSize: 14,
    },
    time: {
      fontSize: "11px",
    },
    files: {
      display: "flex",
      gap: "0.5rem",
      flexWrap: "wrap",
      mt: "0.5rem",
      img: {
        width: "30px",
        height: "30px",
        cursor: "pointer",
        objectFit: "contain",
      },
    },
  },

  user: {
    reply: { alignSelf: "flex-end" },
    header: { alignSelf: "flex-end", pr: "60px" },
    body: { flexDirection: "row-reverse" },
    text: {
      bgcolor: "primary.light",
      fontSize: 14, 
    },
    time: {
      padding: "2px 2rem 0 0",
      alignSelf: "flex-start",
    },
  },

  manager: {
    reply: { alignSelf: "flex-start" },
    header: { alignSelf: "flex-end", pl: "60px" },
    body: { flexDirection: "row" },
    text: {
      bgcolor: "#a16fff2a",
    },
    time: {
      padding: "2px 0 0 2rem",
      alignSelf: "flex-end",
    },
    files: {
      alignSelf: "flex-end",
    },
  },
};
