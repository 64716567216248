import React from 'react';
import { Box } from '@mui/material';
import Header from './Header';
import Paragraph from './Paragraph';

const AiTrading = () => {
    return (
        <>
        <Header />
        <Box className="container" sx={{ mb: 10 }}>
          <Paragraph />
        </Box>
      </>
    );
};

export default AiTrading;