import { t } from "locales";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import _ from "lodash";
import { useSelector } from "react-redux";
import formatDate from "library/formatDate";
import RepliesList from "./RepliesList";
import { getImageUrl, replaceJSX } from "library/helper";
import fileIcon from "assets/images/file.png";
import { Box } from "@mui/system";
import {
  Button,
  LinearProgress,
  Pagination,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { styles } from "./styles";
import ErrorAlert from "components/ErrorAlert";
import { yupResolver } from "@hookform/resolvers/yup";
import { addReplyValidation } from "library/validations/ticketValidation";
import { inputAutoFillStyles, rtlTextFieldStyle } from "assets/styles/styles";
import { getHumanError } from "library/translateServerErrors";
import {
  ArrowBackRounded,
  Close,
  CloudUploadRounded,
  Reply,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
  useGetSingleTicket,
  useGetTicketReplies,
  usePostReply,
} from "_hooks/User/ticket";
import FileModal from "./FileModal";

const IMAGES = ["png", "jpg", "svg", "gif", "jpeg"];
const MIN_FILE_SIZE = 5000000;

const SingleTicket = () => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const allowedFiles = acceptedFiles.filter(
        (f) => f?.size <= MIN_FILE_SIZE
      );

      setFiles((prev) => [...prev, ...allowedFiles]);
    },
  });
  const { lang } = useSelector((state) => state.setting);
  const modal = useSelector((state) => state.app.modal);
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(1);
  const { id } = useParams();

  const { data: ticket, isLoading: loadingTicket } = useGetSingleTicket(id);
  const { data: repliesData, isLoading: loadingReplies } = useGetTicketReplies({
    ticketId: id,
    page,
  });
  const {
    mutate: postReply,
    isLoading: loadingPostReply,
    isError,
    error,
  } = usePostReply(setProgress);

  const { list: replies = [], total = 1 } = repliesData || {};
  const loading = loadingTicket || loadingReplies;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(addReplyValidation),
    defaultValues: {
      text: "",
    },
  });

  const onPageChange = (_, value) => setPage(value);
  const onRemoveFile = (path) => {
    if (!isSubmitting)
      setFiles((prev) => prev?.filter((file) => file?.path !== path));
  };
  const onOpenFile = (file) =>
    modal.show(<FileModal onClose={modal.hide} file={file} />);

  const submitHandler = (data) =>
    postReply(
      { text: data.text, id, files },
      {
        onSuccess: () => {
          setFiles([]);
          reset({ text: "" });
        },
        onSettled: () => setProgress(0),
      }
    );

  const renderedAcceptedFiles = files.map((file, i) => (
    <Box sx={styles.singelFile} key={file.path}>
      <Close onClick={() => onRemoveFile(file.path)} />
      <Typography variant="body2" sx={styles.singelFileName}>
        {i + 1}: {file.path}
      </Typography>
    </Box>
  ));

  const renderedTicketFiles = ticket?.file?.map((f, i) => {
    const fileUrl = f?.location;
    const fileExtension = fileUrl.match(/[^\\]*\.(\w+)$/)?.[1];

    if (IMAGES.includes(fileExtension)) {
      return (
        <img
          key={f?.key ?? i}
          src={getImageUrl(null, f.key) || f?.location}
          alt=""
          onClick={() => onOpenFile(f)}
        />
      );
    }

    return (
      <a key={f?.key ?? i} href={fileUrl}>
        <img src={fileIcon} alt="" />
      </a>
    );
  });

  return (
    <Box className="container">
      <Box sx={styles.singleTicket}>
        <Box sx={styles.header}>
          <Box>
            <Box>
              <Box component={Link} to="/profile/support-ticket" sx={styles.back}>
                <ArrowBackRounded />
                <Typography variant="body1">{t("back")}</Typography>
              </Box>
              <Typography sx={styles.headerTitle} variant="h5">
                {replaceJSX(
                  t("singleTicketHeader"),
                  "#",
                  <span>{ticket?.code}</span>
                )}
              </Typography>
            </Box>
            <Box sx={styles.ticketDetail}>
              <Box sx={styles.firstRowDetail}>
                <Box>
                  <Box>
                    <Typography variant="body1" sx={styles.ticketDetailItemLabel}>
                      {t("title")}
                    </Typography>
                    <Typography variant="body1">
                      {loading ? (
                        <Skeleton
                          variant="rectangular"
                          width="70px"
                          height="18px"
                          sx={{borderRadius: 2.4}}
                        />
                      ) : (
                        ticket?.title ?? "-"
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" sx={styles.ticketDetailItemLabel}>
                      {t("department")}
                    </Typography>
                    <Typography variant="body1">
                      {loading ? (
                        <Skeleton
                        variant="rectangular"
                        width="70px"
                        height="18px"
                        sx={{borderRadius: 2.4}}
                      />
                      ) : (
                        ticket?.department?.name ?? "-"
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Typography variant="body1" sx={styles.ticketDetailItemLabel}>
                      {t("creationTime")}
                    </Typography>
                    <Typography variant="body1">
                      {loading ? (
                        <Skeleton
                        variant="rectangular"
                        width="70px"
                        height="18px"
                        sx={{borderRadius: 2.4}}
                      />
                      ) : (
                        formatDate(ticket?.createdAt, lang)
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body1" sx={styles.ticketDetailItemLabel}>
                      {t("status")}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={() => styles.status(ticket?.status)}
                    >
                      {loading ? (
                        <Skeleton
                          variant="rectangular"
                          width="70px"
                          height="18px"
                          sx={{borderRadius: 2.4}}
                        />
                      ) : (
                        t(ticket?.status) ?? "-"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={styles.secondRowDetail}>
                <Box>
                  <Typography variant="body1" sx={styles.ticketDetailItemLabel}>
                    {t("description")}
                  </Typography>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="90%"
                      height="18px"
                      sx={{borderRadius: 2.4}}
                    />
                  ) : (
                    <Typography variant="body1">{ticket?.text}</Typography>
                  )}
                </Box>
                <Box>
                  <Typography variant="body1" sx={styles.ticketDetailItemLabel}>
                    {t("files")}
                  </Typography>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="80%"
                      height="18px"
                      sx={{borderRadius: 2.4}}
                    />
                  ) : (
                    <Box sx={styles.ticketFiles}>{renderedTicketFiles}</Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <RepliesList loading={loading} replies={replies} ticketData={ticket} />
          {!loading && replies?.length === 0 ? (
            <Box sx={styles.noReply}>
              <Reply fontSize="large" />
              <Typography variant="body1">{t("noReply")}</Typography>
            </Box>
          ) : (
            <Box sx={styles.pagination}>
              <Pagination
                sx={{ width: "fit-content" }}
                count={total}
                page={page}
                onChange={onPageChange}
              />{" "}
            </Box>
          )}
          {ticket?.status !== "CLOSED" && (
            <Box component="form" onSubmit={handleSubmit(submitHandler)} sx={{ "& .MuiFormControl-root": { my: 0 } }}>
              {isError && <ErrorAlert text={t(getHumanError(error))} />}
              <Typography sx={{ mb: 0.5, fontSize: 14, fontWeight: 500, mt: 2 }}>{t('replyText')}</Typography>
              <Controller
                control={control}
                name="text"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    multiline
                    rows={5}
                    type="text"
                    error={errors?.text}
                    helperText={t(errors?.text?.message)}
                    disabled={isSubmitting}
                    margin="normal"
                    fullWidth
                    id="text"
                    name="text"
                    autoComplete="text"
                    autoFocus
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    placeholder={t("enterYourMessage")}
                    sx={{ ...inputAutoFillStyles }}
                  />
                )}
              />

              {loadingPostReply && files?.length > 0 && (
                <Box sx={styles.progressWrapper}>
                  <LinearProgress value={progress} variant="determinate" />
                </Box>
              )}
              <Box sx={styles.footer}>
                <Box sx={styles.fileInput}>
                  <Typography variant="body2" component="label" htmlFor="files">
                    {t("sendingFile")} <span>({t("maxTicketFile")})</span>
                  </Typography>
                  <Box {...getRootProps()} sx={styles.dropzone}>
                    <input id="files" {...getInputProps()} />
                    <Box sx={styles.dropzoneText}>
                      <span>{t("uploadFile")}</span>
                      <CloudUploadRounded size={22} />
                    </Box>
                  </Box>
                  <Box sx={styles.selectedFiles}>{renderedAcceptedFiles}</Box>
                </Box>

                <Box sx={styles.submitBtn}>
                  <Button
                    disabled={
                      !_.isEmpty(errors, true) || loadingPostReply || loading
                    }
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    <Typography variant="body1">{t("sendReply")}</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SingleTicket;
