const PRIORITY_COLOR = {
  HIGH: "#ff3838",
  MEDIUM: "#D29D2B",
  LOW: "#7882A4",
};
const STATUS_COLOR = {
  CLOSED: "#ff3838",
  PENDING: "#D29D2B",
  REPLIED: "#019267",
  CREATED: "#7882A4",
};

export const styles = {
  filters: { display: "flex", flexWrap: "wrap", margin: "1rem 0", gap: "1rem" },
  filter: { display: "flex", alignItems: "center", gap: "0.5rem" },

  selectContainer: {
    fieldset: { border: "none !important" },
    ".MuiSelect-select": { py: "8px" },
  },
  selectInput: { bgcolor: "rgba(0,0,0,0.1)", borderRadius: "0" },

  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    py: 4,
    minHeight: "300px",
  },

  pagination: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mt: 2,
  },

  status: (status) => ({
    color: "#fff",
    bgcolor: STATUS_COLOR[status],
    width: "fit-content",
    borderRadius: "5px",
    p: "1px 8px",
  }),
  priority: (priority) => ({
    color: "#fff",
    bgcolor: PRIORITY_COLOR[priority],
    width: "fit-content",
    borderRadius: "5px",
    p: "1px 8px",
  }),
};
