import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Deposit from "components/WalletActions/Deposit";
import Withdraw from "components/WalletActions/Withdraw";
import DashboardOverview from "./Overview";
import Plans from "./Xtaker";
import Notification from "./Notifications";
import Invite from "./Setting/Referral";
import Ticket from "./Ticket";
import StableCoinTraderGPT from "./Xtaker/StableCoin";
import { useMemo } from "react";
import { profile } from "routing/GeneralRoutes/routes";
import { useLocation } from "react-router-dom";
import { ConfirmationNumberRounded, DownloadRounded, NotificationsRounded, Person, PersonAddAlt1Rounded, UploadRounded } from "@mui/icons-material";

export const PROFILE_ITEMS = [
  {
    title: "overview",
    link: profile.overview,
    icon: Person,
    tabName: "overview",
    component: DashboardOverview,
    isTab: true,
  },
  {
    title: "Plans",
    link: profile.plans,
    icon: PersonAddIcon,
    tabName: "plans",
    component: StableCoinTraderGPT,
    isTab: true,
  },
  {
    title: "supportTicket",
    link: profile.ticket.base,
    icon: ConfirmationNumberRounded,
    tabName: "support-ticket",
    component: Ticket,
    isTab: true,
  },
  {
    title: "deposit",
    link: profile.deposid,
    icon: DownloadRounded,
    tabName: "deposit",
    component: Deposit,
    isTab: true,
  },
  {
    title: "withdraw",
    link: profile.withdraw,
    icon: UploadRounded,
    tabName: "withdraw",
    component: Withdraw,
    isTab: true,
  },
  {
    title: "Notifications",
    link: profile.notification,
    icon: NotificationsRounded,
    tabName: "notification",
    component: Notification,
    isTab: true,
  },
  {
    title: "Invite friend",
    link: profile.inviteFriend,
    icon: PersonAddAlt1Rounded,
    tabName: "invite-friend",
    component: Invite,
    isTab: true,
  },
];

export const UserProfileRoutes = () => {
  const { pathname } = useLocation();
  const splitPathname = pathname?.split("/")[2];
  const Component = useMemo(() => {
    const selectedTab = splitPathname;
    const result = () => {
      const nonSub = PROFILE_ITEMS?.find((si) => si.tabName === selectedTab);
      return nonSub?.component;
    };
    return result();
  }, [splitPathname]);

  return Component ? <Component noPt /> : null;
};
