export const styles = { 
    wrapper: {
        mt: 6,
    },
    iconWrapper: {
        '& svg': {
            width: 38,
            height: 38,
            fill: (theme) => theme.palette.primary.main
        },
        "& div": {
            height: "100%",
            width: "1px",
            bgcolor: (theme) => theme.palette.primary.light
        }
    },
    textsWrapper: {
        pt: 3
    },
    text: {
    },
}