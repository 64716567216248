import React from 'react';

import profile1 from "assets/images/girTrader.jpg";
import profile2 from "assets/images/profile2.jpg";
import profile3 from "assets/images/profile3.jpg";

import { Avatar, Box, Stack, Typography } from '@mui/material';

const Traders = () => {
    return (
        <Stack
            direction={{ xs: "row", sm: "row" }}
            alignItems={{xs: 'center', sm:"center", md: 'flex-start'}}
            spacing={2}
            justifyContent={{xs: 'center',}}
        >
            <Stack direction="column" mt={4}>
                <Box sx={{
                    borderRadius: 6,
                    backgroundColor: 'primary.light',
                    p: {xs: 2, lg: 4},
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Avatar src={profile1} alt="" sx={{width: {xs: 42, lg: 80}, height: {xs: 42, lg: 80}}} />
                    <Typography
                        variant="h6"
                        fontWeight="600"
                        mt={1}
                        color="primary"
                        textAlign="center"
                        sx={{fontSize: {xs: 14, md: 18}}}
                    >
                        Trader
                    </Typography>
                    <Typography
                        variant="body1"
                        fontWeight="600"
                        textAlign="center"
                        
                        sx={{
                            fontSize: {xs: 12, md: 16},
                            color: (theme) =>
                            theme.palette.mode === "dark" ? "#CCC" : "#303234",
                        }}
                    >
                        Elena
                    </Typography>
                </Box>
                <Stack
                    sx={{
                        mt: 3,
                        width: "auto",
                        position: "relative",
                        backgroundColor: "#0F0D19",
                        p: 1,
                        overflow: 'hidden',
                        pl: 2,
                        borderRadius: 4,
                        "&:before": {
                            content: '""',
                            position: "absolute",
                            left: 0,
                            width: 3,
                            height: "100%",
                            backgroundColor: "#00c8b5",
                        },
                    }}
                >
                    <Typography variant="caption" fontWeight="500" color="#fff">
                        Profit Split
                    </Typography>
                    <Typography variant="body1" fontWeight="500" color="#00c8b5">
                        $32,000
                    </Typography>
                </Stack>
            </Stack>
            <Box
                sx={{
                    borderRadius: 6,
                    backgroundColor: 'primary.light',
                    p: {xs: 3, lg: 6},
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
            <Avatar src={profile2} alt="" sx={{width: {xs: 68, lg: 120}, height: {xs: 68, lg: 120}}} />
            <Typography
                variant="h6"
                fontWeight="600"
                mt={1}
                color="primary"
                textAlign="center"
            >
                Trader
            </Typography>
            <Typography
                variant="body1"
                fontWeight="600"
                textAlign="center"
                sx={{
                color: (theme) =>
                    theme.palette.mode === "dark" ? "#CCC" : "#303234",
                }}
            >
                Joseph
            </Typography>
            </Box>
            <Stack
                direction="column"
                sx={{
                    width: "fit-content",
                }}
            >
                <Stack
                    sx={{
                    mb: 3,
                    mt: 3,
                    width: "auto",
                    position: "relative",
                    backgroundColor: "#0F0D19",
                    p: 1,
                    pl: 2,
                    overflow: 'hidden',
                    borderRadius: 4,
                    "&:before": {
                        content: '""',
                        position: "absolute",
                        width: 3,
                        left: 0,
                        height: "100%",
                        backgroundColor: "#00c8b5",
                    },
                    }}
                >
                    <Typography variant="caption" fontWeight="500" color="#fff">
                    Profit Split
                    </Typography>
                    <Typography variant="body1" fontWeight="500" color="#00c8b5">
                    $11,000
                    </Typography>
                </Stack>
                <Box
                    sx={{
                        borderRadius: 6,
                        backgroundColor: 'primary.light',
                        p: {xs: 3, lg: 4},
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Avatar src={profile3} alt="" sx={{width: {xs: 42, lg: 80}, height: {xs: 42, lg: 80}}} />
                    <Typography
                        variant="h6"
                        fontWeight="600"
                        mt={1}
                        color="primary"
                        textAlign="center"
                        sx={{fontSize: {xs: 14, md: 18}}}
                    >
                        Trader
                    </Typography>
                    <Typography
                        variant="body1"
                        fontWeight="600"
                        textAlign="center"
                        sx={{
                            fontSize: {xs: 12, md: 16},
                            color: (theme) =>
                            theme.palette.mode === "dark" ? "#CCC" : "#303234",
                        }}
                    >
                        Alvaro
                    </Typography>
                </Box>
            </Stack>
        </Stack>
    );
};

export default Traders;