import useChart from './useChart';
import ReactApexChart from 'react-apexcharts';
import { Box } from '@mui/material';
import { MAIN_COIN } from 'library/constants';

export default function LineChart({ height, chartLabels, chartData, chartColors, ...other }) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    colors: chartColors,
    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(6)} ${MAIN_COIN}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Box>
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={height} />
    </Box>
  );
}
