export const styles = {
    wrapper: {
        mb: 12,
        py: 6,
        background: 'radial-gradient(circle, rgba(229,237,255,1) 0%, rgba(255,255,255,0.0) 80%)',
    },
    blueBox: {
        px: 2,
        py: 0.5,
        background: (theme) => theme.palette.primary.light,
        borderRadius: 15,
        width: 'fit-content',
    },
    subTitle: {
        color: (theme) => theme.palette.primary.main,
        fontWeight: 600,
    },
    title: {
        fontWeight: "700",
        fontSize: {
            xs: "1.6rem",
            sm: "2rem",
            md: "2.3rem",
            lg: "2.7rem",
            xl: "3rem",
        },
        color: (theme) => theme.palette.primary.contrastText,
        lineHeight: 1.4,
        "& span": {
            color: (theme) => theme.palette.primary.main,
        },
    },
    description: { 
        mt: 2,
        maxWidth: 480, 
        color: "#5E6282",
    },
    commentWrapper: {
        height: '100%',
        display: "flex",
        flexDirection: "column",
        bgcolor: "#fff",
        border: "1px solid rgba(0,0,0,0.1)",
        boxShadow: "none",
        borderRadius: "24px",
        p: {xs: 2, md: 3.5},
        transition: "all 0.2s ease-out",
        '&:hover': {
            border: "1px solid transparent",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
    },
    owner: {
        color: (theme) => theme.palette.primary.contrastText,
        fontSize: 20,
        fontWeight: 700,
    },
    comment: {
        mt: 2,
        color: "#5E6282", 
        fontSize: 17,
        lineHeight: '28px',
    },
};