import React from 'react';
import { styles } from './styles';
import { Box, Stack, Typography, } from '@mui/material';
import { TrackChangesRounded } from '@mui/icons-material';

const Paragraph = () => {
    const paragraphData = [
        {
            id: 1,
            content: 'TraderGPT is a cutting-edge trading system that uses AI tools to analyze cryptocurrency markets and make informed trading decisions. The system is built on a sophisticated algorithm that is capable of processing vast amounts of market data, including historical trends, news events, and economic indicators. This enables the system to identify trading opportunities and predict future market movements with a high degree of accuracy.',
        },
        {
            id: 2,
            content: 'TraderGPT operates 24/7, without the need for human intervention, allowing it to respond quickly to changes in market conditions and capitalize on trading opportunities as they arise. The system uses advanced risk management strategies to ensure that trades are executed in a controlled and responsible manner. This includes setting stop-losses and profit targets to minimize potential losses and lock in profits.',
        },
        {
            id: 3,
            content: 'TraderGPT is designed to be user-friendly, even for those with little to no trading experience. The system provides users with real-time data and analysis, as well as easy-to-understand trading signals and recommendations. This allows users to make informed trading decisions and maximize their profits.',
        },
        {
            id: 4,
            content: 'Overall, TraderGPT is a powerful trading system that harnesses the power of AI to analyze cryptocurrency markets and make profitable trades. Whether you are a seasoned trader or new to the world of cryptocurrency trading, TraderGPT can help you achieve your trading goals.',
        },
    ];
    return (
        <Stack sx={styles.wrapper} gap={2}>
            {paragraphData?.map((item) => (
                <Stack direction="row" spacing={2} key={item?.id}>
                    <Stack alignItems="center" spacing={2} sx={styles.iconWrapper}>
                        <TrackChangesRounded />
                        <Box component="div" orientation="vertical" />
                    </Stack>
                    <Box sx={styles.textsWrapper}>
                        <Typography sx={styles.text}>
                            {item?.content}
                        </Typography>
                    </Box>
                </Stack>
            ))}
        </Stack>
    );
};

export default Paragraph;