import { Stack, Typography } from "@mui/material";
import React from "react";
import { TextField } from "@mui/material";

const CustomTextField = ({
  id,
  noLabel,
  label,
  description,
  inputSx = {},
  ...props
}) => {
  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      {!noLabel && (
        <Stack component="label" htmlFor={id} mb={1}>
          {label && (
            <Typography
              variant="body1"
              fontWeight="600"
              sx={{ opacity: "0.9" }}
            >
              {label}
            </Typography>
          )}
          {description && (
            <Typography
              variant="body2"
              fontWeight="500"
              sx={{ opacity: "0.7", fontSize: "12px" }}
            >
              {description}
            </Typography>
          )}
        </Stack>
      )}

      <TextField
        {...props} // onChange, value, inputRef, onBlur
        sx={{
          px: !props?.multiline && "12px",
          border: "1px solid",
          borderColor: "border.default",
          borderRadius: "10px",
          fontSize: "15px",
          "& .MuiOutlinedInput-input": {
            padding: !props?.multiline && "12px 0",
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "0 !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        }}
      />
    </Stack>
  );
};

export default CustomTextField;
