import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styles } from './styles';


import DogeCoin from '../../assets/coins/DOGE_COIN.webp';
import Dash from '../../assets/coins/DASH.webp';
import ADA from '../../assets/coins/ADA.webp';
import BNB from '../../assets/coins/BNB.webp';
import BTC from '../../assets/coins/BTC.webp';
import COSMOS from '../../assets/coins/COSMOS.webp';
import DOT from '../../assets/coins/DOT.webp';
import LTC from '../../assets/coins/LTC.webp';
import STALLER from '../../assets/coins/STALLER.webp';
import FILECOIN from '../../assets/coins/FILE_COIN.webp';
import Z_CHASH from '../../assets/coins/Z_CHASH.webp';
import HOTTO from '../../assets/coins/HOTTO.webp';
import Dragonereum from '../../assets/coins/Dragonereum.webp';
import GIFTO from '../../assets/coins/GIFTO.webp';
import GENERIC from '../../assets/coins/GENERIC.webp';
import MATIC from '../../assets/coins/MATIC.webp';
import EURUSD from '../../assets/coins/eurusd.png';
import Attention from '../../assets/coins/Attention.webp';
import SBERBANK from '../../assets/coins/SBER_BANK.webp';
import { Link } from 'react-router-dom';
import { mainRoutes } from 'routing/GeneralRoutes/routes';



const TradesAssets = () => {
    const assets = [
        {
            name: 'EUR/USD',
            image: EURUSD,
        },
        {
            name: 'DogeCoin',
            image: DogeCoin,
        },
        {
            name: 'Dash',
            image: Dash,
        },
        {
            name: 'Cardano',
            image: ADA,
        },
        {
            name: 'Bitcoin',
            image: BTC,
        },
        {
            name: 'Binance',
            image: BNB,
        },
        {
            name: 'COSMOS',
            image: COSMOS,
        },
        {
            name: 'Polkadot',
            image: DOT,
        },
        {
            name: 'LiteCoin',
            image: LTC,
        },
        {
            name: 'STALLER',
            image: STALLER,
        },
        {
            name: 'FileCoin',
            image: FILECOIN,
        },
        {
            name: 'SBERBANK',
            image: SBERBANK,
        },
        {
            name: 'ZCash',
            image: Z_CHASH,
        },
        {
            name: 'HOTTO',
            image: HOTTO,
        },
        {
            name: 'DRAGONEREUM',
            image: Dragonereum,
        },
        {
            name: 'ATTENTION',
            image: Attention,
        },
        {
            name: 'GIFTO',
            image: GIFTO,
        },
        {
            name: 'GENERIC',
            image: GENERIC,
        },
        {
            name: 'MATIC',
            image: MATIC,
        },
        {
            name: '...',
        },
    ];
    return (
        <Box sx={styles.wrapper}>
            <Typography 
                sx={styles.title}
                data-aos="fade-up"
                data-aos-duration="600"
                data-aos-delay="0"
            >
                Trade <span> All Assets</span> with Ease using TraderGPT!
            </Typography> 
            <Box sx={styles.assetsWrapper}>
                {assets?.map((item, index) => (
                    <Box
                        key={item?.name} 
                        sx={styles.boxWrapper}
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-delay={index * 10}
                    >
                        {!!item?.image ? 
                            <Box sx={styles.assetImageWrapper}>
                                <img src={item?.image} alt='' />
                            </Box>
                            : null
                        }
                        <Typography sx={{...styles.assetName, textAlign: !!item?.image ? 'left' : 'center'}}>{item?.name}</Typography>
                    </Box>
                ))}
            </Box>
            <Box 
                data-aos="fade-up"
                data-aos-duration="600"
                data-aos-delay='0'
                sx={{mt: 4, textAlign: 'center'}}
            >
                <Button as={Link} to={mainRoutes.signals} sx={styles.linkButton} >
                    Get Started
                </Button>
            </Box>
        </Box>
    );
};

export default TradesAssets;