import { useState, useEffect } from "react";
import { Stack, CircularProgress, Typography } from "@mui/material";

import Table from "./Table";
import { WarningAmberOutlined } from "@mui/icons-material";
import { getImageUrl } from "library/helper";
import noImage from "assets/images/no_image.png";

const columns = [
  {
    id: "name",
    label: "Product",
    minWidth: 178,
    align: "start",
    width: 178,
  },

  {
    id: "interest",
    label: "Est. Profit",
    minWidth: 100,
    align: "end",
    width: 120,
  },
  {
    id: "duration",
    label: "Lock-up period (days)",
    minWidth: 120,
    align: "end",
  },

  {
    id: "invesment",
    label: "Subscription range",
    minWidth: 150,
    align: "end",
  },

  {
    id: "button",
    label: "",
    minWidth: 120,
    align: "start",
    width: 168,
  },
];

export default function FixedTable({ plans = [], isLoading }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (plans?.length) {
      setRows(
        plans?.map?.((item) => ({
          id: item.id,
          icon: getImageUrl(item.icon) ?? noImage,
          // color: item.color,
          name: item.title,
          interest: `${item.minProfit} - ${item.maxProfit}`,
          invesment: `${item.minInvestment} - ${item.maxInvestment}`,
          duration: item.duration,
        }))
      );
    }
  }, [plans]);

  return !isLoading ? (
    !!plans?.length ? (
      <Table columns={columns} rows={rows} key={0} />
    ) : (
      <Stack
        mt={8}
        gap={1}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",

          svg: {
            fontSize: "60px",
            opacity: "0.9",
          },
        }}
      >
        <WarningAmberOutlined />

        <Typography variant="h6">No Plan Found!</Typography>
      </Stack>
    )
  ) : (
    <Stack sx={{ minHeight: "100px", alignItems: "center", mt: 8 }}>
      <CircularProgress size={28} />
    </Stack>
  );
}
