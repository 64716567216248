import { Route, Routes } from "react-router";
import { PrivateRoute, AuthRoute } from "../CustomRoutes";

// Authentication
import { auth, mainRoutes, profile } from "./routes";
import Verify from "pages/auth/verify";
import Login from "pages/auth/login";
import SignUp from "pages/auth/signUp";
import ForgotPassword from "pages/auth/ForgotPassword";
import ResetPassword from "pages/auth/ResetPassword";
import Recovery from "pages/auth/components/recovery";

// Public Routes
import HomePage from "pages/Home";
import TermsOfUsePage from "pages/termsOfUse";
import ContactUsPage from "pages/contactUs";
import TermsAndCondition from "pages/TermsAndCondition";
import XtakerSinglePlan from "pages/SinglePlan";
import PrivacyAndPolicyPage from "pages/privacyAndPolicy";
import Trading from "pages/Trading/Platform";
import Marketplace from "pages/Marketplace";
import Investing from "pages/Investing";
import Resources from "pages/Resources";
import OrderManagement from "pages/Trading/OrderManagement";
import OptionsTrading from "pages/Trading/OptionsTrading";
import ICO from "pages/ICO";

// Profile Routes
import XtakerProfile from "pages/Profile";
import FAQ from "pages/FAQ";
import Swap from "pages/Swap";
import TraderPortfolio from "pages/TraderPortfolio";
import SingleTicket from "pages/Profile/Body/Ticket/SingleTicket";
import AiTrading from "pages/Trading/AiTrading";
import Signals from "pages/Signals";
import Services from "pages/Services";

const publicRoutes = [
  {
    path: mainRoutes.base,
    component: HomePage,
  },
  {
    path: mainRoutes.recovery,
    component: Recovery,
  },
  // {
  //   path: mainRoutes.presale,
  //   component: ICO,
  // },
  {
    path: mainRoutes.TermsAndCondition,
    component: TermsAndCondition,
    exact: true,
  },
  {
    path: mainRoutes.contactUs,
    component: ContactUsPage,
    exact: true,
  },
  {
    path: mainRoutes.termsOfUse,
    component: TermsOfUsePage,
    exact: true,
  },
  {
    path: mainRoutes.privacyAndPolicy,
    component: PrivacyAndPolicyPage,
    exact: true,
  },
  {
    path: mainRoutes.trading.xtakerplatform,
    component: Trading,
    exact: true,
  },
  {
    path: mainRoutes.trading.orderManagement,
    component: OrderManagement,
    exact: true,
  },
  {
    path: mainRoutes.trading.optionsTrading,
    component: OptionsTrading,
    exact: true,
  },
  {
    path: mainRoutes.trading.AiTrading,
    component: AiTrading,
    exact: true,
  },
  {
    path: mainRoutes.marketplace.base,
    component: Marketplace,
    exact: true,
  },
  {
    path: mainRoutes.investing.base,
    component: Investing,
    exact: true,
  },
  {
    path: mainRoutes.resources.base,
    component: Resources,
    exact: true,
  },
  {
    path: mainRoutes.faq,
    component: FAQ,
    exact: true,
  },
  {
    path: mainRoutes.signals,
    component: Signals,
    exact: true,
  },
  {
    path: mainRoutes.services,
    component: Services,
    exact: true,
  },
  {
    path: mainRoutes.TraderPortfolio.trader(),
    component: TraderPortfolio,
  },
];

const authRoutes = [
  {
    path: auth.login,
    component: Login,
  },
  {
    path: auth.register,
    component: SignUp,
  },
  {
    path: auth.verify,
    component: Verify,
  },
  {
    path: auth.forgotPassword,
    component: ForgotPassword,
  },
  {
    path: auth.resetPassword,
    component: ResetPassword,
  },
];

const privateRoutes = [
  {
    path: profile.base,
    component: XtakerProfile,
  },
  {
    path: profile.swap,
    component: Swap,
  },
  {
    path: profile.ticket.singleTicket,
    component: SingleTicket,
  },
  {
    path: "/xtakers/:id",
    component: XtakerSinglePlan,
  },
];

export const GeneralPublicRoutes = () => (
  <Routes>
    {publicRoutes.map(({ exact = false, path, component: Component }, i) => {
      return <Route key={i} exact={exact} path={path} element={<Component />} />;
    })}
  </Routes>
);

export const GeneralAuthRoutes = () => (
  <Routes>
    {authRoutes.map(({ exact = false, path, component: Component }, i) => {
      return (
        <Route
          key={i}
          exact={exact}
          path={path}
          element={
            <AuthRoute>
              <Component />
            </AuthRoute>
          }
        />
      );
    })}
  </Routes>
);

export const GeneralPrivateRoutes = () => (
  <Routes>
    {privateRoutes.map(({ exact = false, path, component: Component }, i) => {
      return (
        <Route
          key={i}
          exact={exact}
          path={path}
          element={
            <PrivateRoute>
              <Component />
            </PrivateRoute>
          }
        />
      );
    })}
  </Routes>
);
