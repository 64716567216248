export const styles = {
  wrapper: {
    p: "20px",
    borderRadius: "24px",
    bgcolor: (theme) => (theme.palette.mode === "dark" ? "card.dark" : "card.light"),
    border: "1px solid",
    borderColor: (theme) => (theme.palette.mode === "dark" ? "transparent" : "#eee"),
  },

  container: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },

  header: { display: "flex", alignItems: "center" },

  mainTitle: { flexGrow: "1", fontWeight: "600", marginBottom: 3  },

  newTicketBtn: {
    display: "flex",
    alignItems: "center",
    gap: 2,
    cursor: "pointer",
    borderRadius: "0.6rem",
    background: "linear-gradient(to right, rgb(39, 115, 255), #2962FF)",
    py: 1,
    px: 2,
    color: "#fff",
  },
};
