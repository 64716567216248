export const styles = {
    wrapper: {
        mt: 10,
    },
    title: {
        fontSize: {
            xs: "1.2rem",
            lg: "2.0rem",
          },
        fontWeight: 600,
        color: (theme) => theme.palette.primary.contrastText,
        '& span': {
            color: (theme) => theme.palette.primary.main
        },
        mb: 2,
    },
    tableWrapper: {
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "16px",
        overflowY: "auto", 
    },
    tableHeader: {
        fontSize: "1.25rem"
    },
    tableCell: {
        border: "1px solid",
        borderColor: "border.default",
        '&:last-child': {
            border: "none"
        }
    }
}