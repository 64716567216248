import React from "react";
import { styles } from "./styles";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { TrackChangesRounded } from "@mui/icons-material";

const List = () => {
  const faqList = [
    {
      title: "What is a crypto Financial account?",
      details:
        "Financial account is a sub-account on Tradergpts created for wealth management purposes. It is differentiated from other sub-accounts on Tradergpts (spot, futures, and cash account) that serve other purposes.",
    },
    {
      title: "What is the difference between crypto savings and staking?",
      details:
        "Staking is an on-chain activity that uses the Proof-of-Stake protocol and with transactions done “on the blockchain”. There is higher security when the transactions are done on the chain to avoid alteration following verification on the network. Savings is a form of staking in which you will need to “lend” your funds to the exchange in return for rewards.",
    },
    {
      title: "What savings rate can I expect?",
      details: "Each coin offers anywhere from 4% annual percentage yield (APY) to 20% APY. There are also limited-time-only offers, which can go as high as 60% APY.",
    },
    {
      title: "How are my earnings calculated?",
      details:
        "The system will begin calculating your fixed savings earnings on a T+1 basis or 1 day following the subscription date. However, you can only redeem the earnings after the subscription period expires. Meanwhile, calculation of earnings for flexible savings accrue immediately with a minimum duration of 1 minute. The earnings will be distributed to your financial account from 00: 00-00: 30 UTC of the next day.",
    },
    {
      title: "When will the earnings be distributed to my Financial account?",
      details:
        "Your earnings will be released to your Financial account 00:00 - 00:30 UTC the next day. The accuracy of the distribution is up to 8 decimal places, which means if you earned 1.123456789 AAB, then 1.12345678 AAB will reflect on your account.",
    },
    {
      title: "Are there fees associated with subscribing, withdrawing, or topping up?",
      details: "There are no fees in subscribing and topping up your Savings account. Coins have varying fees when it comes to withdrawal services, which you may find here.",
    },
    {
      title: "What crypto savings risks are there?",
      details:
        "Price volatility is one of the biggest risks in subscribing your crypto. If the coin’s value drops significantly, you may suffer a loss or break even with the interest you earned. There are ways to minimize risks, such as diversifying your investment, or subscribing to flexible or shorter savings products.",
    },
    {
      title: "Are crypto Financial accounts safe?",
      details:
        "Tradergpts is Cryptocurrency Security Standard (CCSS)-compliant, a universally recognized benchmark for crypto platform security. It also built a proprietary multi-currency wallet system, which is multi-signature and with no single point of failure. Majority of digital assets on the exchange are held in cold storage or secure offline storage facilities as an added protection from cyber attacks. In addition, there are measures in place to protect users accounts such as two-factor authentication.",
    },
    {
      title: "Should I put my savings in crypto?",
      details:
        "Yes, placing a fraction of your crypto in an Tradergpts Financial account and subscribing to any of its savings products is a sure way to earn more crypto. It requires little technical knowledge and maintenance, and you can earn even while you sleep.",
    },
    {
      title: "Which crypto earns the best interest?",
      details: "You can check our list of cryptocurrencies with the highest APY for better earning potential. We are actively offering promotions that give as high as 60% APY on Tradergpts.",
    },
  ];
  return (
    <Box sx={styles.wrapper}>
      <Grid container columnSpacing={4} rowSpacing={3}>
        {faqList?.map((item) => (
          <Grid item xs={12} md={6} key={item.title}>
            <Stack direction="row" spacing={3}>
              <Stack alignItems="center" spacing={2} sx={styles.iconWrapper}>
                <TrackChangesRounded />
                <Box component="div" orientation="vertical" />
              </Stack>
              <Box sx={styles.textsWrapper}>
                <Typography sx={styles.title}>{item.title}</Typography>
                <Typography sx={styles.description}>{item.details}</Typography>
              </Box>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default List;
