export const styles = {
    wrapper: {
        mb: 12,
    },
    textWrapper: {
        maxWidth: 720, 
        mx: 'auto',
        mb: 3,
    },
    title: {
        fontSize: {
            xs: "1.6rem",
            sm: "1.8rem",
            md: "2.1rem",
            lg: "2.4rem",
          },
          textAlign: 'center',
          fontWeight: "700",
          color: (theme) => theme.palette.primary.contrastText,
          lineHeight: 1.4,
          "& span": {
            color: (theme) => theme.palette.primary.main,
          },
    },
    descriptions: {
        mt: 2,
        color: "#5E6282", 
        fontSize: 17,
        lineHeight: '28px',
        textAlign: 'center',
    },
    boxWrapper: {
        height: '100%',
        display: "flex",
        flexDirection: "column",
        bgcolor: "#fff",
        border: "1px solid rgba(0,0,0,0.1)",
        boxShadow: "none",
        borderRadius: "24px",
        p: {xs: 2, md: 3.5},
        transition: "all 0.2s ease-out",
        '&:hover': {
            border: "1px solid transparent",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
    },
    imageWrapper: {
        '& img': {
            width: '100%',
        },
    },
    cardTitle: {
        fontWeight: 700,
        fontSize: 20,
        mt: 2,
        color: 'primary.contrastText',
    },
    cardDescription: {
        mt: 1,
        fontSize: 15,
        color: "#5E6282", 
    },
};