import React from 'react';
import imgWebp from 'assets/images/xtaker/chessman.webp';
import imgjpg from 'assets/images/xtaker/chessman.jpg';
import { Box, Grid, Typography } from '@mui/material';
import Image from 'react-image-webp';

const TextField = () => {
    return (
        <Box sx={{ 
            mt: 18,
            // display: "flex",
            // flexDirection: "column",
            // bgcolor: "#fff",
            // border: "1px solid rgba(0,0,0,0.1)",
            // boxShadow: "none",
            // borderRadius: "24px",
            // p: {xs: 2, md: 3},
            // transition: "all 0.2s ease-out",
            // '&:hover': {
            //   border: "1px solid transparent",
            //   boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            // },
        }}>
            <Grid container spacing={2} direction='row' alignItems='center'>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography
                            sx={{
                            color: (theme) => theme.palette.primary.contrastText,
                            fontSize: {
                                xs: "1.6rem",
                                sm: "2rem",
                                md: "2.3rem",
                                lg: "2.6rem",
                            },
                            fontWeight: 700,
                            mb: 1,
                            "& span": {
                                color: (theme) => theme.palette.primary.main,
                            },
                        }}
                        >
                            <span>TraderGPT</span> Platform!
                        </Typography>
                        <Typography 
                            sx={{
                                mt: 2,
                                color: "#5E6282", 
                                fontSize: 17,
                                lineHeight: '28px',
                            }}
                        >
                            The cutting-edge trading platform that leverages the power of artificial intelligence 
                            to help you make informed trading decisions. Our platform uses state-of-the-art algorithms 
                            and machine learning models to analyze market data and provide you with real-time insights 
                            and predictions. Whether you're a seasoned trader or just starting out, TraderGPT.ai can 
                            help you optimize your trading strategy and achieve your financial goals.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        '& img': {
                            width: '100%',
                            maxWidth: {xs: '100%', md:360},
                            borderRadius: 6
                        }
                    }}>
                        <Image webp={imgWebp} src={imgjpg} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TextField;