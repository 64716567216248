import React, { useState } from "react";
import { t } from "locales";
import { logout } from "store/reducers/auth/asyncActions";
import { profile } from "routing/GeneralRoutes/routes";
import { StyledMenu } from "../styles";
import { getImageUrl } from "library/helper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Avatar, Button, Typography } from "@mui/material";
import { DownloadRounded, UploadRounded, LogoutRounded, PersonRounded, KeyboardArrowDown } from "@mui/icons-material";

const buttonStyles = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  gap: 1,
  py: 1.5,
};

const NavbarUser = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    // <Box
    //   onMouseEnter={() => setShowUserMenu(true)}
    //   onMouseLeave={() => setShowUserMenu(false)}
    //   sx={{
    //     position: "relative",
    //     display: { xs: "none", md: "flex" },
    //     alignItems: "center",
    //     backgroundColor: "#fff",
    //     color: "#222",
    //     padding: "3px 10px",
    //     borderRadius: "12px",
    //     margin: "0 7px",
    //     gap: 1,
    //     cursor: "pointer",
    //   }}
    // >
    //   <Box sx={{display: 'flex',alignItems: 'center', gap: 1}}>
    //     <Avatar src={getImageUrl(user?.avatar)} alt="" sx={{width: 36, height: 36}}>
    //       {user?.name?.[0]?.toUpperCase?.()}
    //     </Avatar>
    //     <Box>
    //       <Typography sx={{fontSize: 14, fontWeight: 500}}>{user?.name}</Typography>
    //       {/* <Typography sx={{opacity: 0.8, fontSize: 12}}>{user?.email?.slice(0, 20)}{user?.email?.length > 20 ? '...' : null}</Typography> */}
    //     </Box>
    //   </Box>
    //   {showUserMenu && (
    //     <Box
    //       onClick={() => setShowUserMenu(false)}
    //       sx={[
    //         {
    //           position: "absolute",
    //           top: "100%",
    //           borderRadius: "8px",
    //           minWidth: "110%",
    //           width: "fit-content",
    //           filter: "drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.06))",
    //           bgcolor: (theme) => (theme.palette.mode === "dark" ? "card.dark" : "card.light"),
    //           display: "flex",
    //           flexDirection: "column",
    //           overflow: "hidden",
    //         },
    //         (theme) => (theme.direction === "rtl" ? { left: 0 } : { right: 0 }),
    //       ]}
    //     >
    //       <ButtonBase
    //         as={Link}
    //         to="/profile?tab=overview"
    //         sx={{
    //           p: "10px 15px",
    //           justifyContent: "flex-start",
    //           gap: 1,

    //           borderBottom: (theme) => (theme.palette.mode === "dark" ? "1px solid #2a2e34" : "1px solid #eee"),
    //         }}
    //       >
    //         <Stack gap='8px' >

    //           {/* <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
    //             <Avatar src={getImageUrl(user?.avatar)} sx={{height: 30, width: 30}} alt="">
    //               {user?.name?.[0]?.toUpperCase?.()}
    //             </Avatar>
    //             <Typography variant="body1" sx={{ fontWeight: "500", whiteSpace: "nowrap" }}>
    //               {user?.name || "Unnamed"}
    //             </Typography>
    //           </Box> */}
    //           <Box sx={{
    //               position: 'relative',
    //               display: 'flex',
    //               alignItems: 'center',
    //               background: '#F3BA2F50',
    //               borderRadius: 24,
    //               width: 'fit-content',
    //               height: 30,
    //               '& img': {
    //                 position: 'absolute',
    //                 left: 0,
    //                 height: 30,
    //                 width: 30,
    //               }
    //             }}>
    //             <Avatar src={wallets?.[MAIN_COIN]?.icon} alt={wallets?.[MAIN_COIN]?.coin} />
    //             <Typography variant="body1" sx={{ fontWeight: "400", whiteSpace: "nowrap", pr: 2 }}>{wallets?.[MAIN_COIN]?.amount ? truncateToDecimals(wallets?.[MAIN_COIN]?.amount, 2) : 0} {wallets?.[MAIN_COIN]?.coin}</Typography>
    //           </Box>
    //         </Stack>
    //       </ButtonBase>

    //       <Box
    //         sx={{
    //           display: "flex",
    //           flexDirection: "column",
    //           justifyContent: "flex-start",
    //           "& > *": {
    //             display: "flex",
    //             alignItems: "center",
    //             gap: 1,
    //             p: "10px 15px !important",
    //             justifyContent: "flex-start !important",
    //             width: "100%",
    //             "&:hover": {
    //               opacity: 0.7,
    //             },
    //           },
    //         }}
    //       >
    //         <ButtonBase as={Link} to={profile.overview}>
    //           <PersonRounded fontSize="small" />
    //           <Typography variant="body2">{t("profile")}</Typography>
    //         </ButtonBase>
    //         <ButtonBase as={Link} to={profile.deposid}>
    //           <DownloadRounded fontSize="small" />
    //           <Typography variant="body2">{t("deposit")}</Typography>
    //         </ButtonBase>
    //         <ButtonBase as={Link} to={profile.withdraw}>
    //           <UploadRounded fontSize="small" />
    //           <Typography variant="body2">{t("withdraw")}</Typography>
    //         </ButtonBase>
    //         {/* <ButtonBase as={Link} to={profile.setting}>
    //           <SettingsRounded fontSize="small" />
    //           <Typography variant="body2">{t("setting")}</Typography>
    //         </ButtonBase> */}
    //         {/* <ButtonBase as={Link} to="/referral">
    //           <PersonAddAlt1Rounded fontSize="small" />
    //           <Typography variant="body2">
    //             {t("inviteFriendsNavLink")}
    //           </Typography>
    //         </ButtonBase> */}
    //         <ButtonBase onClick={logoutHandler} sx={{color: 'red', fill: 'red', '& svg': {'&:hover': {fill: 'red'}}}}>
    //           <LogoutRounded fontSize="small" />
    //           <Typography variant="body2">{t("logout")}</Typography>
    //         </ButtonBase>
    //       </Box>
    //     </Box>
    //   )}
    // </Box>
    <Box>
      <Button onClick={handleClick} endIcon={<KeyboardArrowDown />} sx={{ color: "#000" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Avatar src={getImageUrl(user?.avatar)} alt="" sx={{ width: 36, height: 36 }}>
            {user?.name?.[0]?.toUpperCase?.()}
          </Avatar>
          <Box sx={{ textAlign: "left" }}>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{user?.name}</Typography>
            <Typography sx={{ opacity: 0.8, fontSize: 11 }}>
              {user?.email?.slice(0, 16)}
              {user?.email?.length > 16 ? "..." : null}
            </Typography>
          </Box>
        </Box>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ p: 1, minWidth: 220 }}>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderBottom: "1px solid rgb(220,220,220)",
                gap: 1,
                pb: 1,
                mb: 1,
              }}
            >
              <Avatar src={getImageUrl(user?.avatar)} alt="" sx={{ width: 36, height: 36 }}>
                {user?.name?.[0]?.toUpperCase?.()}
              </Avatar>
              <Box sx={{ textAlign: "center" }}>
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{user?.name}</Typography>
                <Typography sx={{ opacity: 0.8, fontSize: 12 }}>{user?.email}</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              onClick={() => {
                navigate(profile.overview);
                handleClose();
              }}
              sx={buttonStyles}
            >
              <PersonRounded fontSize="small" />
              <Typography variant="body2">{t("profile")}</Typography>
            </Button>
            <Button
              onClick={() => {
                navigate(profile.deposid);
                handleClose();
              }}
              sx={buttonStyles}
            >
              <DownloadRounded fontSize="small" />
              <Typography variant="body2">{t("deposit")}</Typography>
            </Button>
            <Button
              onClick={() => {
                navigate(profile.withdraw);
                handleClose();
              }}
              sx={buttonStyles}
            >
              <UploadRounded fontSize="small" />
              <Typography variant="body2">{t("withdraw")}</Typography>
            </Button>
            <Button
              onClick={logoutHandler}
              sx={{
                color: "red",
                ...buttonStyles,
              }}
            >
              <LogoutRounded fontSize="small" />
              <Typography variant="body2">{t("logout")}</Typography>
            </Button>
          </Box>
        </Box>
      </StyledMenu>
    </Box>
  );
};

export default NavbarUser;
