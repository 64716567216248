import React from 'react';
import Header from './Components/Header';
import Explore from './Components/Explore';
import TradesAssets from './Components/TradesAssets';
import SuccessStories from './Components/SuccessStories';
import { Box } from '@mui/material';
import WhyTraderGPT from './Components/WhyTraderGPT';
import AIPowered from './Components/AIPowered';

const Services = () => {
    return (
        <Box className='container'>
            <Header />
            <WhyTraderGPT />
            <AIPowered />
            <Explore />
            <SuccessStories />
            <TradesAssets />
        </Box>
    );
};

export default Services;