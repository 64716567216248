import Balance from "./Balance";
import { Box } from "@mui/system";
import Plans from "./Plans";
import { useEffect } from "react";
import UserDetail from "../UserDetail";
import PlansTable from "./PlansTable";

const DashboardOverview = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          "& > div": {
            borderRadius: "24px",
            bgcolor: (theme) => `card.${theme.palette.mode}`,
            border: "1px solid",
            borderColor: "#eee",
          },
        }}
      >
        <Box sx={{ display: { xs: "block !important", md: "none !important" }, p: "20px" }}>
          <UserDetail />
        </Box>
        <Box sx={{ p: "20px 30px 30px 30px" }}>
          <Balance />
        </Box>
        <Box sx={{ p: "20px 30px 30px 30px" }}>
          {/* <Plans /> */}
          <PlansTable />
        </Box>
      </Box>
    </>
  );
};

export default DashboardOverview;
