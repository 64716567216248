export const mainRoutes = {
  base: "/",
  faq: "/faq",
  signals: '/ai-signals',
  services: '/services',
  recovery: "/recovery",
  presale: "/presale",
  contactUs: "/contact-us",
  termsOfUse: "/terms-of-use",
  TermsAndCondition: "/terms-and-condition",
  privacyAndPolicy: "/privacy-and-policy",
  trading: {
    base: "/trading",
    xtakerplatform: "/trading/Tradergpt-platform",
    orderManagement: "/trading/order-management",
    optionsTrading: "/trading/options-trading",
    AiTrading: '/trading/ai-trading',
  },
  marketplace: {
    base: "/marketplace",
  },
  investing: {
    base: "/investing",
  },
  resources: {
    base: "/resources",
  },
  features: {
    search: "/features/search",
    summary: "/features/summary",
    monitoring: "/features/monitoring",
    workflow: "/features/workflow",
  },
  plans: {
    base: "/plans",
  },
  TraderPortfolio: {
    base: '/trader',
    trader: (id) => `/trader/${id || ':id'}`
  }
};

export const auth = {
  base: "/auth",
  login: "/auth/login",
  register: "/auth/register",
  verify: "/auth/verify",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password",
};

export const profile = {
  base: "/profile/*",
  overview: "/profile/overview",
  deposid: "/profile/deposit",
  withdraw: "/profile/withdraw",
  inviteFriend: "/profile/invite-friend",
  plans: "/profile/plans",
  swap: "/profile/swap",
  notification: "/profile/notification",
  ticket: {
    base: "/profile/support-ticket",
    singleTicket: "/profile/ticket/:id"
},
  setting: "/profile/settings?tab=account",
};
