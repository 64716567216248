import React from "react";
import { styles } from "./styles";
import { Box, Grid, Typography } from "@mui/material";

const LeftSide = ({ data }) => {
  return (
      <Grid container spacing={3}>
        {data.map((item, i) => (
          <Grid item xs={12} md={4}>
            <Box key={`${i}-leftSideData`} sx={styles.cardWrapper}>
              <Typography sx={styles.cardTitle}>{item?.title}</Typography>
              <Typography sx={styles.cardDescription}>{item?.description}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
  );
};

export default LeftSide;
