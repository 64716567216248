import { Box, Grid, Typography } from "@mui/material";
import Highlights from "./Highlights";
import LeftSide from "./LeftSide";
import { styles } from "./styles";

const Benefits = () => {
  const data = {
    leftSide: [
      { title: "Open Platform", description: "Accept order flow from any EMS, OMS or algo provider via FIX." },
      { title: "Global Order Book", description: "Control the visibility of customer orders and pass ownership to another user, group or desk." },
      { title: "High-Touch Workflows", description: "Accept, manage and execute orders and conduct post-trade confirmations and allocations." },
      {
        title: "Execution Tools",
        description: "Leverage ADL®, Autospreader®, Tradergpt Order Types, broker algos and third-party algos for superior execution on dozens of colocated exchanges worldwide.",
      },
      {
        title: "FIX Routing",
        description: "Route orders via Tradergpt FIX Gateway to any number of destinations, including bank networks, private liquidity pools and third-party systems.",
      },
      {
        title: "Internalization",
        description: "Take advantage of Position Transfer or private matching engines to improve executions and avoid crossing.",
      },
    ],
    rightSide: [
      { description: "Dramatically improved transparency and accountability from consolidating all order management and execution on one platform." },
      { description: "Open architecture provides 100% seamless OMS integration with your existing trading operations." },
      { description: "Accounts and account hierarchy can be imported from your back office." },
      { description: "Receive alerts upon order rejects, unsolicited cancels, wash trades and more." },
      { description: "Automatically generate and send reports to customers on a schedule." },
      { description: "Generate compliance reports for various regulatory needs." },
    ],
  };
  return (
    <Box sx={{ mt: "60px" }} className="container">
      <Typography variant="h2" sx={styles.title}>
        Benefits
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <LeftSide data={data.leftSide} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Highlights data={data.rightSide} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Benefits;
