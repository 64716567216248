import logo from "assets/logo/logo-dark.png";
import { styles } from "./style";
import { mainRoutes } from "routing/GeneralRoutes/routes";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { Link, Typography, Divider, Box, Grid } from "@mui/material";
import { linksList, resourcesItems } from "./footerData";

const Footer = () => {
  const thisYear = new Date().getFullYear();
  const navigate = useNavigate();
  const scroll = (scrollTo) => {
    setTimeout(() => {
      navigate(scrollTo)
    }, 200)
  };

  return (
    <Box sx={styles.wrapper}>
      <Box className="container">
        <Grid container spacing={6} sx={{ mt: { xs: 4, md: 8 }, mb: 6 }}>
          <Grid item xs={12} sm={6} md={5}>
              <Box sx={styles.footerImage}>
                <img src={logo} alt="website logo" />
              </Box>
              <Typography sx={{fontSize: 14, color: "#DDDDDD", maxWidth: {xs: 'auto', md: 400}}}>
                We're passionate about using technology to empower traders and help them achieve their financial goals. 
                Our team of experts in finance and artificial intelligence have come together to create a platform that 
                uses the latest advances in AI to help traders make better, more informed decisions.
              </Typography>
              <Typography sx={{fontSize: 12, color: "#AAA", maxWidth: {xs: 'auto', md: 400}, mt: 3}}>
                Fargolab LTD Registered in England and Wales<br/>
                Registration Number :14233912<br/>
                Address:2 Frederick Street, Kings Cross, London, United Kingdom, WC1X 0ND<br/><br/>
                Incorporated on :<br/> 14 July 2022<br/><br/>
                Category:<br/>58290 - Software publishing
              </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography sx={styles.title}>TraderGPT</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {linksList[0]?.list?.map((item) => (
                <Link 
                  sx={styles.linkStyle} 
                  as={RouterLink} 
                  
                  to={item?.link}
                  onClick={!!item?.scrollTo ? () => scroll(`${item?.link}${item?.scrollTo}`) : () => {}}
                >
                  {item.title}
                </Link>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography sx={styles.title}>Resource</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {resourcesItems?.map((item) => (
                <Link 
                  sx={styles.linkStyle} 
                  as={RouterLink} 
                  to={item?.link} 
                >{item.name}</Link>
              ))}
            </Box>
          </Grid>
        </Grid>

        {/* <Grid container spacing={3} sx={{ mt: { xs: 4, md: 8 }, mb: { xs: 3, md: 6 } }}>
          <Grid item xs={12} md={3}>
            
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={styles.footerDescription}>
              Tradergpt is a Web3 platform which allows people to make their earnings based on market predictions. Our long-term goal is to find and fund the best traders out there.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="row" flexWrap="wrap" spacing={1} mt={1} justifyContent={{ xs: "center", md: "center" }}>
              {socialLinks.map(({ key, icon, link }) => (
                <a href={link} key={key} target="_blank" rel="noreferrer">
                  <Box
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0)",
                      width: "42px",
                      height: "42px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      transition: "all 0.2s linear",
                      "& path": {
                        transition: "all 0.2s linear",
                        fill: "#808080",
                      },
                      "& svg": {
                        fontSize: 30,
                      },
                      "&:hover path": {
                        fill: "#FFF",
                      },
                    }}
                  >
                    {SocialIcon(icon)}
                  </Box>
                </a>
              ))}
            </Stack>
          </Grid>
        </Grid> */}

        <Divider sx={styles.divider} />
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: { xs: "column", md: "row" }, gap: 2, mt: 1 }}>
          <Typography sx={styles.copyrightText}>Copyright © {thisYear} Trader GPT All rights reserved.</Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Link sx={styles.linkStyle} onClick={() => navigate(mainRoutes.privacyAndPolicy)}>
              Privacy Policy
            </Link>
            <Link sx={styles.linkStyle} onClick={() => navigate(mainRoutes.termsOfUse)}>
              Terms of use
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
