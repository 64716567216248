import React, { useEffect, useState } from "react";
import {
  ButtonBase,
  Typography,
  CircularProgress,
  TextField,
  InputAdornment,
  FormControlLabel,
  FormControl,
  Checkbox,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { subscribePlanValidation } from "library/validations/xtakersValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorAlert from "components/ErrorAlert";
import CustomButton from "components/CustomButton";
import _ from "lodash";
import usdtIcon from "assets/icons/cryptoicon/usdt.png";
import { t } from "locales";
import { openToast } from "components/Toast";
import { XTAKER_COIN } from "library/constants";
import TraderSelect from "./TraderSelect";
import { getHumanError } from "library/translateServerErrors";
import FundAccountModal from "components/WalletActions/FundAccount";
import { fixNumber, getImageUrl } from "library/helper";
import noImage from "assets/images/no_image.png";
import { useAddXtakerPlan, useGetSinglePlan } from "_hooks/Xtakers";

const SubscribeModal = ({ closeModal = null, title, id }) => {
  const { mutateAsync: postPlan, isError, error } = useAddXtakerPlan();
  const { data: plan, isFetching: isLoading } = useGetSinglePlan(id);
  const mainBalance = useSelector((state) => state.auth.wallets?.[XTAKER_COIN]);
  const modal = useSelector((state) => state.app.modal);
  const [endDate, setEndDate] = useState(0);
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      initialAmount: 0,
      agree: false,
      // trader: null,
    },
    resolver: yupResolver(subscribePlanValidation(plan?.minInvestment, plan?.maxInvestment, mainBalance?.amount || 0)),
  });
  const enteredAmount = watch("initialAmount");
  const agreeChecked = watch("agree");

  useEffect(() => {
    const now = new Date();
    now.setDate(now.getDate() + plan?.duration);

    setEndDate(
      now.toLocaleString("en-us", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    );
  }, [plan?.duration]);

  const submitHandler = async (data) => {
    try {
      const planData = {
        amount: data?.initialAmount,
        moneyStakePlanId: plan?.id,
      };

      await postPlan(planData);

      openToast("success", "Plan subscribed successfully");
      modal?.hide();
      navigate("/profile/plans");
    } catch (err) {
      console.log(err);
      openToast("error", getHumanError(err));
    }
  };

  const openFundAccountModal = () => {
    navigate("/profile/deposit");
    // modal.show(<FundAccountModal onClose={modal.hide} />);
    closeModal();
  };
  const setBalanceAsAmount = () => setValue("initialAmount", mainBalance?.amount);
  const setMinAsAmount = () => setValue("initialAmount", plan?.minInvestment);

  console.log({ errors });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflow: "initial",
        gap: 3,
        minWidth: { xs: "90vw", sm: "60vw", md: "50vw", lg: "650px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          img: { width: 32, height: 32, mr: 1.5 },
          p: "20px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <img src={getImageUrl(plan?.icon) || noImage} alt="" />
          <Typography variant="body1" sx={{ lineHeight: "2" }}>
            {t("Subscribe ") + (plan?.title || title)}
          </Typography>
        </Box>

        <ButtonBase
          onClick={closeModal}
          sx={{
            width: "38px",
            height: "38px",
            borderRadius: "6px",
            border: "1px solid",
            borderColor: (theme) => (theme.palette.mode === "dark" ? "#2a2e34" : "#eee"),
          }}
        >
          <Close fontSize="small" />
        </ButtonBase>
      </Box>

      {isError && (
        <Box sx={{ width: "100%", px: "20px" }}>
          <ErrorAlert text={error} />
        </Box>
      )}

      {!isLoading ? (
        <Box as="form" onSubmit={handleSubmit(submitHandler)}>
          <Box
            sx={{
              gap: 3,
              p: "24px 30px",
            }}
          >
            <Typography variant="body1" sx={{ opacity: 0.7, marginBottom: "8px" }}>
              Profit and Lock-up period
            </Typography>
            <Box
              sx={{
                border: (theme) => `1px solid ${theme.palette.primary.main}`,
                backgroundColor: (theme) => theme.palette.primary.main + "20",
                borderRadius: 3,
                py: 1,
                px: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                width: "fit-content",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bolder",
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {plan?.minProfit} - {plan?.maxProfit}%
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bolder",
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {plan?.duration} days
              </Typography>
            </Box>
            {/* <Typography variant="body1" sx={{ opacity: 0.7, mt: 3 }}>
              Plan name
            </Typography>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  error={errors?.name}
                  helperText={t(errors?.name?.message)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  placeholder={"Enter a name for your plan"}
                  id="name"
                  // label={t("name")}
                  name="name"
                  autoComplete="name"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  sx={{
                    mt: 1,
                    width: "100%",
                    "& fieldset": {
                      borderRadius: "6px !important",
                    },
                    input: { p: 1, boxSizing: "border-box", height: 46 },
                  }}
                />
              )}
            /> */}
            <Typography variant="body1" sx={{ opacity: 0.7, mt: 2 }}>
              Subscription amount
            </Typography>
            <Controller
              control={control}
              name="initialAmount"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  error={errors?.initialAmount}
                  helperText={t(errors?.initialAmount?.message)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  placeholder={`Minimum ${plan?.minInvestment}`}
                  id="initialAmount"
                  name="initialAmount"
                  autoComplete="initialAmount"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {XTAKER_COIN}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    mt: 1,
                    border: "1px solid #dddddd",
                    width: "100%",
                    "& fieldset": {
                      borderRadius: "6px !important",
                    },
                    input: { p: 1, boxSizing: "border-box", height: 46 },
                  }}
                />
              )}
            />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography variant="body2" sx={{ display: "inline", opacity: 0.7 }}>
                  Limit:{" "}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ display: "inline", cursor: errors?.initialAmount?.message ? "default" : "pointer" }}
                  onClick={errors?.initialAmount?.message ? () => false : setMinAsAmount}
                >
                  {fixNumber(plan?.minInvestment, 3, true)} {XTAKER_COIN}
                </Typography>
              </Box>
              <Stack direction="row" alignItems="center" gap={1}>
                <Box>
                  <Typography variant="body2" sx={{ display: "inline", opacity: 0.7 }}>
                    Balance:{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ display: "inline", cursor: errors?.initialAmount?.message ? "default" : "pointer" }}
                    onClick={errors?.initialAmount?.message ? () => false : setBalanceAsAmount}
                  >
                    {fixNumber(mainBalance?.amount, 3, true) || "0.00"} {XTAKER_COIN}{" "}
                  </Typography>
                </Box>

                <Typography
                  onClick={openFundAccountModal}
                  variant="body2"
                  sx={{
                    fontWeight: "500",
                    color: (theme) => theme.palette.primary.main,
                    display: "inline",
                    cursor: "pointer",
                  }}
                >
                  Add funds
                </Typography>
              </Stack>
            </Box>

            {/* <Stack gap={1}>
              <Typography variant="body1" sx={{ opacity: 0.7, mt: 2 }}>
                Trader
              </Typography>

              <Stack>
                <Controller
                  control={control}
                  name="trader"
                  render={({ field: { onChange, value, ref } }) => <TraderSelect options={traders?.list || []} onChange={onChange} value={value} ref={ref} />}
                />
                {!!errors?.trader && <FormHelperText sx={{ color: "#f44336" }}>{errors?.trader?.id?.message || errors?.trader?.message}</FormHelperText>}
              </Stack>
            </Stack> */}
          </Box>
          <Box
            sx={{
              backgroundColor: (theme) => (theme.palette.mode === "dark" ? theme.palette.background.dark : "#f8f8f8"),
              padding: "24px 30px 30px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1" sx={{ opacity: 0.7 }}>
                Total Profit
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {plan?.minProfit} - {plan?.maxProfit}%
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "3px",
              }}
            >
              <Typography variant="body1" sx={{ opacity: 0.7 }}>
                Expected earnings
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {!enteredAmount
                  ? `0 ${XTAKER_COIN}`
                  : `${fixNumber((enteredAmount * plan?.minProfit) / 100, 3, true)} - ${fixNumber(
                      (enteredAmount * plan?.maxProfit) / 100,
                      3,
                      true
                    )} ${XTAKER_COIN}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "3px",
              }}
            >
              <Typography variant="body1" sx={{ opacity: 0.7 }}>
                Earnings release date
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {endDate}
              </Typography>
            </Box>
            <Box mt={2}>
              <FormControl sx={{ label: { margin: "auto" } }}>
                <Controller
                  control={control}
                  name="agree"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          name="agree"
                          id="agree"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          sx={{
                            "&.MuiCheckbox-root ": {
                              padding: "0 9px 0 0",
                            },
                          }}
                        />
                      }
                      label={
                        <Box>
                          <Typography variant="body1" sx={{ display: "inline" }}>
                            I accept the {t("appName")}{" "}
                          </Typography>
                          <Typography
                            as={Link}
                            target="_blank"
                            to="/terms-and-condition"
                            variant="body1"
                            sx={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              color: "primary.main",
                              display: "inline",
                            }}
                          >
                            Terms of Use.{" "}
                          </Typography>
                        </Box>
                      }
                    />
                  )}
                />
              </FormControl>
            </Box>
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isSubmitting}
              // disabled={true}
              disabled={!_.isEmpty(errors, true) || !agreeChecked}
              loaderHeight="40"
              extraSx={{
                marginTop: 3,
                borderRadius: "0 !important",
                height: "46px",
              }}
              withSpinner
            >
              {t("Confirm Subscription")}
            </CustomButton>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: 92,
          }}
        >
          <CircularProgress size={36} />
        </Box>
      )}
    </Box>
  );
};

export default SubscribeModal;
