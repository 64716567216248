import { isValid } from "date-fns";
import * as yup from "yup";

export const connectWalletValidation = yup.object({
  email: yup.string().required("emailRequired").email("emailNotValid"),
  password: yup
    .string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
      "passwordNotValid"
    )
    .required("passwordRequired"),
  // captcha: yup.string().required("captchaError").nullable(),
});

export const verifyConnectWalletValidation = yup.object({
  code: yup
    .string()
    .matches(/^\d{4}$/, "codeMin")
    .required("codeRequired"),
});

export const putForSaleValidation = yup.object({
  basePrice: yup.string().required("requiredField"),
  immediatePrice: yup.string().required("requiredField"),
  bookingPrice: yup.string().required("requiredField"),
  start: yup
    .string()
    .test("startTime", "invalidTimeFormat", (value) => {
      return !!value && value !== "Invalid Date" && isValid(new Date(value));
    })
    .required("requiredField")
    .nullable(),
  end: yup
    .string()
    .test("startTime", "invalidTimeFormat", (value) => {
      return !!value && value !== "Invalid Date" && isValid(new Date(value));
    })
    .required("requiredField")
    .nullable(),
});

export const putOfferValidation = yup.object({
  amount: yup.number().typeError("mustBeNumber").required("requiredField"),
});

// export const swapValidation = yup.object({
//   from: yup.number().typeError("mustBeNumber").required("requiredField"),
//   to: yup.number().typeError("mustBeNumber").required("requiredField"),
// });

export const getSwapValidation = (min, max, balance) => {
  return yup.object({
    from: yup
      .number()
      .min(parseFloat(min), "minError")
      .max(
        max < balance ? max : balance,
        `${max < balance ? "maxError" : "balanceError"}`
      )
      .typeError("mustBeNumber")
      .required("requiredField"),
    to: yup.number().typeError("mustBeNumber").required("requiredField"),
  });
};
