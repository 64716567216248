import React from 'react';
import Image from 'react-image-webp';
import signalPic from '../../assets/tradergpt-16.png';
import signalPicWebp from '../../assets/tradergpt-16.webp';
import { styles } from './styles';
import { mainRoutes } from 'routing/GeneralRoutes/routes';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';

const Explore = () => {
    const navigate = useNavigate();
    const scroll = (scrollTo) => {
        setTimeout(() => {
          navigate(scrollTo)
        }, 300)
    }
    return (
        <Box sx={styles.wrapper}>
            <Grid container spacing={2} direction='row' alignItems='center'>
                <Grid item xs={12} md={6}>
                    <Box data-aos='zoom-in' data-aos-delay='200' data-aos-duration='600' sx={styles.imageWrapper}>
                        <Image src={signalPic} webp={signalPicWebp} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.textsWrapper}>
                        <Typography data-aos='zoom-in' data-aos-delay='200' data-aos-duration='600' sx={styles.titletext}><span>Explore Our</span> VIP Learning Hub</Typography>
                        <Typography data-aos='zoom-in' data-aos-delay='200' data-aos-duration='600' sx={styles.descriptionText}>Explore our educational courses tailored for traders at all levels, engage with live trading sessions, and gain exclusive access to our VIP Channel for comprehensive technical analysis and signals. Enhance your trading strategy and performance with TraderGPT’s Premium Suite.</Typography>
                        <Button data-aos='zoom-in' data-aos-delay='200' data-aos-duration='600' sx={styles.button} onClick={() => scroll(`${mainRoutes.base}#plans`)}>
                            Join US Now
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Explore;