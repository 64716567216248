import React from "react";
import { styles } from "./styles";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { ChangeHistory, Diamond, HourglassBottomOutlined } from "@mui/icons-material";

const AdvancedOrder = () => {
  const smallDevice = useMediaQuery("(max-width:904px)");

  const data = [
    {
      id: 1,
      icon: <HourglassBottomOutlined fontSize="large" />,
      title: "Stitching",
      description: "Combine buy orders for one expiry with sell orders for another expiry and execute as an exchange-listed spread.",
    },
    {
      id: 2,
      icon: <ChangeHistory fontSize="large" />,
      title: "Splitting",
      description: "Split orders apart and stitch together with orders of equal quantity to execute as spreads. Then execute the leftover contracts as outrights.",
    },
    {
      id: 3,
      icon: <Diamond fontSize="large" />,
      title: "Lock and Release",
      description: "Completely fill a customer’s order before releasing fills and execution reports.",
    },
  ];
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.titleWrapper}>
        <Typography sx={styles.title} data-aos="fade-up" data-aos-duration="500">
          02 ADVANCED ORDER HANDLING
        </Typography>
        <Typography sx={styles.subTitle} data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
          Combine orders into spreads for more efficient executions.
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={smallDevice ? 3 : 1}>
          {data?.map(({ id, title, icon, description }) => (
            <Grid item xs={12} md={4} key={id} data-aos="fade-up" data-aos-duration="500" data-aos-delay={id * 50 + 100}>
              <Box sx={styles.planWrapper}>
                <Box sx={styles.iconWrapper}>{icon}</Box>
                <Box>
                  <Typography sx={styles.planTitle}>{title}</Typography>
                  <Typography sx={styles.planDescription}>{description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AdvancedOrder;
