const pages = {
  // dashboard ::
  getPaid: "Get Paid $25/mo per Signup",
  advTrader: "Advance Trader Terminal",
  newMO: "New Member Orientation",
  startTour: "Start Tour",
  launch: "Launch",
  joinOC: "Join Our Community",
  telegram: "Telegram",
  instagram: "Instagram",

  // dashboard cards titles :
  trustedExchangeAB: "TRUSTED EXCHANGES & BROKERS",
  premiumSignals: "PREMIUM SIGNALS",
  academy: "ACADEMY",
  topUsers: "Top Users",
  tradingCryptoTerminal: "TRADING CRYPTO TERMINAL",
  autoTradeCopier: "AUTO TRADE COPIER",
  cryptoMarketplace: "CRYPTO MARKET PLACE",
  dailyCryptoNews: "DAILY CRYPTO NEWS",
  cryptoMarket: "CRYPTO MARKET",
  allSignals: "All Signals",
  allCourses: "All Courses",
  // dashboard cards contents :
  advanceCryptoAcademy: "Advance Crypto Academy",
  cryptoAcademyText: "Get the skills you need to succeed.",
  cryptoMastryTitle: "Crypto Mastery Program",
  cryptoMastryText: "Exclusive 1-on-1 Program with Neno.",

  cryptoTerminalTitle: "Crypto Terminal",
  cryptoTerminalText: "Advance Crypto Analysis Terminal.",
  chartAnalyzerTitle: "Chart Analyzer",
  chartAnalyzerText: "Advance Chart with Analysis Tools.",
  marketCapTitle: "Market Cap",
  marketCapText: "Cryptocurrency Market Capitalizations.",
  marketScreenerTitle: "Market Screener",
  marketScreenerText: "Filter the Market to find assets.",
  autoTradeCardText:
    "Soon, you will be able to copy every trade made by Neno automatically with the Advance Auto Trade Copier. The easiest way to profit without lifting a finger. Stay tuned for this features and make sure to follow us on Instagram for updates.",
  buyCryptoHere: "BUY CRYPTO HERE",
  enterAmount: "Enter amount",
  buy: "Buy",

  // portfolio ::
  connectPortfolio: "CONNECT PORTFOLIO",
  allAssets: "All Assets",
  managePortfolios: "Manage Portfolios",
  holdings: "holdings",
  nft: "nft",
  openPositions: "open positions",
  charts: "charts",
  orders: "orders",
  trades: "trades",
  transactions: "transactions",
  openOrders: "open orders",
  syncAll: "Sync All",
  moreActions: "MORE ACTIONS",
  addTransaction: "Add Transaction",
  deposite: "Deposite",

  // tradeSignals ::
  signals: "SIGNALS",
  premiumTradingSignals: "PREMIUM TRADING SIGNALS",
  advanceSignalTracker: "ADVANCE SIGNAL TRACKER",
  dateTime: "Date:Time",
  action: "Action",
  pair: "Pair",
  OP: "OP",
  TP: "TP",
  SL: "SL",
  exchange: "Exchange",

  take: "Take",
  stop: "Stop",
  open: "Open",

  binanceFutures: "Binance Futures",
  tradeNow: "Trade Now",

  // tradersTools ::
  // affiliatePortal ::
  paymentsHistory: "PAYMENTS HISTORY",
  signupsHistory: "SIGNUPS HISTORY",
  referralDate: "Referral Date",
  commissionValue: "Commission Value",
  paymentDate: "Payment Date",
  method: "Method",
  amount: "Amount",
  affiliateLinkTitle: "AFFILIATE LINK",
  affiliateLinkText1:
    "Share this link to your friend or on social media and get paid $25 reccuring commission EVERY MONTH for every monthly signups. As for yealy signups, we offer a one time commision of $100.",
  affiliateLinkText2:
    "Payment to our affiliates are usually done on the first of every month. Please provide paypal, or bitcoin wallet address to receive payment for commission .",
  referralHint:
    "You may also share your affiliate ID to ensure sponsoring incase cookies are disabled",
  copyUrl: "Copy Url",
  saveAddress: "Save Address",

  monthlyClicks: "MONTHLY CLICKS",
  totalClicks: "TOTAL CLICKS",
  monthlySignups: "MONTHLY SIGNUPS",
  totalSignups: "TOTAL SIGNUPS",
  totalPaidOut: "TOTAL PAID OUT",
  owingAmount: "OWING AMOUNT",

  // profile ::
  accountSettings: "Account Settings",
  walletDetail: "wallet detail",
};

export default pages;
