import React from "react";
import { styles } from "./styles";
import { Card } from "./Cards";
import { Box, Grid, Typography } from "@mui/material";

const Roadmap = () => {
  const roadMapData = [
    {
      id: 1,
      sesion: "2022.Q1",
      description: "Tradergpt was Established in 2022, with Headquarter in Dubai",
    },
    {
      id: 2,
      sesion: "2022.Q2",
      description: "One of the first successful Ai Machine Signal sold to large quant funds will billions under management.",
    },
    {
      id: 3,
      sesion: "2022.Q3",
      description: "Tradergpt starts trading real money since 2022 with strategies powered by proprietary ML signal.",
    },
    {
      id: 4,
      sesion: "2022.Q4",
      description: "Tradergpt passed 2 years trading experience with over 80% winning rate.",
    },
    {
      id: 5,
      sesion: "2023",
      description: "Tradergpt started to open the AI to public.",
    },
  ];
  return (
    <Box sx={styles.wrapper}>
      <Typography sx={styles.title}>
        History of <span>Tradergpt</span> Financial
      </Typography>
      <Box sx={styles.roadmapWrapper}>
        <Grid container>
          {roadMapData?.map((roadmap) => (
            <Grid item xs={12} sm={3} md={2.4}>
              <Card data={roadmap} length={roadMapData?.length} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Roadmap;
