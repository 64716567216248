import { AddRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import { useSelector } from "react-redux";
import NewTicket from "./NewTicket";
import TicketsList from "./TicketsList";
import { styles } from "./styles";
import { useEffect } from "react";

const Ticket = () => {
  const modal = useSelector((state) => state.app.modal);
  const openNewTicketModal = () => modal.show(<NewTicket onClose={modal.hide} />);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant="h6" sx={styles.mainTitle}>
            {t("tickets")}
          </Typography>
          <Box onClick={openNewTicketModal} sx={styles.newTicketBtn}>
            <AddRounded />
            <Typography component="span" variant="body1">
              {t("newTicket")}
            </Typography>
          </Box>
        </Box>
        <Box>
          <TicketsList />
        </Box>
      </Box>
    </Box>
  );
};

export default Ticket;
