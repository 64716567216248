import { Stack, IconButton } from "@mui/material";
import { AccountBalanceWalletRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { toggleWalletSidebar } from "store/reducers/app/appSlice";

const NavbarWallet = () => {
  const dispatch = useDispatch();

  const toggleHandler = () => dispatch(toggleWalletSidebar());

  return (
    <Stack
      onClick={toggleHandler}
      sx={{ color: "navbarIcon.default", cursor: "pointer" }}
      id="navbar-wallet-icon"
    >
      <IconButton aria-label="settings">
        <AccountBalanceWalletRounded />
      </IconButton>
    </Stack>
  );
};

export default NavbarWallet;
