import { useState } from "react";
import { t } from "locales";
import DataTable from "components/DataTable";
import { useSelector } from "react-redux";
import { Pagination, Typography, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { SkeletonLoader } from "components/Skeleton";
import { useGetRawTradeHistory, useGetTradeHistory } from "_hooks/Xtakers";
import { CircleSkeleton } from "components/Skeleton";
import noImage from "assets/images/no_image.png";
import { fixNumber, getImageUrl } from "library/helper";
import { XTAKER_COIN } from "library/constants";
import NoData from "components/NoData";

const LOADING_LIST = Array(5).fill({
  time: "",
  coin: "",
  amount: "",
  status: "",
  depositAddress: "",
  txid: "",
});

const TradesHistory = () => {
  const [page, setPage] = useState(1);
  const isRtl = useSelector(
    (state) => state.setting.lang === "fa" || state.setting.lang === "ar"
  );
  const { data: historyData, isLoading: historyLoading } = useGetTradeHistory({
    page,
  });
  const { data: rawHistoryData, isLoading: rawHistoryLoading } =
    useGetRawTradeHistory({ page });

  const onPageChange = (_, value) => setPage(value);

  const loading = historyLoading || rawHistoryLoading;
  const tableData = historyData?.lenght ? historyData : rawHistoryData;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        borderRadius: "24px",
        bgcolor: (theme) => `card.${theme.palette.mode}`,
        border: "1px solid",
        borderColor: "#eee",
        p: "20px",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "600" }}>
        {t("tradesHistory")}
      </Typography>
      <Box>
        <DataTable
          columns={[
            {
              title: `${t("asset")} ( ${t("fromTo")} )`,
              field: "asset",
              render: ({ from, to }) => {
                const fromCoin = from?.coin;
                const toCoin = to?.coin;
                const fromIcon = getImageUrl(from?.icon) ?? noImage;
                const toIcon = getImageUrl(to?.icon) ?? noImage;

                return loading ? (
                  <Stack direction="row" alignItems="center">
                    <CircleSkeleton isLight radius="20" />
                    <CircleSkeleton
                      style={{
                        transform: isRtl
                          ? "translatex(+50%)"
                          : "translatex(-50%)",
                      }}
                      isLight
                      radius="20"
                    />
                    <SkeletonLoader
                      isLight
                      height="15"
                      width="50"
                      borderRadius="2"
                    />
                  </Stack>
                ) : (
                  <Stack direction="row" alignItems="center">
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        ".asset-from, .asset-to": {
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        },

                        ".asset-to": {
                          transform: "translateX(-50%)",
                        },
                      }}
                    >
                      <img className="asset-from" src={fromIcon} alt="" />
                      <img className="asset-to" src={toIcon} alt="" />
                    </Stack>
                    <Typography variant="body2">{`${fromCoin}/${toCoin}`}</Typography>
                  </Stack>
                );
              },
            },
            {
              title: t("type"),
              field: "type",
              render: ({ type }) =>
                loading ? (
                  <SkeletonLoader
                    isLight
                    height="15"
                    width="35"
                    borderRadius="2"
                  />
                ) : (
                  t(type)
                ),
            },

            {
              title: t("yourProfit"),
              field: "totalProfit",
              render: ({ totalProfit }) =>
                loading ? (
                  <SkeletonLoader
                    isLight
                    height="15"
                    width="60"
                    borderRadius="2"
                  />
                ) : (
                  <Stack direction="row" alignItems="center" spacing={0.8}>
                    <Typography variant="body2">
                      {fixNumber(totalProfit, 6)}
                    </Typography>
                    <Typography variant="body2">{XTAKER_COIN}</Typography>
                  </Stack>
                ),
            },
            {
              title: t("status"),
              field: "profitPercentage",
              render: ({ profitPercentage: percent }) => {
                return loading ? (
                  <SkeletonLoader
                    isLight
                    height="15"
                    width="35"
                    borderRadius="2"
                  />
                ) : (
                  <Typography variant="body2">
                    {percent > 0 ? "+" : "-"}
                  </Typography>
                );
              },
            },
          ]}
          data={loading ? LOADING_LIST : tableData?.list}
          rowClass="py-2"
          headClass="table-head"
        />
        {!loading && tableData?.list?.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 5,
            }}
          >
            <NoData />
          </Box>
        ) : (
          <Box sx={{ mx: "auto", width: "fit-content", mt: 3 }}>
            <Pagination
              sx={{ width: "fit-content" }}
              count={tableData?.total || 1}
              page={page}
              onChange={onPageChange}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TradesHistory;
