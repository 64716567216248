import { Box, Typography } from "@mui/material";
import { styles } from "./styles";
import chart from "assets/images/chart.png";
const HowDoesItWork = () => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.containerBox}>
        <Typography variant="h2" sx={styles.title}>
          HOW DOES Tradergpt OMS FIT WITHIN Tradergpt?
        </Typography>
        <img src={chart} alt="chart" style={styles.image} />
      </Box>
    </Box>
  );
};
export default HowDoesItWork;
