import React from "react";
import { styles } from "./styles";
import { Box, Typography } from "@mui/material";

const Highlights = ({ data }) => {
  return (
    <Box sx={styles.cardWrapper}>
      <Typography sx={styles.cardTitle}>Highlights</Typography>
      <Box style={styles.ul}>
        {data?.map((item, i) => (
          <Box key={`${i}-Highlights`} sx={styles.li} className="highlightsItem">
            <Box className="wrapper">
              <Typography sx={styles.cardDescription}>
                {item?.description}
              </Typography>
            </Box>
          </Box>  
        ))}
      </Box>
    </Box>
  );
};

export default Highlights;
