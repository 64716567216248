import SocketService from "./socketService";
import { useEffect, useRef } from "react";
import { SOCKET_URL } from "config/Config";
import { useSelector } from "react-redux";
import { SocketContext } from "./socket";
import { PRIVATE_EVENTS } from "_hooks/app/useUserSocket";

const SocketProvider = ({ children }) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const socket = useRef(new SocketService(SOCKET_URL, accessToken));

  useEffect(() => {
    if (!!accessToken && socket?.current?.accessToken !== accessToken) {
      socket.current?.refreshAccessToken(accessToken);
    }

    if (!accessToken) {
      PRIVATE_EVENTS.forEach((event) => {
        socket.current?.removeListener(event);
      });
    }
  }, [accessToken]);

  return (
    <SocketContext.Provider value={{ socket: socket.current }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
