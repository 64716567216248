export const styles = {
    card: {
        px: 4,
        py: 2,
        borderRadius: "24px",
        bgcolor: "#FFF",
        border: "1px solid rgba(0,0,0,0.1)",
        boxShadow: "none",
        transition: "all 0.2s ease-out",
        '&:hover': {
            border: "1px solid transparent",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
    },
    cardTitle: {
        fontSize: 16,
        fontWeight: 600,
    },
    blueCardTitle: {
        fontSize: 16,
        fontWeight: 600,
        color: (theme) => theme.palette.primary.main,
    },
    cardsubTitle: {
        fontSize: 14,
        color: "#5E6282"
    }
};