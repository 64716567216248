import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "locales";
import React from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { termsAndConditionContent } from "pages/TermsAndCondition/termsAndCondition";
import { Link } from "react-router-dom";

const TermsOfUsePage = () => {
  const lang = useSelector((state) => state.setting.lang);

  const headerContent = termsAndConditionContent.header;
  const bodyContent = termsAndConditionContent.body[lang] || termsAndConditionContent.body.en;
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Tradergpt</title>
        <meta name="description" content="Terms and Conditions | Tradergpt" />
      </Helmet>
      <Box sx={{ display: "flex", flexDirection: "column", my: 5 }} className="container">
        {/* <Box sx={{ mb: 3 }}>
          <Typography variant="h3" sx={{ mb: 3 }}>
            {t(headerContent.title)}
          </Typography>
          <Typography variant="body1">{t(headerContent.desc)}</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {bodyContent?.map?.(({ title, items }) => (
            <Box
              sx={{
                bgcolor: (theme) => `card.${theme.palette.mode}`,
                p: "20px 10px",
                borderRadius: "5px",
                li: {
                  mb: 1,
                  listStyleType: "square",
                },
              }}
            >
              <Typography variant="h4" sx={{ mb: 2 }}>
                {t(title)}
              </Typography>
              <Box sx={{ pl: 1 }}>
                {items?.map?.((item) =>
                  typeof item === "string" ? (
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {t(item)}
                    </Typography>
                  ) : (
                    <>
                      {item.title && (
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          {t(item.title)}
                        </Typography>
                      )}
                      {!!item?.list ? (
                        <ul>
                          {item?.list?.map?.((item) => (
                            <li>
                              <Typography variant="body2">{t(item)}</Typography>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        item.items?.map?.((item) =>
                          typeof item === "string" ? (
                            <Typography variant="body2" sx={{ mb: 2 }}>
                              {t(item)}
                            </Typography>
                          ) : (
                            <>
                              {item.title && (
                                <Typography variant="body1">
                                  {t(item.title)}
                                </Typography>
                              )}
                              {!!item?.list && (
                                <ul>
                                  {item?.list?.map?.((item) => (
                                    <li>
                                      <Typography variant="body2">
                                        {t(item)}
                                      </Typography>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </>
                          )
                        )
                      )}
                    </>
                  )
                )}
              </Box>
            </Box>
          ))}
        </Box> */}

        <Box sx={{ mb: 3 }}>
          <Typography variant="h3" sx={{ mb: 3 }}>
            Terms Of Service
          </Typography>
        </Box>

        <Typography sx={{ mb: 2 }}>
          fargo lab, Inc., and/or its affiliates (“we,” “our,” or “us”) provides its software services through its website located at{" "}
          <span>
            <Link to="/" style={{ textDecoration: "none" }}>
              Tradergpt.ai
            </Link>
          </span>{" "}
          and related mobile applications and products (collectively the “Services” or “Tradergpt”). Before using our Services, please read the Terms of Service (the “Terms”) carefully, along with any
          other policies or notices on our website or mobile applications.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          AGREEMENT TO TERMS
        </Typography>
        <Typography sx={{ mb: 2 }}>
          By accessing or using any or all of the Services, you expressly acknowledge that (i) you have read and understood these Terms; (ii) you agree to be bound by these Terms; and (iii) you are
          legally competent to enter into these Terms. If you do not agree to be bound by these Terms or any updates or modifications to these Terms, you may not access or use our Services. WE DO NOT
          PROVIDE INVESTMENT OR FINANCIAL ADVICE OR CONSULTING SERVICES. WE ARE SOLELY THE PROVIDER OF Tradergpt ANDWE DO NOT ADVISE OR MAKE RECOMMENDATIONS ABOUT ENGAGING IN DIGITAL ASSET
          TRANSACTIONS OR OPERATIONS. DECISIONS TO ENGAGE IN TRANSACTIONS OR PERFORM OPERATIONS INVOLVING DIGITAL ASSETS SHOULD BE TAKEN ON YOUR OWN ACCORD.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          PRIVACY POLICY
        </Typography>
        <Typography sx={{ mb: 2 }}>
          For an explanation on how we collect, use and disclose information from our users please see our Privacy Policy at{" "}
          <span>
            <Link to="https://Tradergpt.ai/privacy_and_policy" style={{ textDecoration: "none" }}>
              https://Tradergpt.ai/privacy_and_policy
            </Link>
          </span>
          . You acknowledge and agree that your use of the Services is subject to, and that we can collect, use and/or disclose your information (including any personal data you provide to us) in
          accordance with our Privacy Policy.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          UPDATES TO TERMS OR SERVICES
        </Typography>
        <Typography sx={{ mb: 2 }}>
          We reserve the right to update or modify these Terms at any time at our sole discretion. If we do so, we’ll let you know by either posting the revised Terms on our website, on our mobile
          application or through other methods of communication which we deem reasonable. Such revised Terms as posted will take effect immediately, unless otherwise indicated. You should regularly
          check our website to inform yourself of any such changes and decide whether or not to accept the revised version of these Terms. If you continue to use Tradergpt following any update or
          modification of the Terms you shall be deemed to have accepted the revised Terms. If you do not agree to the Terms or any update or modification to the Terms, you must cease to access or use
          our Services. Our Services are evolving over time, we may change or discontinue all or any part of the Services, at any time and without prior notice, and at our sole discretion.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          ELIGIBILITY
        </Typography>
        <Typography sx={{ mb: 2 }}>
          To be eligible to use Tradergpt: (i) you must be at least eighteen (18) years old and legally competent to enter into these Terms; (ii) you must not be a resident of sanctioned jurisdictions
          according to any trade embargoes, UN Security Council Resolutions (“UNSCR”) or HM Treasury's financial sanctions regime; and (iii) you must not be currently the subject of or subject to
          economic sanctions such as the United Nations Security Council Sanctions List, the list of specially designated nationals maintained by OFAC, the denied persons or entity list of the U.S.
          Department of Commerce or any similar list maintained by any other relevant sanctions authority. If you are using our Services on behalf of a legal entity, you further represent and warrant
          that: (iv) the legal entity is duly organized and validly existing under the applicable laws of the jurisdiction of its organization; and (v) you are duly authorized by such legal entity to
          act on its behalf. You can only use our Services if permitted under the laws of your jurisdiction. For the avoidance of doubt, you may not use our Services if you are located in, or a
          citizen or resident of any state, country, territory or other jurisdiction where your use of our Services would be illegal or otherwise violate any applicable laws. Please make sure that
          these Terms are in compliance with all laws, rules, and regulations that apply to you. You agree that you are only using our Services with legally-obtained funds that rightfully belong to
          you. By using Tradergpt, you represent and warrant that you meet all eligibility requirements that we outline in these Terms. We may still refuse to let certain people access or use
          Tradergpt, however, and we reserve the right to change our eligibility criteria at any time.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          SERVICES
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Tradergpt is a non-custodial wallet software, for digital assets such as cryptocurrencies, virtual commodities and NFTs (“Digital Assets”), meaning you are solely in control of and
          responsible for your Digital Assets and private keys, and accordingly you can authorize transactions from your wallet address. You expressly acknowledge and agree that as Tradergpt is a
          non-custodial wallet software, you are solely responsible for your activity and any risk of loss at all times.
        </Typography>
        <Typography sx={{ mb: 2 }}>Tradergpt allows you to:</Typography>
        <ul>
          <li>generate wallet addresses and associated private keys that you may use to send and receive digital assets</li>
          <li>browse and access third party decentralized application(s) (“DApp(s)”) and third party decentralized exchanges (“DEX”) through the mobile application’s web browser</li>
          <li>swap/trade digital assets through DApp functionality made available by third party service provider(s)</li>
          <li>stake certain digital assets in a third party ‘proof of stake’ network through staking services (“Staking Service”)</li>
          <li>view digital asset price information made available by third party service provider(s); and</li>
          <li>
            broadcast Digital Asset Transaction data to various blockchains supported by Tradergpt without requiring you to download or install the associated blockchain-based software to your local
            device.
          </li>
        </ul>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Wallet Address, Private Key, and Backup Capabilities
        </Typography>
        <Typography sx={{ mb: 2 }}>
          An encrypted backup of certain information associated with your wallet can be stored on eligible devices. The private key is associated with the wallet address and, together, they can be
          used to authorize the transfer of Digital Assets to and from that wallet address. You are solely responsible for the retention and security of your private key and any mnemonic phrase
          (“Secret Phrase”) associated with your wallet. You must keep your wallet address, Secret Phrase, and private key access information secure. It is very important that you backup your private
          keys, backup phrases or passwords. Failure to do so may result in the loss of control of Digital Assets associated with your wallet. You acknowledge and agree that we do not receive or store
          your wallet password, encrypted private key, unencrypted private key, or Secret Phrase associated with your wallet. We cannot generate a new password for your wallet if you fail to remember
          your original password. If you have not safely stored a backup of any wallet address and private key pairs maintained in your wallet, you accept and acknowledge that any Digital Assets you
          have associated with such wallet address will become inaccessible. Accordingly, we shall have no responsibility or liability whatsoever in the event you are unable to access your wallet for
          any reason including without limitation your failure to keep your wallet address, Secret Phrase and private key information secure.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Use of DApps and DEX
        </Typography>
        <Typography sx={{ mb: 2 }}>
          If you access or use DApps or DEX, including, without limitation, DApp functionality embedded within the Services such as the Trade/Swap Digital Assets function, you acknowledge and agree
          that: (i) Tradergpt is not responsible for your access or use of DApps or DEX and shall have no liability whatsoever in connection with your use of DApps or DEX, including, without
          limitation, any transactions you dispute; (ii) the limits of amounts that you may exchange via DEX per day shall be subject to any requirements of the third-party developed Smart Contracts;
          (iii) blockchain operations are irrevocable meaning when you conduct any transactions via DApps or DEX, you shall be solely responsible for the consequences of any issues associated with
          such transactions, including, without limitation, your transfer to an incorrect address or problems associated with the node servers selected by you; (iv) when you use DApps or DEX,
          third-party developed Smart Contracts may charge you handling fees and/or service fees and any information displayed on Tradergpt relating to such fees are for your reference only as
          Tradergpt cannot and does not guarantee its accuracy, applicability, reliability, integrity or appropriateness, nor shall Tradergpt be liable for any loss or damage that may be caused
          directly or indirectly by your use of these contents; and (v) Tradergpt does not currently charge any service fees or handling fees for the use of DApps or DEX, however, Tradergpt may, in
          its discretion, charge such fees at any time in the future. Any updated fees will apply to any transaction that occurs following the effective date of the updated fees.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Digital Asset Transactions
        </Typography>
        <Typography sx={{ mb: 2 }}>
          In order for all proposed Digital Asset transactions to be completed, they must be confirmed and recorded in the Digital Asset’s associated public blockchain. Such networks are
          decentralized, peer-to-peer networks supported by independent third parties, which we do not own, control, or operate. We have no control over the blockchain networks and, therefore, cannot
          and do not ensure that any transaction details that you submit via our Services will be confirmed and processed. By using Tradergpt, you acknowledge and agree that: (i) we do not have the
          ability to cancel or otherwise modify your transaction; (ii) the transaction details you submit may not be completed, or may be substantially delayed, by the applicable blockchain networks;
          (iii) we do not store, send, or receive Digital Assets; and (iv) any transfer that occurs in relation to any Digital Asset occurs on the relevant blockchain network and not on a network
          owned by us and therefore we do not guarantee the transfer of title or right in any Digital Asset.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Accuracy of Information Provided by User
        </Typography>
        <Typography sx={{ mb: 2 }}>
          You represent and warrant that any information you provide via the Services is accurate and complete. You accept and acknowledge that we are not responsible for any errors or omissions that
          you make in connection with any Digital Asset transaction initiated via the Services. We strongly encourage you to review your transaction details carefully before attempting to transfer a
          Digital Asset.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          WALLET REGISTRATION AND SECURITY
        </Typography>
        <Typography sx={{ mb: 2 }}>
          You must either import or create a wallet in order to use Tradergpt. When you create a wallet, you will be assigned a private key. You agree to immediately notify us of any unauthorized use
          of your private key or any other breach of security of your wallet. Notwithstanding the foregoing, you acknowledge and agree that you shall assume all risks related to the use of the
          Services and you shall be solely responsible for maintaining the confidentiality and security of your private key. When you create a wallet, you are strongly advised to take precautions in
          order to avoid loss of access to and/or control over your wallet. Suggested measures include, but are not limited to, the following: (a) creating a strong unique password that you do not use
          for any other purpose (i.e. different to your phone password or any other passwords you use for websites, online services, etc.) and leveraging biometric authentication if available; (b) do
          not store the private key and Secret Phrase in plain text online or in an unsecured physical location; (c) limiting access to your devices and your wallet; (d) taking all necessary
          precautions against malware on your devices and networks; and (e) promptly notifying us if you discover or otherwise suspect any security breaches related to your wallet. Notwithstanding
          anything to the contrary herein, we shall have no liability whatsoever in connection with activities that occur on your wallet with or without your authorization.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Push Notifications
        </Typography>
        <Typography sx={{ mb: 2 }}>
          You may agree to receive push notifications from Tradergpt that will alert you when blockchain networks supported by Tradergpt are congested and when transactions involving your wallet have
          been completed. If you would like to receive push notifications, you must opt in to the service by accessing “Settings” and enabling “Push Notifications”.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Payment and Fees
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Fees applicable to the Services or any component of the Services, if any, shall be set forth at{" "}
          <span>
            <Link to="/" style={{ textDecoration: "none" }}>
              Tradergpt.ai
            </Link>
          </span>{" "}
          and/or the Tradergpt mobile application.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Transaction Fees
        </Typography>
        <Typography sx={{ mb: 2 }}>
          There may be transaction fees (e.g. mining fees) associated with your virtual currency transactions that are required by the virtual currency system or blockchain network that you engage
          with. You must ensure that you have an adequate balance in your wallet and/or “gas” to complete transactions before initiating a transaction. You acknowledge and agree that we will not be
          liable for any failed transactions or losses you incur due to incorrectly set transaction fees (i.e. too low or too high) or due to insufficient funds or gas associated with your wallet
          address. You further acknowledge and agree that we do not have access to your or anyone else’s transactions.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Taxes
        </Typography>
        <Typography sx={{ mb: 2 }}>
          It is your responsibility to determine what, if any, taxes apply to the transactions that you have submitted transaction details for via the Services, and it is your responsibility to report
          and remit the correct tax to the appropriate tax authority. You agree that we are not responsible for determining whether taxes apply to your transactions or for collecting, reporting,
          withholding, or remitting any taxes arising from any Digital Asset-related transactions.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          THIRD PARTY SERVICES AND CONTENT
        </Typography>
        <Typography sx={{ mb: 2 }}>
          In no event shall a description or reference to a third party’s product or service (including, but not limited to, providing a description or reference via hyperlink) be construed as an
          endorsement or promotion of such third party products or services by us. We retain the exclusive right to add to, modify, or cancel the availability of any Third Party Service. You may agree
          to receive push notifications from Third Party Content providers. In order to receive push notifications, you must opt in to the service. Push notifications will not be automatically enabled
          on your device for Third Party Content. We do not control, endorse, or adopt any Third Party Content shared through push notifications, and will have no responsibility for Third Party
          Content including, but not limited to, token availability and/or sales. If, to the extent permitted by Tradergpt, you grant express permission to a third party to access or connect to your
          Tradergpt account, either through the third party’s product or service or through Tradergpt, you acknowledge that granting permission to a third party to take specific actions on your behalf
          does not relieve you of any of your responsibilities under these Terms. You are fully responsible for all acts or omissions of any third party with access to your wallet.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          INTELLECTUAL PROPERTY
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          License
        </Typography>
        <Typography sx={{ mb: 2 }}>
          As long as you agree to and comply with the present Terms, we grant you a non-exclusive, non-sublicensable and non-transferable license to use Tradergpt for your personal use or internal
          business use only. Except as otherwise expressly permitted in these Terms, you will not: (a) reproduce, modify, adapt or create derivative works of any part of Tradergpt; (b) rent, lease,
          distribute, sell, sublicense, transfer, or provide access to Tradergpt; (c) use Tradergpt for the benefit of any third party; (d) incorporate Tradergpt into a product or service you provide
          to a third party without our prior written consent; (d) circumvent mechanisms in Tradergpt intended to limit your use; (f) reverse engineer, disassemble, decompile, translate, or otherwise
          seek to obtain or derive the source code, including images and texts, underlying ideas, algorithms, file formats or non-public APIs to Tradergpt, except to the extent expressly permitted by
          applicable law (and then only upon advance notice to us); (g) remove or obscure any proprietary or other notices contained in Tradergpt (h) use Tradergpt for competitive analysis, as part of
          any other software or project of any kind or to build competitive products.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Feedback
        </Typography>
        <Typography sx={{ mb: 2 }}>
          From time to time, you may choose to submit feedback to us. We may, in connection with Tradergpt, freely use, copy, disclose, license, distribute and exploit any feedback in any manner
          without any obligation, royalty or restriction based on intellectual property rights or otherwise. No feedback will be considered your Confidential Information, and nothing in this Agreement
          limits our right to independently use, develop, evaluate, or market products or services, whether incorporating feedback or otherwise.
        </Typography>

        {/* <Typography  variant="h6" sx={{mb:2}}>
          Open Source
        </Typography>
        <Typography sx={{mb:2}}>
          Tradergpt was developed under a GPL open source license. Information regarding Tradergpt’s current software can be found at https://github.com/TrustWallet. Since software development is community-based, it can be accessed, used, and shared, in modified or unmodified form, by anyone. Please be aware that GPL is a copyleft license, which means that any derivative works can only be distributed under the same license terms as the original software. If you have any questions, you should review GNU’s terms and conditions at https://www.gnu.org/licenses/gpl-3.0.en.html. 
        </Typography> */}

        <Typography variant="h6" sx={{ mb: 2 }}>
          Content
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Tradergpt may contain copyrighted material and trademarks including, but not limited to, text and graphics (the “Content”), which is protected by copyright law, registered and unregistered
          trademarks, and other intellectual property rights. Unless otherwise provided, we exclusively own the Content. Your use of Tradergpt does not grant you any right, title, or interest in the
          Content. You agree that you will not copy, reproduce, modify, republish, upload, post, transmit, distribute, collect, sell, license, create derivative works from, or, in any other way,
          whether manual or automated, exploit any of the Content, in whole or in part.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          License to NFT Content
        </Typography>
        <Typography sx={{ mb: 2 }}>
          You may be able to store non-fungible tokens (“NFTs”) on Tradergpt. You hereby represent and warrant that you own all legal right, title in and interest to, including all intellectual
          property rights to the content associated with the NFT (“NFT Content”), or you are legally authorized by the owner of the intellectual property in the NFT Content to store the NFTs on
          Tradergpt. You retain all rights to the NFT Content stored or Tradergpt except for rights expressly granted herein. By using Tradergpt, you grant us a license to access, use, host, cache,
          store, copy, reproduce, transmit, display, publish, distribute, adapt and modify (for technical purposes, e.g., making sure content is viewable on smartphones as well as computers and other
          devices) the NFT Content in any and all media or distribution methods (now know or later developed) solely as required to be able to operate and provide services of Tradergpt. We do not
          monitor the NFTs and NFT Content stored on Tradergpt for any infringement of a third party’s intellectual property rights. Accordingly, we assume no liability for any action regarding any
          content provided by you. You further acknowledge and agree it is your sole responsibility to carry out all necessary due diligence for all your activities relating to NFTs, and you represent
          and warrant that you have not and are not relying on, and shall have no remedies, in respect of any statement or representation made by us in relation to any transfer or interaction
          otherwise with any NFTs. If you have a dispute in relation to the NFTs and/or NFT Content, YOU RELEASE US FROM CLAIMS, DEMANDS, AND DAMAGES OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN,
          ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. IN ENTERING INTO THIS RELEASE YOU EXPRESSLY WAIVE ANY PROTECTIONS (WHETHER STATUTORY OR OTHERWISE) THAT WOULD OTHERWISE LIMIT THE
          COVERAGE OF THIS RELEASE TO INCLUDE THOSE CLAIMS WHICH YOU MAY KNOW OR SUSPECT TO EXIST IN YOUR FAVOR AT THE TIME OF AGREEING TO THIS RELEASE.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          YOUR USE OF Tradergpt
        </Typography>
        <Typography sx={{ mb: 2 }}>
          You agree that you will not violate any laws when using our Services. This includes any local, provincial, state, federal, national, or international laws that may apply to you. You agree
          that you will not use our Services to pay for, support, or otherwise engage in any illegal activities including, but not limited to, illegal or otherwise prohibited trade, illegal gambling,
          fraud, money laundering, or terrorist activities. For the avoidance of doubt, if we discover that you have violated these Terms and/or any applicable laws or regulatory requirements,
          including, but not limited to, the Bank Secrecy Act, we reserve all of our rights and remedies under these Terms and at law and will take all necessary actions against you. You further agree
          that: (i) you will not encourage or induce any third party to engage in any of the activities prohibited under this Section; (ii) you will not impersonate someone or use or attempt to use
          another user’s wallet without authorization or use our Services in any manner that could interfere, disrupt, negatively affect, or inhibit other users from fully enjoying it; (iii) you will
          not distribute any virus or other harmful computer code through Tradergpt; (iv) you will not take any action that may impose an unreasonable or disproportionately large load on our or any of
          our third party providers’ infrastructure; (v) you will not reverse engineer or bypass, circumvent, or attempt to bypass or circumvent any measures that we may use to prevent or restrict
          access to the Services including, without limitation, other accounts, computer systems, or networks connected to the Services; and (vi) you will not violate, misappropriate or infringe the
          rights of Tradergpt, our users, or others, including privacy, publicity, intellectual property, or other proprietary rights. Although we have no obligation to monitor any user content, we
          have absolute discretion to take any necessary actions any time and for any reason without notice in the event you breach these Terms. Any use of Tradergpt other than as specifically
          authorized in these Terms, without our prior written permission, is strictly prohibited and we shall have the right to terminate your license to use Tradergpt immediately without notice. You
          acknowledge and accept that by using the Services, you may be exposed to content that is offensive, indecent, or objectionable. We take no responsibility and assume no liability for any user
          content, including any loss or damage to any of your user content.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          LIMITATION OF LIABILITY & DISCLAIMER OF WARRANTIES
        </Typography>
        <Typography sx={{ mb: 2 }}>
          You acknowledge and agree that we have no control over, and no duty to take any action regarding: (i) failures, disruptions, errors, or delays in the processing of Digital Assets that you
          may experience while using the Services; (ii) the risk of failure of hardware, software, and Internet connections; (iii) the risk of malicious software being introduced or found in the
          software underlying Tradergpt; (iv) the risk that third parties may obtain unauthorized access to information stored within your wallet, including, but not limited to your wallet address,
          private key, and Secret Phrase; and (v) the risk of unknown vulnerabilities in or unanticipated changes to the the applicable blockchain networks. You release us from all liability related
          to any losses, damages, or claims arising from: (a) user error such as forgotten passwords, incorrectly constructed transactions, or mistyped Digital Asset addresses; (b) server failure or
          data loss; (c) unauthorized access to the Tradergpt application; (d) bugs or other errors in the Tradergpt software; and (e) any unauthorized third party activities, including, but not
          limited to, the use of viruses, phishing, brute forcing, or other means of attack against Tradergpt. We make no representations concerning any Third Party Content contained in or accessed
          through our Services. Any other terms, conditions, warranties, or representations associated with such content, are solely between you and such organizations and/or individuals.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Limitation of Liability
        </Typography>
        <Typography sx={{ mb: 2 }}>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE, OR OUR AFFILIATES, OR ANY OF OUR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
          REPRESENTATIVES, SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY DIRECT DAMAGES OR INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR SIMILAR DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING,
          WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA, INFORMATION, REVENUE, PROFITS OR OTHER BUSINESSES OR FINANCIAL BENEFITS) WHETHER UNDER CONTRACT, TORT, NEGLIGENCE, STATUTE, STRICT LIABILITY OR
          OTHER THEORY EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF YOUR ACCESS OR USE OF THE SERVICES, INCLUDING, BUT NOT LIMITED TO: ANY UNAUTHORIZED USE OF YOUR
          WALLET ADDRESS AND/OR PRIVATE KEY DUE TO YOUR FAILURE TO MAINTAIN THE CONFIDENTIALITY OF YOUR WALLET, ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES OR ANY BUGS,
          VIRUSES, TROJAN HORSES, OR THE LIKE THAT ARE FOUND IN THE Tradergpt SOFTWARE OR THAT MAY BE TRANSMITTED TO OR THROUGH OUR SERVICES BY ANY THIRD PARTY (REGARDLESS OF THE SOURCE OF
          ORIGINATION); ANY ACTIONS WE TAKE OR FAIL TO TAKE AS A RESULT OF COMMUNICATIONS YOU SEND TO US; HUMAN ERRORS; TECHNICAL MALFUNCTIONS; FAILURES, INCLUDING PUBLIC UTILITY OR TELEPHONE OUTAGES;
          OMISSIONS, INTERRUPTIONS, LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE OR NETWORK, PROVIDERS, OR SOFTWARE (INCLUDING, BUT NOT LIMITED TO, THOSE THAT DO NOT PERMIT PARTICIPATION IN OUR
          SERVICES); ANY INJURY OR DAMAGE TO COMPUTER EQUIPMENT; INABILITY TO FULLY ACCESS OUR WEBSITE, Tradergpt OR THE SERVICES OR ANY OTHER WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR UNAUTHORIZED
          ACCESS TO, IMAGES OR OTHER CONTENT OF ANY KIND; DATA THAT IS PROCESSED LATE OR INCORRECTLY OR IS INCOMPLETE OR LOST; TYPOGRAPHICAL, PRINTING OR OTHER ERRORS, OR ANY COMBINATION THEREOF; OR
          ANY OTHER MATTER RELATING TO THE WEBSITE, Tradergpt APPLICATION OR ANY OTHER ASPECT OF THE SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
          CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT SHALL OUR LIABILITY TO YOU FOR ALL DAMAGES (OTHER THAN MAY BE REQUIRED BY APPLICABLE LAW IN
          CASES INVOLVING PERSONAL INJURY) EXCEED THE AMOUNT OF ONE HUNDRED U.S. DOLLARS ($USD100.00) OR ITS EQUIVALENT IN THE LOCAL CURRENCY OF THE APPLICABLE JURISDICTION.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          Warranty Disclaimer
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Tradergpt IS PROVIDED "AS IS" AND “AS AVAILABLE”, AND WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR
          IMPLIED, RELATING TO THE SERVICES AND UNDERLYING SOFTWARE OR ANY ASPECT OF THE INFORMATION, CONTENT, OR THE SERVICES, WHETHER PROVIDED OR OWNED BY US, OR BY ANY THIRD PARTY OR CONTAINED IN
          ANY THIRD PARTY MATERIALS OR ON ANY THIRD PARTY WEBSITES ACCESSIBLE OR LINKED TO OUR WEBSITE OR THE SERVICES, INCLUDING WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM COMPUTER VIRUS, AND ANY IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE IN TRADE, ALL OF WHICH
          ARE EXPRESSLY DISCLAIMED. IN ADDITION, WE DO NOT REPRESENT OR WARRANT THAT THE CONTENT ACCESSIBLE VIA THE SERVICES IS ACCURATE, COMPLETE, AVAILABLE, CURRENT, FREE OF VIRUSES OR OTHER HARMFUL
          COMPONENTS, OR THAT THE RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS. WE DO NOT MAKE ANY REPRESENTATIONS OR GIVE WARRANTIES THAT THE ACCESS TO Tradergpt OR USE OF THE SERVICES
          AND THE FUNCTIONALITY THEREOF WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY OR ERROR-FREE. SOME STATES DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, SO THE FOREGOING DISCLAIMERS MAY NOT
          APPLY TO YOU.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          INDEMNITY
        </Typography>
        <Typography sx={{ mb: 2 }}>
          To the extent permitted by applicable law, you agree to defend, indemnify, and hold harmless us, our affiliates, and our respective shareholders, members, directors, officers, employees,
          attorneys, agents, representatives, suppliers and contractors, from and against any and all claims, damages, obligations, losses, liabilities, tort, costs or debt, and expenses (including,
          but not limited to, attorney’s fees) arising from: (a) your use of and access to the Services; (b) any feedback or submissions you provide to us concerning Tradergpt; (c) violation of any
          Third Party Content (d) your violation of the Terms; or (e) your violation of any law, rule, or regulation, or the rights of any third party.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          TIME LIMITATION ON CLAIMS
        </Typography>
        <Typography sx={{ mb: 2 }}>
          You agree that any claim you may have arising out of or related to your relationship with us must be filed within one year after such claim arises, otherwise, your claim is permanently
          barred.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          GOVERNING LAW
        </Typography>
        <Typography sx={{ mb: 2 }}>
          No matter where you’re located, the laws of the State of California will govern these Terms and the parties’ relationship as if you signed these Terms in California, without regard to
          California’s conflicts of laws rules. If any provisions of these Terms are inconsistent with any applicable law, those provisions will be superseded or modified only to the extent such
          provisions are inconsistent. The parties agree to submit to the federal or state courts in California for exclusive jurisdiction of any dispute arising out of or related to your use of the
          Services or your breach of these Terms. You waive any objection based on lack of personal jurisdiction, place of residence, improper venue, or forum non conveniens in any such action.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          NOTE TO INTERNATIONAL USERS
        </Typography>
        <Typography sx={{ mb: 2 }}>
          If you are a user accessing or using our Services from a region with laws or regulations governing personal data collection, use, and disclosure that differ from United States laws, please
          be advised that we do not collect or process your personal data, except as provided for in our Privacy Policy.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          TERMINATION
        </Typography>
        <Typography sx={{ mb: 2 }}>
          In the event of termination concerning your license to use Tradergpt, your obligations under this Agreement will still continue. Your access to the funds in your Wallet after termination
          will depend on your access to your backup of your Wallet address and private key.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          DISCONTINUANCE OF SERVICES
        </Typography>
        <Typography sx={{ mb: 2 }}>
          We may, in our sole discretion and without cost to you, with or without prior notice, and at any time, modify or discontinue, temporarily or permanently, any portion of our Services. You are
          solely responsible for storing outside of the Services a backup of any wallet address and private key pair that you maintain in your wallet. Maintaining an external backup of any wallet
          address and private key pairs associated with your wallet will allow you to access the blockchain network upon which your wallet is secured. Such a backup will allow you to fully restore
          your wallet at any time without cost or loss of your Digital Assets. If you do not maintain a backup of your wallet data outside of the Services, you will not be able to access the Digital
          Assets associated with your wallet. Tradergpt shall not be held responsible or liable for any loss of Digital Assets in the event that we discontinue all or any part of the Services.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          NO WAIVER
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Our failure to exercise or delay in exercising any right, power, or privilege under this Agreement shall not operate as a waiver; nor shall any single or partial exercise of any right,
          power, or privilege preclude any other or further exercise thereof. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized
          representative of us. Except as expressly set forth in this Agreement, the exercise by either party of any of its remedies under this Agreement will be without prejudice to its other
          remedies under this Agreement or otherwise.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          SEVERABILITY
        </Typography>
        <Typography sx={{ mb: 2 }}>
          If it turns out that any part of this Agreement is invalid, void, or for any reason unenforceable, that term will be deemed severable and limited or eliminated to the minimum extent
          necessary. The limitation or elimination of the term shall not affect or impair the validity or enforceability of any remaining part of that term, clause or provision or any other terms,
          clauses or provisions of these Terms.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          ARBITRATION & WAIVER OF CLASS ACTION
        </Typography>
        <Typography sx={{ mb: 2 }}>
          The parties agree to arbitrate any dispute arising from this Agreement or your use of the Services on an individual basis. ARBITRATION PREVENTS YOU FROM SUING IN COURT OR FROM HAVING A JURY
          TRIAL. THE PARTIES HEREBY EXPRESSLY WAIVE TRIAL BY JURY. The parties agree that: (a) any arbitration will occur in San Francisco, California; and (b) the arbitration will be conducted
          confidentially by a single arbitrator in accordance with the rules of American Arbitration Association for arbitration of consumer-related disputes, in the English language, and with limited
          discovery. At your request, hearings may be conducted in person or by telephone and the arbitrator may provide for submitting and determining motions on briefs, without oral hearings. Other
          than class procedures and remedies discussed below, the arbitrator has the authority to grant any remedy that would otherwise be available to a court or other tribunal. THE PREVAILING PARTY
          IN ANY ACTION OR PROCEEDING TO ENFORCE THESE TERMS SHALL BE ENTITLED TO COSTS AND ATTORNEYS' FEES. THE ARBITRAL DECISION MAY BE ENFORCED IN ANY COURT. WHETHER THE DISPUTE IS HEARD IN
          ARBITRATION OR IN COURT, YOU AND Tradergpt WILL NOT COMMENCE AGAINST THE OTHER A CLASS ACTION, CLASS ARBITRATION, OR REPRESENTATIVE ACTION OR PROCEEDING.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          FORCE MAJEURE
        </Typography>
        <Typography sx={{ mb: 2 }}>
          We shall not be held liable for any delays, failure in performance, or interruptions of service which result directly or indirectly from any cause or condition beyond our reasonable control,
          including but not limited to: any delay or failure due to any acts of God, acts of civil or military authorities, acts of terrorism, civil or industrial disturbances, blockages, embargoes,
          war, strikes or other labor disputes, fire, earthquakes, storms or other nature-related events, interruption in electrical telecommunications or Internet services or network provider
          services, failure of hardware equipment and/or software or other utility failures, smart contract bugs or weaknesses, technological changes, changes in interest rates or other monetary
          conditions, and, for the avoidance of doubt, changes to any blockchain-related protocol, other catastrophe, or any other occurrences which are beyond our reasonable control, and shall not
          affect the validity and enforceability of any remaining provisions. If we are unable to perform our Services outlined in the Terms due to factors beyond our control, including, but not
          limited to, the aforementioned force majeure events or changes in applicable laws and/or sanctions policies, we shall not be liable for the Services provided under these Terms during the
          time period coincident with the event in question.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          ASSIGNMENT
        </Typography>
        <Typography sx={{ mb: 2 }}>
          You agree that we may assign any of our rights and/or transfer, sub-contract, or delegate any of our obligations under these Terms without any notice or consent from you. These Terms will
          bind and inure to the benefit of the parties, their successors and permitted assigns. Your agreement to these Terms is personal to you and you may not transfer or assign it to any third
          party.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          NO THIRD PARTY BENEFICIARIES
        </Typography>
        <Typography sx={{ mb: 2 }}>You agree that, except as otherwise expressly provided in these Terms, there shall be no third party beneficiaries to the Terms.</Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          ENTIRE AGREEMENT
        </Typography>
        <Typography sx={{ mb: 2 }}>
          This Agreement sets forth the entire understanding and agreement as to the subject matter hereof and supersedes any and all prior discussions, agreements, and understandings of any kind
          (including, without limitation, any prior versions of the Terms) and every nature between us. Except as provided for above, any modification to these Terms must be in writing and must be
          signed by both parties.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          NOTICES
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Any notices or other communications provided by us under these Terms, including those regarding modifications to it will be given by posting to the Services and/or through other electronic
          communication. You agree and consent to receive electronically all communications, agreements, documents, notices and disclosures that we provide in connection with your account and your use
          of the Services.
        </Typography>

        <Typography variant="h6" sx={{ mb: 2 }}>
          QUESTIONS OR COMMENTS
        </Typography>
        <Typography sx={{ mb: 2 }}>
          If you have any questions relating to these Terms, your rights and obligations arising from these Terms and/or your use of Tradergpt and our Services or any other matter please send us a
          message on our contact page at info@Tradergpt.ai.
        </Typography>
      </Box>
    </>
  );
};

export default TermsOfUsePage;
