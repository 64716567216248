import React, { useEffect } from "react";
import usdt from "assets/icons/cryptoicon/usdt.svg";
import Plans from "./components/Plans";
import FAQ from "./components/FAQ";
import Header from "./components/Header";
import TradersInfo from "./components/TradersInfo";
import TextSection from "./components/TextSection";
import { XTAKER_COIN } from "library/constants";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import TextField from "./components/TextField";
import Statistic from "./components/Statistic";
import Comments from "./components/Comments";
import TradingStress from "./components/TradingStress";
import Signals from "./components/Signals";
import { useNavigate, useSearchParams } from "react-router-dom";

const coins = [
  {
    label: XTAKER_COIN,
    order: "First Time order",
    value: 1,
    icon: usdt,
  },
];

const Xtakers = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamValue = searchParams.get("plans");

  useEffect(() => {
    if (searchParamValue) {
      setTimeout(() => {
        navigate("/#plans");
      }, 1500);
    }
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Trader GPT | Home page</title>
        <meta name="description" content="Trader GPT | Home page" />
      </Helmet>
      <Box className="container">
        <Header coins={coins} />
        <Statistic />
        <TradingStress />
        <Signals />
        <TextField />
        <TradersInfo />
        <TextSection />
        <Plans />
        <Comments />
        <FAQ />
      </Box>
    </>
  );
};

export default Xtakers;
