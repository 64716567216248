import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Strategies from "./Strategies";
import Roadmap from "./Roadmap";
import Header from "./Header";
import TradeWork from "./TradeWork";

const Investing = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Header />
      <Box className="container" sx={{ mt: 6 }}>
        <Strategies />
        <Roadmap />
      </Box>
        <TradeWork />
    </>
  );
};

export default Investing;
