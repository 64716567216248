import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { styles } from "./styles";

const LearnMore = () => {
  return (
    <Box sx={styles.wrapper}>
      <Box className="container">
        <Box sx={styles.titleWrapper}>
          <Typography sx={styles.title} data-aos="fade-up" data-aos-duration="500">
            LEARN MORE
          </Typography>
          <Typography sx={styles.subTitle} data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
            Want to learn more about how you can take advantage of advanced options execution using TT? Contact our Sales team today for your personalized demo.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", mt: "2rem" }}>
        <Button sx={styles.button}>GET MORE DETAILS</Button>
      </Box>
    </Box>
  );
};

export default LearnMore;
