import * as yup from "yup";

export const subscribePlanValidation = (min, max, balance) => {
  const maxError =
    max < balance ? { amount: max, message: `Maximum investment is ${max} USDT!` } : { amount: balance, message: "Insufficient balance!" };

  return yup.object({
    initialAmount: yup
      .number()
      .required("Please enter an amount")
      .min(min, `Investment cannot be less than ${min}!`)
      .max(maxError?.amount || 0, maxError?.message),
    agree: yup.boolean().oneOf([true], "termNotChecked"),
    // name: yup.string().required("Plan name is required."),
    // trader: yup
    //   .object()
    //   .shape({ id: yup.string().required("Trader is required,") }),
  });
};

export const subscribeModalPlanValidation = (min, max, balance) => {
  const maxError =
    max < balance ? { amount: max, message: `Maximum investment is ${max} USDT!` } : { amount: balance, message: "Insufficient balance!" };

  return yup.object({
    initialAmount: yup
      .number()
      .required("Please enter an amount")
      .min(min, `Investment cannot be less than ${min}!`)
      .max(maxError?.amount || 0, maxError?.message),
    agree: yup.boolean().oneOf([true], "termNotChecked"),
    // name: yup.string().required("Plan name is required."),
    id: yup.string().required("Please select a plan."),
    // trader: yup
    //   .object()
    //   .shape({ id: yup.string().required("Trader is required,") }),
  });
};
