import React, { useEffect, useState } from "react";
import {
  ButtonBase,
  Typography,
  CircularProgress,
  TextField,
  InputAdornment,
  FormControlLabel,
  FormControl,
  Checkbox,
  Stack,
  FormHelperText,
} from "@mui/material";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAddXtakerPlan, useGetAllPlans, useGetSinglePlan } from "_hooks/Xtakers";
import { subscribeModalPlanValidation } from "library/validations/xtakersValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorAlert from "components/ErrorAlert";
import CustomButton from "components/CustomButton";
import _ from "lodash";
import { t } from "locales";
import { openToast } from "components/Toast";
import { XTAKER_COIN } from "library/constants";
import { getHumanError } from "library/translateServerErrors";
import FundAccountModal from "components/WalletActions/FundAccount";
import { fixNumber } from "library/helper";
import { updateWallets } from "store/reducers/auth/asyncActions";
import { profile } from "routing/GeneralRoutes/routes";
import PlansSelect from "./PlansSelect";

const SubscribeModal = ({ closeModal = null, refetchHistory }) => {
  const [id, setId] = useState(null);
  const { data: plans } = useGetAllPlans();
  const { mutateAsync: postXtakerPlan, isError, error } = useAddXtakerPlan();
  const { data: plan, refetch, isLoading, isFetching } = useGetSinglePlan(id || plans[0]?.id);
  const mainBalance = useSelector((state) => state.auth.wallets?.[XTAKER_COIN]);
  const modal = useSelector((state) => state.app.modal);
  const [endDate, setEndDate] = useState(0);
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      agree: false,
      // trader: null,
      initialAmount: 0,
    },
    resolver: yupResolver(subscribeModalPlanValidation(plan?.minInvestment, plan?.maxInvestment, mainBalance?.amount || 0)),
  });
  const enteredAmount = Number(watch("initialAmount") || 0);
  const agreeChecked = watch("agree");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const now = new Date();
    now.setDate(now.getDate() + plan?.duration);

    setEndDate(
      now.toLocaleString("en-us", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    );
  }, [plan?.duration]);

  useEffect(() => {
    refetch();
  }, [id, plans]);

  useEffect(() => {
    if (plans?.length) {
      setValue("id", id || plans?.[0]?.id);
    }
  }, [plans]);

  const submitHandler = async (data) => {
    try {
      const planData = {
        amount: data?.initialAmount,
        // name: data?.name,
        moneyStakePlanId: data?.id,
        // traderId: data?.trader?.id,
      };
      await postXtakerPlan(planData);
      dispatch(updateWallets());
      navigate(profile.overview);
      openToast("success", "Plan subscribed successfully");
      refetchHistory();
      modal?.hide();
    } catch (err) {
      openToast("error", getHumanError(err));
    }
  };
  const openFundAccountModal = () => modal.show(<FundAccountModal onClose={modal.hide} />);
  const setBalanceAsAmount = () => setValue("initialAmount", mainBalance?.amount);
  const setMinAsAmount = () => setValue("initialAmount", plan?.minInvestment);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflow: "initial",
        minWidth: { xs: "90vw", sm: "60vw", md: "50vw", lg: "650px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          img: { width: 32, height: 32, mr: 1.5 },
          p: "20px",
        }}
      >
        <Box>
          <Typography sx={{ color: "primary.main", fontSize: 20, fontWeight: 600 }}>Subscribe Plan</Typography>
        </Box>
        <ButtonBase
          onClick={closeModal}
          sx={{
            width: "38px",
            height: "38px",
            borderRadius: "6px",
            border: "1px solid",
            borderColor: (theme) => (theme.palette.mode === "dark" ? "#2a2e34" : "#eee"),
          }}
        >
          <Close fontSize="small" />
        </ButtonBase>
      </Box>

      {isError && (
        <Box sx={{ width: "100%", px: "20px" }}>
          <ErrorAlert text={error} />
        </Box>
      )}
      <Box
        sx={{
          p: "0px 30px",
          mb: !isLoading && !isFetching ? 0 : 4,
        }}
      >
        <Stack gap={1}>
          <Typography variant="body1" sx={{ opacity: 0.7 }}>
            Plans
          </Typography>
          <Stack>
            <Controller
              control={control}
              name="id"
              render={({ field: { onChange, value, ref } }) => (
                <PlansSelect options={plans || []} onChange={onChange} value={value || id || plans[0]?.id} setId={setId} ref={ref} />
              )}
            />
            {!!errors?.id && <FormHelperText sx={{ color: "#f44336" }}>{errors?.id?.id?.message || errors?.id?.message}</FormHelperText>}
          </Stack>
        </Stack>
      </Box>
      {!isLoading && !isFetching ? (
        <Box as="form" onSubmit={handleSubmit(submitHandler)}>
          <Box
            sx={{
              gap: 3,
              p: "0px 30px",
            }}
          >
            {/* <Typography variant="body1" sx={{ opacity: 0.7, mt: 3 }}>
              Plan name
            </Typography>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  error={errors?.name}
                  helperText={t(errors?.name?.message)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  placeholder={"Enter a name for your plan"}
                  id="name"
                  name="name"
                  autoComplete="name"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  sx={{
                    mt: 1,
                    width: "100%",
                    "& fieldset": {
                      borderRadius: "8px !important",
                      border: "1px solid #BFBFBF !important",
                    },
                    input: { px: 1.5, py: 1, boxSizing: "border-box", height: 46 },
                  }}
                />
              )}
            /> */}
            <Typography variant="body1" sx={{ opacity: 0.7, mt: 2 }}>
              Subscription amount
            </Typography>
            <Controller
              control={control}
              name="initialAmount"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  error={errors?.initialAmount}
                  helperText={t(errors?.initialAmount?.message)}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  placeholder={`Minimum ${plan?.minInvesment}`}
                  id="initialAmount"
                  name="initialAmount"
                  autoComplete="initialAmount"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {XTAKER_COIN}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    mt: 1,
                    width: "100%",
                    "& fieldset": {
                      borderRadius: "8px !important",
                      border: "1px solid #BFBFBF !important",
                    },
                    input: { px: 1.5, py: 1, boxSizing: "border-box", height: 46 },
                  }}
                />
              )}
            />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography variant="body2" sx={{ display: "inline", opacity: 0.7 }}>
                  Limit:{" "}
                </Typography>
                <Typography variant="body2" sx={{ display: "inline", cursor: "pointer" }} onClick={setMinAsAmount}>
                  {fixNumber(plan?.minInvestment, 3, true)} {XTAKER_COIN}
                </Typography>
              </Box>
              <Stack direction="row" alignItems="center" gap={1}>
                <Box>
                  <Typography variant="body2" sx={{ display: "inline", opacity: 0.7 }}>
                    Balance:{" "}
                  </Typography>
                  <Typography variant="body2" sx={{ display: "inline", cursor: "pointer" }} onClick={setBalanceAsAmount}>
                    {fixNumber(mainBalance?.amount, 3, true) || "0.00"} {XTAKER_COIN}{" "}
                  </Typography>
                </Box>

                <Typography
                  onClick={openFundAccountModal}
                  variant="body2"
                  sx={{
                    fontWeight: "500",
                    color: (theme) => theme.palette.primary.main,
                    display: "inline",
                    cursor: "pointer",
                  }}
                >
                  Add funds
                </Typography>
              </Stack>
            </Box>

            {/* <Stack gap={1}>
              <Typography variant="body1" sx={{ opacity: 0.7, mt: 2 }}>
                Trader
              </Typography>

              <Stack>
                <Controller
                  control={control}
                  name="trader"
                  render={({ field: { onChange, value, ref } }) => 
                    <TraderSelect 
                      options={traders?.list || []} 
                      onChange={onChange} 
                      value={value || traders?.list[0]} 
                      ref={ref} 
                    />
                  }
                />
                {!!errors?.trader && <FormHelperText sx={{ color: "#f44336" }}>{errors?.trader?.id?.message || errors?.trader?.message}</FormHelperText>}
              </Stack>
            </Stack> */}
          </Box>
          <Box
            sx={{
              backgroundColor: (theme) => (theme.palette.mode === "dark" ? theme.palette.background.dark : theme.palette.background.light),
              padding: "24px 30px 30px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1" sx={{ opacity: 0.7 }}>
                Total Profit
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {plan?.minProfit} - {plan?.maxProfit}%
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "3px",
              }}
            >
              <Typography variant="body1" sx={{ opacity: 0.7 }}>
                Expected earnings
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {!enteredAmount
                  ? `0 ${XTAKER_COIN}`
                  : `${fixNumber((enteredAmount * plan?.minProfit) / 100, 3, true)} - ${fixNumber(
                      (enteredAmount * plan?.maxProfit) / 100,
                      3,
                      true
                    )} ${XTAKER_COIN}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "3px",
              }}
            >
              <Typography variant="body1" sx={{ opacity: 0.7 }}>
                Earnings release date
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {endDate}
              </Typography>
            </Box>
            <Box mt={2}>
              <FormControl sx={{ label: { margin: "auto" } }}>
                <Controller
                  control={control}
                  name="agree"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          name="agree"
                          id="agree"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          sx={{
                            "&.MuiCheckbox-root ": {
                              padding: "0 9px 0 0",
                            },
                          }}
                        />
                      }
                      label={
                        <Box>
                          <Typography variant="body1" sx={{ display: "inline" }}>
                            I accept the {t("appName")}{" "}
                          </Typography>
                          <Typography
                            as={Link}
                            target="_blank"
                            to="/terms-and-condition"
                            variant="body1"
                            sx={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              color: "primary.main",
                              display: "inline",
                            }}
                          >
                            Terms of Use.{" "}
                          </Typography>
                        </Box>
                      }
                    />
                  )}
                />
              </FormControl>
            </Box>
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isSubmitting}
              disabled={!_.isEmpty(errors, true) || !agreeChecked}
              loaderHeight="40"
              extraSx={{
                marginTop: 3,
                height: "46px",
              }}
              withSpinner
            >
              {t("Confirm Subscription")}
            </CustomButton>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: 92,
          }}
        >
          <CircularProgress size={36} />
        </Box>
      )}
    </Box>
  );
};

export default SubscribeModal;
