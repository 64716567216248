import React, { useEffect } from 'react';
import Header from './Header';
import About from './About';
import Statistics from './Statistics';
import OtherTrader from './OtherTrader';
import { Box } from '@mui/material';
import Roadmap from './Roadmap';
import { useGetXtakerSingleTrader } from '_hooks/Xtakers';
import { useParams } from 'react-router-dom';

const TraderPortfolio = () => {
    const {id} = useParams();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    const { data, isLoading } = useGetXtakerSingleTrader({id})
    return (
        <>
        <Header data={data} loading={isLoading} />
        <Box className="container">
            <About data={data} loading={isLoading} />
            <OtherTrader data={data} loading={isLoading} />
        </Box>
        </>
    );
};

export default TraderPortfolio;