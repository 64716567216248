import { Route, Routes } from "react-router";
import Navbar from "components/Navbar";
import { Stack } from "@mui/material";
// import { useSelector } from "react-redux";

import { navbarHeight } from "library/layout";

export const FULL_PAGE_ROUTES = [];
export const routes = [];

const FullPageRoutes = () => {
  return (
    <>
      <Stack
        sx={{
          minHeight: `calc(100vh - ${navbarHeight}px)`,
        }}
      >
        <Navbar />
        <Routes>
          {routes.map(({ exact = false, path, component: Component }, i) => {
            return (
              <Route
                key={i}
                exact={exact}
                path={path}
                element={<Component />}
              />
            );
          })}
        </Routes>
      </Stack>
    </>
  );
};
export default FullPageRoutes;
