import React from "react";
import UserNav from "./UserNav";
import Submenu from "./Submenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import NavbarNotification from "./Notifications/NavbarNotification";
import WalletSidebarButton from "./WalletSidebar";
import { navbarMenu } from "enum/navbarMenu";
import { MenuRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { navbarHeight } from "library/layout";
import { useGetAllPlans } from "_hooks/Xtakers";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toggleMobileSidebar } from "store/reducers/app/appSlice";
import { Stack, Box, AppBar, Toolbar, Link, IconButton, Button, Typography } from "@mui/material";
import XtakerLogo from "assets/logo/xtakerLogo";

const Navbar = () => {
  const isAuth = useSelector((state) => !!state.auth.user);
  const { theme } = useSelector((state) => state.setting);
  const isMobileSize = useMediaQuery("(max-width:1200px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: plans, isFetching: isLoading } = useGetAllPlans();
  const scroll = (scrollTo) => {
    setTimeout(() => {
      navigate(scrollTo)
    }, 200)
  };
  return (
    <Stack>
      <AppBar
        position="fixed"
        id="main-navbar"
        sx={{
          borderBottom: "1px solid",
          borderColor: "transparent",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          bgcolor: "background.default",
          backgroundImage: "none",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          maxWidth: "100vw",
        }}
      >
        <Toolbar
          sx={{
            height: navbarHeight,
            pl: {
              xs: "0 !important",
              md: "0 !important",
            },
            pr: {
              xs: "0 !important",
              md: "0 !important",
            },
            justifyContent: "space-between",
            position: { xs: "static", lg: "relative" },
            backgroundColor: "transparent",
          }}
        >
          <Stack className="container" direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isMobileSize && (
                <IconButton mx={1} onClick={() => dispatch(toggleMobileSidebar())}>
                  <MenuRounded sx={{ fontSize: 28 }} />
                </IconButton>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  a: {
                    display: "flex",
                  },
                }}
              >
                <Link component={RouterLink} to="/" color="inherit" underline="none">
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <Box sx={{
                      width: 40,
                      height: 40,
                    }}>
                      <XtakerLogo />
                    </Box>
                    <Typography sx={{
                      color: 'primary.contrastText',
                      fontWeight: 'bold',
                      fontSize: 20,
                      display: {xs: 'none', md: 'inherit'},
                    }}>TraderGPT</Typography>
                  </Stack>
                </Link>
              </Box>
            </Box>

            {!isMobileSize && (
              <Box>
                <Stack direction={"row"} columnGap={2} alignItems={"center"} style={{ color: theme === "dark" ? "white" : "#333" }}>
                  {navbarMenu({plans, planLoading: isLoading})?.map((item) =>
                    item?.isSubmenu ? (
                      <Submenu item={item} />
                    ) : (
                      <Link 
                          key={item?.id}
                          component={item?.as} 
                          to={item?.to}
                          href={item?.to} 
                          target={item?.isBlank && '_blank'} 
                          color={"#000"} 
                          underline="none"
                        >
                        <Button onClick={!!item?.scrollTo ? () => scroll(`${item?.to}${item?.scrollTo}`) : () => {}} sx={{borderRadius: 2.4}}>
                            {item?.title}
                        </Button>
                      </Link>
                    )
                  )}
                </Stack>
              </Box>
            )}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Stack
                direction="row"
                sx={{
                  svg: {
                    fill: (theme) => (theme.palette.mode === "dark" ? "white" : "#333"),
                  },
                }}
              >
                {isAuth && (
                  <>
                    <NavbarNotification />
                    <WalletSidebarButton />
                  </>
                )}
              </Stack>

              <Box sx={{ mx: 1 }}></Box>

              {isMobileSize ? null : <UserNav />}
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>
    </Stack>
  );
};

export default Navbar;
