export const styles = {
    title: {
        color: (theme) => theme.palette.primary.contrastText,
        fontSize: 24,
        fontWeight: 700,
        mb: 1,
        "& span": {
            color: (theme) => theme.palette.primary.main,
        },
    },
};