import { ChangeHistory, DialerSipOutlined, Diamond, HourglassBottomOutlined } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import Details from "./Details";
import { styles } from "./styles";
import video from "assets/images/vid.jpg";

const AdvancedOrder = () => {
  const data = [
    {
      icon: video,
      title: "Options Chain ",
      description: "Tradergpt’s Options Chain gives options traders the ability to view options contracts in an industry-standard display and the power to quickly en…",
    },
    {
      icon: video,
      title: "Options Trade Monitor ",
      description: "The Options Trade Monitor lets you view time and sales data for an entire family of options products. In this video, we will set up our Opti…",
    },
    {
      icon: video,
      title: "Vol Curve Manager for Options Trading ",
      description: "Tradergpt’s Vol Curve Manager gives users the ability to view implied vol curves and the power to create their own vol curves, which can be used …",
    },
  ];
  return (
    <Box sx={styles.container}>
      <Box className="container">
        <Details data={data} />
      </Box>
    </Box>
  );
};

export default AdvancedOrder;
