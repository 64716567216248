import { createTheme } from "@mui/material";
import { useMemo } from "react";
import { faIR } from "@mui/material/locale";

// MUI BREAKPOINTS
// xs:    0px
// sm:    600px
// md:    900px
// lg:    1200px
// xl:    1536px

const useTheme = () => {
  const isLight = true;
  const appTheme = useMemo(
    () =>
      createTheme({
        direction: "ltr",
        faIR,

        palette: {
          mode: "light",
          primary: {
            light: "#E5EDFF",
            main: "#2962FF",
            dark: "#2962FF",
            contrastText: "#181E4B",
            danger: "red",
          },

          secondary: {
            light: "#DEFCF9",
            main: isLight ? "#DEFCF9" : "#2a2a2c",
            dark: "#262626",
            contrastText: "#2962FF",
            gray: "#1a1a1a",
          },

          paper: {
            main: "#2a2e34",
            dark: "#2a2e34",
            light: "#F1F0F5",
          },

          background: {
            default: isLight ? "#FFFFFF" : "#FFFFFF",
            main: "#2a2e34",
            dark: "#141414",
            light: "#FFFFFF",
          },
          gradient: {
            pink: "linear-gradient(-25deg, rgb(246,98,27) 0%, rgb(208,49,209) 100%)",
            blue: "linear-gradient(89.8deg, rgb(39, 115, 255) 4.3%, rgb(62, 211, 232) 101.4%)",
            purpule: "linear-gradient( -41deg, rgb(125,27,246) 0%, rgb(160,24,238) 99%)",
            orange: "linear-gradient(to right, rgba(246,119,27,1) 0%, rgba(219,58,20,1) 100%)",
          },
          solid: {
            blue: "rgb(6, 97, 220)",
            grayLight: "#6E737C",
            discord: "rgb(114, 137, 218)",
            twitter: "rgb(0, 172, 237)",
            error: "#f44336",
          },
          card: {
            default: isLight ? "#FDFDFF" : "#0b0b0b",
            dark: "#0b0b0b",
            light: "#FDFDFF",
          },
          border: {
            default: isLight ? "#FCFCFF" : "#2a2e34",
            dark: "#2a2e34",
            light: "#FCFCFF",
          },
          borderLight: {
            default: isLight ? "#cccccc" : "#333333",
            dark: "#333333",
            light: "#cccccc",
          },
          menu: {
            default: isLight ? "#333333" : "#fff",
            dark: "#fff",
            light: "#333333",
          },
          navbarIcon: {
            default: isLight ? "#222" : "#fff",
            dark: "#222",
            light: "#fff",
          },
          iconButton: {
            default: isLight ? "#A6A6A6" : "#fff",
            dark: "#A6A6A6",
            light: "#fff",
          },
          greenColor: {
            default: isLight ? "#34b349" : "#6ccf59",
            dark: "#6ccf59",
            light: "#34b349",
          },
          redColor: {
            default: isLight ? "#f02934" : "#ff4d4d",
            dark: "#ff4d4d",
            light: "#f02934",
          },
          typographyColor: {
            default: isLight ? "#1a1a1a" : "#e5e5e5",
            dark: "#e5e5e5",
            light: "#1a1a1a",
          },
          drodownBg: {
            default: isLight ? "#f2f2f2" : "#0d0d0d",
            dark: "#0d0d0d",
            light: "#f2f2f2",
          },
        },
        typography: {
          color: "#5E6282",
          transition: ".5s color,.5s background",
          button: {
            textTransform: "none",
          },
          body3: {
            fontFamily: "Poppins",
            fontSize: ".8rem",
          },
        },

        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                position: "relative",
                boxShadow: "1px 0px 12px 0px rgb(0 0 0 / 33%)",
                borderRadius: "10px",
              },
            },
          },
          MuiContainer: {
            styleOverrides: {
              maxWidthLg: {
                maxWidth: "1380px !important",
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              paper: {
                boxShadow: "none !important",
                border: `1px solid ${isLight ? "#bfbfbf" : "#404040"}`,
                backgroundColor: isLight ? "#f2f2f2 !important" : "#0d0d0d !important",
                borderRadius: "0.73684rem !important",
                color: isLight ? "#1a1a1a" : "#e5e5e5",
                "& li": {
                  color: "inherit",
                },
              },
            },
          },
          MuiButtonBase: {
            styleOverrides: {
              root: {
                transition: ".5s color,.5s background",
                "&:hover": {
                  "& svg": {
                    color: "#2962FF",
                  },
                },
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                // "& svg": {
                //   color: "#a6a6a6",
                // },
                transition: ".5s color,.5s background",
                "&:hover": {
                  "& svg": {
                    color: "#2962FF",
                  },
                },
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              text: {
                color: "inherit",
                "& svg": {},
                transition: ".5s color,.5s background",
                "&:hover": {
                  color: "#2962FF",
                  "& svg": {
                    color: "#2962FF !important",
                  },
                },
              },
              contained: {
                boxShadow: "none",
                borderRadius: "10px",
                color: "#fff",
                ":hover": {
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: "primary.main",
                    boxShadow: `4px 4px 15px rgba(41,  98, 255, 0.3)`,
                  },
                },
              },

              outlinedPrimary: {
                borderRadius: "10px",
                borderColor: "#2962FF",
                color: "#2962FF",
                ":hover": {
                  backgroundColor: "#2962FF",
                  color: "#fff",
                  boxShadow: "1px 4px 15px 0px rgb(0 0 0 / 8%)",
                },
              },
            },
          },
          MuiSelect: {
            styleOverrides: {
              root: {
                borderRadius: "0.52632rem",
                "& input::placeholder": {
                  color: "#A6A6A6",
                  opacity: 1,
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "0.52632rem",
                  border: "1px solid",
                  borderWidth: "1px",
                  borderColor: isLight ? "#BFBFBF" : "#2a2e34",
                },
                "& .MuiOutlinedInput-notchedOutline ": {
                  borderRadius: "0.52632rem",
                  border: "1px solid",
                  borderColor: isLight ? "#BFBFBF" : "#2a2e34",
                  "&:hover": {
                    border: "1px solid",
                    borderColor: isLight ? "#BFBFBF" : "#2a2e34",
                  },
                },
                "& .MuiInputBase-input:-webkit-autofill": {
                  WebkitBoxShadow: (theme) => `0 0 0 100px ${theme.palette.mode === "dark" ? "#1d1c23" : "#9524ff1f"} inset`,
                },
              },
            },
          },
          MuiTextField: {
            styleOverrides: {
              root: {
                borderRadius: "0.6rem",
                "& input::placeholder": {
                  color: "rgba(79, 85, 90, 0.8)",
                  opacity: 1,
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "0.6rem",
                  border: "1px solid",
                  borderWidth: "1px",
                  borderColor: "transparent",
                },
                "& .MuiOutlinedInput-notchedOutline ": {
                  borderRadius: "0.6rem",
                  border: "1px solid",
                  borderColor: "transparent",
                  "&:hover": {
                    border: "1px solid",
                    borderColor: "transparent",
                  },
                },
                "& .MuiInputBase-input:-webkit-autofill": {
                  WebkitBoxShadow: (theme) => `0 0 0 100px ${theme.palette.mode === "dark" ? "#1d1c23" : "#9524ff1f"} inset`,
                },
              },
            },
          },
          // Scrollbar styles
          MuiCssBaseline: {
            styleOverrides: {
              html: {
                scrollBehavior: "smooth",
                "&, & *": {
                  scrollbarColor: "#f5f5f5 #555",
                  scrollbarWidth: "thin",
                },

                "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
                  backgroundColor: "#E5EDFF",
                  borderRadius: "20px",
                },
                "&::-webkit-scrollbar-track, & *::-webkit-scrollbar": {
                  width: "3px",
                  height: "2px",
                  backgroundColor: "#E5EDFF",
                  borderRadius: "20px",
                },

                "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-thumb": {
                  backgroundColor: "#181E4B",
                  borderRadius: "20px",
                },
              },
            },
          },
          MuiTypography: {
            styleOverrides: {
              button: {
                fontSize: "12px",
              },
              caption: {
                fontSize: " 0.65rem",
                opacity: 0.8,
              },
            },
          },
        },
      }),
    []
  );

  return appTheme;
};

export default useTheme;
