import { Typography, Stack } from "@mui/material";
import { t } from "locales";

const TextSection = () => {
  return (
    <Stack gap={2} mt={15} alignItems="center">
      <Typography
        data-aos="fade-up"
        data-aos-duration="600"
        variant="h4" 
        fontWeight="600"
      >
        {t("earnByMinute")}
      </Typography>
      <Typography 
        data-aos="fade-up"
        data-aos-duration="600"
        data-aos-delay="200"
        variant="body1" 
        fontWeight="500"
      >
        {t("earnByMinuteDesc")}
      </Typography>
    </Stack>
  );
};

export default TextSection;
