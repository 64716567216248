import React from "react";
import { styles } from "./styles";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

const Strategies = () => {
  const plans = [
    {
      id: 1,
      title: "Increased efficiency",
      description: "AI can analyze vast amounts of data and execute trades faster than human traders, which can lead to more profitable trades and reduced costs.",
    },
    {
      id: 2,
      title: "Improved accuracy",
      description: "AI can process and analyze large datasets to identify patterns and predict market trends with greater accuracy than humans.",
    },
    {
      id: 3,
      title: "Reduced human bias",
      description: "Automated trading using AI can help reduce the impact of human emotions and biases on trading decisions, which can improve overall trading performance.",
    },
    {
      id: 4,
      title: "Increased scalability",
      description: "AI can handle a large volume of trades and scale up or down as needed, allowing for greater flexibility in trading strategies.",
    },
    {
      id: 5,
      title: "24/7 availability",
      description: "AI can operate around the clock, continuously monitoring markets and executing trades, which can lead to more opportunities for profitable trades.",
    },
  ];
  const smallDevice = useMediaQuery("(max-width:904px)");

  return (
    <Box>
      <Box sx={styles.titleWrapper}>
        <Typography sx={styles.subTitle} data-aos="fade-up" data-aos-duration="500">
          AI Investing + Human
        </Typography>
        <Typography sx={styles.title} data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
          Systematic Strategies
        </Typography>
      </Box>

      <Grid container spacing={smallDevice ? 3 : 1}>
        {plans?.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={2.4} key={item.id} data-aos="fade-up" data-aos-duration="500" data-aos-delay={item.id * 50 + 100}>
            <Box sx={styles.planWrapper}>
              <Grid container rowSpacing={"2px"} columnSpacing={"4px"} sx={{...styles.planNumberWrapper, maxWidth: item?.id !== 5 ? '32px' : '48px'}}>
                {Array(item?.id)
                  .fill({})
                  ?.map((x) => (
                    <Grid key={x} item xs={item?.id !== 5 ? 6 : 4}>
                      <Box sx={styles.planNumber} />
                    </Grid>
                  ))}
              </Grid>
              <Box>
                <Typography sx={styles.planTitle}>{item.title}</Typography>
                <Typography sx={styles.planDescription}>{item.description}</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Strategies;
