export const styles = {
    wrapper: {
        mb: 12,
    },
    title: {
        fontSize: {
            xs: "1.6rem",
            sm: "1.8rem",
            md: "2.1rem",
            lg: "2.4rem",
        },
        textAlign: 'center',
        fontWeight: "700",
        color: (theme) => theme.palette.primary.contrastText,
        lineHeight: 1.4,
        "& span": {
            color: (theme) => theme.palette.primary.main,
        },
    },
    assetsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '8px',
        mt: 3,
    },
    boxWrapper: {
        minWidth: 56.5,
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        bgcolor: "#fff",
        border: "1px solid rgba(0,0,0,0.1)",
        boxShadow: "none",
        borderRadius: 24,
        p: 1,
        transition: "all 0.2s ease-out",
        '&:hover': {
            border: "1px solid transparent",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
    },
    assetImageWrapper: {
        width: '36px',
        height: '36px',
        '& img': {
            height: '36px',
            width: '36px'
        }
    },
    assetName: {
        color: (theme) => theme.palette.primary.contrastText,
        fontWeight: 700,
        fontSize: 16,
        flex: 1,
    },
    linkButton: {
        display: "inline-block",
        borderRadius: 2.4,
        p: {xs: "6px 12px", lg: "8px 16px"},
        mt: 3,
        fontSize: {xs: 12, lg: 14},
        bgcolor: "primary.main",
        border: "1px solid transparent",
        color: '#FFF',
        textDecoration: 'none',
        '&:hover': {
            borderColor: 'primary.main',
            color: 'primary.main',
        },
    }
};