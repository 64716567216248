import React from 'react';
import pic1 from '../../assets/Cryptocurrency-Why-Image-1.webp';
import pic2 from '../../assets/Cryptocurrency-Why-Image-2.webp';
import pic3 from '../../assets/Cryptocurrency-Why-Image-3.webp';
import { Box, Grid, Typography } from '@mui/material';
import { styles } from './styles';

const WhyTraderGPT = () => {
    const cardsData = [
        {
            id: '1',
            image: pic1,
            title: 'AI-Based Indicators',
            description: 'Real-time signal alerts ensure you never miss a trading opportunity again.',
        },
        {
            id: '2',
            image: pic2,
            title: 'Educational Community',
            description: 'We offer a range of educational resources to help you learn more about trading.',
        },
        {
            id: '3',
            image: pic3,
            title: 'Market Analysis',
            description: 'Our VIP channel provides up-to-date analysis on the financial markets.',
        },
    ]
    return (
        <Box sx={styles.wrapper}>
            <Box sx={styles.textWrapper}>
                <Typography 
                    sx={styles.title}
                    data-aos="fade-up"
                    data-aos-duration="600"
                    data-aos-delay="0"
                >
                    Designed for <span> beginners and experienced </span> traders!
                </Typography>
                <Typography
                    sx={styles.descriptions}
                    data-aos="fade-up"
                    data-aos-duration="600"
                    data-aos-delay="100"
                >
                    Our Services is designed to make trading simple, accessible, and profitable for everyone.
                </Typography>
            </Box>
            <Grid container spacing={3} direction='row' alignItems='stretch'>
                {
                    cardsData?.map((item, index) => (
                        <Grid item xs={12} md={4} key={item?.id}
                            data-aos="fade-up"
                            data-aos-duration="600"
                            data-aos-delay={index * 100}
                        >
                            <Box sx={styles.boxWrapper}>
                                <Box sx={styles.imageWrapper}>
                                    <img src={item?.image} alt='' />
                                </Box>
                                <Box>
                                    <Typography sx={styles.cardTitle}>{item?.title}</Typography>
                                    <Typography sx={styles.cardDescription}>{item?.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    );
};

export default WhyTraderGPT;