export const styles = {
  wrapper: {
    mt: 10,
  },
  title: {
    fontSize: { xs: "1.1rem", md: "1.25rem" },
    fontWeight: 600,
    color: (theme) => theme.palette.primary.contrastText,
    "& span": {
      color: (theme) => theme.palette.primary.main,
    },
  },
  subtitle: {
    mt: 1,
    fontWeight: 600,
    color: (theme) => theme.palette.primary.contrastText,
  },
  table: {
    width: "100%",
    border: "1px solid",
    borderColor: "border.default",
    mt: 4,
  },
  tableItemWrapper: {
    borderBottom: "1px solid",
    borderColor: "border.default",
  },
  tableItem: {
    borderRight: "1px solid",
    borderColor: "border.default",
  },
  headTitle: {
    px: 2,
    py: 1,
    fontWeight: 600,
  },
  headbody: {
    px: 2,
    py: 1,
    fontWeight: 400,
  },
};
