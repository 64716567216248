import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { t } from "locales";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

const getStyle = ( controlStyle = {}, optionStyle = {}) => {
    return {
      control: (provided) => ({
        ...provided,
        backgroundColor: "#eee",
        color: "#222",
        borderColor: "#eee",
        borderRadius: "8px",

        ...controlStyle,
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        color: "#34384c",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#222",
      }),
      menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#eee",
        color: "#222",
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#fff" : "#222",
        backgroundColor: state.isSelected
          ? "#2962FF"
          : state.isFocused
          ? "#aaa"
          : "#eee",

        ...optionStyle,
      }),
    };
};

const OptionWithIcon = ({ children, ...rest }) => (
  <components.Option {...rest}>
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <img
        style={{ height: 20, widows: 20, borderRadius: "50%" }}
        src={rest?.data?.icon}
        alt=""
      />
      <span>{children}</span>
    </Box>
  </components.Option>
);

const ControlComponent = ({ children, ...rest }) => {
  const curValue = rest.getValue()?.[0];

  return (
    <components.Option {...rest}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <img
          style={{ height: 20, widows: 20, borderRadius: "50%" }}
          src={curValue?.icon}
          alt=""
        />
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          {children}
        </Box>
      </Box>
    </components.Option>
  );
};

const CustomReactSelect = ({
  options,
  onChange,
  value,
  isDisabled,
  defaultValue,
  className,
  label = "",
  error,
  renderError = null,
  placeholder = "Select",
  isSearchable = false,
  withIcon = false,
  controlWithIcon = false,
  controlStyle = {},
  optionStyle = {},
}) => {
  const theme = useSelector((state) => state.setting.theme);
  const [inValue, setInValue] = useState(defaultValue ?? options?.[0]);

  useEffect(() => {
    if (!inValue) {
      setInValue(options?.[0]);
    } // eslint-disable-next-line
  }, [options]);

  const renderedError = error
    ? renderError
      ? renderError(t(error?.message))
      : t(error.message)
    : null;

  const CustomSelect = (props) =>
    withIcon ? (
      controlWithIcon ? (
        <Select
          {...props}
          components={{ Option: OptionWithIcon, Control: ControlComponent }}
        />
      ) : (
        <Select {...props} components={{ Option: OptionWithIcon }} />
      )
    ) : (
      <Select {...props} />
    );

  const changeHandler = (newValue) => {
    onChange(newValue);
    setInValue(newValue);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {label && (
        <label className={"form-label"} htmlFor="priority">
          {t(label)}
        </label>
      )}
      <CustomSelect
        placeholder={placeholder}
        className={className}
        options={options}
        onChange={changeHandler}
        isSearchable={isSearchable}
        value={value ?? inValue}
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        styles={getStyle(controlStyle, optionStyle)}
      />
      <div className="d-flex my-2">
        <div className="flex-1">
          {renderedError && (
            <>
              <span className="is-invalid" />
              <div className={`invalid-feedback m-0`}>
                {renderedError ? renderedError : null}
              </div>
            </>
          )}
        </div>
      </div>
    </Box>
  );
};

export default CustomReactSelect;
