import React, { useEffect, useState } from "react";
import _ from "lodash";
import outAxios from "axios";
import CustomButton from "components/CustomButton";
import defaultProfileImg from "assets/images/default-profile.png";
import { t } from "locales";
import { BASE_URL } from "library/config";
import { openToast } from "components/Toast";
import { useDropzone } from "react-dropzone";
import { yupResolver } from "@hookform/resolvers/yup";
import { getHumanError } from "library/translateServerErrors";
import { changePassword, updateUserData } from "store/reducers/auth/asyncActions";
import { Controller, useForm } from "react-hook-form";
import { inputAutoFillStyles } from "assets/styles/styles";
import { b64toBlob, getImageUrl } from "library/helper";
import { changePasswordValidation, updateProfileValidation } from "library/validations/authValidation";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Badge, Box, ButtonBase, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Close, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import AvatarModal from "./AvatarModal";
import ErrorAlert from "components/ErrorAlert";

const EditProfileModal = ({closeModal}) => {
    const MIN_FILE_SIZE = 3000000; // 1MB
    const [image , setImage] = useState("")
    const [open, setOpen] = useState(false);
    const { user, accessToken } = useSelector((state) => state.auth);
    const [file, setFile] = useState();
    const [preview, setPreview] = useState(!!user?.avatar?.length ? user?.avatar : defaultProfileImg);
    const dispatch = useDispatch();
    const handleClose = () => setOpen(false);
    const setImageFromCropper = async (image) => {
        const blob = await b64toBlob(image);
        setPreview(image);
        setFile(blob);
    };
    const handleOpen = (image) => {
        setImage(image);
        setOpen(true);
    };
    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: {
        "image/jpeg": [".jpg", ".jpeg"],
        "image/png": [".png"],
        },
        onDrop: (acceptedFiles) => {
        const selectedFile = acceptedFiles?.[0];

        if (selectedFile?.size <= MIN_FILE_SIZE) {
            handleOpen(window.URL.createObjectURL(selectedFile));
        } else {
            openToast("warning", "Maximum supported file size is 1MB!");
        }
        },
    });
    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: { name: user?.name ?? "", clubName: "", status: "" },
        resolver: yupResolver(updateProfileValidation),
    });

    const submitHandler = async (data) => {
        try {
            const { name } = data;
            const formData = new FormData();
            formData.append("name", name);
            if (file) formData.append("avatar", file);
            
            const config = {
                method: "put",
            url: `${BASE_URL}user`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                authorization: `Bearer ${accessToken}`,
            },
            data: formData,
        };
        
        await outAxios(config);
        
        closeModal()
        openToast("success", "Your profile updated successfully.");

        await dispatch(updateUserData());
        } catch (error) {
        const hError = getHumanError(error);

        openToast("error", hError);
        }
    };

    useEffect(() => {
        return () => {
        URL.revokeObjectURL(preview);
        };
    }, [preview]);








    // change password
    const { status, changePasswordError } = useSelector((state) => state.auth);
    const [showPassword, setShowPassword] = useState({
        cur: false,
        new: false,
        confirm: false,
    });

    const {
        handleSubmit: passwordSubmit,
        control: passwordController,
        formState: { errors: passwordError, isSubmitting: passwordSubmitting },
    } = useForm({
        defaultValues: {
        currentPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        },
        resolver: yupResolver(changePasswordValidation),
    });

    const chnagePasswordHandler = async (data) => {
        try {
        const reqData = {
            oldPassword: data?.currentPassword,
            newPassword: data?.newPassword,
        };

        await dispatch(changePassword(reqData));

        if (status === "fulfilled-changePassword" && !changePasswordError) {
            await dispatch(updateUserData());
            closeModal?.();
        }
        } catch (error) {
        console.log(error?.response);
        }
    };

    useEffect(() => {
        if (status === "fulfilled-changePassword" && !changePasswordError) {
        dispatch(updateUserData());
        openToast("success", "Password has been updated.");
        closeModal?.();
        }
        // eslint-disable-next-line
    }, [status]);

    const handleClickShowPassword = (input) => {
        setShowPassword((prev) => ({ ...prev, [input]: !prev[input] }));
    };
    return (
        <Box 
            sx={{
                display: "flex",
                flexDirection: "column",
                p: "20px",
                minWidth: { xs: "90vw", sm: "60vw", md: "50vw", lg: "560px" },
          }}
        >
            {/* Edit User profile */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
            }}>
                <ButtonBase
                    onClick={closeModal}
                    sx={{
                        width: "38px",
                        height: "38px",
                        borderRadius: "6px",
                        border: "1px solid",
                        borderColor: (theme) => (theme.palette.mode === "dark" ? "#2a2e34" : "#eee"),
                    }}
                    >
                    <Close fontSize="small" />
                </ButtonBase>
            </Box>
            <Box 
                as="form" 
                onSubmit={handleSubmit(submitHandler)} 
                sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        img: { width: "96px", maxHeight: "200px", borderRadius: "1.2rem" },
                    }}
                >
                    <Box {...getRootProps({ className: "dropzone" })} sx={{cursor: 'pointer',}}>
                        <input {...getInputProps()} />
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <Box sx={{
                                    bgcolor: 'primary.main',
                                    width: 24,
                                    height: 24,
                                    borderRadius: '50% !important',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Edit sx={{width: 14, fill: '#FFF'}} />
                                </Box>
                            }
                        >
                            <Avatar  src={getImageUrl(preview)} sx={{width: 96, height: 96}} alt="" />
                        </Badge>
                        
                    </Box>
                </Box>
                <Box>
                    <Controller
                        control={control}
                        name="name"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextField
                            error={errors?.name}
                            helperText={t(errors?.name?.message)}
                            fullWidth
                            name="name"
                            label={t("name")}
                            type="name"
                            id="name"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            ref={ref}
                            sx={inputAutoFillStyles}
                            />
                        )}
                    />
                </Box>
                <CustomButton 
                    type="submit" 
                    fullWidth 
                    variant="contained" 
                    loading={isSubmitting} 
                    disabled={!_.isEmpty(errors, true)} 
                    loaderHeight="40" 
                    withSpinner 
                    extraSx={{ borderRadius: "0.6rem", width: "100%", py: '12.05px' }}
                >
                    {t("updateProfile")}
                </CustomButton>
            </Box>
            <AvatarModal
                imageSrc={image}
                createImage={(image) => {
                    setImageFromCropper(image);
                    handleClose()
                }}
                minSize={MIN_FILE_SIZE}
                {...{open, handleClose}} 
            />



            {/* Edit password */}

           

            <Box
                as="form"
                onSubmit={passwordSubmit(chnagePasswordHandler)}
                sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2.5,
                mt: 4
                }}
            >
                <Typography variant="h6">{t("changePassword")}</Typography>
                {changePasswordError && 
                    <Box sx={{ width: "100%" }}>
                        {<ErrorAlert text={changePasswordError} />}
                    </Box>
                }
                <Controller
                    control={passwordController}
                    name="currentPassword"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                        error={passwordError?.currentPassword}
                        helperText={t(passwordError?.currentPassword?.message)}
                        fullWidth
                        name="currentPassword"
                        label={t("oldPassword")}
                        id="currentPassword"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        ref={ref}
                        sx={inputAutoFillStyles}
                        type={showPassword?.cur ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end" sx={{ mr: "5px" }}>
                                <IconButton
                                onClick={() => handleClickShowPassword("cur")}
                                edge="end"
                                >
                                {showPassword?.cur ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        />
                    )}
                />
                <Controller
                    control={passwordController}
                    name="newPassword"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                        error={passwordError?.newPassword}
                        helperText={t(passwordError?.newPassword?.message)}
                        fullWidth
                        name="newPassword"
                        label={t("newPassword")}
                        id="newPassword"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        ref={ref}
                        sx={inputAutoFillStyles}
                        type={showPassword?.new ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end" sx={{ mr: "5px" }}>
                                <IconButton
                                onClick={() => handleClickShowPassword("new")}
                                edge="end"
                                >
                                {showPassword?.new ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        />
                    )}
                />
                <Controller
                    control={passwordController}
                    name="newPasswordConfirm"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                        error={passwordError?.newPasswordConfirm}
                        helperText={t(passwordError?.newPasswordConfirm?.message)}
                        fullWidth
                        name="newPasswordConfirm"
                        label={t("newPasswordConfirm")}
                        id="newPasswordConfirm"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        ref={ref}
                        sx={inputAutoFillStyles}
                        type={showPassword?.confirm ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end" sx={{ mr: "5px" }}>
                                <IconButton
                                onClick={() => handleClickShowPassword("confirm")}
                                edge="end"
                                >
                                {showPassword?.confirm ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        />
                    )}
                />
                <CustomButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={passwordSubmitting}
                    disabled={!_.isEmpty(passwordError, true)}
                    loaderHeight="40"
                    withSpinner
                    extraSx={{ borderRadius: "0.6rem", width: "100%", py: '12.05px' }}
                    >
                    Change Password
                </CustomButton>
            </Box>
        </Box>
    );
};

export default EditProfileModal;