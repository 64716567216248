import React from 'react';
import { Link } from 'react-router-dom';
import { styles } from './styles';
import { mainRoutes } from 'routing/GeneralRoutes/routes';
import { Box, Button, Stack, Typography } from '@mui/material';

const Header = () => {
    return (
        <Box sx={styles.wrapper} data-aos='zoom-in'>
            <Stack
                direction="column"
                alignItems="center"
                sx={{maxWidth: 640, mx: 'auto', pt: 12, pb: 24}}
            >
                <Box
                    data-aos="fade-up"
                    data-aos-duration="600"
                    data-aos-delay="0"
                    sx={styles.blueBox}
                >
                    <Typography sx={styles.subTitle}>
                        DISCOVER YOUR PREMIER SERVICES
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-delay="150"
                        sx={styles.title}
                    >
                        Take control of your <span> Financial </span> Future!
                    </Typography>
                    <Typography 
                        data-aos="fade-up" 
                        data-aos-duration="600" 
                        data-aos-delay="250" 
                        variant="body1"
                        sx={styles.description}>
                        At TraderGPT, we understand the importance of staying ahead in the fast-paced world of financial trading.
                    </Typography>
                </Box>
                <Box sx={{mt: 4, textAlign: 'center'}}>
                    <Button as={Link} to={mainRoutes.signals} sx={styles.linkButton} >
                        Get Start
                    </Button>
                </Box>
            </Stack>
        </Box>
    );
};

export default Header;