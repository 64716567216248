import React from "react";
import { styles } from "./styles";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { ChangeHistory, HourglassBottomOutlined } from "@mui/icons-material";

const BulkOrder = () => {
  const smallDevice = useMediaQuery("(max-width:904px)");

  const data = [
    {
      id: 1,
      icon: <HourglassBottomOutlined fontSize="large" />,
      title: "Bulking",
      description: "Combine multiple care orders with the same instrument, side and price into a single order for more efficient execution.",
    },
    {
      id: 2,
      icon: <ChangeHistory fontSize="large" />,
      title: "Allocations",
      description: "Assign bulk order fills to the appropriate accounts using the desired allocation algorithm.",
    },
  ];
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.titleWrapper}>
        <Typography sx={styles.title} data-aos="fade-up" data-aos-duration="500">
          03 BULK ORDERS
        </Typography>
        <Typography sx={styles.subTitle} data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
          Optimize executions by grouping orders together.
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={smallDevice ? 3 : 1}>
          {data?.map(({ id, title, icon, description }) => (
            <Grid item xs={12} md={6} key={id} data-aos="fade-up" data-aos-duration="500" data-aos-delay={id * 50 + 100}>
              <Box sx={styles.planWrapper}>
                <Box sx={styles.iconWrapper}>{icon}</Box>
                <Box>
                  <Typography sx={styles.planTitle}>{title}</Typography>
                  <Typography sx={styles.planDescription}>{description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default BulkOrder;
