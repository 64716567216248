import layout from "./layout.js";
import pages from "./pages";
import app from "./app";
import common from "./common";
import landing from "./landing";
import mainFa from "./main-fa";
import invite from "./invite";
import user from "./user";
import assets from "./assets";
import cryptoWars from "./crypto-wars";

const fa = {
  ...layout,
  ...pages,
  ...app,
  ...common,
  ...landing,
  ...mainFa,
  ...invite,
  ...user,
  ...assets,
  ...cryptoWars,
};

export default fa;
