import React from "react";
import { Box, styled } from "@mui/system";
import { Table, Thead, Tbody, Tr, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const TdCustom = styled(Td)(({ count }) => ({
  padding: "1rem 0",
  paddingLeft: count === "0" && 2,
  fontSize: 15
}));

const TrCustom = styled(Tr)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#999" : "#444",
}));

const DataTable = ({ flex, columns, data = [], sx = {}, option = {}, onSelect = null, action = null, ...props }) => {
  return (
    <Box
      sx={{
        ...sx,
        "tbody > tr": { cursor: onSelect && "pointer" },
        ".responsiveTable tbody tr": {
          border: 'none',
          borderBottom: '1px solid #ccc !important',
        },
      }}
    >
      <Table>
        <Thead sx={{}}>
          <TrCustom>
            {!option?.noColumns &&
              columns?.map((column, i) => {
                return (
                  <TdCustom key={i} count={i}>
                    {column.title}
                  </TdCustom>
                );
              })}
          </TrCustom>
        </Thead>
        <Tbody>
          {data?.map((row, i) => (
            <Tr
              key={i}
              {...row?.prop}
              className={"data-row " + (props?.rowClass ?? " ") + (i % 2 ? " row-1" : " row-2") + (onSelect ? " cursor-pointer" : "")}
              onClick={() => (onSelect ? onSelect(row) : null)}
              style={row?.style || row?.value?.style}
            >
              {columns.map((column, j) => (
                <TdCustom key={j} count={i}>
                  {column?.render ? column?.render(row) : row[column.field]}
                </TdCustom>
              ))}
              {action && action(row)}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default DataTable;
