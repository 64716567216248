import React from 'react';
import image from "assets/images/xtaker/STYLIKE.jpg";
import { styles } from './styles';
import { Box, Typography } from '@mui/material';

const TradeWork = () => {
    return (
        <Box sx={styles.wrapper}>
            <Box className='container'>
                <Box sx={styles.titleWrapper}>
                    <Typography sx={styles.title} data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
                        How does TraderGPT works?
                    </Typography>
                    <Box sx={styles.image}>
                        <img src={image} alt='' />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default TradeWork;