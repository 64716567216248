export const styles = {
  newTicket: {
    display: "flex",
    flexDirection: "column",
    p: "1.5rem",
    width: { xs: "95vw", sm: "60vw", lg: "50vw", xl: "700px" },
    maxWidth: "700px",
  },

  header: {
    display: "flex",
    alignItems: "center",
    mb: " 1.5rem",
  },

  selectContainer: {
    fieldset: { border: "none !important" },
  },
  selectInput: { bgcolor: "rgb(234, 240, 247)" },

  headerTitle: {
    flexGrow: "1",
    fontWeight: "bold",
  },

  closeWrapper: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid",
    borderRadius: "3px",
    color: (theme) => (theme.palette.mode === "dark" ? "#eee" : "#414244"),
    borderColor: (theme) => `border.${theme.palette.mode}`,
  },

  dropdownWrapper: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    gap: { xs: 3, sm: 2 },
    my: 2,

    "& > div": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  },

  fileInput: {
    display: "flex",
    flexDirection: "column",
    mt: 2,

    "& > label": {
      mb: 1,
    },
  },

  dropzone: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "fit-content",
    padding: "1rem 3rem",
    bgcolor: (theme) => theme.palette.primary.light,
    cursor: "pointer",
  },

  dropzoneText: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },

  selectedFiles: {
    display: "flex",
    flexDirection: "column",
    margin: "1rem 0 1.5rem 0",
  },

  singleSelectedFile: {
    display: "flex",
    alignitems: "center",
    width: "fit-content",

    "& > p": {
      zIndex: "2000",
    },

    "& > svg": {
      transform: "translateX(20px)",
      opacity: "0",
      zIndex: "1500",
      transition: "all 300ms ease",
      cursor: "pointer",
      color: "red",
    },

    "&:hover": {
      "& > svg": {
        opacity: "1",
        transform: "translateX(0)",
      },
    },
  },

  progressWrapper: {
    margin: "1rem 0",

    ".progress": {
      borderRadius: "3px !important",
    },
  },

  footer: {
    display: "flex",
    alignitems: "center",
    justifyContent: "flex-end",
  },

  submitBtn: {
    outline: "none",
    border: "none",
    padding: "0.75rem 1.5rem",
    borderRadius: "0.3rem",
    background: "linear-gradient(to right, #5b1af2, #8b01d2)",
    color: "#fff",

    "&:disabled": {
      background: "rgb(47, 50, 65) !important",
    },
  },

  cancelBtn: {
    display: "flex",
    alignItems: "center",
    margin: "0 0.8rem",
    px: 3,
    borderRadius: "0.6rem",
    cursor: "pointer",
  },
};
