export const styles = {
    wrapper: {
        py: 4,
        width: "100%",
        borderTop: "1px solid",
        background: "#0F0D19",
    },
    footerImage: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        mb: 2,
        "& img": {
            width: "100%",
            maxWidth: 200,
        }
    },
    footerDescription: {
        color: "#FFF",
        fontSize: 14,
        fontWeight: 300,
    },
    title: {
        color: "#DDDDDD",
        fontSize: 16,
        mb: 2,
    },
    linkStyle: {
        display: "flex",
        alignItems: "cener",
        fontSize: 14,
        color: "#919191",
        cursor: "pointer",
        textDecoration: "none",
        "& svg": {
            width: 14,
            ml: 0.2
        },
        "&:hover": {
            color: "#FFF",
            transition: "all 0.2s ease-out"
        }
    },
    divider: {
        mt: 4,
        background: "#919191",
        width: "100%"
    },
    copyrightText: {
        color: "#919191",
        fontSize: 13
    },
}