import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import UserMenu from "./UserMenu";
import { useSelector } from "react-redux";
import { t } from "locales";

export default function UserNav() {
  const isLoggedIn = useSelector((state) => !!state.auth.user);

  if (isLoggedIn) {
    return <UserMenu />;
  }
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Button
        component={Link}
        to={"/auth/login"}
        variant="text"
        sx={[
          {
            boxShadow: "none",
            padding: "7px 20px",
            borderRadius: "10px",
          },
          (theme) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
              color: "#000"
            }
          }),
        ]}
        >
        {t("login")}
      </Button>
      <Button
        component={Link}
        to={"/auth/register"}
        variant="text"
        sx={{
          bgcolor: "common.white",
          color: "common.black",
          padding: "7px 20px",
          borderRadius: "10px",
          border: "1px solid transparent",
          "&:hover": {
            bgcolor: (theme) => theme.palette.primary.light,
            color: "#000"
          },
        }}
      >
        {t("getStarted")}
      </Button>
    </Stack>
  );
}
