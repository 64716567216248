import { Box, Typography } from '@mui/material';
import WidgetBot from '@widgetbot/react-embed';
import React, { useRef } from 'react';
import Header from './Header';

const Signals = () => {
    const ref = useRef(null);
    const offsetWidth = ref.current?.offsetWidth;
    return (
        <Box >
            <Header />
            <Box className="container">
                <Box ref={ref} id='ai-signals' sx={{my: 6}}>
                    <WidgetBot
                        server="1113799791877562438"
                        channel="929330082877681747"
                        height={600}
                        width={offsetWidth}
                        style={{
                            display: 'none !important',
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Signals;