import { Avatar, Box, Button, CircularProgress, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { StyledMenu } from './styles';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Submenu = ({item}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const menuHandler = ({url}) => {
        navigate(url);
        setAnchorEl(null);
    };
    return (
        <Box>
            <Button
                onClick={handleClick}
                endIcon={<KeyboardArrowDown />}
                sx={{color:"#000"}}
            >
                {item.title}
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{ 'aria-labelledby' : 'demo-customized-button' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
            {item?.isLoading ?
                <Box sx={{textAlign: "center", py: 3}}>
                    <CircularProgress />
                </Box>
                :
                item?.submenu?.map(({title, to, icon: Icon, img, id}) => (
                    <MenuItem onClick={() => menuHandler({url: to})} key={id}>
                        {!!img ?
                        <Box>
                            <Avatar src={img} alt="" sx={{width: 16, height: 16, mr: "12px"}} />
                        </Box>
                        :
                        <Icon />
                        }
                        <Typography variant='body2'>{title}</Typography>
                    </MenuItem>
                ))
            }
            </StyledMenu>
        </Box>
    );
};

export default Submenu;