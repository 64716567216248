import React from 'react';
import Card from './Card';
import { getImageUrl } from 'library/helper';
import { Box, Grid, Typography } from '@mui/material';

const About = ({data, loading}) => {
    return (
        <Box sx={{mt: 8}}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Box sx={{
                        '& img': {
                            width: '100%',
                            borderRadius: 6,
                            boxShadow: "none",
                            transition: "all 0.2s ease-out",
                            '&:hover': {
                                transform: 'scale(1.03)',
                                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            },
                        }
                    }}>
                        <img src={getImageUrl(data?.traders?.trader?.avatar)} alt="" />
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box>
                        <Box>
                            <Typography sx={{color: 'primary.main', fontWeight: 600}}>Discover</Typography>
                            <Typography sx={{ fontWeight: 600, fontSize: 32}}>About Me</Typography>
                            <Box sx={{height: '3px', width: '49px', bgcolor: 'primary.main', borderRadius: 24}} />
                        </Box>
                        <Box sx={{mt: 3}}>
                            <Card data={data} loading={loading} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default About;