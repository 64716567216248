import { useState, useEffect } from "react";
import { Button, Typography, Stack, Box, TextField, InputAdornment, CircularProgress, FormHelperText, Grid } from "@mui/material";
import { XTAKER_COIN } from "library/constants";
import SelectInput from "components/SelectInput";
import { useGetAllPlans } from "_hooks/Xtakers";
import { getImageUrl } from "library/helper";
import noImage from "assets/images/no_image.png";

const PublicContent = () => {
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedPlanId, setSelectedPlanId] = useState();
  const { data: plans, isFetching: isLoading } = useGetAllPlans(null, (res) => res?.length && setSelectedPlanId(res[0]?.id));
  console.log("🚀 ~ file: PublicContent.jsx:13 ~ PublicContent ~ plans:", plans);
  const [activeCurrency, setActiveCurrency] = useState(0);
  const [inputValue, setInputValue] = useState(10000);
  const [error, setError] = useState(null);

  useEffect(() => {
    setInputValue(selectedPlan?.maxInvestment);
  }, [selectedPlan]);

  useEffect(() => {
    if (+inputValue > +selectedPlan?.maxInvestment) {
      setError(`Maximum investment is ${selectedPlan?.maxInvestment} ${XTAKER_COIN}`);
    } else if (+inputValue < +selectedPlan?.minInvestment) {
      setError(`Minimum investment is ${selectedPlan?.minInvestment} ${XTAKER_COIN}`);
    } else {
      setError(null);
    }
  }, [inputValue, selectedPlan?.maxInvestment, selectedPlan?.minInvestment]);

  useEffect(() => {
    const filteredData = plans?.find((item) => item?.id === selectedPlanId);
    setSelectedPlan(filteredData);
  }, [selectedPlanId, inputValue, plans]);

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      data-aos="fade-up"
      data-aos-duration="600"
      sx={{
        mt: "100px",
        width: { xs: "100%", md: "45%" },
      }}
    >
      {isLoading ? (
        <Stack sx={{ minHeight: "100px", alignItems: "center" }}>
          <CircularProgress size={28} />
        </Stack>
      ) : (
        <Box
          sx={{
            borderRadius: "16px",
            overflow: "hidden",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        >
          <Grid container direction="row" alignItems="stretch">
            <Grid item xs={12} lg={6}>
              <Stack
                direction="column"
                alignItems="flex-start"
                sx={{
                  position: "relative",
                  width: "100%",
                  p: { xs: "16px", lg: 4 },
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight={500}
                  data-aos="fade-up"
                  data-aos-duration="600"
                  sx={{
                    opacity: 0.7,
                    mb: 1,
                  }}
                >
                  Select Plan
                </Typography>
                <Box data-aos="fade-up" data-aos-duration="600" data-aos-delay="150" sx={{ width: "100%" }}>
                  <SelectInput
                    options={plans?.map((item) => ({
                      icon: item?.icon?.[0]?.location || noImage,
                      label: item?.title,
                      id: item?.id,
                    }))}
                    withIcon
                    onChange={setSelectedPlanId}
                    value={selectedPlanId}
                    setId={true}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "0 !important",
                        },
                      },
                    }}
                    containerSx={{
                      width: "100%",
                      border: "1px solid",
                      boxShadow: "none !important",
                      borderColor: "rgba(0,0,0,0.0) !important",
                      "& .Mui-focused": {
                        "& fieldset": {
                          borderColor: "rgba(0,0,0,0.0) !important",
                        },
                      },
                      "& fieldset": {
                        borderColor: (theme) => (theme.palette.mode === "light" ? "rgba(0,0,0,0.0)" : "#cecece16"),
                        boxShadow: (theme) => theme.palette.mode === "light" && "0 4px 20px 0 rgb(223 227 234 / 30%)",
                      },
                    }}
                    itemSx={{
                      "& img": {
                        width: "30px",
                        height: "30px",
                      },
                    }}
                  />
                </Box>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="250"
                  sx={{
                    opacity: 0.7,
                    mt: 3,
                    mb: 1,
                  }}
                >
                  I want to invest
                </Typography>

                <Stack sx={{ width: "100%" }}>
                  <TextField
                    data-aos="fade-up"
                    data-aos-duration="600"
                    data-aos-delay="200"
                    type="number"
                    fullWidth
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: "12px",
                        boxShadow: "none !important",
                        border: "1px solid",
                        borderColor: "rgba(0,0,0,0.2) !important",
                      },
                    }}
                    value={inputValue}
                    onChange={(event) => setInputValue(event.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                setActiveCurrency(0);
                              }}
                              sx={{
                                color: (theme) => (activeCurrency === 0 ? theme.palette.primary.main : "#7b8291"),
                                border: "solid 1px",
                                borderolor: (theme) => (activeCurrency === 0 ? theme.palette.primary.main + "10" : "rgba(123, 130, 145, 0.15)"),
                                backgroundColor: activeCurrency === 0 ? "rgba(0, 125, 255, 0.1)" : "transparent",
                                padding: "6px 8px",
                                fontSize: "14px",
                                minWidth: "55px",
                                boxSizing: "border-box",
                                textAlign: "center",
                                fontWeight: activeCurrency === 0 ? "600" : "500",
                                lineHeight: "normal",
                                marginLeft: "10px",
                                borderRadius: "8px",
                                letterSpacing: "0.1px",
                                "&:hover": {
                                  color: (theme) => (activeCurrency === 0 ? theme.palette.primary.main : "#7b8291"),
                                  backgroundColor: (theme) => (activeCurrency === 0 ? theme.palette.primary.main + "20" : "transparent"),
                                },
                              }}
                            >
                              {XTAKER_COIN}
                            </Button>
                          </Stack>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {!!error && <FormHelperText sx={{ color: "#f44336", zIndex: "1" }}>{error}</FormHelperText>}
                </Stack>

                <Typography
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="250"
                  variant="body1"
                  fontWeight={500}
                  sx={{
                    opacity: 0.7,
                    mt: 3,
                    mb: 1,
                  }}
                >
                  Lock-up period (days)
                </Typography>
                <Box
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="300"
                  sx={{
                    position: "relative",
                    opacity: 1,
                    height: "28px",
                    color: (theme) => theme.palette.primary.main,
                    border: "none",
                    cursor: "default",
                    fontWeight: 600,
                    backgroundColor: (theme) => theme.palette.primary.main + "20",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    px: 1,
                  }}
                >
                  <Typography variant="body1">{selectedPlan?.duration || "-"}</Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Stack
                direction="column"
                alignItems="flex-start"
                sx={{
                  p: { xs: "16px", lg: 4 },
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#362748" : theme.palette.primary.main),
                }}
              >
                <Typography
                  variant="body2"
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="100"
                  sx={{
                    color: (theme) => theme.palette.common.white,
                    opacity: 0.7,
                  }}
                >
                  Est. earnings({XTAKER_COIN})
                </Typography>
                <Typography
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="150"
                  fontWeight={600}
                  mt={1}
                  sx={{
                    color: (theme) => theme.palette.common.white,
                    fontSize: 20,
                  }}
                >
                  {Math.round((Number(inputValue) + (selectedPlan?.minProfit * Number(inputValue)) / 100) * 1000) / 1000 || 0} -{" "}
                  {Math.round((Number(inputValue) + (selectedPlan?.maxProfit * Number(inputValue)) / 100) * 1000) / 1000 || 0}
                </Typography>
                <Typography
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="200"
                  variant="body2"
                  mt={5}
                  sx={{
                    color: (theme) => theme.palette.common.white,
                    opacity: 0.7,
                  }}
                >
                  Est. Profit
                  {/* APY */}
                </Typography>
                <Typography
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="250"
                  variant="h5"
                  fontWeight={600}
                  mt={1}
                  sx={{
                    color: (theme) => theme.palette.common.white,
                  }}
                >
                  {selectedPlan?.minProfit ? (
                    <>
                      {selectedPlan?.minProfit}% - {selectedPlan?.maxProfit}%
                    </>
                  ) : (
                    "-"
                  )}
                </Typography>
                <Typography
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="300"
                  mt={3}
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.common.white,
                    opacity: 0.7,
                  }}
                >
                  This calculator is for reference only and the offers may change from time to time. The final details will be shown when you
                  subscribe.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </Stack>
  );
};

export default PublicContent;
