import { Link } from "react-router-dom";
import { LiveHelpOutlined, Games, DescriptionOutlined, MenuBook, HomeRounded, PersonRounded, NotificationAddRounded, PersonAdd, DownloadRounded, UploadRounded } from "@mui/icons-material";
import { discord, whitePaper } from "library/links";
import { Link as MuiLink } from "@mui/material";
import CalculateIcon from "@mui/icons-material/Calculate";
import { profile } from "routing/GeneralRoutes/routes";

export const topMenuItems = [
  {
    path: profile.overview,
    title: "Profile",
    icon: PersonRounded,
    as: Link,
    justMobile: true,
  },
  {
    path: profile.deposid,
    title: "Deposit",
    icon: DownloadRounded,
    as: Link,
    justMobile: true,
  },
  {
    path: profile.withdraw,
    title: "Withdraw",
    icon: UploadRounded,
    as: Link,
    justMobile: true,
  },
];

export const bottomMenuItems = [
  {
    href: whitePaper,
    title: "Whitepaper",
    icon: DescriptionOutlined,
    as: MuiLink,
    otherProps: {
      target: "_blank",
      rel: "noreferrer",
    },
  },
  {
    title: "Community",
    icon: Games,
    href: discord,
    as: MuiLink,
    otherProps: {
      target: "_blank",
      rel: "noreferrer",
    },
  },
  {
    title: "FAQ",
    icon: LiveHelpOutlined,
    path: "/faq",
    as: Link,
  },
  {
    title: "Calculator",
    icon: CalculateIcon,
    path: "/guide/calculator",
    as: Link,
  },
  {
    title: "Guide",
    icon: MenuBook,
    path: "/guide",
    as: Link,
  },
];
