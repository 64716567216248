import { SkeletonLoader } from "components/Skeleton";
import { t } from "locales";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useTerminatePlan } from "_hooks/Xtakers";
import { Box, Stack, Typography, Button } from "@mui/material";
import { getHumanError } from "library/translateServerErrors";
import { openToast } from "components/Toast";

const ConfirmModal = ({
  toggleNeedRefresh,
  onCancel,
  onSuccess,
  id,
  isFinished = false,
}) => {
  const { mutateAsync: terminatePlan, isLoading } = useTerminatePlan();
  const isLight = useSelector((state) => state.setting.theme === "light");

  const onTerminate = async () => {
    const reqUrl = isFinished
      ? "xtakerUserPlan/v1/withdrawPlan"
      : "xtakerUserPlan/v1/terminatePlan";

    try {
      await terminatePlan({ reqUrl, id });

      toggleNeedRefresh?.();
      onSuccess();
    } catch (error) {
      const hError = getHumanError(error) || "Something went wrong!";
      openToast("error", hError);
    }
  };

  return (
    <Stack
      gap={2}
      sx={{
        "& > div": { p: 2 },
        minWidth: "400px",
        width: { sx: "90vw", sm: "70vw", md: "50vw", lg: "40vw" },
      }}
    >
      <Stack direction="row" alignItems="center">
        <Typography variant="h6" flexGrow="1">
          {t("confirm")}
        </Typography>
        <Close size={20} onClick={onCancel} sx={{ cursor: "pointer" }} />
      </Stack>

      <Box className="body">
        <Typography variant="body1">{t("confirmTerminatePlanDesc")}</Typography>
      </Box>

      <Stack
        direction="row"
        gap={1}
        sx={{ button: { borderRadius: "4px", py: 1 } }}
      >
        {!isLoading && (
          <Button fullWidth variant="outlined" onClick={onCancel}>
            {t("cancel")}
          </Button>
        )}

        {isLoading ? (
          <SkeletonLoader
            isLight={isLight}
            width="130"
            height="48"
            borderRadius="1.5rem"
          />
        ) : (
          <Button fullWidth variant="contained" onClick={onTerminate}>
            {isFinished ? t("withdraw") : t("terminate")}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default ConfirmModal;
