import { useState } from "react";
import { t } from "locales";
import DataTable from "components/DataTable";
import { useSelector } from "react-redux";
import { Pagination, Typography, Box, Stack } from "@mui/material";
import { SkeletonLoader } from "components/Skeleton";
import { useGetClaimRewardHistory } from "_hooks/Xtakers";
import formatDate from "library/formatDate";
import { XTAKER_COIN } from "library/constants";

const LOADING_LIST = Array(5).fill({
  time: "",
  coin: "",
  amount: "",
  status: "",
  depositAddress: "",
  txid: "",
});

const ClaimRewardHistory = ({ id }) => {
  const [page, setPage] = useState(1);
  const isLight = useSelector((state) => state.setting.theme === "light");
  const { data, isLoading: loading } = useGetClaimRewardHistory(id, {
    page,
    limit: 5,
  });

  const onPageChange = (_, value) => setPage(value);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        bgcolor: (theme) => `card.${theme.palette.mode}`,
        p: "20px",
        borderRadius: "12px",
        height: "100%",
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "500" }}>
        Claim History
      </Typography>
      <Stack
        sx={{
          flexGrow: "1",
          justifyContent: data?.total !== 0 ? "space-between" : "unset",
        }}
      >
        <DataTable
          columns={[
            {
              title: t("amount"),
              field: "amount",
              render: ({ amount }) =>
                loading ? (
                  <SkeletonLoader
                    isLight={isLight}
                    height="15"
                    width="35"
                    borderRadius="2"
                  />
                ) : (
                  <Typography variant="body2">
                    {amount} {XTAKER_COIN}
                  </Typography>
                ),
            },

            {
              title: t("time"),
              field: "createdAt",
              render: ({ createdAt }) =>
                loading ? (
                  <SkeletonLoader
                    isLight={isLight}
                    height="15"
                    width="60"
                    borderRadius="2"
                  />
                ) : (
                  <Typography variant="body2">
                    {formatDate(createdAt, {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      lang: "en",
                    })}
                  </Typography>
                ),
            },
          ]}
          data={loading ? LOADING_LIST : data?.list}
          rowClass="py-2"
          headClass="table-head"
        />
        {!loading && data?.total === 0 ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 5,
            }}
          >
            <Typography variant="body1">{t("noDataFound")}</Typography>
          </Box>
        ) : (
          <Box sx={{ mx: "auto", width: "fit-content", mt: 3 }}>
            <Pagination
              sx={{ width: "fit-content", mt: "auto" }}
              count={data?.totalPage || 1}
              page={page}
              onChange={onPageChange}
            />
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default ClaimRewardHistory;
