import React from "react";
import { styles } from "./styles";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { DragIndicator, LocalGroceryStoreRounded, TrendingUpRounded } from "@mui/icons-material";

const Visualize = () => {
  const smallDevice = useMediaQuery("(max-width:904px)");
  const plans = [
    {
      id: 1,
      Icon: LocalGroceryStoreRounded,
      title: "Market Information",
      description: "See real-time market data, market depth and time and sales in numerous widgets designed to suit your needs.",
    },
    {
      id: 2,
      Icon: DragIndicator,
      title: "MD Trader",
      description: "Track the ebb and flow of the market and get a visual sense of relative price movement with our static price ladder.",
    },
    {
      id: 3,
      Icon: TrendingUpRounded,
      title: "Charts",
      description: "Integrated charts and technical analysis provide traders a complete trading solution.",
    },
  ];
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.titleWrapper}>
        <Typography sx={styles.title} data-aos="fade-up" data-aos-duration="500">
          02 VISUALIZE
        </Typography>
        <Typography sx={styles.subTitle} data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
          View, chart and trade the markets with flexibility and ease
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={smallDevice ? 3 : 1}>
          {plans?.map(({ id, title, Icon, description }) => (
            <Grid item xs={12} md={4} key={id} data-aos="fade-up" data-aos-duration="500" data-aos-delay={id * 50 + 100}>
              <Box sx={styles.planWrapper}>
                <Box sx={styles.iconWrapper}>
                  <Icon />
                </Box>
                <Box>
                  <Typography sx={styles.planTitle}>{title}</Typography>
                  <Typography sx={styles.planDescription}>{description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Visualize;
