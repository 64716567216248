import React, { useState } from "react";
import { styles } from "./styles";
import { useSelector } from "react-redux";
import { getImageUrl } from "library/helper";
import { ChevronRightRounded, ContentCopyOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import ChangePasswordModal from "./Setting/AccountSetting/PasswordModal";
import GetBscModal from "components/getBscModal";
import { t } from "locales";
import { openToast } from "components/Toast";
import CopyToClipboard from "react-copy-to-clipboard";
import EditProfileModal from "./EditProfileModal";

const UserDetail = () => {
  const [isCopied, setIsCopied] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const bscAddrress = user?.address;
  const modal = useSelector((state) => state.app.modal);
  const closeModal = () => modal?.hide();
  const openPasswordModal = () => {
    modal.show(<ChangePasswordModal closeModal={closeModal} />);
  };
  const openEditProfileModal = () => {
    modal.show(<EditProfileModal closeModal={closeModal} />);
  };
  const openBscModal = () => {
    modal.show(
      <GetBscModal
        onClose={closeModal}
        onWalletSeted={() => {
          openToast("success", t("saveSuccessfuly"));
          modal.hide();
          setIsCopied(true);
        }}
      />
    );
  };
  const onLeaveCopySection = () => {
    setTimeout(() => {
      setIsCopied(false);
    }, 300);
  };
  const onCopey = () => setIsCopied(true);
  return (
    <Box sx={styles.userDetailWrapper}>
      <Box sx={styles.avatarWrapper}>
        <Avatar src={getImageUrl(user?.avatar)} sx={styles.avatar} />
      </Box>
      <Typography sx={styles.name}>{user?.name}</Typography>
      <Typography sx={styles.email}>{user?.email}</Typography>
      <Box sx={styles.bscAddrressWrapper}>
        <Typography sx={{ fontSize: 14, opacity: 0.8 }}>{bscAddrress && bscAddrress?.slice(0, 8) + "..." + bscAddrress?.slice(bscAddrress.length - 5, bscAddrress.length)}</Typography>
        {bscAddrress && (
          <IconButton className="value">
            <CopyToClipboard text={bscAddrress} onMouseLeave={onLeaveCopySection} onCopy={onCopey}>
              <Tooltip arrow placement="top" title={isCopied ? "Copied" : "Copy"}>
                <ContentCopyOutlined sx={{ width: 18, height: 18 }} />
              </Tooltip>
            </CopyToClipboard>
          </IconButton>
        )}
      </Box>
      <Grid container spacing={1} sx={styles.buttonWrapper}>
        {/* <Grid item xs={12} md={6}>
          <Button sx={styles.bscButton} onClick={openBscModal}>
            USDT Address
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button sx={styles.editButton} onClick={openPasswordModal}>
            Password
          </Button>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <Button onClick={openEditProfileModal} endIcon={<ChevronRightRounded />} sx={styles.editButton}>
            Edit Profile
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetail;
