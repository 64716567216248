import { Menu } from "@mui/material";
import { styled, alpha } from '@mui/material/styles';

export const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: "12px !important",
      marginTop: theme.spacing(1),
      minWidth: 180,
      border: "1px solid rgb(215,215,215)",
      boxShadow: "none !important",
      '& .MuiMenu-list': {
        padding: '6px',
        backgroundColor: "#FFF",
        borderRadius: "6px !important",
      },
      '& .MuiMenuItem-root': {
        borderRadius: "6px !important",
        margin: "3px 0 !important",
        padding: "8px 12px !important",
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));