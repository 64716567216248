import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "store/reducers/auth/asyncActions";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordValidation } from "library/validations/authValidation";
import { Button, CircularProgress, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { t } from "locales";
import { inputAutoFillStyles, rtlTextFieldStyle } from "assets/styles/styles";
import { useEffect } from "react";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { openToast } from "components/Toast";
import AuthLayout from "components/AuthLayout";
import { Helmet } from "react-helmet";
import CustomButton from "components/CustomButton";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState({ new: false, con: false });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleClickShowPassword = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forgotPasswordToken = useSelector((state) => state.auth.forgotPasswordToken);

  // CHECK FOR VERIFY TOKEN
  useEffect(() => {
    if (!forgotPasswordToken) {
      navigate("/auth/forgot-password");
    }
    // eslint-disable-next-line
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { email: "", mobile: "", password: "" },
    resolver: yupResolver(resetPasswordValidation),
  });

  const onSubmit = async (data) => {
    try {
      const gToken = await executeRecaptcha();
      const resetData = {
        password: data?.password,
        token: forgotPasswordToken,
        gRecaptchaResponse: gToken,
      };

      const result = await dispatch(resetPassword(resetData));
      if (!result.error) {
        openToast("success", "Password successfully changed");
        navigate("/auth/login");
      } else {
        openToast("error", "Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthLayout>
      <Helmet>
        <title>Reset Password | Tradergpt</title>
        <meta name="description" content="Reset Password | Tradergpt" />
      </Helmet>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            mb: "48px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("resetPassword")}
            </Typography>
          </Box>

          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{"& .MuiFormControl-root": {my: 0}}}>
                <Typography sx={{ mb: 0.5, fontSize: 14, fontWeight: 500 }}>{t("password")}</Typography>
                <Controller
                  control={control}
                  name="password"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      type={showPassword?.new ? "text" : "password"}
                      error={errors?.password}
                      helperText={t(errors?.password?.message)}
                      margin="normal"
                      fullWidth
                      id="password"
                      placeholder={t("password")}
                      name="password"
                      autoFocus
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      sx={[{ ...inputAutoFillStyles }, (theme) => theme.direction === "rtl" && rtlTextFieldStyle]}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ mr: "5px" }}>
                            <IconButton onClick={() => handleClickShowPassword("new")} edge="end">
                              {showPassword?.new ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{"& .MuiFormControl-root": {my: 0}}}>
                <Typography sx={{ mb: 0.5, fontSize: 14, fontWeight: 500 }}>{t("passwordConfirmation")}</Typography>
                <Controller
                  control={control}
                  name="passwordConfirmation"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      type={showPassword?.con ? "text" : "password"}
                      error={errors?.passwordConfirmation}
                      helperText={t(errors?.passwordConfirmation?.message)}
                      margin="normal"
                      fullWidth
                      name="passwordConfirmation"
                      placeholder={t("passwordConfirmation")}
                      id="passwordConfirmation"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                      sx={[{ ...inputAutoFillStyles }, (theme) => theme.direction === "rtl" && rtlTextFieldStyle]}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ mr: "5px" }}>
                            <IconButton onClick={() => handleClickShowPassword("con")} edge="end">
                              {showPassword?.con ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{"& .MuiFormControl-root": {my: 0}}}>
              <CustomButton 
                type="submit" 
                fullWidth 
                variant="contained" 
                withSpinner
                disabled={isSubmitting}
                loading={isSubmitting}
                extraSx={{
                  borderRadius: 2.4,
                  width: "100%",
                  color: "#FFF",
                  py: "11.15px",
                  mt: 1,
                }}
              >
                {t("submit")}
              </CustomButton>
              </Grid>
            </Grid>  
          </Box>
        </Box>
      </Container>
    </AuthLayout>
  );
};

export default ResetPassword;
