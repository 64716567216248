import { calcTotalPages } from "library/helper";
import { SingleLabelValue } from "pages/Home/components/TraderSelect";
import { useQuery, useMutation, useQueryClient } from "react-query";
import ApiCall, { ApiCallAlgo } from "_clients/apiCall";
import axios from "axios";

export const useGetAllPlans = (params = {}, onSuccess) => {
  return useQuery(["xtakers-plans", params], () => ApiCall("GET", "stake/money", null, params, (res) => res.data?.data), { onSuccess });
};

export const useGetAllIcoPlans = (params = {}, onSuccess) => {
  return useQuery(["ico-plans", params], () => ApiCall("GET", "token/ico", null, params, (res) => res.data?.data), { onSuccess });
};

export const useBuyToken = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => ApiCall("POST", "token/ico", data), {
    onSuccess: () => {
      queryClient.invalidateQueries("ico-user-plans");
    },
  });
};

export const useGetSinglePlan = (id) => {
  return useQuery("xtakers-single-plan", () => ApiCall("GET", `stake/money/${id}`, null, null, (res) => res.data?.data));
};

export const useGetXtakerBalance = () => {
  return useQuery("xtaker-balance", () => ApiCall("GET", "wallet/v1/getXtakerBalance", null, null, (res) => res.data?.data));
};

export const useAddXtakerPlan = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => ApiCall("POST", "stake/money", data), {
    onSuccess: () => {
      queryClient.invalidateQueries("xtaker-user-plans");
    },
  });
};

export const useTerminatePlanProfitOfXtakerPlan = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => ApiCall("POST", "xtakerUserPlan/v1/terminatePlan", data), {
    onSuccess: () => {
      queryClient.invalidateQueries("xtaker-user-plans-profit-withdraw");
    },
  });
};

export const useWithdrawPlanProfitOfXtakerPlan = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => ApiCall("POST", "xtakerUserPlan/v1/withdrawPlan", data), {
    onSuccess: () => {
      queryClient.invalidateQueries("xtaker-user-plans-profit-withdraw");
    },
  });
};

export const useGetUserPlans = (params = {}, enabled = true) => {
  return useQuery(
    ["xtaker-user-plans", params],
    () =>
      ApiCall("GET", "xtakerUserPlan/v1/userPlans", null, params, (data) => ({
        list: data.data?.data,
        total: calcTotalPages(data.data?.data?.total, data.data?.data?.pageSize),
      })),
    { keepPreviousData: true, enabled }
  );
};

export const useGetSingleSubscribedPlan = (params = {}, onError = () => {}) => {
  return useQuery(["xtaker-user-plan", params], () => ApiCall("GET", "xtakerUserPlan/v1/userPlan", null, params, (data) => data.data?.data), {
    onError,
  });
};

export const useGetSingleUserPlan = (params = {}, onError = () => {}) => {
  return useQuery(
    ["xtaker-single-user-plan", params],
    () => ApiCall("GET", `xtakerUserPlan/v1/userPlan?id=${params?.id}`, null, params, (data) => data.data?.data),
    { onError }
  );
};

export const useTerminatePlan = () => {
  const queryClient = useQueryClient();

  return useMutation(({ reqUrl, id }) => ApiCall("POST", reqUrl, { id }), {
    onSuccess: () => {
      queryClient.invalidateQueries("xtaker-user-plans");
    },
  });
};

export const useGetSpotBalance = (params = {}) => {
  return useQuery(["spot-balance", params], () => ApiCall("GET", "wallet/v1/getSpot", null, params, (data) => data.data?.data));
};

export const useGetTradeHistory = (params = {}) => {
  return useQuery(["xtaker-trade-history", params], () =>
    ApiCall("GET", "trade/v1/history", null, params, (data) => ({
      list: data.data?.data?.trades,
      total: calcTotalPages(data.data?.data?.count, 10),
    }))
  );
};

export const useGetRawTradeHistory = (params = {}) => {
  return useQuery(["xtaker-raw-trade-history", params], () =>
    ApiCall("GET", "trade/v1/rawHistory", null, params, (data) => ({
      list: data.data?.data?.trades,
      total: calcTotalPages(data.data?.data?.count, 10),
    }))
  );
};

export const useGetXtakerTraders = (params = {}) => {
  return useQuery(["xtaker-traders-lists", params], () =>
    ApiCall("GET", "trader/v1/traders", null, params, (data) => ({
      list: data.data?.data?.map((item) => ({
        ...item,
        value: item.id,
        label: <SingleLabelValue item={item} />,
      })),
      total: calcTotalPages(data.data?.total, 10),
    }))
  );
};

export const useChangeXtakerTrader = () => {
  const queryClient = useQueryClient();

  return useMutation((data = {}) => ApiCall("POST", "xtakerUserPlan/v1/changePlanTrader", data), {
    onSuccess: () => {
      queryClient.invalidateQueries("xtaker-user-plan");
    },
  });
};

export const useClaimPlanReward = () => {
  const queryClient = useQueryClient();

  return useMutation((id) => ApiCall("POST", "claim/v1/claim", { id }), {
    onSuccess: () => {
      queryClient.invalidateQueries("xtaker-user-plan");
      queryClient.invalidateQueries("xtaker-claim-reward-history");
    },
  });
};

export const useGetClaimRewardHistory = (id, params = {}) => {
  return useQuery(["xtaker-claim-reward-history", id, params], () =>
    ApiCall("GET", `claim/v1/claim-history/${id}`, null, params, (data) => data?.data?.data)
  );
};

// public/tag?page&limit&sort&order&id&createdAt&searchQuery&name&color
export const useGetAllTags = (params = {}) => {
  return useQuery(["xtaker-claim-reward-history", params], () =>
    ApiCall("GET", `public/tag`, null, params, (data) => ({
      list: data.data?.data?.data,
      total: calcTotalPages(data.data?.data?.total, data.data?.data?.pageSize),
    }))
  );
};

export const useGetXtakerStats = (params = {}) => {
  return useQuery(["xtaker-user-stats", params], () => ApiCall("GET", `xtakerUserPlan/v1/stats`, null, params, (data) => data.data?.data));
};
export const useGetXtakerSingleTrader = (params = {}) => {
  return useQuery(["xtaker-single-trader", params], () => ApiCall("GET", `trade/v1/trader`, null, params, (data) => data.data?.data));
};

export const useGetCurrencyDetails = (params) => {
  return useQuery(["get-currency-details", params], () => axios.get(`https://api.coingecko.com/api/v3/coins/${params?.coin}`), {
    enabled: false,
  });
};

export const useGetCoinPriceDetails = (params) => {
  return useQuery(
    ["get-price-details", params],
    () =>
      axios.get(`https://api.coingecko.com/api/v3/coins/${params?.coin}/market_chart?vs_currency=usd&days=30`).then((res) => {
        return {
          data: res?.data,
        };
      }),
    {
      enabled: false,
    }
  );
};
