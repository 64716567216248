import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  erc20Wallet: {
    state: "idle",
    address: null,
    chainId: null,
    name: null,
    error: null,
  },
  tronLinkWallet: {
    state: "idle",
    balance: null,
    address: null,
    chainId: null,
    name: null,
    error: null,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    sendErc20ConnectRequest: (state) => {
      state.erc20Wallet = {
        ...initialState.erc20Wallet,
        state: "loading",
      };
    },
    rejectErc20WalletRequest: (state, { payload }) => {
      state.erc20Wallet = {
        ...initialState.erc20Wallet,
        state: payload?.state || "rejected",
        error: payload?.error || payload,
      };
    },
    setErc20Wallet: (state, { payload }) => {
      state.erc20Wallet = { ...payload, state: "connected" };
    },
    setErc20Address: (state, { payload }) => {
      state.erc20Wallet = { ...state.erc20Wallet, address: payload };
    },
    changeErc20Address: (state, { payload }) => {
      state.erc20Wallet = { ...state.erc20Wallet, address: payload };
    },
    setErc20ChainId: (state, { payload }) => {
      state.erc20Wallet = { ...state.erc20Wallet, chainId: payload };
    },
    clearErc20Wallet: (state) => {
      state.erc20Wallet = { ...initialState.erc20Wallet };
    },

    sendTronLinkConnectRequest: (state) => {
      state.tronLinkWallet = {
        ...initialState.tronLinkWallet,
        state: "loading",
      };
    },
    rejectTronLinkWalletRequest: (state, { payload }) => {
      console.log({ payload });

      state.tronLinkWallet = {
        ...initialState.tronLinkWallet,
        state: payload?.state || "rejected",
        error: payload?.error || payload,
      };
    },
    setTronLinkWallet: (state, { payload }) => {
      state.tronLinkWallet = { ...payload, state: "connected" };
    },
    clearTronLinkWallet: (state) => {
      state.tronLinkWallet = { ...initialState.tronLinkWallet };
    },
  },
});

export const {
  setErc20ChainId,
  sendErc20ConnectRequest,
  rejectErc20WalletRequest,
  setErc20Wallet,
  clearErc20Wallet,
  setErc20Address,
  changeErc20Address,
  sendTronLinkConnectRequest,
  rejectTronLinkWalletRequest,
  setTronLinkWallet,
  clearTronLinkWallet,
} = authSlice.actions;
export default authSlice;
