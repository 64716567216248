
import {
    ButtonBase,
    CircularProgress,
    FormHelperText,
    InputBase,
    Typography,
    Box,
} from "@mui/material";

import { Controller } from "react-hook-form";

import AssetSelect from "./AssetSelect";
import { t } from "locales";
import { inputAutoFillStyles, numberInputStyle } from "assets/styles/styles";
// import noImage from 'assets/img/unknowncoin.png';
import { changePriceFormat } from "library/helper";


const SwapForm = ({
    title,
    control,
    options,
    image,
    walletDataObject,
    handleChangeCoin,
    currentCoin,
    wallets,
    swapPriceLoading,
    currentInputInfo,
    error,
    walletData,
    changeStep,
    checkAmount,
    setValue,
    mode,
}) => {
    // console.log({ currentInputInfo, auth: auth });
    return (
        <Box
            sx={{
                // display: "flex",
                // flexDirection: "column",
                // // bgcolor: (theme) =>
                // //   theme.palette.mode === "dark" ? "#111" : "#f6f0fa",
                // padding: "10px 20px",
                // borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                // bgcolor: (theme) =>
                //   theme.palette.mode === "dark" ? "#10141d" : "#f6f0fa",
                padding: "10px 20px",
                borderRadius: "12px",
                border: (theme) => (theme.palette.mode === "dark" ? "1px solid #363C4E" : "1px solid #E0E3EB"),
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body1">{title}</Typography>
                    <Box
                        sx={{ display: "flex", gap: 1, cursor: "pointer" }}
                        onClick={() => {
                            setValue(mode, wallets?.[mode]?.amount || 0);
                            checkAmount({ target: { value: wallets?.[mode]?.amount } })

                        }}
                    >
                        <Typography variant="body1">{t("balance")}</Typography>
                        <Typography variant="body1">
                            {wallets?.[mode]?.amount
                                ? changePriceFormat(wallets?.[mode]?.amount, 4)
                                : "0"}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>

                    <Box
                        sx={{
                            // bgcolor: (theme) => (theme.palette.mode === "dark" ? "rgba(128,80,200, 0.15)" : "rgba(128,80,200, 0.05)"),
                            transition: "all 0.1s ease-out",
                            padding: "4px 16px",
                            borderRadius: 2.5,
                            border: "1px solid transparent",
                            width: "100%",
                            // "&:hover": {
                            //   borderColor: (theme) => theme.palette.primary.main,
                            // },
                            display: "flex", alignItems: "center", gap: 2
                        }}
                    >

                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                img: {
                                    width: "32px",
                                    height: "32px",
                                    borderRadius: "50%",
                                },
                            }}
                        >


                            <img src={wallets?.[mode]?.icon || "/assets/images/no-image-circle.png"} key={image} alt="" />
                            <AssetSelect
                                options={options}
                                splited={0}
                                walletDataObject={walletDataObject}
                                disabled={swapPriceLoading}

                                onChange={(e, value) => {
                                    handleChangeCoin(value)
                                }}
                                value={currentCoin}

                                // inputValue={fromInputValue}
                                // onInputChange={handleFromInputChange}
                                sx={{
                                    width: 130,
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: 0,
                                        p: 0,
                                        borderColor: "transparent !important",
                                    },
                                    "& .MuiAutocomplete-endAdornment": {
                                        right: "0 !important",
                                    },
                                    "& .MuiInputBase-root": {
                                        pl: 0,
                                    },
                                    "& .Mui-focused": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: 0,
                                            p: 0,
                                            borderColor: "transparent !important",
                                        },


                                    },
                                }}
                            />
                        </Box>

                        {swapPriceLoading && currentInputInfo ? (
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    paddingTop: "8px",
                                    textAlign: "end"
                                }}
                            >
                                <CircularProgress size="25px" />
                            </Box>
                        ) :
                            <Box
                                sx={{
                                    bgcolor: (theme) => (theme.palette.mode === "dark" ? "rgba(128,80,200, 0.15)" : "rgba(128,80,200, 0.05)"),
                                    transition: "all 0.1s ease-out",
                                    padding: "4px 16px",
                                    borderRadius: 2.5,
                                    border: "1px solid transparent",
                                    "&:hover": {
                                        borderColor: "#8050c8",
                                    },
                                }}
                            >
                                <Controller
                                    control={control}
                                    name={mode}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <InputBase
                                            // autoFocus
                                            walletData={walletData}
                                            step={changeStep}
                                            name={mode}
                                            label={t(mode)}
                                            id={mode}
                                            type="number"
                                            onChange={(e) => {
                                                onChange(e)
                                                checkAmount(e)
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            ref={ref}
                                            placeholder={"0.0"}
                                            // sx={{
                                            //   ...inputAutoFillStyles,
                                            //   flexGrow: 1,
                                            //   input: {
                                            //     ...numberInputStyle,
                                            //     fontSize: "1.5rem",
                                            //     "::placeholder": {
                                            //       fontSize: "1rem",
                                            //       opacity: 0.5,
                                            //       margin: "auto",
                                            //     },
                                            //   },
                                            //   label: {
                                            //     fontSize: "1.2rem",
                                            //   },
                                            // }}
                                            sx={{
                                                ...inputAutoFillStyles,
                                                flexGrow: 1,
                                                input: {
                                                    textAlign: "end",
                                                    ...numberInputStyle,
                                                    fontSize: "1.3rem",
                                                    fontFamily: "poppins",
                                                    color: "#8050C8",
                                                    fontWeight: "700",
                                                    "::placeholder": {
                                                        opacity: 0.5,
                                                        margin: "auto",
                                                    },
                                                },
                                                label: {
                                                    fontSize: "1.2rem",
                                                },
                                            }}
                                        />
                                    )}
                                />

                            </Box>

                        }


                    </Box>

                </Box>

            </Box>

            {wallets?.[mode]?.options ? <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "0.8rem",
                    // color: (theme) => theme.palette.primary.main,
                    // fontFamily: "poppins",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "0.8rem", }} >  {t("min")}</Typography >
                    <Box sx={{ mx: "3px" }} />
                    <Typography sx={{ fontSize: "0.8rem", }}>  {changePriceFormat(wallets?.[mode]?.options?.min)}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "0.8rem", }} >   {t("max")}</Typography >
                    <Box sx={{ mx: "3px" }} />
                    <Typography sx={{ fontSize: "0.8rem", }}>  {changePriceFormat(wallets?.[mode]?.options?.max)}</Typography>
                </Box>
                {/* {"$" + getFixed(fromPriceInUsd, 4) || "$0.0"} */}
            </Box> : ""}
            {error && (
                <FormHelperText sx={{ color: "#f44336" }}>
                    {t(error?.message)}
                </FormHelperText>
            )}
        </Box>
    )
}
export default SwapForm;